import React, { Component } from 'react';
import {buttonStyle} from '../../DesignSystem';
import './button.css';
import Icon from '../icon/Icon';
import StateMouse from "../StateMouse";
import Help from '../helpMessage/help';

export default class Button extends StateMouse{

    stylesElem = {};

    render() {
        let icon = this.props.icon;
        let type = this.props.type;
        if (!type) {
            icon ? type="iconed" : type = "standard";
        }

        let view = this.props.view;
        if (!view) view = "button_filled";

        let layout = this.props.layout;
        if (!layout) layout = "layout_default";

        switch (type) {
            case "standard": {
                if (this.props.disabled == true){
                    this.stylesElem = Object.assign({}, buttonStyle.default_attrs, buttonStyle.button_standard_disabled[layout], this.props.style);
                } else {
                    this.stylesElem = Object.assign({}, buttonStyle[view][layout][this.state.mode], buttonStyle.default_attrs, this.props.style);
                }
            }
                break;
            case "iconed": {
                if (this.props.disabled == true){
                    this.stylesElem = Object.assign({},  buttonStyle.button_iconed_disabled[layout], buttonStyle.default_attrs_button_iconed);
                } else {
                    this.stylesElem = Object.assign({}, buttonStyle.default_attrs_button_iconed, buttonStyle.button_iconed[layout][this.state.mode], {...this.props.style});
                }
                /*this.stylesElem =  Object.assign({}, buttonStyle.default_attrs_button_iconed, buttonStyle.button_iconed[layout][this.state.mode] )*/
            }
                break;
        }

        let buttonProps = {
            href: this.props.href ? this.props.href : "",
            id: this.props.id,
            onClick: this.props.onClick,
            // disabled: this.props.disabled,
            className: "button button__" + this.props.view + " " + this.props.className + (icon ? " button__iconed" : "" ) + " " + (this.props.disabled ? " button_disabled" : "" ),
        };

        let elementButtonFunc = () => {
            let elem = null;

            if (icon && !this.props.disabled) {
                elem = <button {...buttonProps} {...this.mouseEvents} style={this.stylesElem}>
                            <span className={"btn_icon"}>
                                <Icon name={icon.name} width={icon.width}
                                      height={icon.height}
                                      fill={this.stylesElem.icon_color}
                                />
                            </span>
                            <span
                                style={{borderBottom: this.stylesElem.borderBottomSpan, color: this.stylesElem.color}}
                            >
                                {this.props.children}
                            </span>
                        </button>
            } else if (!this.props.disabled) {
                elem =  <button
                                {...buttonProps}
                                {...this.mouseEvents}
                                style={this.stylesElem}
                        >
                            {this.props.children}
                        </button>
            } else if (icon && this.props.disabled) {
                elem =  <div {...this.mouseEvents} className={'wrap-button'}>
                            <button
                                {...buttonProps}
                                style={this.stylesElem}
                            >
                                <span className={"btn_icon"}>
                                    <Icon name={icon.name} width={icon.width}
                                          height={icon.height}
                                          fill={this.stylesElem.icon_color}
                                    />
                                </span>
                                <span
                                    style={{borderBottom: this.stylesElem.borderBottomSpan, color: this.stylesElem.color}}
                                >
                                        {this.props.children}
                                </span>
                            </button>
                        </div>
            } else if (this.props.disabled) {
                elem =  <div {...this.mouseEvents} className={'wrap-button'}>
                            <button
                                {...buttonProps}
                                style={this.stylesElem}
                            >
                                {this.props.children}
                            </button>
                        </div>
            }

            return elem;
        };

        return (
            <React.Fragment>
                {
                    elementButtonFunc()
                }
                {
                    this.props.disabledMessage && this.props.disabled ?
                        <Help
                            text={ this.props.disabledMessage }
                            x={ this.state.x - pageXOffset  }
                            y={ this.state.y - pageYOffset + 20 }
                        />
                        : null
                }
            </React.Fragment>
        )
    }
}

