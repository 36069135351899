import React, {Component} from 'react'
import Heading from "../../designSystem/elements/text/Heading"
import Button from "../../designSystem/elements/button/Button"
import Tabs from "../../designSystem/elements/tabs/Tabs"
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Link from "../../designSystem/elements/links/Link";
import {connect} from "react-redux";
import {PromotionsList} from "../../actions/promotions";
import {clearSendPolicy, policyDownload, policyDownloadMemo, policySend} from "../../actions/polices";
import {fetchGetApplication, getApplication} from "../../actions/common";

class LegalInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            listLinks: [
                {title: "Контактная информация", to: "https://www.ugsk.ru/about/contacts/"},
                {title: "Сведения о руководителях страховщика", to: "https://www.ugsk.ru/about/top/"},
                {title: "Сведения об акционерах страховщика", to: "https://www.ugsk.ru/about/nash-aktsioner/"},
                {title: "Лицензии и свидетельства", to: "https://www.ugsk.ru/about/license/"},
                {title: "Правила страхования", to: "https://www.ugsk.ru/about/pravila/"},
                {title: "Официальные документы", to: "https://www.ugsk.ru/about/info/"},
                {title: "Рейтинги", to: "https://www.ugsk.ru/about/rate/"},
                {title: "Награды", to: "https://www.ugsk.ru/about/awards/"}
            ]
        };
        this.tabItems = [
            {title: "Юридическая информация", icon: {name: "page", width: "15px", height: "18px"}},
            {title: "Политика конфиденциальности", icon: {name: "secure", width: "15px", height: "18px"}}
        ];

        this.tabStyles = {
            zIndex: 1000,
        };
        this.companyInfo = {
            name: 'Акционерное общество «Государственная страховая компания «Югория»',
            address: '628011, Ханты-Мансийский автономный округ — Югра, Ханты-Мансийск, ул. Комсомольская, д. 61',
            phone: '8 (3467) 357-222',
            ogrn: '1048600005728'
        };
    }

    componentDidMount() {
        this.props.getApplication();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.application !== this.props.application) {
            this.setState({
                listLinks: [
                    {title: "Контактная информация", to: "https://www.ugsk.ru/about/contacts/"},
                    {title: "Сведения о руководителях страховщика", to: "https://www.ugsk.ru/about/top/"},
                    {title: "Сведения об акционерах страховщика", to: "https://www.ugsk.ru/about/nash-aktsioner/"},
                    {title: "Лицензии и свидетельства", to: "https://www.ugsk.ru/about/license/"},
                    {title: "Правила страхования", to: "https://www.ugsk.ru/about/pravila/"},
                    {title: "Официальные документы", to: "https://www.ugsk.ru/about/info/"},
                    {title: "Рейтинги", to: "https://www.ugsk.ru/about/rate/"},
                    {title: "Награды", to: "https://www.ugsk.ru/about/awards/"},
                    {title: "Соглашение на обработку персональных данных", to: this.props.application ? this.props.application.link : ''},
                ]
            })
        }
    }

    render() {
        return (
            <div className={"page"}>
                <div className="page_container textPage_container"
                     style={{paddingTop: spaces.l, paddingBottom: spaces.xl}}>
                    <Heading size={"l"} style={{marginTop: spaces.m}} screen_size={this.props.screen_size}>Юридическая
                        информация</Heading>
                    <div>
                        <TextElem>{this.companyInfo.name}</TextElem>
                    </div>
                    <div>
                        <TextElem>Почтовый адрес: {this.companyInfo.address}</TextElem>
                    </div>
                    <div>
                        <TextElem>Телефон: {this.companyInfo.phone}</TextElem>
                    </div>
                    <div>
                        <TextElem>ОГРН: {this.companyInfo.ogrn}</TextElem>
                    </div>
                    <div style={{marginTop: spaces.m}}>
                        {
                            this.state.listLinks.map((item, index) => {
                                return (
                                    <div key={index} style={{marginBottom: spaces.s}}>
                                        <Link func={() => {
                                        }} targetBlank={true} to={item.to}>{item.title}</Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        application: state.common.application
    }),
    dispatch => () => {
        return {
            getApplication: () => dispatch(getApplication()),
        }
    }
)(LegalInformation);