import React from 'react';
import Button from "../../designSystem/elements/button/Button";
import Input from "../../designSystem/elements/input/Input";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import Checkbox from "../../designSystem/elements/checkbox/Checkbox";
import Icon from "../../designSystem/elements/icon/Icon";
import Caption from "../../designSystem/elements/text/Caption";

export function ChangePersonalDataRequestModal ({ isChecked, onClick, onChange, screenSize, onChangeTextArea, errorMessage }) {
    let textareaHeight = "93px";
    (screenSize === "xs") ? textareaHeight = "132px" : "";
    let message = !!errorMessage ? 'К сожалению, при отправке запроса произошла ошибка. Пожалуйста, повторите попытку позже.' : false;

    return <div className={'ugsk-form-input-code'}>
        <div className={''}>
            <Heading size={"m"} style={{marginTop:0}} screenSize={screenSize}>Запрос на изменение личных данных</Heading>
            <div style={{marginBottom: spaces.m}}>
                <TextElem screenSize={screenSize}> Для смены имени и фамилии обратитесь к своему менеджеру по персоналу или оставьте заявку в форме ниже и мы свяжемся с вами</TextElem>
            </div>
            <div className={"checkbox-cols-wrap"}>
                <div><Checkbox onChange={onChange} name="surname">Фамилия</Checkbox></div>
                <div><Checkbox onChange={onChange} name="patronymic">Отчество </Checkbox> </div>
                <div><Checkbox onChange={onChange} name="firstName">Имя</Checkbox></div>
                <div><Checkbox onChange={onChange} name="birthDate">Дата рождения</Checkbox></div>
            </div>
            <div>
                <Input
                    typeInput={"textarea"}
                    height={textareaHeight}
                    style={{width: "100%", marginBottom: spaces.m}}
                    label={"Комментарий"}
                    placeholder={""}
                    onChangeTextArea={onChangeTextArea}
                    textareaMaxLength={"1000"} />
            </div>
            <div className={'personal-data-btn-wrap'}>
                <Button disabled={!isChecked} onClick={onClick}>Запросить</Button>
                {!!message ?
                    <div
                        className={'personal-data-error-message'}
                        style={{
                            color: 'rgb(236, 27, 35)',
                            fontSize: '13px',
                            lineHeight: '19px',
                            fontWeight: 'normal'
                            }}>
                        {message}
                    </div>
                : null}
            </div>
        </div>

    </div>;
}

export function SucceedRequestModal({ onClick, screenSize, title = "Запрос на изменение личных данных", caption}) {
    return <div className={'ugsk-form-change-person-date'}>
        <div className={ 'ugsk-form-change-person-date__complete-request-change ugsk-complete-request-change' }>
            <Heading size={"m"} style={{marginTop: 0}} screenSize={screenSize}>{title}</Heading>
            <div className={'ugsk-complete-request-change__text-icon'}>
                <Icon
                    name={'success'}
                    className={'ugsk-complete-request-change__icon'}
                    fill={ colors.green }
                />
                <TextElem
                    className={'ugsk-complete-request-change__text'}
                    color={colors.green}
                >Запрос отправлен</TextElem>
            </div>
            {caption ? <Caption
                className={'ugsk-complete-request-change__caption'}
                color={colors.gray}
            >{caption}</Caption> : null}
            <Button onClick={onClick} className={'ugsk-complete-request-change__btn'} view={"button_bordered"}>Готово</Button>
        </div>
    </div>;
}

export function EmailConfirmationModal ({ isChecked, onClick, onChange, screenSize, value, error_text, onBlur, onFocus, inputOnKeyDown }) {

    return <div className={'ugsk-form-input-code'}>
        <div className={'ugsk-form-change-person-date'}>
            <Heading size={"m"} style={{marginTop:0}} screenSize={screenSize}>Подтверждение e-mail</Heading>
            <Input
                typeInput='input'
                type='email'
                label='Email'
                width='100%'
                placeholder='email@mail.ru'
                name={'confirmEmailModal'}
                value={value}
                style={{marginBottom: spaces.m}}
                onChange={onChange}
                onBlur={(e)=>{onBlur(e, 'confirmEmail')}}
                error_text={error_text}
                onFocus={onFocus}
                inputOnKeyDown={(e)=>{inputOnKeyDown(e, 'confirmEmailModal')}}
                className='ugsk-form-request-access__input ugsk-form-request-access__input_password' />
            <Button disabled={isChecked} onClick={()=>{onClick('modal')}} onChange={onChange}>Запросить ссылку</Button>
        </div>
    </div>;
}

export function EmailConfirmationSuccessfullySentModal ({ onClick, screenSize, }) {

    return <div className={'ugsk-form-input-code'}>
        <div className={'ugsk-form-change-person-date'}>
            <Heading size={"m"} style={{marginTop:0}} screenSize={screenSize}>Подтверждение успешно отправлено</Heading>
            <div className={'ugsk-complete-request-change__text-icon'}>
                <Icon
                    name={'success'}
                    className={'ugsk-complete-request-change__icon'}
                    fill={ colors.green }
                />
                <TextElem
                    className={'ugsk-complete-request-change__text'}
                    color={colors.green}
                >Запрос отправлен</TextElem>
            </div>
            <Caption
                className={'ugsk-complete-request-change__caption'}
                color={colors.gray}
            >На указанную вами электронную почту отправлена ссылка. Пожалуйста, пройдите по ней в течение 5 минут с момента настоящего запроса</Caption>
            <Button onClick={onClick} className={'ugsk-complete-request-change__btn'} view={"button_bordered"}>Готово</Button>
        </div>
    </div>;
}