import React, {Component} from "react";

import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import ComponentWithCaption from "../../components/Settings/ComponentWithCaption/ComponentWithCaption";
import StyledLink from "../../designSystem/elements/links/Link";
import Icon from "../../designSystem/elements/icon/Icon";
import Cards from "../../designSystem/elements/cards/Cards";

export default class Policies extends Component {
    render(){

        let {
            screenSize,
            stylesTitle,
            stylePolis,
            infoPolis
        } = this.props;

        return <div className='ugsk-settings-page__container ugsk-settings-page__container_archive-policies'>
            <div className='page_container'>
                <div className="wrap-tab-item">
                    <Heading
                        size='l'
                        className='ugsk-settings-page__title'
                        style={ stylesTitle }
                        screen_size={screenSize}
                    >Архивные полисы</Heading>
                    <div className='ugsk-settings-page__list-polises'>
                        {infoPolis.map((item) => <div key={item.number} className='ugsk-settings-page__item-polis'>
                            <Cards
                                type="polis"
                                info={  item }
                                screen_size={screenSize}
                                style={ stylePolis }
                            />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    }
}