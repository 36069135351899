import React, { Component } from 'react';
import Icon from "../../designSystem/elements/icon/Icon";
import Link from "../../designSystem/elements/links/Link";
import { colors, fontSizes } from '../../designSystem/DesignSystem';
import './CookieNotification.css';

class CookieNotification extends Component {

    constructor(props){
        super(props);

        this.state = {
            message: "Сайт использует файлы cookie. Они позволяют узнавать Вас и&nbsp;получать информацию о&nbsp;Вашем пользовательском опыте. Это нужно, чтобы улучшать сайт и&nbsp;предлагать Вам наилучшие условия обслуживания. Посещая страницы сайта и&nbsp;предоставляя свои данные, Вы&nbsp;разрешает нам предоставлять их&nbsp;сторонним партнерам. Если согласны, продолжайте пользоваться сайтом. Если нет&nbsp;&mdash; установите специальные настройки в&nbsp;браузере или обратитесь в&nbsp;техподдержку. ",
            link: "https://www.ugsk.ru/about/personaldata/index.php?sphrase_id=2290596",
        }
    }

    render() {

        let { message, link } = this.state;
        let { cookieClick } = this.props;

        return (
            this.props.show === true ?
            <React.Fragment>
                <div className='cookie-notification'>
                    <Icon
                        className="icon-cookie"
                        name="icon-cookie"
                        fill={ colors.extra_light_gray }
                    />
                    <div className="cookie-notification-container">
                        <span className="cookie-notification-txt_dscr"
                              style={{
                                  fontSize: fontSizes.regular.default.size,
                                  lineHeight: fontSizes.regular.default.lh
                              }}
                              dangerouslySetInnerHTML={{ __html: message }}
                        />
                        <Link to={ link } typeLink="external" targetBlank={true}>
                            Подробнее
                        </Link>
                        <div className="cookie-notification-close" onClick={ cookieClick }>
                            <Icon
                                className="icon-close"
                                name="close-light"
                                fill={colors.gray}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment> : null
        )
    }
}

export default CookieNotification;