import React, {Component} from 'react';

import Input from "../../../../../designSystem/elements/input/Input";
import {colors} from "../../../../../designSystem/DesignSystem";
import Button from "../../../../../designSystem/elements/button/Button";

import './PhoneInputCode.css';
import Icon from "../../../../../designSystem/elements/icon/Icon";
import StyledLink from "../../../../../designSystem/elements/links/Link";
import {phoneMask} from "../../../../../tools/mask-helper";
import Caption from "../../../../../designSystem/elements/text/Caption";
import Timer from "../../../../Timer/Timer";

const getStylesCaption = (screenSize) => {

    return {
        display: 'block',
        color: colors.gray,
        marginTop: '5px'
    }
};

const getStylesInput = (screenSize) => {

    return {
        width: '100%',
        pointerEvents: 'none'
    }
};

export function onClickRequest(type, onChangeConfirmSmsRequest, sendConfirmPhoneRequest){
    if (type === 'code-validate-old') {
        sendConfirmPhoneRequest();
    } else {
        onChangeConfirmSmsRequest(type);
    }
}

export function decOfNum(number, titles) {
    var decCache = [],
    decCases = [2, 0, 1, 1, 1, 2];

    if(!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
    return titles[decCache[number]];
}

export default class FormEditPhone extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTimer: true
        }
    }

    setShowTimer = (showTimer) => {
        this.setState({showTimer})
    }

    render() {

        const {
            screenSize,
            onChangePhoneOld,
            inputPhoneOnKeyDown,
            errorTxtPhoneValidation,
            onBlurPhone,
            onChangeSmsCode,
            errorTxtSmsCodeValidation,
            onBlurValues,
            onChangeConfirmSmsRequest,
            confirmPhoneNumber,
            handlerGetCodeBtn,
            closeFormEdit,
            setActionDefault,
            requestAction,
            sendConfirmPhoneRequest,
            clearChangePhoneData
        } = this.props;

        let actionRequestCode = '';
        let label = "Номер телефона"
        if (requestAction === 'code-validate') {
            actionRequestCode = 'code-send';
            label = "Новый номер телефона, который Вы хотите установить";
        } else if (requestAction === 'code-validate-old') {
            actionRequestCode = 'code-send-old';
            label = "Старый номер телефона, по которому Вы входили в личный кабинет"
        }
        return (
            <React.Fragment>
                <div className={'ugsk-phone-form-edit__actions_container ugsk-phone-form-edit_code__actions_container'+(requestAction === 'code-validate-old' ? ' ugsk-phone-form-edit_code_old__actions_container' : '')}>
                    <div className='ugsk-phone-form-edit__inputs'>
                        <div className='ugsk-phone-form-edit__input'>
                            <Input
                                style={getStylesInput(screenSize)}
                                type="text"
                                label={label}
                                mask={phoneMask}
                                placeholder="+7 (___) ___-__-__"
                                name={'confirmOldPhoneNumber'}
                                onChange={onChangePhoneOld}
                                error_text={errorTxtPhoneValidation}
                                inputOnKeyDown={(e) => {
                                    inputPhoneOnKeyDown(e, 'confirmOldPhoneNumber')
                                }}
                                onBlur={(e) => {
                                    onBlurValues(e, 'onBlurPhone')
                                }}
                                value={confirmPhoneNumber && confirmPhoneNumber.confirmPhoneNumber}
                                disabled="disabled"
                            />
                        </div>
                        <div className='ugsk-phone-form-edit__input_code'>
                            <Input
                                typeInput={'input'}
                                type='text'
                                label={'Код из SMS'}
                                name={'code'}
                                placeholder={''}
                                onChange={onChangeSmsCode}
                                error_text={errorTxtSmsCodeValidation}
                                onBlur={(e) => {
                                    onBlurValues(e, 'confirmSms')
                                }}
                            />
                        </div>
                                {this.state.showTimer ? <div className='ugsk-phone-form-edit__timer'>
                                        <Timer  text="Повторно запросить код можно через" onTimerEnd={() => this.setShowTimer(false)}/>
                                    </div>:
                                    <div className='ugsk-phone-form-edit__link-icon'>
                                        <Icon className={'ugsk-form-input-code__icon'} name={'rotate-ccw'}/>
                                        <StyledLink func={() => {
                                            handlerGetCodeBtn(actionRequestCode);
                                            this.setShowTimer(true);
                                        }}
                                                    className='ugsk-form-input-code__link'>
                                            Выслать еще раз
                                        </StyledLink>
                                    </div>}
                        {/*<div className={'ugsk-timer-wrap'}>{timerValue}{decOfNum(timerValue, [' секунда', ' секунды', ' секунд'])}</div> }*/}
                    </div>
                    <div className="ugsk-phone-form-edit__buttons">
                        <Button
                            className='ugsk-phone-form-edit__btn-ready'
                            onClick={() => {
                                onClickRequest(requestAction, onChangeConfirmSmsRequest, sendConfirmPhoneRequest)
                            }}
                        >Готово
                        </Button>
                        <Button className='ugsk-phone-form-edit__btn-cancel' view={"button_bordered"} onClick={() => {
                            closeFormEdit();
                            this.setShowTimer(false);
                            setActionDefault('code-send');
                            clearChangePhoneData();
                        }}>Отменить</Button>
                    </div>
                </div>
                <Caption className='ugsk-phone-form-edit__caption' style={getStylesCaption(screenSize)}>
                    <p>Для изменения, пожалуйста, подтвердите:</p>
                    <p><span style={{marginRight: '8px'}}>•</span>новый номер телефона, который Вы хотите установить</p>
                    <p><span style={{marginRight: '8px'}}>•</span>старый номер телефона, по которому Вы входили в личный
                        кабинет</p>
                </Caption>
            </React.Fragment>
        );
    }
};