import React, {Component} from "react";

import Heading from "../../designSystem/elements/text/Heading";
import Button from "../../designSystem/elements/button/Button";
import StyledLink from "../../designSystem/elements/links/Link";
import Caption from "../../designSystem/elements/text/Caption";
import Input from "../../designSystem/elements/input/Input";
import {spaces} from "../../designSystem/DesignSystem";
import CreateNewPassword from "../Authorization/CreateNewPassword/CreateNewPassword";

export default class Password extends Component {

    constructor(props){
        super(props);

        this.state = {
            changePassword: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.changePasswordSuccess != nextProps.changePasswordSuccess && nextProps.changePasswordSuccess) {
            this.setState({changePassword: false});
        }
    }


    handlerShowFormChangePassword = () => {
        this.setState({
            changePassword: true
        })
    };

    render(){

        let {
            screenSize,
            stylesTitle,
            stylesInput,
            stylesCaption,
            onChange = () => {},
            onBlur = () => {},
            onFocus = () => {},
            onSubmit = (e) => {e.preventDefault()},
            errors = {},
            onClickLink = () => {},

            firstPasswordRule,
            secondPasswordRule,
            thirdPasswordRule,
            progressBarCount,
            newPasswordValue
        } = this.props;
        
        let inputProps = {
            screen_size: screenSize,
            style: stylesInput,
            typeInput: "input",
            type: "password",
            onChange
        };

        let errorStyle = {position: "relative", bottom: 0};

        return <div className='ugsk-settings-page__container ugsk-settings-page__container_password'>
            <div className='page_container'>
                <div className="wrap-tab-item">
                    <Heading
                        size='l'
                        className='ugsk-settings-page__title'
                        style={ stylesTitle }
                        screen_size={screenSize}
                    >Пароль</Heading>
                    {
                        this.state.changePassword
                            ?
                            <form onSubmit={onSubmit} className='ugsk-settings-page__change-password-form ugsk-change-password-form'>
                                <div className="ugsk-create-new-password__input_container">
                                    <Input {...inputProps}
                                           label="Старый пароль"
                                           name="oldPassword"
                                           error_text={errors.oldPassword}
                                           errorStyle={errorStyle}
                                           onBlur={onBlur}
                                           onFocus={onFocus}
                                    />
                                    <StyledLink className={'ugsk-change-password-form__link'} func={onClickLink} screen_size={screenSize} >Забыли пароль?</StyledLink>
                                </div>
                                <CreateNewPassword
                                    errors={errors}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    onSubmit={onSubmit}
                                    passwordValue={newPasswordValue}
                                    firstPasswordRule={firstPasswordRule}
                                    secondPasswordRule={secondPasswordRule}
                                    thirdPasswordRule={thirdPasswordRule}
                                    progressBarCount={progressBarCount}
                                    onClick={onSubmit}
                                    fieldsOnly={true}
                                />
                                <div className={'ugsk-changep-password-form__btns'}>
                                    <Button className={ 'ugsk-change-password-form__ready' }  screen_size={screenSize} onClick={ onSubmit }>Подтвердить</Button>
                                    <Button className={'ugsk-change-password-form__cancel'}  screen_size={screenSize} onClick={ ()=>{this.setState({changePassword: false})}}  view={"button_bordered"}>Отменить</Button>
                                </div>
                            </form>
                            : <Button className={'ugsk-settings-page__btn-change-password'}  screen_size={screenSize} onClick={ this.handlerShowFormChangePassword }>Сменить пароль</Button>
                    }
                </div>
            </div>
        </div>
    }
}