import React, { Component } from 'react';
import {PaginationStyle} from '../../DesignSystem'
import './pagination.css'
import StateMouse from "../StateMouse";


export default class Pagination extends StateMouse {

    constructor(props) {
        super(props);
        let active= this.props.active;
        let mode = "normal_mode";
        active ?  mode="active_mode" : "";
        this.state = {
            mode: mode
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.active != this.props.active) {
            this.setState({mode: nextProps.active ? "active_mode" : "normal_mode"}, () => {

            });
        }
    }

    render() {

        let {
            active,
            onClick = () => {}
        } = this.props;

        let stylesElem =  Object.assign({}, PaginationStyle.default_attrs, PaginationStyle.mode_attrs[this.state.mode]);

        return (
            <a href={()=>{return false}} onClick={onClick} className={"page-number"} style={stylesElem} {...(active ? {} : this.mouseEvents)}>
                {this.props.children}
            </a>
        )
    }
}

