import React, { Component } from 'react';

import Input from "../../../designSystem/elements/input/Input";
import Button from "../../../designSystem/elements/button/Button";

import {spaces} from '../../../designSystem/DesignSystem';

class FormRequestAccess extends Component {

    getStyleInput = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            case 'm':
            case 's':
            case 'xs': {
                return {
                    marginBottom: spaces.m
                }
            }
        }
    };

    render() {

        let { className, screenSize } = this.props;

        return (
            <div className={'ugsk-form-request-access ' + className }>
                <Input
                    typeInput='input'
                    type='text'
                    label='Номер телефона'
                    width='100%'
                    placeholder='+7 (___) ___-__-__'
                    className='ugsk-form-request-access__input ugsk-form-request-access__input_login'
                    style={ this.getStyleInput(screenSize) }
                />
                <Input
                    typeInput='input'
                    type='email'
                    label='Email'
                    width='100%'
                    placeholder=''
                    className='ugsk-form-request-access__input ugsk-form-request-access__input_password'
                    style={ this.getStyleInput(screenSize) }
                />
                <div className='ugsk-form-request-access__btn'>
                    <Button>Запросить доступ</Button>
                </div>
            </div>
        )
    }
}

export default FormRequestAccess;
