import React, { Component } from 'react';
import Input from "../../../designSystem/elements/input/Input";
import {phoneMask, birthDateMask} from "../../../tools/mask-helper";
import AuthActions from "../AuthActions/AuthActions";
import './SignUp.css';
import Checkbox from "../../../designSystem/elements/checkbox/Checkbox";
import SimpleHeader from "../../SimpleHeader/SimpleHeader";
import {InputStyle} from "../../../designSystem/DesignSystem";
import Caption from "../../../designSystem/elements/text/Caption";

class SignUp extends Component {
    render() {
        const {
            onSubmit = (e) => {e.preventDefault()},
            onClick = () => {},
            onChange = (e, type) => {},
            onBlur = (e, type) => {},
            onFocus = (e, type) => {},
            errors = {},
            onCheckboxChange,
        } = this.props;

        let commonError = errors.surname && (errors.surname === errors.name) && (errors.surname === errors.birthday) ? errors.surname : ""

        return (
            <form onSubmit={onSubmit}>
                <div className="ugsk-sign-up__header_container">
                    <SimpleHeader size="h2">Регистрация</SimpleHeader>
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        errorAction={onClick}
                        typeInput='input'
                        type='tel'
                        label='Номер телефона'
                        width='100%'
                        placeholder='+7 (___) ___-__-__'
                        name="phone_number"
                        mask={phoneMask}
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.phone_number}
                    />
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        typeInput='input'
                        type='text'
                        label='Номер полиса ДМС'
                        width='100%'
                        name="policy_number"
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.policy_number}
                    />
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        typeInput='input'
                        type='text'
                        label='Фамилия'
                        width='100%'
                        name="surname"
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.surname}
                        errorStyle={commonError ? {display: 'none'} : {}}
                    />
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        typeInput='input'
                        type='text'
                        label='Имя'
                        width='100%'
                        name="name"
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.name}
                        errorStyle={commonError ? {display: 'none'} : {}}
                    />
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        typeInput='input'
                        type='text'
                        label='Отчество'
                        width='100%'
                        name="patronymic"
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.patronymic}
                    />
                </div>
                <div className="ugsk-sign-up__input__container">
                    <Input
                        typeInput='input'
                        type='text'
                        inputmode='decimal'
                        label='Дата рождения'
                        placeholder="дд.мм.гггг"
                        mask={birthDateMask}
                        width='100%'
                        name="birthday"
                        onChange={(ev) => onChange(ev, 'signUpValues')}
                        onBlur={(ev) => onBlur(ev, 'register')}
                        onFocus={(ev) => onFocus(ev, 'register')}
                        error_text={errors.birthday}
                        errorStyle={commonError ? {display: 'none'} : {}}
                        className="input_birthday"
                    />
                </div>
                <div className='ugsk-sign-up__wrap_checkbox__container'>
                    <div className='ugsk-sign-up__checkbox__container'>
                        <Checkbox error_text={errors.agreement} onChange={onCheckboxChange}>
                            {/* Согласие на обработку персональных данных1 */}
                            Я согласен на&ensp;
                            <a
                                className='ugsk-sign-up__checkbox__link_text'
                                href='https://www.ugsk.ru/about/personalagree/'
                                target="_blank"
                            >
                                обработку Персональных данных
                            </a> 
                            &ensp;и ознакомлен с&ensp;
                            <a
                                className='ugsk-sign-up__checkbox__link_text'
                                href='https://ugsk.ru/about/personaldata/Politika_PND_new.pdf'
                                target="_blank"
                            >
                                Политикой конфиденциальности
                            </a>
                            
                        </Checkbox>
                    </div>
                </div>
                {commonError || errors.recaptcha ? <div className='ugsk-sign-up__checkbox__container'>
                    <Caption className="input__error" style={{color: InputStyle.layout_default.error_mode.errColor}}>
                        <div dangerouslySetInnerHTML={{__html: commonError || errors.recaptcha}} />
                    </Caption>
                </div> : null}
                <div className='ugsk-sign-up__actions__container'>
                    <AuthActions
                        onSubmit={onSubmit}
                        typeForBlueBtn="signUp"
                        whiteBtnLabel="Назад"
                        type="formAuth"
                        blueBtnLabel="Далее"
                        onClick={onClick}
                    />
                </div>
            </form>
        )
    }
}

export default SignUp;