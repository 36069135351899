import {checkErrors, errorMessage, fetchApi, checkErrorsDataPersonal} from "../tools/api-helper";
import {
    call,
    put,
    select,
    take,
    fork
} from 'redux-saga/effects';
import {parseParams, passwordError} from "../tools/form-helper";

const client = state => state.client;

export function me() {
    return {
        type: "ME"
    }
}

export function* fetchMe(action) {

    try {
        // let clientState = yield select(client);

        // if (clientState.token) {
        //     yield put(checkErrors(action.type, clientState, 200));
        // } else {
        const { status, data, error } = yield call( () => {
            return fetchApi("/me", {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }
        // }


    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function changePassword(data, isRecovery = false) {
    return {
        type: "CHANGE_PASSWORD",
        data,
        isRecovery
    }
}

export function* fetchChangePassword(action) {

    let errors = [], err = "Поле обязательно для заполнения";
    if (!action.data) {
        errors.push({field: 'oldPassword', message: err});
        errors.push({field: 'newPassword', message: err});
        errors.push({field: 'repeatPassword', message: err});
    } else {
        let data = parseParams(action.data)
        if (!action.isRecovery) {
            err = passwordError(data.oldPassword);
            if (err) {
                errors.push({field: 'oldPassword', message: err})
            }
        }
        err = passwordError(data.newPassword);
        if (err) {
            errors.push({field: 'newPassword', message: err})
        }
        err = passwordError(data.repeatPassword);
        if (err) {
            errors.push({field: 'repeatPassword', message: err})
        }
    }


    if (errors.length) {
        yield put(checkErrors(action.type, errors, 422));
    } else {
        try {

            const { status, data, error } = yield call( () => {
                return fetchApi("/password", {
                    method: 'PUT',
                    body: action.data
                })
            });

            if (error) {
                yield put(checkErrors(action.type, {message: error}, 500));
            } else {
                yield put(checkErrors(action.type, data, status));
            }


        } catch(e) {
            yield put(checkErrors(action.type, {message: errorMessage}, 500));
        }
    }

}

export function clearChangePersonalData(){
    return {
        type: "CHANGE_PERSONAL_DATA_CLEAR"
    }
}

export function clearChangePhoneData(){
    return {
        type: "CHANGE_PHONE_DATA_CLEAR"
    }
}

export function changePersonalData(data) {
    return {
        type: "CHANGE_PERSONAL_DATA",
        data
    }
}

export function* fetchChangePersonalData(action) {

    const {status, data, error} = yield call(() => {
        return fetchApi("/personal-request", {
            method: 'POST',
            body: action.data
        })
    });

    if (status === 200){
        yield put(checkErrors(action.type, data, 200));
    } else {
        yield put(checkErrors(action.type, {errorMessage: true}, 'requesErrPersonalData'));
    }
}

export function changeAddress(data) {
    return {
        type: "CHANGE_ADDRESS",
        data
    }
}

export function* fetchChangeAddress(action) {

    const { status, data, error } = yield call( () => {
        return fetchApi("/address", {
            method: 'PUT',
            body: action.data
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

export function changeEmail(data, params) {
    return {
        type: "CHANGE_EMAIL",
        data,
        params
    }
}

export function* fetchChangeEmail(action) {

    const {status, data, error} = yield call(() => {
        return fetchApi("/email?action="+action.params, {
            method: 'POST',
            body: action.data
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        let shouldCheckErrors = true;
        if (action.params === 'confirm') {
            if (status === 200) {
                yield put({type: "SET_EMAIL", email: action.data.get("email")})
            }
            if (status === 422) {
                let clientState = yield select(client);
                if (clientState.email === action.data.get("email") && clientState.is_email_confirmed) {
                    shouldCheckErrors = false;
                    yield put({type: "CHANGE_EMAIL_RESEND", data})
                }
            }
        }
        if (shouldCheckErrors) {
            yield put(checkErrors(action.type, data, status));
        }
        yield put({type: "RESET_EMAIL_FORM"})
    }
}

export function changePhoneRequest(data, params) {
    switch (params) {
        case 'code-send':
            return {
                type: "CHANGE_PHONE_CODE_SEND",
                data,
                params
            }
        case 'code-validate':
            return {
                type: "CHANGE_PHONE_CODE_VALIDATE",
                data,
                params
            }
        case 'code-send-old':
            return {
                type: "CHANGE_PHONE_CODE_SEND_OLD",
                data,
                params
            }
        case 'code-validate-old':
            return {
                type: "CHANGE_PHONE_CODE_VALIDATE_OLD",
                data,
                params
            }
        case 'code-validate-recovery':
            return {
                type: "CHANGE_PHONE_CODE_VALIDATE_RECOVERY",
                data,
                params
            }
        case 'code-send-recovery':
            return {
                type: "CHANGE_PHONE_CODE_SEND_RECOVERY",
                data,
                params
            }

    }
    return null;
}

export function* fetchChangePhone(action) {

    const {status, data, error} = yield call(() => {
        return fetchApi("/phone?action=" + action.params, {
            method: 'POST',
            body: action.data
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        if (status === 422) {
            switch (action.params) {
                case 'code-send':
                    yield put(checkErrors(action.type, {[data[0].field]: data[0].message }, 'validPhoneNumber'));
                    break;
                case 'code-validate':
                    yield put(checkErrors(action.type, data, 'errorRequestSms'));
                    break;
                case 'code-send-old':
                    yield put(checkErrors(action.type, data, 'errorRequestPhoneOld'));
                    break;
                case 'code-validate-old':
                    yield put(checkErrors(action.type, data, 'errorRequestValidateOld'));
                    break;
                default:
                    yield put(checkErrors(action.type, data, status));
            }
        } else if (status === 400) {
            switch (action.params) {
                case 'code-send':
                case 'code-send-old':
                    yield put(checkErrors(action.type, [{field: "phone_number", message: data.message}], 422));
                    break;
                default:
                    yield put(checkErrors(action.type, data, status));
            }
        } else {
            yield put(checkErrors(action.type, data, status));
        }
    }
}

export function sendReview(data) {
    return {
        type: "SEND_REVIEW",
        data
    }
}

export function sendReviewClear() {
    return {
        type: "SEND_REVIEW_CLEAR"
    }
}

export function* fetchSendReview(action) {

    const { status, data, error } = yield call( () => {
        return fetchApi("/review", {
            method: 'POST',
            body: action.data
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}