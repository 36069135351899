import React, { Component } from 'react';
import StateMouse from "../StateMouse";
import {TabStyle, fonts, LinkStyle} from "../../DesignSystem";
import Icon from "../icon/Icon";
import {NavLink as ReactLink} from "react-router-dom";


export default class TabSwitchItem extends StateMouse {

    constructor(props) {
        super(props);
        let mode="normal_mode";
        if (this.props.active===true) mode="active_mode";
        this.state={
            mode: mode,
            prevMode: "normal_mode",
        }
    }

    componentDidMount() {
        this.mouseEvents.onMouseUp = ()=>{};
        this.mouseEvents.onMouseDown = ()=>{};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
           if ((this.props.active === true)&&(this.state.mode!="active_mode")){
            this.setState((prevState)=>{
                return {mode: "active_mode"}
            });
        }
        if ((this.props.active === false)&&(this.state.mode==="active_mode")){
            this.setState((prevState)=>{
                return {mode: "normal_mode"}
            });
        }
    }



    renderItem = ()=> {

        let styleSwitcher = this.props.styleSwitcher;
        let typeSwitcher = this.props.typeSwitcher;
        let styles = {};
        let result="";

        switch(styleSwitcher){
            case "iconed":
            {
                styles =  Object.assign({}, TabStyle.iconed.common_attrs, TabStyle.iconed[this.state.mode], fonts.text.body );


                result = <div className={"tabSwitchItem tabSwitchItem__iconed "+ (this.props.active ? "active" : "")} {...this.mouseEvents} style={styles} onClick={()=>{this.props.onToggleTab(this.props.index)}}>
                    <Icon className={"tabSwitchItem__icon"} style={{ fill: TabStyle.iconed[this.state.mode].fill }} name={this.props.children.icon.name} width={this.props.children.icon.width} height={this.props.children.icon.height} />
                    <div className={"text"} > {this.props.children.title} </div>
                </div>;




            }
                break;
            case "underlined":
            {
                styles =  Object.assign({}, TabStyle.underlined.common_attrs, TabStyle.underlined[this.state.mode], fonts.text.body);
                result = typeSwitcher === "url" ? <ReactLink to={this.props.children.to ? this.props.children.to : "#"}
                                                             exact={true}
                                                             activeClassName="active"
                                                             className="tabSwitchItem"
                                                             {...this.mouseEvents}
                                                             style={{...styles, ...LinkStyle.default_attrs}}
                                                             activeStyle={TabStyle.underlined.active_mode}
                    >
                        {this.props.children.title}
                </ReactLink> :
                    <div className={"tabSwitchItem "+ (this.props.active ? "active" : "")} {...this.mouseEvents} style={styles} onClick={()=>{this.props.onToggleTab(this.props.index)}}>
                    {this.props.children.title}
                </div>;
            }
                break;
        }
        return result;
    };

    render() {
        return (
           <React.Fragment>
               {this.renderItem()}
           </React.Fragment>
        )
    }
}



