import React, { Component } from 'react';
import Icon from "../icon/Icon";
import Heading from "../text/Heading";


export default class BreakpointsVisual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screen_size: this.props.screen_size,
            breakpoints: {

                xs: {
                    img: "320",
                    title: "320",
                    text: "320 - 600"
                },
                s: {
                    img: "768",
                    title: "768",
                    text: "600 - 960"
                },
                m: {
                    img: "1024",
                    title: "1024",
                    text: "960 - 1200"
                },
                l: {
                    img: "1440",
                    title: "1440",
                    text: "1200 - 1920"
                },
                xl: {
                    img: "1440",
                    title: "2560",
                    text: "1920 - ..."
                }
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            screen_size: nextProps.screen_size
        })
    }



    render() {

        return (
            <div className={"breakpoints"}>
                <div className={"wrap"}>
                    <Icon name={this.state.breakpoints[this.state.screen_size].img} width={"30px"} height={"30px"}/>
                    <Heading size={"s"}>{this.state.breakpoints[this.state.screen_size].title}</Heading>
                    <div className="text">
                        {this.state.breakpoints[this.state.screen_size].text}
                    </div>
                </div>
            </div>


        )
    }
}

