import React, {Component} from "react";

import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import ComponentWithCaption from "../../components/Settings/ComponentWithCaption/ComponentWithCaption";
import StyledLink from "../../designSystem/elements/links/Link";
import Icon from "../../designSystem/elements/icon/Icon";

import {phoneMask, prettyDateMask} from "../../tools/mask-helper";

export default class Personal extends Component {
    render(){

        let {
            onClickLink,
            data = {},
            canEdit = true,
            screenSize,
            stylesTitle,
            stylesName,
            styleLinkEdit,
            onChange,
            valueInputAddress,
            disabledBtnReady,
            onChangeAddress,
            changeAddressSuccess,
            onChangeEmail,
            error_text,
            clearErrorTxt,
            onBlurEmail,
            onFocusEmail,
            onChangeEmailDataRequest,
            changeEmailSuccess,
            inputOnKeyDown,
            onChangePhone,
            inputPhoneOnKeyDown,
            errorTxtPhoneValidation,
            onBlurPhone,
            changePhoneRequest,
            onChangePhoneOld,
            onChangeSmsCode,
            errorTxtSmsCodeValidation,
            onChangeConfirmSmsRequest,
            onBlurValues,
            confirmPhoneNumber,
            requestAction,
            setActionDefault,
            sendConfirmPhoneRequest,
            clearChangePhoneData
        } = this.props;

        return <div className='ugsk-settings-page__container ugsk-settings-page__container_personal-data' >
            <div className='page_container'>

                <div className="wrap-tab-item">
                    <Heading
                        size='l'
                        className='ugsk-settings-page__title'
                        style={ stylesTitle }
                        screen_size={screenSize}
                    >Личные данные</Heading>
                    <div className="ugsk-settings-page__row-title">
                        <Heading
                            size='m'
                            className='ugsk-settings-page__name'
                            style={ stylesName }
                            screen_size={screenSize}
                        >{data.surname + " " + data.name +(data.patronymic ? " " + data.patronymic : "")}</Heading>

                        {canEdit ? <div className='ugsk-settings-page__link'>
                            <Icon
                                className='ugsk-settings-page__icon'
                                name='pencil-edit'
                            />
                            <div className='ugsk-settings-page__link_edit'>
                                <StyledLink func={onClickLink}>Запросить изменение личных данных</StyledLink>
                            </div>

                        </div> : null}

                    </div>
                    <div className='ugsk-settings-page__user-date'>
                        <div className='ugsk-settings-page__user-date-fields'>
                            <div className='ugsk-settings-page__user-data-block'>
                                <div className='ugsk-settings-page__date-birth'>
                                    <ComponentWithCaption
                                        title={"Дата рождения"}
                                        screenSize={ screenSize }
                                    >
                                        <TextElem>{prettyDateMask(data.birthday)}</TextElem>
                                    </ComponentWithCaption>
                                </div>
                                <div className="ugsk-settings-page__email">
                                    {canEdit ? <StyledLink
                                        typeFormEdit={"editEmail"}
                                        typeLink={"link_edit"}
                                        label={"Email"}
                                        classNmae={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_email"}
                                        screenSize={ screenSize }
                                        style={ styleLinkEdit }
                                        onChange={ onChange }
                                        onChangeEmail={ onChangeEmail }
                                        error_text={ error_text }
                                        clearErrorTxt={ clearErrorTxt }
                                        onBlurEmail={ onBlurEmail }
                                        onFocusEmail={onFocusEmail}
                                        onChangeEmailDataRequest={onChangeEmailDataRequest}
                                        changeEmailSuccess={changeEmailSuccess}
                                        inputOnKeyDown={inputOnKeyDown}
                                    >{data.email}</StyledLink> :
                                    <ComponentWithCaption
                                        title={"Email"}
                                        screenSize={ screenSize }
                                    >
                                        <TextElem>{data.email}</TextElem>
                                    </ComponentWithCaption>}
                                </div>
                            </div>
                            <div className='ugsk-settings-page__user-data-block'>
                                <div className='ugsk-settings-page__address'>
                                    {canEdit ? <StyledLink
                                        typeFormEdit={"editAddress"}
                                        typeLink={"link_edit"}
                                        label={"Адрес проживания"}
                                        className={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_address"}
                                        style={ styleLinkEdit }
                                        screenSize={this.props.screenSize}
                                        valueLink={valueInputAddress}
                                        disabledBtnReady={disabledBtnReady}
                                        onChangeAddress={onChangeAddress}
                                        onChange={onChange}
                                        changeAddressSuccess={changeAddressSuccess}
                                    >{data.address}</StyledLink> :
                                    <ComponentWithCaption
                                        title={"Адрес проживания"}
                                        screenSize={ screenSize }
                                    >
                                        <TextElem>{data.address}</TextElem>
                                    </ComponentWithCaption>}
                                </div>
                            </div>
                            <div className='ugsk-settings-page__user-data-block'>
                                <div className='ugsk-settings-page__phone'>
                                    {canEdit ? <StyledLink
                                        typeFormEdit={"editPhone"}
                                        typeLink={"link_edit"}
                                        label={"Номер телефона"}
                                        className={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_phone"}
                                        screenSize={ screenSize }
                                        style={ styleLinkEdit }
                                        onChangePhone={onChangePhone}
                                        inputPhoneOnKeyDown={inputPhoneOnKeyDown}
                                        errorTxtPhoneValidation={errorTxtPhoneValidation}
                                        onBlurPhone={onBlurPhone}
                                        changePhoneRequest={changePhoneRequest}
                                        clientData={changeEmailSuccess}
                                        clearErrorTxt={clearErrorTxt}
                                        onChangePhoneOld={onChangePhoneOld}
                                        onChangeSmsCode={onChangeSmsCode}
                                        errorTxtSmsCodeValidation={errorTxtSmsCodeValidation}
                                        onChangeConfirmSmsRequest={onChangeConfirmSmsRequest}
                                        onBlurValues={onBlurValues}
                                        confirmPhoneNumber={confirmPhoneNumber}
                                        requestAction={requestAction}
                                        setActionDefault={setActionDefault}
                                        sendConfirmPhoneRequest={sendConfirmPhoneRequest}
                                        clearChangePhoneData={clearChangePhoneData}
                                    >{phoneMask(data.phone_number.toString())}</StyledLink> :
                                    <ComponentWithCaption
                                        title={"Номер телефона"}
                                        screenSize={ screenSize }
                                    >
                                        <TextElem>{phoneMask(data.phone_number.toString())}</TextElem>
                                    </ComponentWithCaption>}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    }
}