import React, { Component } from 'react';

import './SettingWithModals.css'
import {closeModal, isEmpty, openModal} from "../../designSystem/elements/modal/Modal";
import Button from "../../designSystem/elements/button/Button";
import RenderModal from "../../designSystem/elements/modal/RenderModal";
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";
import StyledLink from "../../designSystem/elements/links/Link";
import TextElem from "../../designSystem/elements/text/TextElem";
import Caption from "../../designSystem/elements/text/Caption";
import { colors } from "../../designSystem/DesignSystem";

class SettingsWithModalCompleteRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:{},
            modal2: {},
            modal3: {}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    getStyleForText = () => {
        return {
            color: colors.green,
            fontSize: '18px',
            lineHeight: '25px'
        }
    };

    getStyleForCaption = () => {
        return {
            fontSize: '15px',
            lineHeight: '23px',
            color: colors.gray
        }
    };

    render() {
        let flag = !isEmpty(this.state.modal);

        let screen_size = this.props.screenSize;
        return (
            <React.Fragment>
                <div className="page">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", "222")}}>Запрос на изменение личных данных</Button>

                        {flag === true ?
                            <RenderModal closeModal={this.closeModal} modal={this.state.modal} heading={"Запрос на изменение личных данных"} screen_size={this.props.screenSize}>
                                <div className={'ugsk-form-change-person-date'}>
                                    <div className={ 'ugsk-form-change-person-date__complete-request-change ugsk-complete-request-change' }>
                                        <div className={'ugsk-complete-request-change__text-icon'}>
                                            <Icon
                                                name={'success'}
                                                className={'ugsk-complete-request-change__icon'}
                                                fill={ colors.green }
                                            />
                                            <TextElem
                                                className={'ugsk-complete-request-change__text'}
                                                style={ this.getStyleForText() }
                                            >Запрос отправлен</TextElem>
                                        </div>
                                        <Caption
                                            className={'ugsk-complete-request-change__caption'}
                                            style={ this.getStyleForCaption() }
                                        >Ваш менеджер по персоналу Иванова Анна</Caption>
                                        <Button className={'ugsk-complete-request-change__btn'} view={"button_bordered"}>Готово</Button>
                                    </div>
                                </div>
                            </RenderModal> : ""}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SettingsWithModalCompleteRequest;