import React, {Component} from 'react'

import {spaces} from "../../DesignSystem";
import TextElem from "../text/TextElem";

export default class Mark extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hovered: 0
        }
    }

    onMouseMove = (val) => {
        return (e) => {
            this.setState({hovered: val})
        }
    }

    onClick = (val) => {

        let {
            name,
            value,
            onChange = (val, name) => {}
        } = this.props;

        return (e) => {
            this.setState({hovered: 0})
            onChange(val === value ? 0 : val, name)
        }
    }

    render() {

        let {
            label,
            value,
            marks = [1, 2, 3, 4, 5],
            color = "#FFC348"
        } = this.props;

        let {
            hovered
        } = this.state;

        return <div>
            {label ? <TextElem className="label" style={{marginBottom: spaces.xs, display: "block"}}>{label}</TextElem> : null}
            <div className="stars">
                {marks.map((item) => <Star key={item}
                                           color={color}
                                           selected={!hovered && value >= item || hovered >= item}
                                           onMouseEnter={this.onMouseMove(item)}
                                           onMouseLeave={this.onMouseMove(0)}
                                           onClick={this.onClick(item)}
                />)}
            </div>
        </div>
    }
}

function Star({selected, color, onMouseEnter, onMouseLeave, onClick}){

    let stroke = selected ? {fill: color} : {stroke: color}

    return <span className="stars__item" style={{cursor: 'pointer'}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path {...stroke} d="M39.8576 14.306C40.1972 15.3441 39.9193 16.4607 39.1319 17.2216L32.1242 23.9954C31.8255 24.2845 31.6885 24.6998 31.759 25.1073L33.4138 34.6711C33.5996 35.7457 33.1631 36.8107 32.2732 37.4524C31.7702 37.8154 31.1807 37.9988 30.5888 37.9988C30.133 37.9988 29.6749 37.89 29.252 37.6692L20.5895 33.1534C20.2203 32.9612 19.7797 32.9612 19.4105 33.1534L10.7488 37.6692C9.77404 38.1783 8.61664 38.0949 7.72757 37.4524C6.8377 36.8107 6.40117 35.7457 6.58699 34.6711L8.24099 25.1065C8.31148 24.6998 8.17451 24.2837 7.87575 23.9946L0.868083 17.2216C0.0807315 16.4607 -0.197204 15.3441 0.142406 14.306C0.482817 13.268 1.36949 12.527 2.458 12.3706L12.1433 10.9743C12.5558 10.9148 12.913 10.6583 13.0973 10.2874L17.4281 1.58524C17.9143 0.607568 18.9011 0 20 0C21.0997 0 22.0865 0.607568 22.5727 1.58524L26.9027 10.2874C27.087 10.6583 27.4434 10.9148 27.8559 10.9743L37.5412 12.3698C38.6305 12.527 39.5172 13.268 39.8576 14.306Z" />
        </svg>
    </span>
}