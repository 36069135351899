import React, {Component} from "react";
import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import Link from "../../designSystem/elements/links/Link";
import Icon from "../../designSystem/elements/icon/Icon";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Input from "../../designSystem/elements/input/Input";
import CheckboxListFilter from "./CheckboxListFilter";
import Map from "../../designSystem/elements/map/Map";
import Button from "../../designSystem/elements/button/Button";
import {LeftItem} from "./List";
import './FilterPanel.css';
import MapImage from '../../images/map.png'
import AuthorizationImg from "../../images/authorization-page.png";

export default class FilterPanel extends Component {

    constructor(props){
        super(props);
        this.state={
            scrolled: false,
            scrolledToBottom: false
        };
    }

    onScrollFunc = ()=>{
        if(window.pageYOffset > 70) {
            if (this.state.scrolled === false){
                this.setState({
                    scrolled: true
                })
            }
        } else {
            if (this.state.scrolled === true){
                this.setState({
                    scrolled: false
                })
            }
        }
        if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 175)) {
            this.setState({
                scrolledToBottom: true
            })
        } else {
            this.setState({
                scrolledToBottom: false
            })
        }
    };

    componentWillMount() {
        window.addEventListener("scroll", this.onScrollFunc);
    }

    render() {

        let {
            screen_size,
            isOnMap,
            hasMap,
            shownDetail,
            resetDetail,
            hidden,
            toggleHidden,
            toggleTab,
            fields,
            searchInputParams = {},
            openOnMap,
            tabReset,
            tabBlock,
            activeTab
            // points
        } = this.props;

        let a = document.body.scrollHeight;
        let b = document.documentElement.clientHeight + document.documentElement.scrollTop;
        let heightCalc = a - b;
        let topCoord = "calc(140px " + "- " + ((this.state.scrolled === true) ? ((window.pageYOffset > 70) ? "70px" : window.pageYOffset + "px") : "0px")  + ")";
        let blockHeight = "calc(100vh - " + ((this.state.scrolled === true) ? ((window.pageYOffset > 70) ? "70px" : window.pageYOffset + "px") : "140px")
            + ((this.state.scrolledToBottom === true) ? " - 175px" : "") + ")";

        let filter_panelHeight = "100vh";
        if (screen_size === "xs" || isOnMap === true && this.state.scrolled === true) {
            filter_panelHeight = "100%";
        } else if (isOnMap === true) {
            filter_panelHeight = "auto";
        }

        let panelStyles = screen_size === "xs" ? (hidden && !shownDetail ? {} : {
            background: "white",
            position: (this.state.scrolled === true) && screen_size !== "xs" ? "fixed" : "relative",
            right: 0,
            zIndex: 1000,
            height: screen_size === "xs" ? "100%" : "calc(100vh - 140px)",
            top: screen_size !== "xs" ? topCoord : 0
        } ) : {
            // minWidth: getPanelWidth(screen_size),
            background: "white",
            borderLeft: "1px solid " + colors.grayBg_color,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.09)",
            position: (this.state.scrolled === true) ? "fixed" :  "relative",
            height: filter_panelHeight,
            top: (this.state.scrolled === true) && screen_size !== "xs"  ? topCoord : 0
        };

        if (isOnMap && screen_size === "s" && hidden && !shownDetail) {
            return <div style={{position: "absolute", bottom: spaces.m, right: spaces.m}}>
                <Button view={"button_bordered"} onClick={toggleHidden}>Показать фильтр</Button>
            </div>
        }

        return <div className={"filter_panel"} style={panelStyles}>
            {shownDetail && (isOnMap || screen_size === "s" || screen_size === "xs") ? <div style={{width: getPanelBodyWidth(screen_size)}}>
                <div style={{paddingLeft: spaces.m, paddingTop: spaces.m}} onClick={resetDetail}>
                    <TextElem style={{color: colors.middle_blue, cursor: "pointer"}}>
                        <Icon name="chevron-down" width="20px" height="20px" fill={colors.middle_blue} style={{
                            marginRight: spaces.xs,
                            transform: "rotate(90deg)",
                            verticalAlign: "middle",
                            position: "relative",
                            top: "-1.5px"
                        }}/>
                        {isOnMap && screen_size !== "xs" ? "Фильтр" : "Клиники"}
                    </TextElem>
                </div>
                <div style={{maxHeight: screen_size !== "xs" ? 'calc(100vh - 300px)' : null, overflowY: 'scroll', marginRight: "-20px"}}>
                    {shownDetail.map((item) => <LeftItem key={item.id} screen_size={screen_size} item={item} isOnMap={isOnMap} openOnMap={openOnMap}/>)}
                </div>
            </div> : <div style={{width: getPanelBodyWidth(screen_size)}}>
                {isOnMap && screen_size === "s"  ? <div style={{paddingLeft: spaces.m, paddingTop: spaces.m}} onClick={toggleHidden}>
                    <TextElem style={{color: colors.middle_blue, cursor: "pointer"}}>
                        <Icon name="close-light" width="24px" height="24px" fill={colors.middle_blue} style={{
                            marginRight: spaces.xs,
                            verticalAlign: "middle"
                        }}/>
                        Скрыть фильтр
                    </TextElem>
                </div> : null}
                {screen_size === "xs" && !hidden ? null :
                    <div style={{padding: screen_size === "xs" ? spaces.m : "20px 20px 20px 20px", display: screen_size !== "xs" ? "block" : "flex"}}>
                        <Input typeInput={"search"} placeholder={"Метро или адрес"} style={{display: "flex", flexGrow: screen_size !== "xs" ? 0 : 1}}  {...searchInputParams}/>
                        {screen_size === "xs" ? <span onClick={toggleHidden} style={{
                            marginLeft: spaces.s,
                            minWidth: "42px",
                            width: "42px",
                            height: "42px",
                            borderRadius: "8px",
                            background: colors.grayBg_color,
                            padding: "9px"
                        }}>
                            <Icon name="filter" width="24px" height="24px" fill={colors.middle_blue} />
                        </span> : null}
                    </div>
                    }
                {screen_size === "xs"  || isOnMap || !hasMap ? null : <div style={{width: "100%", height: "150px"}}>

                    <div style={{width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 0,
                        left: 0,
                        background: `url(${ MapImage })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        cursor: 'pointer'}}
                        onClick={tabBlock ? () => {toggleTab(1)} : null}
                        // onClick={() => {openOnMap(points)} }
                    >
                        {/*<Button view={"button_bordered"} onClick={() => {openOnMap(points)} }>Показать на карте</Button>*/}
                        {tabBlock ? <Button view={"button_bordered"} onClick={() => {toggleTab(1)}}>Показать на карте</Button> : <Button disabled view={"button_bordered"} >Показать на карте</Button>}
                    </div>

                </div>}
                {screen_size === "xs" && hidden ? null : <div style={{padding: spaces.m}}>
                    {screen_size === "xs" ? <div onClick={toggleHidden} style={{paddingBottom: spaces.m}}>
                        <TextElem style={{color: colors.middle_blue, cursor: "pointer"}}>
                            <Icon name="chevron-down" width="20px" height="20px" fill={colors.middle_blue} style={{
                                marginRight: spaces.xs,
                                transform: "rotate(90deg)",
                                verticalAlign: "middle"
                            }}/>
                            {isOnMap ? "Карта" : "Список"}
                        </TextElem>
                    </div>: null}
                    {fields.map((field, key) => <CheckboxListFilter {...field} key={key} tabReset={tabReset} activeTab={activeTab}/>)}
                    {screen_size === "xs" ? <Button view={"button_bordered"} onClick={toggleHidden}>
                        Готово
                    </Button>: null}
                </div>}
            </div>}
            {screen_size !== "xs" && heightCalc <= 212 && activeTab === 0 ? <div style={{width: "100%", height: "225px"}}><br/></div> : null}
        </div>
    }
}

function getPanelBodyWidth(screen_size) {
    switch (screen_size) {
        case "m":
            return "320px";
        case "s":
            return "290px";
        case "xs":
            return "100%";
        default:
            return "380px";
    }
}

function getPanelWidth(screen_size) {
    switch (screen_size) {
        case "m":
            return "320px";
        case "s":
            return "290px";
        case "xs":
            return "100%";
        case "xl":
            return "calc(379px + 50vw - 770px)";
        case "xxl":
            return "calc(379px + 30vw - 770px)";
        default:
            return window.innerWidth >= 1440 ? "calc(379px + 50vw - 700px)" : "380px";
    }
}