import {
    call,
    put
} from 'redux-saga/effects';

import { setCookie } from "../tools/tools";

import {fetchApi, checkErrors, errorMessage} from "../tools/api-helper";
import {
    passwordError,
    phoneError,
    smsCodeError,
    birthdayError,
    policyNumberError,
    nameError,
    surnameError,
} from "../tools/form-helper";

export function auth(data, remember){
    return {
        type: "AUTH",
        data,
        remember
    }
}

export function* fetchAuth(action) {
    let errors = {
        password: passwordError(action.data.get("password")),
        phone_number: phoneError(action.data.get("phone_number"))
    };

    if (errors.length) {
        yield put(checkErrors(action.type, {errors}, 422));
    } else {
        try {
            const { status, data } = yield call( () => {
                return fetchApi("/auth", {
                    method: 'POST',
                    body: action.data
                }, false)
            });
            yield put(checkErrors(action.type, data, status));

            if (status === 200) {
                localStorage.setItem('ugskdmsAccessToken', data.token);

                for (let key in data) {
                    let options = action.remember ? {} : {expires: 30*24*3600, path: "/"};
                    setCookie(key, data[key], options);
                }
            }
        } catch(e) {
            yield put(checkErrors(action.type, {message: errorMessage}, 500));
        }
    }
}

export function register(data, method){
    return {
        type: "REGISTER",
        data,
        method,
    }
}

export function* fetchRegister(action) {
    let errors = [];

    let err = phoneError(action.data.get("phone_number"));
    err && errors.push({field: 'phone_number', message: err});
    err = policyNumberError(action.data.get("policy_number"));
    err && errors.push({field: 'policy_number', message: err});
    err = surnameError(action.data.get("surname"));
    err && errors.push({field: 'surname', message: err});
    err = nameError(action.data.get("name"));
    err && errors.push({field: 'name', message: err});
    err = birthdayError(action.data.get("birthday"));
    err && errors.push({field: 'birthday', message: err});

    if (errors.length) {
        yield put(checkErrors(action.type, errors, 422));
    } else {
        try {
            const { status, data } = yield call( () => {
                return fetchApi("/registration", {
                    method: 'POST',
                    body: action.data
                }, false)

            });

            yield put(checkErrors(action.type, data, status));
            if (status === 200) {
                action.method('SMSCode');
            }
        } catch(e) {
            yield put(checkErrors(action.type, {message: errorMessage}, 500));
        }
    }
}

export function logout(){

    return {
        type: "LOGOUT"
    }
}

export function* fetchLogout(){

    localStorage.removeItem('ugskdmsAccessToken');

    yield put({type: "LOGOUT_SUCCESS"});
}

export function invite(action) {
    return {
        type: "INVITATION",
        action
    }
}

export function* fetchInvitation(action) {
    try {
        const { status, data } = yield call( () => {
            return fetchApi(`/invitation${action.action}`, {
                method: 'GET',
            }, false);
        });
        yield put(checkErrors(action.type, data, status));

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clearInvitation()
{
    return {
        type: "INVITATION_CLEAR"
    }
}

export function confirm(action, data, method, component, auth) {
    return {
        type: "CONFIRMATION",
        action,
        data,
        method,
        component,
        auth
    }
}

export function* fetchConfirm(action) {

    let type = action.type+"_"+action.action.toUpperCase();
    let phoneErr = phoneError(action.data.get("phone_number"));
    let codeErr = smsCodeError(action.data.get("code"));

    if (action.action === "send-sms-code" && phoneErr) {
        yield put(checkErrors(type, [{field: 'phone_number', message: phoneErr}], 422));
    } else if (action.action === "validate-sms-code" && codeErr) {
        yield put(checkErrors(type, {code: codeErr}, 422));
    } else {
        let urlAction = ['validate-sms-code'].indexOf(action.action) === -1 ? action.action : 'validate-code';
        try {
            const { status, data } = yield call( () => {
                return fetchApi("/"+(action.auth ? "recovery-" : "")+"confirmation?action="+urlAction, {
                    method: 'POST',
                    body: action.data
                }, action.auth)
            });
            yield put(checkErrors(type, data, status));
            if (action.component && status === 200) {
                action.method(action.component);
            }
        } catch(e) {
            yield put(checkErrors(type, {message: errorMessage}, 500));
        }
    }
}

export function setPassword(data, count, auth) {
    return {
        type: "SET_PASSWORD",
        data,
        count
    }
}

export function* fetchSetPassword(action) {

    let errors = [], err = "Поле обязательно для заполнения";
    if (!action.data) {
        errors.push({field: 'newPassword', message: err});
        errors.push({field: 'repeatPassword', message: err});
    } else {
        err = passwordError(action.data.get('newPassword'));
        if (err) {
            errors.push({field: 'newPassword', message: err})
        }
        err = passwordError(action.data.get('repeatPassword'));
        if (err) {
            errors.push({field: 'repeatPassword', message: err})
        }
        if (action.count < 2) {
            errors.push({field: 'newPassword', message: 'Необходимо, чтобы пароль был надежным.'})
        }

    }

    if (errors.length) {
        yield put(checkErrors(action.type, errors, 422));
    } else {
        try {
            const { status, data } = yield call( () => {
                return fetchApi("/password", {
                    method: 'POST',
                    body: action.data
                }, action.auth)
            });
            yield put(checkErrors(action.type, data, status));

        } catch(e) {
            yield put(checkErrors(action.type, {message: errorMessage}, 500));
        }
    }


}

export function clearError(type, field){
    return {
        type: type.toUpperCase()+"_CLEAR_VALIDATION",
        field
    }
}

export function validate(type, field, val) {
    return {
        type: type.toUpperCase()+"_VALIDATION",
        field,
        val
    }
}

export function* fetchValidate(action) {
    let err;
    switch (action.field) {
        case 'phone_number':
            err = phoneError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'code':
            err = smsCodeError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'policy_number':
            err = policyNumberError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'surname':
            err = surnameError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'name':
            err = nameError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'birthday':
            err = birthdayError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
        case 'password':
        case 'oldPassword':
        case 'newPassword':
        case 'repeatPassword':
            err = passwordError(action.val);
            if (err) {
                yield put(checkErrors(action.type, [{field: action.field, message: err}], 422));
            }
            break;
    }

}