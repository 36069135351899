import React, { Component } from 'react';


/*import Heading from '../designSystem/elements/heading/Heading';*/
import Button from '../designSystem/elements/button/Button';
import Input from '../designSystem/elements/input/Input';
import Icon from '../designSystem/elements/icon/Icon';
import BreakpointsVisual from '../designSystem/elements/breakpoints/BreakpointsVisual'
import Pagination from '../designSystem/elements/pagination/Pagination'
import TextElem from '../designSystem/elements/text/TextElem'
import Caption from '../designSystem/elements/text/Caption'
import Heading from '../designSystem/elements/text/Heading'
import Tabs from '../designSystem/elements/tabs/Tabs'
import Link from  '../designSystem/elements/links/Link'
import Radio from '../designSystem/elements/radio/Radio'
import Checkbox from '../designSystem/elements/checkbox/Checkbox'
import MapContainer from "../designSystem/elements/map/GoogleMap"
import Cards from '../designSystem/elements/cards/Cards'
import '../designSystem/common.css';
import {BreakPoints, colors, fonts} from '../designSystem/DesignSystem';
import lake from '../images/lake.jpg';
import NotificationsContainer from '../designSystem/elements/notification/NotificationsContainer'
import ToggleHeading from "../designSystem/elements/text/ToggleHeading";


class Dls extends Component {


    constructor(props) {
        super(props);
        this.state = {
            screen_size: "l",
            activeTab: 0,
            showNotifications: false,
            rightBottomCards: [
                {
                    icon: {name: "calendar", fill: colors.middle_blue_opacity5,  width: "20px", height: "20px"},
                    text: "Полис ДМС 683400210 скоро истекает",
                    shouldClose: true,
                    button: {
                        text: "Продлить",
                        type: "standard",
                        view: "button_bordered"
                    },
                    show: true
                },
                {
                    icon: {name: "calendar", fill: colors.middle_blue_opacity5,  width: "20px", height: "20px"},
                    text: "Полис ДМС 683400210 скоро истекает",
                    shouldClose: true,
                    bgColor: colors.white,
                    show: false
                }
            ]
        };
        this.getScreenSize = this.getScreenSize.bind(this);
    }

    componentDidMount() {
        this.getScreenSize();
        window.addEventListener("resize", this.getScreenSize);
    }

    getScreenSize(){
        if (typeof document != 'undefined'){
            let width = document.documentElement.clientWidth;
            let size = "";
            BreakPoints.map((item)=>{
                if ((width >= item.from)&&(width<= item.to)){
                    size = item.name;
                }
            });

            this.setState(
                {screen_size: size}
            )
        }
    }
    onClickPolis=()=>{
        /*go to ....*/
    };

    addNewNotification = () => {
        let notificationRightBottom = this.state.rightBottomCards.slice();

        notificationRightBottom.push({
            icon: {name: "calendar", fill: colors.middle_blue_opacity5,  width: "20px", height: "20px"},
            text: "Полис ДМС 683400210 скоро истекает скоро истекает скоро истекает",
            shouldClose: true,
            bgColor: colors.white
        });

        this.setState({
            rightBottomCards: notificationRightBottom
        })
    };

    setShow = (index) => {
        let notificationArr = this.state.rightBottomCards.slice();

        notificationArr[index].show = true;

        this.setState({
            rightBottomCards: notificationArr
        })
    };

    setHide = (index) => {
        let notificationArr = this.state.rightBottomCards.slice();

        notificationArr[index].show = false;

        this.setState({
            rightBottomCards: notificationArr
        })
    };

    render() {
        let s= "normal";
        if (this.state.screen_size === "xs"){
            s="mobile"
        }

        let list=[
            {
                name: "Пункт 1",
                icon:{
                    name: "lock",
                    width: "13px",
                    height: "17px"
                }
            },
            {
                name: "Пункт 2",
                icon:{
                    name: "exit",
                    width: "15px",
                    height: "17px"
                }
            }
        ];
        let itemsRadio= [ {value:"1", label:"first", active: false}, {value:"2", label:"second", active: false}, {value:"3", label:"third", disabled: true, active: false}];

      let infoPolis={
        name: "Мария Воробьева",
        title: "Полис путешественника",
        label: "Истекает",
        date: "10 апреля 2019",
        buttons: {
        action_button: {
            text: "Продлить",
            view: "button_filled",
        },

        more_button:{
            text: "Подробнее",
            view: "button_bordered",
            onClick: this.onClickPolis
        }
    },
        
        icon: {
          name: "card1",
          width: "22px",
          height: "22px",
          fill: colors.middle_blue_opacity5
        }
      };
        let infoPolis2={
            name: "Мария Воробьева",
            title: "Полис путешественника",
            label: "Истекает",
            date: "10 апреля 2019",
            buttons: {
              more_button:{
                    text: "Подробнее",
                    view: "button_bordered",
                    onClick: this.onClickPolis
                }
            },

            icon: {
                name: "card1",
                width: "22px",
                height: "22px",
                fill: colors.middle_blue_opacity5
            }
        };

      let infoAdvert={
          to: "#",

          title: "Путешествие налегке",
          text: "Скидки на полный страховой пакет для путешествий до 25%",
          image:{
            name: "bgCard"
          }        
      };
       let infoAdvert2={
          to: "#",
           color: colors.white,
          title: "Лето – время скидок",
          text: "Скидки до 50% на страхование с 1 июня по 25 июля ",
          image:{
            name: "bgCard2"
          }        
      };
       let infoNewPolis={
          to: "#",      
          text: "Оформить новый полис путешественника",
       };
      let leftTopCards = [
            {
                type: "authorizeError",
                text: "Авторизация заблокирована на 60 минут после 10 неудачных попыток входа. Осталось 42 минуты.",
                shouldClose: false,
            },
            {
                type: "error",
                text: "Ошибка при сохранении. Обновите страницу",
                shouldClose: true,
            },
            {
                type: "success",
                text: "Заявка успешно отправлена",
                shouldClose: true,
            },
        ];
      let resultTips= [{title: "1111"}, {title: "2222"}];
      let selectItems = [{title: "Кардиология"},{title: "Дерматология"},{title: "Урология"},{title: "Гинекология"},{title: "Кардиология"},{title: "Дерматология"},{title: "Урология"}];


        return (
            <div className={"App " + "App__screen_size_" + this.state.screen_size}   >
                <header>
                </header>
                <div className="page">
                    {/*notification container*/}
                    {this.state.showNotifications === true ?
                    <NotificationsContainer
                        screen_size = {this.state.screen_size}
                        leftTopCards={leftTopCards}
                        rightBottomCards ={this.state.rightBottomCards}
                        setShow={ this.setShow }
                        setHide={ this.setHide }
                    />
                        :"" }
                    <div className="page_container">
                        <BreakpointsVisual screen_size={this.state.screen_size}/>

                        <div className="components">

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Типографика</Heading>

                            <Heading size={"l"} screen_size={this.state.screen_size}>Heading 1 {s==="mobile" ? "mobile" : ""} • {fonts.headings.l[s].fontSize}/{fonts.headings.l[s].lineHeight} montserrat bold </Heading>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Heading 2 {s==="mobile" ? "mobile" : ""} • {fonts.headings.m[s].fontSize}/{fonts.headings.m[s].lineHeight} montserrat bold </Heading>
                            <Heading size={"m2"} screen_size={this.state.screen_size}>Heading 2 list • {fonts.headings.m2[s].fontSize}/{fonts.headings.m2[s].lineHeight} montserrat bold </Heading>
                            <Heading size={"s"} screen_size={this.state.screen_size}> Heading 3 • {fonts.headings.s[s].fontSize}/{fonts.headings.s[s].lineHeight} roboto medium  </Heading>
                            <TextElem> Body text • {fonts.text.body.fontSize}/{fonts.text.body.lineHeight} roboto regular</TextElem> <br/>
                            <Caption> Caption • {fonts.text.caption.fontSize}/{fonts.text.caption.lineHeight} roboto regular</Caption> <br/>
                            <TextElem type={"date"}> date • {fonts.text.date.fontSize}/{fonts.text.date.lineHeight} roboto medium</TextElem>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Кнопка</Heading>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Кнопки с заполнением</Heading>
                            <Button>Кнопка</Button>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Кнопки с обводкой </Heading>
                            <Button view={"button_bordered"}>Кнопка</Button>

                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Кнопка с заполнением на цветном фоне </Heading>
                                <Button layout={"layout_colored"}>Кнопка</Button>

                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Кнопка с обводкой на цветном фоне</Heading>
                                <Button view={"button_bordered"} layout={"layout_colored"}>Кнопка</Button>
                            </div>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Неактивная кнопка</Heading>
                            <Button disabled>Кнопка</Button>

                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Неактивная кнопка</Heading>
                                <Button disabled layout={"layout_colored"}>Кнопка</Button>
                            </div>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Пагинация</Heading>

                            <div style={{display: "flex", marginBottom: "20px"}}>
                                <Pagination>1 </Pagination>
                                <Pagination active> 2</Pagination>
                                <Pagination> 3 </Pagination>
                            </div>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Кнопки действия</Heading>
                            <Button icon={ { name: "geo", width: 15, height: 19 }} onClick={()=>{}} >Кнопка</Button>
                            <Button icon={ { name: "geo", width: 15, height: 19 }} disabled onClick={()=>{}} >Кнопка</Button>

                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Кнопки действия</Heading>
                                <Button layout={"layout_colored"} icon={ { name: "geo", width: 15, height: 19 }} onClick={()=>{}} >Кнопка</Button>
                                <Button disabledMessage={"Превышен лимит запросов, подождите несколько минут и попробуйте снова"} layout={"layout_colored"} icon={ { name: "geo", width: 15, height: 19 }} disabled onClick={()=>{}} >Кнопка</Button>
                            </div>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Табы</Heading>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Табы главного меню</Heading>

                            <Tabs  typeSwitcher={"url"} styleSwitcher={"underlined"} items={[{title: "Первый таб"}, {title: "Второй таб"}, {title: "Третий таб"}]}>
                            </Tabs>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Табы второго меню</Heading>
                            <Tabs typeSwitcher={"url"} styleSwitcher={"iconed"} items={[{title: "Первый таб", icon: {name: "list", width: "17px", height: "14px"}}, {title: "Второй таб", icon:{name: "list", width: "17px", height: "14px"}}, {title: "Третий таб", icon:{name: "list", width: "17px", height: "14px"}}]}>
                            </Tabs>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Формы ввода</Heading>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Форма на белом фоне</Heading>

                            <Input  typeInput={"input"} type="text" label={"Название"} width={"209px"} placeholder={"Placeholder"} />
                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Форма на цветном фоне</Heading>
                                <Input layout={"layout_colored"} typeInput={"input"} type="text" label={"Название"} placeholder={"Placeholder"} />
                            </div>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Форма поиска</Heading>
                            <Input typeInput={"search"} resultTips = {resultTips} />


                            <Heading size={"m"} screen_size={this.state.screen_size}>Textarea</Heading>
                            <Input screen_size={this.state.screen_size}  typeInput={"textarea"} resizable={true} label={"Комментарий"}/>


                            <Heading size={"m"} screen_size={this.state.screen_size}>Ссылки на редактирование</Heading>
                            <Link typeLink={"link_edit"} to={"#"} label={"Номер телефона"}>+7 (999) 999-99-99</Link>
                            <br/>
                            <br/>
                            <Link typeLink={"link_edit"} to={"#"} disabled label={"Номер телефона"}>+7 (999) 999-99-99</Link>
                            <br/><br/>
                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}> Ссылки на редактирование на цветном фоне </Heading>
                                <Link layout={"layout_colored"} typeLink={"link_edit"}  to={"#"} label={"Номер телефона"}>+7 (999) 999-99-99</Link>
                                <br/>
                                <br/>
                                <Link layout={"layout_colored"} typeLink={"link_edit"}  to={"#"} disabled label={"Номер телефона"}>+7 (999) 999-99-99</Link>
                            </div>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Селектор </Heading>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Селектор на белом фоне</Heading>
                            <Input  typeInput={"select"} selectItems={selectItems} label={"Название селектора"} />
                            <div className={"layoutColor"}>
                              <Heading size={"m"}  color={"white"} screen_size={this.state.screen_size}>Селектор на цветном фоне</Heading>
                              <Input  typeInput={"select"} selectItems={selectItems} label={"Название селектора"} layout={"layout_colored"} />
                            </div>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Чекбоксы и радиокнопки</Heading>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Чекбоксы</Heading>
                            <Checkbox>Круглосуточно </Checkbox>
                            <Checkbox disabled={true}>Круглосуточно </Checkbox>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Радиокнопки</Heading>
                            <Radio type={"radio"} items={itemsRadio} name={"group1"}> </Radio>

                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Чекбоксы на цветном фоне</Heading>
                                <Checkbox layout={"layout_colored"}>Круглосуточно </Checkbox>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Радиокнопки на цветном фоне</Heading>
                                <Radio type={"radio"} items={itemsRadio} name={"group1"} layout={"layout_colored"}> </Radio>
                            </div>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <Heading size={"l"} screen_size={this.state.screen_size}>Ссылки</Heading>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Выпадающее меню</Heading>

                            <Link typeLink={"menu"} to={"#"} list={list}>Меню</Link>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Ссылка на белом фоне</Heading>
                            <Link to={"#"}>Ссылка</Link>
                            <Heading size={"m"} screen_size={this.state.screen_size}>Пунктирная ссылка</Heading>
                            <Link to={"#"} typeLink={"link_dotted"}>Ссылка</Link>

                            <div className={"layoutColor"}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Ссылка на цветном фоне</Heading>
                                <Link to={"#"} layout={"layout_colored"}>Ссылка</Link>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Пунктирная ссылка на цветном фоне</Heading>
                                <Link to={"#"} typeLink={"link_dotted"} layout={"layout_colored"}>Ссылка</Link>
                            </div>

                            {/*-----------------------------------------------------------------------------------------------------------*/}

                            <MapContainer/>

                            <div className="imageBg">
                                <Heading size={"l"} color={"white"} screen_size={this.state.screen_size}>Карточки</Heading>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Карточка нового полиса</Heading>
                                <div className={"row-cards"}>
                                      <Cards type="polis" info={infoPolis} layout={"layout_colored"} screen_size={this.state.screen_size}></Cards>
                                      <Cards type="newPolis" info={infoNewPolis} screen_size={this.state.screen_size}></Cards>
                                      <Cards type="newPolis" info={infoNewPolis} screen_size={this.state.screen_size}></Cards>
                                </div>
                            </div>

                            <div className={"layoutColor"} style={{backgroundColor: colors.middle_blue}}>
                                <Heading size={"m"} color={"white"} screen_size={this.state.screen_size}>Карточка полиса</Heading>
                                <div className={"row-cards"}>
                                    <Cards type="polis" info={infoPolis} layout={"layout_colored"} screen_size={this.state.screen_size}></Cards>
                                    <Cards type="polis" info={infoPolis2} layout={"layout_colored"} screen_size={this.state.screen_size}></Cards>
                                    <Cards type="polis" info={infoPolis2} layout={"layout_colored"} screen_size={this.state.screen_size}></Cards>
                                </div>
                            </div>
                            <div>
                                <div className={"row-cards"}>
                                    <Cards type="polis" info={infoPolis} screen_size={this.state.screen_size}></Cards>
                                    <Cards type="polis" info={infoPolis2}  screen_size={this.state.screen_size}></Cards>
                                    <Cards type="polis" info={infoPolis} screen_size={this.state.screen_size}></Cards>
                                </div>
                            </div>

                            <Heading size={"m"} screen_size={this.state.screen_size}>Карточка рекламы</Heading>
                            <div className={"row-cards"}>
                                <Cards type="advert" info={infoAdvert} screen_size={this.state.screen_size}></Cards>
                                <Cards type="advert" info={infoAdvert2} screen_size={this.state.screen_size}></Cards>
                                <Cards type="advert" info={infoAdvert2} screen_size={this.state.screen_size}></Cards>
                            </div>

                            <Heading size={"l"} screen_size={this.state.screen_size}>Уведомления</Heading>
                            <Button onClick={()=>{this.setState((prevState)=>{ let s={...prevState}; s.showNotifications = !s.showNotifications;  return s;})}}>{this.state.showNotifications === true ? "Скрыть уведомления" : "Показать уведомления"}</Button>
<br/>
<br/>
                            <Button onClick={ this.addNewNotification }>Показать анимацию уведомления</Button>
                            <br/>
                            <br/>
                            <ToggleHeading
                                content={<React.Fragment><p>1</p><p>2</p><p>3</p></React.Fragment>}
                            >
                                Информация
                            </ToggleHeading>

                        </div>
                    </div>
                </div>
            </div>
    );
    }
}

export default Dls;
