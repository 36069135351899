export const apiUrl = API_URL;
export const errorMessage = "Возникла непредвиденная ошибка. Попробуйте позже.";

/**
 * Fetch helper function
 * @param url
 * @param params
 * @param auth bool
 * @param responseType string
 * @returns {Promise<{status: number, data: any, headers: Headers} | {error: any}>}
 */
export function fetchApi(url, params = {}, auth = true, responseType = 'json')
{

    if (auth) {
        params.headers = {
            Authorization: "Bearer "+localStorage.getItem("ugskdmsAccessToken")
        }
    }

    return fetch(apiUrl + url, params).then(response => {

        switch (responseType) {
            case 'blob':
                return response.status === 200 ? response.blob()
                    .then(value => ({
                        status: response.status,
                        data: value,
                        headers: response.headers
                    })) : response.json()
                    .then(value => ({
                        status: response.status,
                        data: value,
                        headers: response.headers
                    }));

            default:
                return response.json()
                    .then(json => {

                        var pagination = {};
                        if (response.headers.get('X-Pagination-Current-Page')) pagination.currentPage = response.headers.get('X-Pagination-Current-Page');
                        if (response.headers.get('X-Pagination-Page-Count')) pagination.pageCount = response.headers.get('X-Pagination-Page-Count');
                        if (response.headers.get('X-Pagination-Per-Page')) pagination.perPage = response.headers.get('X-Pagination-Per-Page');
                        if (response.headers.get('X-Pagination-Total-Count')) pagination.totalCount = response.headers.get('X-Pagination-Total-Count');

                        return {
                            status: response.status,
                            data: json,
                            headers: response.headers,
                            pagination: pagination
                        }
                    });

        }
    }).catch(error => ({
        error: error
    }));
}

/**
 * Check response
 * @param type
 * @param data
 * @param status
 * @returns {*}
 */
export function checkErrors(type, data, status)
{
    switch (status) {
        case 200:
            return { type: type + '_SUCCESS', data: data};
        case 201:
            return { type: type + '_CREATED', data: data};
        case 204:
            return { type: type + '_EMPTY_BODY', data: data};
        case 400:
            return { type: type + '_BAD_REQUEST', data: data};
        case 401:
            return { type: 'LOGOUT' };
        case 403:
            return { type: type + '_FAILED_TO_FETCH', data: data};
        case 404:
            return { type: type + '_NOT_FOUND', data: data};
        case 413:
            return { type: type + '_TOO_LARGE', data: data};
        case 422:
            return { type: type + '_VALIDATION_ERROR', data: data};
        case 423:
            return { type: 'BLOCKED', data: data};
        case 424:
            return { type: 'FAILED_DEPENDENCY', data: data};
        case 'requesErrPersonalData':
            return { type: type + '_FALSE', data: data};
        case 'validPhoneNumber':
            return { type: type + '_VALID_OLD_NUMBER', data: data};
        case 'errorRequestSms':
            return { type: type + '_REQUEST_SMS', data: data};
        case 'errorRequestPhoneOld':
            return { type: type + '_REQUEST_PHONE', data: data};
        case 'errorRequestValidateOld':
            return { type: type + '_REQUEST_VALIDATE', data: data};
        case 'reRequestSms':
            return { type: type + '_RE_REQUEST_SMS', data: data};
        case 'reRequestSmsOld':
            return { type: type + '_RE_REQUEST_SMSOLD', data: data};
        default:
            return { type: type + '_ERROR',  data: data};
    }
}

/**
 * Parse errors of data validation response as object
 * @param errors
 * @returns {*}
 */
export function parseErrors(errors) {
    var errorsObject = {};
    errors.map((err) => {
        errorsObject[err.field] = err.message;
    });
    return errorsObject;
}

export function getActionsByRoute(route) {
    switch (route) {
        case "/":
            return [
                {type: "ME"},
                {type: "POLICES_ACTIVE_LIST"},
                {type: "ADDITIONAL_SERVICES"}
            ];
        case "/clinics":
            return [
                {type: "ME"},
                {type: "CLINICS_LIST"},
                {type: "CLINICS_SERVICES"},
                {type: "CLINICS_ADDRESSES"},
                {type: "CLINICS_CONDITIONS"}
            ];
        case "/settings":
            return [
                {type: "ME"},
                {type: "POLICES_ARCHIVE_LIST"}
            ];
        case "/pdf":
            return [
                {type: "ME"}
            ];
        default:
            return [];
    }
}
