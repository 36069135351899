import {
    call,
    put,
} from 'redux-saga/effects';

import {fetchApi, checkErrors, errorMessage} from "../tools/api-helper";

export function getKey(){
    return {
        type: "RECAPTCHA",
    }
}

export function* fetchRecaptcha(action){
    try {
        const { status, data } = yield call( () => {
            return fetchApi("/recaptcha", {
                method: 'GET',
            }, false);
        });
        yield put(checkErrors(action.type, data, status));

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}