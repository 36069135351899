import React from 'react'
import ReactDOM from "react-dom"
import "./serviceModal.css"

import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import Link from "../../designSystem/elements/links/Link";
import {buttonStyle} from "../../designSystem/DesignSystem";

export default class ServiceModal extends React.Component {

    componentDidMount() {
        this.prepareContent()
    }

    prepareElement = (item) => {
        let {screen_size} = this.props;
        let children = item.children.length ? <span style={{fontFamily: "inherit", color: "inherit"}} dangerouslySetInnerHTML={{__html: item.innerHTML}}/> : item.innerText

        let style = this.prepareStyle(item.getAttribute("style"))
        let commonProps = {
            screen_size: screen_size,
            style
        }

        if (item.tagName === "A" && item.innerHTML.indexOf("button") !== -1) {
            commonProps.style = Object.assign({borderRadius: '6px', display: 'inline-flex'}, buttonStyle.default_attrs, buttonStyle.button_filled.layout_default.normal_mode);
        }

        switch (item.tagName) {
            case "A":
                return React.createElement(Link, {
                    typeLink: "external",
                    noFollow: true,
                    targetBlank: item.getAttribute("target") === "_blank",
                    to: item.getAttribute("href"),
                    ...commonProps
                }, children)
            case "H1":
                return React.createElement(Heading, {
                    size: "l",
                    ...commonProps
                }, children)
            case "H2":
                return React.createElement(Heading, {
                    size: "m",
                    ...commonProps
                }, children )
            case "H3":
                return React.createElement(Heading, {
                    size: "s",
                    ...commonProps
                }, children)
            case "P":
                return React.createElement(TextElem, {
                    type: "paragraph",
                    ...commonProps
                }, children)
            case "LI":
                return React.createElement(TextElem, {
                    ...commonProps
                }, children)
        }

        return React.createElement(item.tagName, {style}, children);
    }

    prepareTag = (tagName) => {
        let content = document.getElementById("service-html-content");
        let tag = tagName === "A" ? "SPAN" : (tagName === "LI" ? "LI" : "DIV")

        let items = content.getElementsByTagName(tagName);

        items = [...items];
        for (let i = 0; i < items.length; i++) {
            let el = document.createElement(tag)
            el.setAttribute("id", tagName+"-"+i)
            items[i].parentElement.insertBefore(el, items[i])
            ReactDOM.render(this.prepareElement(items[i]), document.getElementById(tagName+"-"+i))
        }
        for (let i = 0; i < items.length; i++) {
            items[i].remove()
        }
    }

    prepareContent = () => {
        this.prepareTag("P")
        this.prepareTag("H1")
        this.prepareTag("H2")
        this.prepareTag("H3")
        this.prepareTag("LI")
        setTimeout(() => {
            this.prepareTag("A")
        }, 100)
    }

    prepareStyle = (styleStr) => {
        let styles = {};
        if (styleStr) {
            styleStr.split(";").map((item) => {
                let arr = item.split(": ")
                let index = arr[0].indexOf("-")
                if (index !== -1) {
                    arr[0].replace("-", "")
                    arr[0].replace(arr[0][index], arr[0][index].toUpperCase())
                }
                styles[arr[0]] = arr[1]
            })
        }
        return styles
    }

    render() {
        let {link, content, screen_size} = this.props;

        return <div>
            <div id="test"/>
            <div id="service-html-content" dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    }
}