import React, { Component } from 'react';
import {fonts, colors} from '../../DesignSystem';



export default class TextElem extends Component {

    constructor(props) {
        super(props);
        this.state={
            size_elem: this.calcElemSize(props.screen_size),
            screen_size: props.screen_size ? props.screen_size : "xs",
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.setState({
            screen_size: nextProps.screen_size,
            size_elem: this.calcElemSize(nextProps.screen_size)
        })
    }

    calcElemSize = (size)=>{
      if (size !== "xs")
          return "normal";
      else
        return "mobile";
    };

    render() {

        let type=this.props.type;
        let color = colors.dark_gray;
        this.props.color? color = this.props.color : "";

        let result = "";

        switch(type) {
            case "heading": {
                let size = this.props.size;
                let size_elem = this.state.size_elem;
                let styles = Object.assign({color: color}, fonts.headings[size].common_attrs, fonts.headings[size][size_elem], this.props.style);
                switch (size) {
                    case "l":
                        result = <h1 className={"heading__size_l " + this.props.nameClass} {...this.props} style={styles} >{this.props.children}</h1>;
                        break;
                    case "m":
                        result = <h2 className={"heading__size_m " + this.props.nameClass}  {...this.props} style={styles}>{this.props.children}</h2>;
                        break;
                    case "m2":
                        result = <h2 className={"heading__size_m2 " + this.props.nameClass} {...this.props} style={styles} >{this.props.children}</h2>;
                        break;
                    case "s":
                        result = <h3 className={"heading__size_s " + this.props.nameClass} {...this.props} style={styles} >{this.props.children}</h3>;
                        break;
                    default:
                        result = <h2 className={"heading__size_m " + this.props.nameClass} {...this.props} style={styles} >{this.props.children}</h2>;
                        break;
                }
            }
            break;
            case "caption": {
                let styles =  Object.assign({color: color}, fonts.text.caption, this.props.style);
                result = <span {...this.props} style={styles}>{this.props.children}</span>
            }
            break;
            case "date": {
                let styles = Object.assign({color: color}, fonts.text.date, this.props.style);
                result = <span {...this.props} style={styles}>{this.props.children}</span>
            }
            break;
            case "paragraph": {
                let styles = Object.assign({color: color}, fonts.text.body,  this.props.style);
                result = <p {...this.props} className={this.props.className ? this.props.className : ""} style={styles}>{this.props.children}</p>
            }
            break;
            default: {
                let styles = Object.assign({color: color}, fonts.text.body,  this.props.style);
                result = <span {...this.props} className={this.props.className ? this.props.className : ""} style={styles}>{this.props.children}</span>
            }
            break;

        }



        return (
            <React.Fragment>
                {result}
            </React.Fragment>
        )
    }
}

