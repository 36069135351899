import React, { Component } from 'react';
import './Timer.css';
import {getCounter} from "../../tools/tools";

class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: 59
        }

        this.intervalId = null;
    }

    componentDidMount() {
        this.setTimer();
    }

    setTimer = () => {
        this.intervalId = setInterval(() => {
            if (this.state.count <= 0) {
                clearInterval(this.intervalId);
                this.props.onTimerEnd()
                return;
            }

            this.setState(prevState => ({
                count: prevState.count - 1,
            }))
        }, 1000)
    }

    renderSec = (cnt) => {
        return getCounter(cnt, this.props.text ? 'секунду' : 'секунда', 'секунды', 'секунд')
    }

    render() {

        let {text = 'Запросить пароль повторно через'} = this.props;

        return(
            <div className="ugsk-timer">
                {text} <span style={{whiteSpace: 'nowrap'}}>{this.state.count} {this.renderSec(this.state.count)}</span>
            </div>
        )
    }
}

Timer.defaultProps = {
    onTimerEnd: () => {},
}

export default Timer;