import React, { Component } from 'react';

import Caption from "../../../designSystem/elements/text/Caption";

import './ComponentWithCaption.css';

class ComponentWithCaption extends Component {

    render() {

        let { title, screenSize, className } = this.props;

        return (
            <div className={'ugsk-component-with-caption ' + className }>
                <Caption
                    className='ugsk-component-with-caption__caption'
                >
                    { title }
                </Caption>
                { this.props.children }
            </div>
        )
    }
}

export default ComponentWithCaption;