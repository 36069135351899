import React, { Component } from 'react';
import Link from "../../designSystem/elements/links/Link";
import './TosComponent.css';

class TosComponent extends Component {
    render() {
        const privacyPolicyLink = 'https://policies.google.com/privacy';
        const serviceLink = 'https://policies.google.com/terms';

        return (
            <div className="ugsk-tos-component__text__container">
                <p>
                    Этот сайт защищен reCAPTCHA и применяются
                    {' '}
                    <Link to={privacyPolicyLink} typeLink="external" style={{fontSize: '13px', lineHeight: '19px'}}>
                        Политика конфиденциальности
                    </Link> и
                    {' '}
                    <Link to={serviceLink} typeLink="external" style={{fontSize: '13px', lineHeight: '19px'}}>
                        Условия обслуживания Google
                    </Link>.
                </p>
            </div>
        )
    }
}

export default TosComponent;