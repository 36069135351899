import React, { Component } from 'react';

import Heading from './Heading'
import Icon from "../icon/Icon"
import {colors, fonts, spaces} from "../../DesignSystem";

export default class ToggleHeading extends Component {

    constructor(props){
        super(props);
        this.state={
            opened: false
        }
    }

    toggleFunc=()=>{
        this.setState((prevState)=>{
            let c = prevState;
            c.opened = !prevState.opened;
            return c
        })
    };
    render() {
        let {
            content,
            size = "m"
        }
        =this.props;

        return (
            <div className={"toggle-heading"}>
                <div className={"row-toggle-heading"} onClick={this.toggleFunc} style={{marginBottom: fonts.headings[size].common_attrs.marginBottom}}>
                     <Heading  color={colors.middle_blue} size={size}  screen_size = {this.props.screen_size}>{this.props.children}</Heading>
                    <span style={{marginLeft: spaces.xs}}>
                        <Icon className={"toggle-heading__icon"} style={this.state.opened ? {transform: 'rotate(180deg)'} : {}} name={"chevron-down"} width={"18px"} height={"16px"} fill={colors.middle_blue}/>
                    </span>
                </div>
                <div className="toggle-heading__content" style={this.state.opened ? {maxHeight: 10000, overflow: 'visible', opacity: 1} : {} }>
                    {content}
                </div>
            </div>
        )
    }
}
