import React, { Component } from 'react';
import TextElem from '../text/TextElem';
import {colors, spaces} from "../../DesignSystem";


export default class dataLabled extends Component{

    render() {

        return (
            <React.Fragment>
                <div className={"dataLabled"}>
                    <div style={{marginBottom: spaces.xs}}>
                        <TextElem color={colors.gray}>{this.props.label}</TextElem>
                    </div>
                    <div>
                        {this.props.text}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

