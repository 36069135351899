import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";

export default class Layout extends Component {

    constructor(props){
        super(props);
        this.state = {
            pathname: props.history.location.pathname
        };

        this.hideHeaderRoutes = ["/auth", "/pdf"];
        this.hideFooterRoutes = ["/pdf"];
    }

    shouldComponentUpdate(nextProps, nextState){

        if (nextProps.isGuest !== this.props.isGuest && nextProps.isGuest) {
            return true;
        }

        if (nextProps.history.location.pathname != this.state.pathname) {
            if (nextProps.pageLoaded == nextProps.history.location.pathname) {
                this.setState({pathname: nextProps.history.location.pathname});
                // console.log("LET UPDATE 1");
                return true;
            } else {
                if (!nextProps.pageLoading) {
                    // console.log("RUN LOAD", nextProps.pageLoaded, nextProps.history.location.pathname);
                    this.props.loadPage(nextProps.history.location.pathname);
                }
                return false;
            }
        } else {
            // console.log("LET UPDATE 2");
            return true;
        }

    }

    render() {

        let {
            id,
            className,
            info,
            screen_size,
            isGuest,
            pageLoaded,
            children,
            history,
            reviewStatus,
            reviewMessage,
            sendReview,
            clearReview
        } = this.props;

        return <div id={id} className={className} key={pageLoaded}>
            {this.hideHeaderRoutes.indexOf(history.location.pathname) !== -1 ? null : <Header isGuest={isGuest}  {...info} screen_size={screen_size} />}
            {children}
            {this.hideFooterRoutes.indexOf(history.location.pathname) !== -1 ? null : <Footer isGuest={isGuest}
                                                                                              {...info}
                                                                                              screen_size={screen_size}
                                                                                              reviewStatus={reviewStatus}
                                                                                              reviewMessage={reviewMessage}
                                                                                              sendReview={sendReview}
                                                                                              clearReview={clearReview}
            />}
        </div>;
    }

}