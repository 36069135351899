import React, { Component } from 'react';
import Input from "../../../designSystem/elements/input/Input";
import Link from '../../../designSystem/elements/links/Link';
import AuthActions from "../AuthActions/AuthActions";
import './SMSCode.css';
import {smsCodeMask} from "../../../tools/mask-helper";
import SimpleHeader from "../../SimpleHeader/SimpleHeader";
import Icon from "../../../designSystem/elements/icon/Icon";
import Timer from "../../Timer/Timer";
import Caption from "../../../designSystem/elements/text/Caption";
import {InputStyle} from "../../../designSystem/DesignSystem";

class SMSCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTimer: true,
        }
    }

    hideTimer = () => {
        this.setState({showTimer: false});
    }

    onSubmit = (ev) => {
        this.setState({showTimer: true});

        this.props.confirmedPhoneNumber? this.props.onSubmit(ev, 'forgotPassword') : this.props.onSubmit(ev, 'signUp')
    }

    render() {
        const {
            errors={},
            onSubmit = (e, type) => {e.preventDefault()},
            onClick = () => {},
            onChange = (e, type) => {},
            onBlur = (e, type) => {},
            onFocus = (e, type) => {},
        } = this.props;

        return (
            <form onSubmit={onSubmit}>
                <div className="ugsk-sms-code__header_container">
                    <SimpleHeader size="h2">Ввод СМС-кода</SimpleHeader>
                </div>
                <div className="ugsk-sms-code__block__container">
                    <div className="ugsk-sms-code__input__container">
                        <Input
                            errorAction={onClick}
                            mask={smsCodeMask}
                            typeInput='input'
                            type='number'
                            label='Код из SMS'
                            width='100%'
                            name="code"
                            onChange={(ev) => onChange(ev, 'confirmValues')}
                            error_text={errors.code || errors.phone_number}
                            onBlur={(ev) => onBlur(ev, 'confirmation')}
                            onFocus={(ev) => onFocus(ev, 'confirm')}
                        />
                    </div>
                    <div className={"ugsk-sms-code__container"+(errors.code ? " has-error" : "")}>
                        <Icon
                            className={'ugsk-form-input-code__icon'}
                            name={'rotate-ccw'}
                        />
                        {this.state.showTimer ? (
                            <Timer onTimerEnd={this.hideTimer} />
                        ) : (
                            <Link func={this.onSubmit} className='ugsk-form-input-code__link'>
                                Выслать еще раз
                            </Link>
                        )}
                    </div>
                </div>
                {errors.recaptcha ? <div className='ugsk-sign-up__checkbox__container'>
                    <Caption className="input__error" style={{color: InputStyle.layout_default.error_mode.errColor}}>
                        <div dangerouslySetInnerHTML={{__html: errors.recaptcha}} />
                    </Caption>
                </div> : null}
                <div className="ugsk-sms-code__actions_container">
                    <AuthActions
                        onSubmit={onSubmit}
                        whiteBtnLabel="Назад"
                        typeForBlueBtn="SMSCode"
                        type="formAuth"
                        blueBtnLabel="Далее"
                        onClick={onClick}
                    />
                </div>
            </form>
        )
    }
}

export default SMSCode;