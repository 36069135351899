import React, { Component } from 'react';
import Icon from "../icon/Icon";
import StateMouse from "../StateMouse";
import {buttonStyle, LinkStyle, colors} from "../../DesignSystem";
import TextElem from "../text/TextElem";
import FormEditPersonalData from "../../../components/Settings/FormEditPersonalData/FormEditPersonalData";
import {Link} from "react-router-dom";
import './link.css';


export default class StyledLink extends StateMouse{

    constructor(props){
        super(props);

        this.listDropDownRef = React.createRef();
        this.menuRef = React.createRef();
        this.editRef = React.createRef();

    }

    componentDidMount() {
            if(this.props.typeLink === "menu"){
                this.setState({
                    opened: false,
                    styleList: {left: "auto", right: -15}
                },);

                document.addEventListener("click", ()=>{this.closeElem(event, this.menuRef)})
                document.addEventListener("touchstart", ()=>{this.closeElem(event, this.menuRef)})
            }

            if(this.props.typeLink === "link_edit"){
                this.editRef.current.addEventListener("mousemove", this.onMouseMove)
            }

            if (this.props.disabled) {
                this.setState({
                    mode: "disabled_mode",
                    prevMode: "disabled_mode"
                })
            }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.changeAddressSuccess !== nextProps.changeAddressSuccess && !!this.state.openFormEdit || !!this.props.changeEmailSuccess && !!nextProps.changeEmailSuccess.changeEmail && nextProps.changeEmailSuccess.changeEmail ) {
            this.closeFormEdit();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.typeLink === "link_edit" && this.editRef.current && prevState.openFormEdit && !this.state.openFormEdit){
            this.editRef.current.addEventListener("mousemove", this.onMouseMove)
        }
    }

    componentWillUnmount() {
        if(this.props.typeLink === "link_edit" && this.editRef.current){
            if (this.styleElem) {
                this.styleElem.remove();
            }
            this.editRef.current.removeEventListener("mousemove", this.onMouseMove)
        }
    }

    onMouseMove = (e) => {
        if (this.styleElem) {
            this.styleElem.remove();
        }
        this.styleElem = document.head.appendChild(document.createElement("style"));
        this.styleElem.innerHTML = ".wrap__link_edit .row__link_edit:hover:after {left: "+(e.pageX - this.editRef.current.offsetLeft - 15)+"px}";
    }

    closeElem=(event, ref)=>{

        let node = event.target;

        let elem = ref.current;

        if (elem) {
            let children = elem.getElementsByTagName('*');

            let shouldClose = true;

            if (node === elem) {
                shouldClose = false
            }
            else {
                for (let i = 0; i < children.length; i++) {
                    if (node===children[i]) {
                        shouldClose = false;
                        break;
                    }
                }
            }
            if (shouldClose === true) {
                this.closeListFunc()
            }
        }

    };
    closeListFunc=()=>{
        this.setState({
            opened: false
        });
    };


    onToggleListMenu = () => {
        this.setState((prevState) => {
            return ({opened: !prevState.opened})
        }, () => {
            this.calcPosList()
        });
    };

    calcPosList=()=>{
        if (this.listDropDownRef.current) {
            if (this.state.opened === true) {
                if (this.listDropDownRef.current.getBoundingClientRect().left <= 20) {
                    this.setState({
                        styleList: {left: 0, right: "auto"}
                    })
                } else {
                    this.setState({
                        styleList: {left: "auto", right: "-15px"}
                    })
                }
            } else {
                this.setState({
                    styleList: {left: "auto", right: "-15px"}
                })
            }
        }
    };

    handlerClickOnLinkIcon = () => {
        this.setState(prevState => {
            return {
                openFormEdit: !prevState.openFormEdit,
                mode: "normal_mode"
            }
        })

        if (!!this.props.clearErrorTxt) this.props.clearErrorTxt();
    };

    closeFormEdit= ()=>{
        this.setState((prevState)=> {
            return {
                openFormEdit: false
            }
        })
        // if (!!this.props.clearErrorTxt) this.props.clearErrorTxt();
    };

    getStylesForLinkWithMenuToMenu = () => {

        let standartStyleList = {},
            animateStyle = {};

        if (this.state.styleList) {
            standartStyleList = {
                left: this.state.styleList.left,
                right: this.state.styleList.right
            }
        } else {
            standartStyleList = {
                left: "5px",
                right: "auto"
            }
        }

        if (this.state.opened) {
            animateStyle = {
                maxHeight: '500px',
                paddingTop: '4px',
                borderColor: '#EAEDF5',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.09)'
            }
        } else {
            animateStyle = {}
        }

        return {
            ...standartStyleList,
            ...animateStyle
        }
    };

    getStylesForLinkWithMenuToLinkItem = () => {
        let styleLinkItem = {};

        if (this.state.opened) {
            styleLinkItem = {
                opacity: 1,
                paddingTop: '10px',
                paddingBottom: '10px',
                overflow: 'visible',
                maxHeight: '150px',
                display: 'inline-flex'
            }
        } else {
            styleLinkItem = {}
        }

        return styleLinkItem;
    };

    getStylesForLinkWithMenuToIcon = () => {
        let styleIcon = {};

        if (this.state.opened) {
            styleIcon = {
                display: 'block'
            }
        } else {
            styleIcon = {}
        }

        return styleIcon;
    };

    renderLink = ()=>{

            let {
                to = "#",
                func,
                noFollow,
                targetBlank,
                label,
                noArrow,
                className,
                screenSize,
                valueLink = '',
                onChange = (e, type) => {},
                disabledBtnReady,
                onChangeAddress,
                onChangeEmail,
                error_text,
                clearErrorTxt,
                onBlurEmail,
                onFocusEmail,
                onChangeEmailDataRequest,
                inputOnKeyDown,
                onChangePhone,
                inputPhoneOnKeyDown,
                errorTxtPhoneValidation,
                onBlurPhone,
                changePhoneRequest,
                clientData,
                onChangePhoneOld,
                onChangeSmsCode,
                errorTxtSmsCodeValidation,
                onChangeConfirmSmsRequest,
                onBlurValues,
                confirmPhoneNumber,
                requestAction,
                setActionDefault,
                sendConfirmPhoneRequest,
                clearChangePhoneData
            } = this.props;
            let layout = this.props.layout ? this.props.layout : "layout_default";
            let typeLink = this.props.typeLink ? this.props.typeLink : "link_simple";  //link_simple, link_menu, link_edit

            let elem = '';
            this.stylesElem = "";

            switch(typeLink) {
                case "menu": {

                    elem= <div className={"link__dropdown"+(className ? " "+className : "")} style={this.props.style} ref={this.menuRef} >
                        <div className="link__row" onClick={this.onToggleListMenu}>
                            <div className="link"><TextElem color={colors.blue}>{this.props.children}</TextElem></div>
                            {noArrow ? null : <Icon name={"chevron-down"} width={"15px"} height={"12px"} fill={colors.blue} style={{marginLeft: "4px"}}/>}
                        </div>

                        {this.state.opened ?
                            <div className={"triangle"}></div>
                            : ""
                        }
                        <div className={"link__list"} ref={this.listDropDownRef} style={ this.getStylesForLinkWithMenuToMenu() }>
                            {this.props.list.map((item,key)=>
                                <StyledLink key={key} style={ this.getStylesForLinkWithMenuToLinkItem() } isMenu={true} to={this.props.list[key].to ? this.props.list[key].to : "#" } func={this.props.list[key].func} typeLink={"link_iconed"} className={"link_list__dropdown_item link__dropdown_item"} icon={this.props.list[key].icon} name={item.name} onClick={this.onToggleListMenu} />
                            )}
                        </div>
                    </div>
                }
                break;
                case "link_edit": {

                    if (this.props.disabled == true){
                        this.stylesElem = Object.assign({}, LinkStyle.link_edit[layout]["disabled_mode"], LinkStyle.default_attrs);
                    }else{
                        this.stylesElem = Object.assign({}, LinkStyle.link_edit[layout][this.state.mode], LinkStyle.default_attrs);
                    }

                    elem = <div className={"wrap__link_edit " + className}>
                            {
                                this.state.openFormEdit
                                ?
                                    <FormEditPersonalData
                                        typeFormEdit={ this.props.typeFormEdit }
                                        screenSize={ this.props.screenSize }
                                        closeFormEdit = {this.closeFormEdit}
                                        screenSize={ screenSize }
                                        valueImput={ valueLink }
                                        onChange = { onChange }
                                        disabledBtnReady={ disabledBtnReady }
                                        onChangeAddress={ onChangeAddress }
                                        onChangeEmail={ onChangeEmail }
                                        error_text={ error_text }
                                        clearErrorTxt={ clearErrorTxt }
                                        onBlurEmail={ onBlurEmail }
                                        onFocusEmail={ onFocusEmail }
                                        onChangeEmailDataRequest={ onChangeEmailDataRequest }
                                        inputOnKeyDown={inputOnKeyDown}
                                        onChangePhone={onChangePhone}
                                        inputPhoneOnKeyDown={inputPhoneOnKeyDown}
                                        errorTxtPhoneValidation={errorTxtPhoneValidation}
                                        onBlurPhone={onBlurPhone}
                                        changePhoneRequest={changePhoneRequest}
                                        clientData={clientData}
                                        onChangePhoneOld={onChangePhoneOld}
                                        onChangeSmsCode={onChangeSmsCode}
                                        errorTxtSmsCodeValidation={errorTxtSmsCodeValidation}
                                        onChangeConfirmSmsRequest={onChangeConfirmSmsRequest}
                                        onBlurValues={onBlurValues}
                                        confirmPhoneNumber={confirmPhoneNumber}
                                        requestAction={requestAction}
                                        setActionDefault={setActionDefault}
                                        sendConfirmPhoneRequest={sendConfirmPhoneRequest}
                                        clearChangePhoneData={clearChangePhoneData}
                                    />
                                :
                                   <React.Fragment>
                                        <div className={"label__link_edit"} style={LinkStyle.label}>{label}</div>
                                        <div className={"link row__link_edit"} ref={this.editRef}  {...this.mouseEvents}>
                                            <div className="text">
                                                <a onClick={this.handlerClickOnLinkIcon} href={()=>{return false}} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} style={this.stylesElem}>{this.props.children}</a>
                                                <div className="link__icon" onClick={ this.handlerClickOnLinkIcon }>
                                                    <Icon name={"edit"} width={"14px"} height={"14px"} fill={this.stylesElem.fill}/>
                                                </div>

                                            </div>

                                        </div>
                                    </React.Fragment>
                            }


                    </div>
                }
                break;
                case "link_dotted":{
                    let stylesElem = Object.assign({}, LinkStyle.link_dotted[layout][this.state.mode], LinkStyle.default_attrs);
                    if (typeof func === 'function') {
                        elem = <a onClick={func} className={"link"} href={to ? to : "#"} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents} style={stylesElem}>{this.props.children}</a>
                    }
                    else {
                        elem = <a href={to ? to : "#"} className={"link"} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents} style={stylesElem}>{this.props.children}</a>
                    }
                }
                break;
                case "link_iconed":{
                    let styleElem = {...LinkStyle.link_simple[layout][this.state.mode], ...LinkStyle.default_attrs, background: LinkStyle.link_menu[this.state.mode].backgroundColor, ...this.props.style};
                    if (typeof func === 'function') {
                        elem = <Link onClick={func} to={to ? to : "#"} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} className={this.props.className} {...this.mouseEvents} style={styleElem}>
                                {<Icon {...this.props.icon} fill={LinkStyle.link_menu[this.state.mode].fill}/>} <span className={"name"} style={{color: LinkStyle.link_menu[this.state.mode].color}}>{this.props.name}</span>
                            </Link>
                    }
                    else {
                        elem =
                            <Link to={to ? to : "#"} onClick={this.props.onClick} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} className={this.props.className} {...this.mouseEvents} style={styleElem}>
                                {<Icon {...this.props.icon} fill={LinkStyle.link_menu[this.state.mode].fill}/>} <span className={"name"} style={{color: LinkStyle.link_menu[this.state.mode].color}}>{this.props.name}</span>
                            </Link>
                    }

                }
                break;
                case "external":{
                    if (this.props.disabled ===true) {
                        let stylesElem = Object.assign({}, LinkStyle.default_attrs, LinkStyle.disabled_attrs);
                        elem = <a href={() => {return false}} className={"link " + this.props.className} target={targetBlank === undefined || targetBlank ? "_blank" : "_self"}
                                  rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents}
                                  style={stylesElem}>{this.props.children}</a>
                    }
                    else {
                        let stylesElem = Object.assign({}, LinkStyle.link_simple[layout][this.state.mode], LinkStyle.default_attrs, this.props.style);
                        elem = <a href={to ? to : "#"} className={"link " + this.props.className} target={targetBlank === undefined || targetBlank ? "_blank" : "_self"}
                                  rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents}
                                  style={stylesElem}>{this.props.children}</a>
                    }

                }
                break;
                default:{
                    let stylesElem = Object.assign({}, LinkStyle.link_simple[layout][this.state.mode], LinkStyle.default_attrs, this.props.style);
                    if (typeof func === 'function') {
                       elem = <a onClick={func} className={"link " + this.props.className} href={to ? to : ()=>{return false}} target={targetBlank ? "_blank" : ""} rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents} style={stylesElem}>{this.props.children}</a>
                    }
                    else {
                        elem = <Link to={to ? to : "#"} className={"link " + this.props.className} target={ targetBlank ? "_blank" : "" } rel={noFollow ? 'nofollow' : ''} {...this.mouseEvents} style={stylesElem}>{this.props.children}</Link>
                    }
                }
                break;
            }
            return elem;
    };

    render() {

        return (
            <React.Fragment>
                {this.renderLink()}
            </React.Fragment>
        )
    }
}

