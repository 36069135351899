export const colors = {
    text_color: '#222222',
    blue_color: '#2C4799',
    lightGray_color: '#969696',
    lightGray_opacity_color: "rgba(150, 150, 150, 0.4)",
    lightGray_opacity_color6: "rgba(150, 150, 150, 0.6)",
    grayBg_color: '#EAEDF5',
    light_blue1: "rgba(44, 71, 153, 0.3)",



    white: 'white',
    white_opacity2: "rgba(255, 255, 255, 0.2)",
    white_opacity3: "rgba(255, 255, 255, 0.3)",
    white_opacity4: "rgba(255, 255, 255, 0.4)",
    white_opacity5: "rgba(255, 255, 255, 0.5)",
    white_opacity6: "rgba(255, 255, 255, 0.6)",
    white_opacity7: "rgba(255, 255, 255, 0.7)",
    white_opacity8: "rgba(255, 255, 255, 0.8)",
    dark_gray: "#222222",
    dark_gray_opacity3 : "rgba(34,34,34,0.3)",
    dark_gray_opacity5 : "rgba(34,34,34,0.5)",
    gray: "#969696",
    gray_opacity2: "rgba(150,150,150,0.2)",
    gray_opacity3: "rgba(150,150,150,0.3)",
    gray_opacity4: "rgba(150,150,150,0.4)",
    gray_opacity5: "rgba(150,150,150,0.5)",
    gray_opacity8: "rgba(150,150,150,0.8)",
    light_gray: "#EAEDF5",
    light_gray_opacity2: "rgba(234,237,245,0.2)",
    light_gray_opacity5: "rgba(234,237,245,0.5)",
    extra_light_gray: "#DADADA",


    dark_blue: "#012962",
    dark_blue_opacity3: "rgba(1, 41, 98, 0.3)",
    blue: "#1A4686",
    middle_blue: "rgba(44, 71, 153, 1)",
    middle_blue_opacity3: "rgba(44, 71, 153, 0.3)",
    middle_blue_opacity5: "rgba(44, 71, 153, 0.5)",
    middle_blue_opacity8: "rgba(44, 71, 153, 0.8)",
    light_blue: "#3765BD",
    light_blue_opacity3: "rgba(55, 101, 189, 0.3)",
    light_blue_opacity5: "rgba(55, 101, 189, 0.5)",
    blue_opacity: "rgba(246, 248, 255, 0.7)",

    dark_red: "#C61C22",
    red: "#EC1B23",
    green: "#39AD1C"
};


export const spaces={
   xl: "70px",
   l: "50px",
   m: "20px",
   sm: "15px",
   s: "10px",
   xs: "5px",

   smNegative: "-15px",
   mNegative: "-20px"
};


const fontFamilies = {
    headings: 'Montserrat',
    body: 'Roboto',
};

const fontWeights = {
    regular: 'normal',
    medium: 500,
    bold: 'bold',
    normal: 'normal',
};
export const fontSizes = {
    extra: {
        default: {
            size: "40px",
            lh: "50px"
        },
        mobile:{
            size: "28px",
            lh: "40px"
        }
    },
    big: {
        default: {
            size: "24px",
            lh: "29px",
            fw: fontWeights.bold
        },
        mobile:{
            size: "20px",
            lh: "26px",
            fw: fontWeights.bold
        }
    },
    mediumUp: {
        default: {
            size: "20px",
            lh: "26px"
        },
        mobile:{
            size: "20px",
            lh: "26px"
        }
    },
    mediumSmall: {
        default: {
            size: "18px",
            lh: "25px",
            fw: "500"
        },
        mobile:{
            size: "18px",
            lh: "25px",
            fw: "500"
        }
    },
    medium: {
        default: {
            size: "17px",
            lh: "25px"
        },
        mobile:{
            size: "17px",
            lh: "25px"
        }
    },
    regular: {
        default: {
            size: "15px",
            lh: "23px"
        },
        mobile:{
            size: "15px",
            lh: "23px"
        }
    },
    small: {
        default: {
            size: "13px",
            lh: "19px"
        },
        mobile:{
            size: "13px",
            lh: "19px"
        }
    },
    tiny: {
        default: {
            size: "11px",
            lh: "18px"
        },
        mobile:{
            size: "11px",
            lh: "18px"
        }
    }

};

export const fonts = {

    headings:
        {
            l:
                {
                    common_attrs:{
                        fontFamily: fontFamilies.headings,
                        fontWeight: fontWeights.bold,
                        marginBottom: "20px",
                        marginTop: "70px"
                    },
                    normal:{
                        fontSize: fontSizes.extra.default.size,
                        lineHeight: fontSizes.extra.default.lh,
                    },
                    mobile: {
                        fontSize: fontSizes.extra.mobile.size,
                        lineHeight: fontSizes.extra.mobile.lh,
                    }
                },
            m:
                {
                    common_attrs:{
                        fontFamily: fontFamilies.headings,
                        fontWeight: fontWeights.bold,
                        marginBottom: "20px",
                        marginTop: "40px"
                    },
                    normal:{
                        fontSize: fontSizes.big.default.size,
                        lineHeight: fontSizes.big.default.lh,
                    },
                    mobile: {
                        fontSize: fontSizes.big.mobile.size,
                        lineHeight: fontSizes.big.mobile.lh,
                    }
                },
            m1:{
                common_attrs:{
                    fontFamily: fontFamilies.headings,
                    fontWeight: fontWeights.bold,
                },
                normal:{
                    fontSize: fontSizes.extra.mobile.size,
                    lineHeight: fontSizes.extra.mobile.lh,
                },
                mobile: {
                    fontSize: fontSizes.mediumUp.mobile.size,
                    lineHeight: fontSizes.mediumUp.mobile.lh,
                }
            },
            m2:{
                common_attrs:{
                    fontFamily: fontFamilies.headings,
                    fontWeight: fontWeights.bold,
                    marginBottom: "10px"
                },
                normal:{
                    fontSize: fontSizes.mediumUp.default.size,
                    lineHeight: fontSizes.mediumUp.default.lh,
                },
                mobile: {
                    fontSize: fontSizes.mediumUp.mobile.size,
                    lineHeight: fontSizes.mediumUp.mobile.lh,
                }
            },
            s:
                {
                    common_attrs:{
                        fontFamily: fontFamilies.body,
                        fontWeight: fontWeights.medium,
                        marginBottom: "20px",
                        marginTop: "20px"
                    },
                    normal:{
                        fontSize: fontSizes.medium.default.size,
                        lineHeight: fontSizes.medium.default.lh,
                    },
                    mobile: {
                        fontSize: fontSizes.medium.mobile.size,
                        lineHeight: fontSizes.medium.mobile.lh,
                    }
                }
        },
    text: {
       body:
            {
                fontSize: fontSizes.regular.default.size,
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.regular,
                lineHeight: fontSizes.regular.default.lh,
            },
        caption:
            {
                fontSize: fontSizes.small.default.size,
                lineHeight: fontSizes.small.default.lh,
                fontWeight: fontWeights.regular,
                fontFamily: fontFamilies.body,
            },
        date: {
            fontSize: fontSizes.tiny.default.size,
            fontFamily: fontFamilies.body,
            fontWeight: fontWeights.medium,
            lineHeight: fontSizes.tiny.default.lh,
            textTransform: "uppercase"
        }
    }
};

export const NotificationStyle={
    common_attrs: {
        padding: "11px 15px",
        marginBottom: "10px",
        boxShadow: "0px 8px 17px rgba(0, 0, 0, 0.12)",
    },
    rightBottomCards:{
        border: "1px solid " + colors.light_gray,
        maxWidth: "310px"
    }
};

export const CardStyle={
  default_attrs:{
    borderRadius: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  cardAdvert:{
    common_attrs:{
     
    },
    normal:{
      common_attrs:{
        background: colors.white,
        paddingTop: "22px",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "240px",
        heightContent: "200px"
      },
      normal_mode: {
        lay:{
          opacity: 0,
          zIndex: -1
        }      
      },
      hovered_mode: {
        lay:{
            opacity: 1,
            zIndex: 2,
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          borderRadius: "20px",
        },
        boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.15)"
      },
      clicked_mode: {
        lay:{
            opacity: 1,
            zIndex: 1,
          backgroundColor: "rgba(21, 21, 21, 0.1)",
           borderRadius: "20px",
        },
        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)"
      }
    },
    mobile:{
      common_attrs:{
        background: colors.white,
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "auto",
        minHeight: "180px",
        heightContent: "150px"
      },
      normal_mode: {
        lay:{
          display: "none"
        }      
      },
      hovered_mode: {
        lay:{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          borderRadius: "20px"
        }              
      },
      clicked_mode: {
        lay:{
          backgroundColor: "rgba(21, 21, 21, 0.1)",
           borderRadius: "20px"
        }
      }
      
    }
  },
  cardPolis:{
    
    normal:{
        layout_default:{
          common_attrs:{
            background: colors.white,
            paddingLeft: "45px",
            paddingRight: "20px",
            height: "220px",
              border: "1px solid " + colors.light_gray,
              bgIcon: colors.light_gray
          },
          normal_mode: {
            titleColor: colors.dark_gray,
            boxShadow: "none"
          },
          hovered_mode: {
             titleColor: colors.middle_blue,
              boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.15)",
          },
          clicked_mode: {
            boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)",
             titleColor: colors.dark_blue
          }
        },
        layout_colored:{
            common_attrs:{
                background: colors.white,
                paddingLeft: "45px",
                paddingRight: "20px",
                height: "220px"
            },
            normal_mode: {
                titleColor: colors.dark_gray,
                boxShadow: "none"
            },
            hovered_mode: {
                titleColor: colors.middle_blue,
                boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.24)",
            },
            clicked_mode: {
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.28)",
                titleColor: colors.dark_blue
            }
        }
    },
    mobile:{
        layout_default: {
            common_attrs: {
                background: colors.white,
                paddingLeft: "15px",
                paddingRight: "15px",
                height: "auto",
                border: "1px solid " + colors.light_gray,
                bgIcon: colors.light_gray
            },
            normal_mode: {
                titleColor: colors.middle_blue,
                boxShadow: "none"
            },
            hovered_mode: {
                titleColor: colors.middle_blue,
                boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.24)",
            },
            clicked_mode: {
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.28)",
                titleColor: colors.middle_blue
            }
        },
      layout_colored:{
          common_attrs: {
              background: colors.white,
              paddingLeft: "15px",
              paddingRight: "15px",
              height: "auto"
          },
          normal_mode: {
              titleColor: colors.middle_blue,
              boxShadow: "none"
          },
          hovered_mode: {
              titleColor: colors.middle_blue,
              boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.24)",
          },
          clicked_mode: {
              boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.28)",
              titleColor: colors.middle_blue
          }
      }
    }
  },
  cardNewPolis:{
     normal:{
      common_attrs:{
        background: "inherit",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "220px",
        overflow: "hidden",
      },
      normal_mode: {
        lay:{
          boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.3)",
          filter: "blur(0.4rem)"
        }      
      },
      hovered_mode: {
        lay:{
           boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.4)",
          filter: "blur(0.4rem)"
        }
      },
      clicked_mode: {
        lay:{
           boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.2)",
          filter: "blur(0.4rem)"
        }
      }
    },
    mobile:{
      common_attrs:{
        background: "inherit",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "153px",
        overflow: "hidden"
      },
       normal_mode: {
        lay:{                    
          boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.3)",
          filter: "blur(10px)"
        }      
      },
      hovered_mode: {
        lay:{
           boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.5)",
          filter: "blur(10px)"
        }              
      },
      clicked_mode: {
        lay:{
           boxShadow: "inset 0 0 0 200px rgba(255, 255, 255, 0.4)",
          filter: "blur(10px)"
        }
      }
      
    }
  },
  cardNewAdvert:{
     normal:{
      common_attrs:{
        background: "inherit",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "240px",
        overflow: "hidden"
      },
      normal_mode: {
        border: "1px solid "+colors.grayBg_color
      },
     hovered_mode: {
         border: "1px solid "+colors.grayBg_color,
         boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.15)"
     },
     clicked_mode: {
         border: "1px solid "+colors.grayBg_color,
         boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)"
     }
    },
    mobile:{
      common_attrs:{
        background: "inherit",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "130px",
        overflow: "hidden"
      },
        normal_mode: {
            border: "1px solid "+colors.grayBg_color
        },
        hovered_mode: {
            border: "1px solid "+colors.grayBg_color,
            boxShadow: "0px 10px 22px rgba(0, 0, 0, 0.15)"
        },
        clicked_mode: {
            border: "1px solid "+colors.grayBg_color,
            boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)"
        }

    }
  }
};

export const PaginationStyle = {
    default_attrs: {
        width: "48px",
        height: "48px",
        color: colors.dark_gray,
        fontSize: "15px",
        marginRight: "10px"
    },
    mode_attrs: {
        normal_mode: {
            backgroundColor: colors.white,
            color: colors.dark_gray,
            border: "none"
        },
        hovered_mode: {
            minWidth: "48px",
            backgroundColor: colors.white,
            color: colors.dark_gray,
            border: "1px solid " + colors.light_blue_opacity3
        },
        active_mode: {
            minWidth: "48px",
            backgroundColor: colors.middle_blue,
            color: colors.white,
            border: "1px solid " + colors.middle_blue
        }
    },

};

export const CheckBoxRadioStyle={
    default_attrs: {

        text:{
            fontSize: fonts.text.body.fontSize,
            fontFamily: fonts.text.body.fontFamily,
            lineHeight: fonts.text.body.lineHeight,
            fontWeight: fonts.text.body.fontWeight,
        },
        icon:{
            checkbox: {
                display: "flex",
                borderRadius: "4px",
                border: "1px solid transparent",
                width: "22px",
                minWidth: "22px",
                height: "22px",
                marginRight: "10px"
            },
            radio: {
                display: "flex",
                borderRadius: "50%",
                border: "1px solid transparent",
                width: "22px",
                height: "22px",
                marginRight: "10px"
            }
        }
    },
    icon_attrs:{
        layout_default: {
            normal_mode: {
                backgroundColor: colors.white,
                borderColor: colors.light_blue_opacity3
            },
            hovered_mode: {
                backgroundColor: colors.light_gray,
                borderColor: colors.light_blue_opacity5
            },
            selected_mode: {
                backgroundColor: colors.middle_blue,
                borderColor: colors.dark_blue_opacity3,
                fill: colors.white
            },
            disabled_mode:{
                backgroundColor: colors.light_gray,
                borderColor: colors.light_gray
            }
        },

        layout_colored: {
            normal_mode: {
                backgroundColor: "transparent",
                borderColor: colors.white_opacity3
            },
            hovered_mode: {
                backgroundColor: colors.white_opacity2,
                borderColor: colors.white_opacity6
            },
            selected_mode: {
                backgroundColor: colors.white,
                borderColor: colors.white,
                fill: colors.middle_blue
            },
            disabled_mode:{
                backgroundColor: colors.light_gray_opacity2,
                borderColor: colors.light_gray_opacity2
            }
        }
    },
    text_attrs:{
        layout_default: {
            normal_mode: {
                color: colors.dark_gray
            },
            hovered_mode: {
                color: colors.middle_blue
            },
            selected_mode: {
                color: colors.dark_gray
            },
            disabled_mode:{
                color: colors.gray
            }
        },

        layout_colored: {
            normal_mode: {
                color: colors.white
            },
            hovered_mode: {
                color: colors.white
            },
            selected_mode: {
                color: colors.white
            },
            disabled_mode:{
                color: colors.white_opacity6
            }
        }
    }

};

export const LinkStyle={

    default_attrs: {
        fontSize: fonts.text.body.fontSize,
        fontFamily: fonts.text.body.fontFamily,
        lineHeight: fonts.text.body.lineHeight,
        fontWeight: fonts.text.body.fontWeight,
        textDecoration: "none"
    },
    disabled_attrs: {
        color: colors.gray
    },
    label:{
        color: colors.gray,
        fontSize: fonts.text.body.fontSize,
        fontFamily: fonts.text.body.fontFamily,

        fontWeight: fonts.text.body.fontWeight
    },


    link_simple:{
        layout_default: {
            normal_mode: {
                color: colors.middle_blue,
                borderBottom: "1px solid transparent"
            },
            hovered_mode: {
                color: colors.light_blue,
                borderBottom: "1px solid " + colors.light_blue_opacity3
            },
            clicked_mode: {
                color: colors.dark_blue,
                borderBottom: "1px solid " + colors.dark_blue_opacity3
            }
        },

        layout_colored: {
            normal_mode: {
                color: colors.white,
                borderBottom: "1px solid " + colors.white_opacity2
            },
            hovered_mode: {
                color: colors.white,
                borderBottom: "1px solid " + colors.white_opacity6
            },
            clicked_mode: {
                color: colors.white,
                borderBottom: "1px solid " + colors.white_opacity6
            }
        }
    },
    link_dotted: {
        layout_default: {
            normal_mode: {
                color: colors.middle_blue,
                borderBottom: "1px dotted " + colors.middle_blue_opacity5
            },
            hovered_mode: {
                color: colors.light_blue,
                borderBottom: "1px dotted " + colors.light_blue
            },
            clicked_mode: {
                color: colors.dark_blue,
                borderBottom: "1px dotted " + colors.dark_blue
            }
        },

        layout_colored: {
            normal_mode: {
                color: colors.white,
                borderBottom: "1px dotted " + colors.white_opacity6
            },
            hovered_mode: {
                color: colors.white,
                borderBottom: "1px dotted " + colors.white
            },
            clicked_mode: {
                color: colors.white,
                borderBottom: "1px dotted " + colors.white
            }
        }
    },
    link_menu:{
        normal_mode: {
            color: colors.dark_gray,
            fill: colors.middle_blue_opacity5,
            backgroundColor: "transparent"
        },
        hovered_mode: {
            color: colors.middle_blue,
            fill: colors.middle_blue,
            backgroundColor: colors.light_gray_opacity5
        },
        clicked_mode: {
            color: colors.middle_blue,
            fill: colors.middle_blue,
            backgroundColor: colors.light_gray
        }

    },
    link_edit:{
        layout_default: {
            normal_mode: {
                color: colors.middle_blue,
                fill: colors.middle_blue_opacity5
            },
            hovered_mode: {
                color: colors.light_blue,
                fill: colors.middle_blue_opacity8
            },
            clicked_mode: {
               color: colors.dark_blue,
               fill: colors.dark_blue
            },
            disabled_mode: {
                color: colors.dark_gray,
                fill: colors.dark_gray_opacity3
            }
        },

        layout_colored: {
            normal_mode: {
                color: colors.white,
                borderBottom: "1px dashed " + colors.white_opacity6,
                fill: colors.white_opacity6
            },
            hovered_mode: {
                color: colors.white,
                borderBottom: "1px dashed " + colors.white,
                fill: colors.white_opacity8
            },
            clicked_mode: {
                color: colors.white,
                borderBottom: "1px dashed " + colors.white,
                fill: colors.white
            },
            disabled_mode: {
                color: colors.white,
                fill: colors.white_opacity3
            }
        }
    }
};

export const buttonStyle = {

    default_attrs: {
        padding: "5px 20px",
        height: "42px",
        fontSize: fonts.text.body.fontSize,
        fontWeight: "normal",
        transition: "all .3s ease-in-out"
    },

    default_attrs_button_iconed:{
        border: "none",
        backgroundColor: "transparent",
        fontSize: fonts.text.body.fontSize,
        fontWeight: "normal",
        transition: "all .3s ease-in-out"
    },

    button_standard_disabled: {
        layout_default: {
            backgroundColor: colors.light_gray,
            color: colors.gray,
            border: "1px solid " + colors.light_gray
        },
        layout_colored: {
            backgroundColor: colors.white_opacity2,
            color: colors.white_opacity2,
            border: "1px solid rgba(1,1,1,0)"
        }
    },

    button_iconed_disabled: {
        layout_default: {
            icon_color: colors.gray_opacity5,
            color: colors.gray,

        },
        layout_colored: {
            icon_color: colors.white_opacity3,
            color: colors.white_opacity4,
            borderBottomSpan: "none"

        }
    },

    button_filled: {

        layout_default: {
            normal_mode: {
                backgroundColor: colors.middle_blue,
                color: colors.white,
                border: "1px solid " + colors.middle_blue
            },
            hovered_mode: {
                backgroundColor: colors.light_blue,
                color: colors.white,
                border: "1px solid " + colors.light_blue
            },
            clicked_mode: {
                backgroundColor: colors.blue,
                color: colors.white,
                border: "1px solid " + colors.blue
            },
            disabled_mode:{
                bgColor: colors.light_gray,
                color: colors.gray,
                border: "1px solid " + colors.light_gray
            }
        },

        layout_colored: {
            normal_mode: {
                backgroundColor: colors.white,
                color: colors.dark_gray,
                border: "1px solid " + colors.white
            },
            hovered_mode: {
                backgroundColor: colors.white,
                color: colors.middle_blue,
                border: "1px solid " + colors.white
            },
            clicked_mode: {
                backgroundColor: colors.light_gray,
                color: colors.middle_blue,
                border: "1px solid " + colors.light_gray
            },
            disabled_mode: {
                bgColor: colors.light_gray,
                color: colors.gray,
                border: "1px solid " + colors.light_gray
            }
        }
    },


    button_bordered: {

        layout_default: {
            normal_mode: {
                backgroundColor: colors.white,
                color: colors.middle_blue,
                border: "1px solid " + colors.middle_blue_opacity3
            },
            hovered_mode: {
                backgroundColor: colors.blue_opacity,
                color: colors.middle_blue,
                border: "1px solid " + colors.middle_blue
            },
            clicked_mode: {
                backgroundColor: colors.blue,
                color: colors.white,
                border: "1px solid " + colors.blue
            }
        },

        layout_colored: {
            normal_mode: {
                backgroundColor: "transparent",
                color: colors.white,
                border: "1px solid " + colors.white_opacity6
            },
            hovered_mode: {
                backgroundColor: "transparent",
                color: colors.white,
                border: "1px solid " + colors.white
            },
            clicked_mode: {
                backgroundColor: colors.white,
                color: colors.middle_blue,
                border: "1px solid " + colors.white
            }
        }
    },
    button_iconed: {
        layout_default: {
            normal_mode: {
                icon_color: colors.middle_blue_opacity5,
                color: colors.middle_blue,
                borderBottom: "none"
            },
            hovered_mode: {
                icon_color: colors.middle_blue_opacity8,
                color: colors.light_blue,
                borderBottom: "none"
            },
            clicked_mode: {
                icon_color: colors.dark_blue,
                color: colors.dark_blue,
                borderBottom: "none"
            },
         },
        layout_colored: {
            normal_mode: {
                icon_color: colors.white_opacity6,
                color: colors.white,
                borderBottomSpan: "1px dotted " + colors.white_opacity6
            },
            hovered_mode: {
                icon_color: colors.white_opacity8,
                color: colors.white,
                borderBottomSpan: "1px dotted " + colors.white
            },
            clicked_mode: {
                icon_color: colors.white,
                color: colors.white,
                borderBottomSpan: "1px dotted " + colors.white
            }
        }
     }
};

export const TabStyle={
    underlined:{
        common_attrs:{
            padding: "23px 20px",
            height: "70px",
        },
        normal_mode: {
            color: colors.dark_gray,
            borderBottom: "none"
        },
        hovered_mode: {
            color: colors.middle_blue,
            borderBottom: "2px solid " + colors.middle_blue_opacity3
        },
        active_mode: {
            color: colors.dark_gray,
            borderBottom: "2px solid " + colors.middle_blue
        }
    },
    iconed:{
        common_attrs:{
            padding: "5px 15px",
            height: "34px",
        },
        normal_mode: {
            color: colors.dark_gray,
            fill: colors.middle_blue_opacity5
        },
        hovered_mode: {
            color: colors.middle_blue,
            fill: colors.middle_blue
        },
        active_mode: {
            color: colors.dark_gray,
            //backgroundColor: colors.light_gray,
            borderRadius: "37px",
            fill: colors.middle_blue
        },
    },

    iconedMenuTop:{
        padding: "0px 20px",
        position:  "fixed" ,
        borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
        width: "100%",
        background: "white"
       /* top: this.props.screen_size === "xs" ? "49px" : "0",
        width: "100%",
        height: this.props.screen_size === "xs" ? spaces.l : spaces.xl,
        background: "white",
        zIndex: 1000,
        borderBottom: "1px solid " + colors.light_gray,*/
    }
};

export const InputStyle={
    default_attrs:{
        borderRadius: "8px",
        padding: "11px 15px 11" +
            "px 15px",
        fontFamily: fontFamilies.body,
        fontSize: fonts.text.body.fontSize,
        lineHeight: fonts.text.body.fontWeight,
        boxShadow: "none",
        background: "transparent",
        border: "1px solid " + colors.gray_opacity4,
        outline: "none",
        listBorder: "1px solid " + colors.light_gray,
        listShadow: "0px 6px 9px rgba(0, 0, 0, 0.08);",
        minHeight: "42px",
        display: "flex",
        alignItems: "center"
    },
    layout_default: {
        normal_mode: {
            border: "1px solid " + colors.gray_opacity4,
            labelColor: colors.gray,
            color: colors.dark_gray,
            fillSearch: colors.gray_opacity3,
          fillSelect: colors.dark_gray_opacity3
        },
        hovered_mode: {
            border: "1px solid " + colors.gray_opacity8,
            borderSearch: "1px solid " + colors.gray,
            labelColor: colors.gray,
            color: colors.dark_gray,
            fillSearch: colors.gray,
          fillSelect: colors.dark_gray_opacity5
        },
        active_mode:{
            border: "1px solid " + colors.gray_opacity8,
            borderSearch: "1px solid " + colors.gray,
            labelColor: colors.dark_gray,
            color: colors.dark_gray,
            fillSearch: colors.gray,
            fillSelect: colors.gray,
        },
        error_mode:{
            border: "1px solid " + colors.red,
            labelColor: colors.dark_gray,
            color: colors.dark_gray,
            errColor: colors.red
        },
        filled_mode:{
            border: "1px solid " + colors.gray_opacity4,
            labelColor: colors.gray,
            color: colors.dark_gray
        },
        selectIcon:{
            borderRadius: "50%",
            background: colors.gray_opacity2,

        }

    },
    layout_colored: {
        normal_mode: {
            border: "1px solid " + colors.white_opacity4,
            labelColor: colors.white_opacity5,
            color: colors.white,
            fillSearch: colors.white_opacity4,
            fillSelect: colors.white_opacity3,
        },
        hovered_mode: {
            border: "1px solid " + colors.white_opacity8,
            labelColor: colors.white_opacity5,
            color: colors.white,
            fillSearch: colors.white,
            fillSelect: colors.white_opacity3,
        },
        active_mode:{
            border: "1px solid " + colors.white_opacity8,
            labelColor: colors.white,
            color: colors.white,
            fillSearch: colors.white_opacity5,
            fillSelect: colors.white_opacity4,

        },
        error_mode:{
            border: "1px solid " + colors.white,
            labelColor: colors.white,
            color: colors.white,
            errColor: colors.white_opacity7
        },
        filled_mode:{
            border: "1px solid " + colors.gray_opacity4,
            labelColor: colors.white_opacity5,
            color: colors.white,
        },
        selectIcon:{
            borderRadius: "50%",
            background: colors.gray_opacity3,

        }
    }

};
export const InputListStyle={
   itemStyle: {
       normal:{
           background: colors.white,
           color: colors.dark_gray
       },
       active:{
            background: colors.light_gray_opacity5,
            color: colors.middle_blue
       },
       selected:{
           background: colors.light_gray,
           color: colors.middle_blue
       }

   }
};

export const BreakPoints = [
    {
        name: "xxl",
        from: 1920,
        to: 9999
    },
    {
        name: "xl",
        from: 1600,
        to: 1919
    },
    {
        name: "l",
        from: 1200,
        to: 1599
    },
    {
        name: "m",
        from: 960,
        to: 1199
    },
    {
        name: "s",
        from: 600,
        to: 959
    },
    {
        name: "xs",
        from: 0,
        to: 599
    }
];



