import React, { Component } from 'react';

import SettingsWithModalCompleteRequest from "./SettingsWithModalCompleteRequest";
import SettingsWithModalRequest from "./SettingsWithModalRequest";
import {closeModal, openModal, isEmpty} from "../../designSystem/elements/modal/Modal";
import Button from "../../designSystem/elements/button/Button";
import RenderModal from "../../designSystem/elements/modal/RenderModal";
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";
import Checkbox from "../../designSystem/elements/checkbox/Checkbox";
import StyledLink from "../../designSystem/elements/links/Link";
import {colors, spaces} from "../../designSystem/DesignSystem";
import TextElem from "../../designSystem/elements/text/TextElem";
import Caption from "../../designSystem/elements/text/Caption";
import Heading from "../../designSystem/elements/text/Heading";
class SettingsWithAllModals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:{}
          };
        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    render() {
        let flag = !isEmpty(this.state.modal);
        let screen_size = this.props.screen_size;
        let textareaHeight = "93px";
        (screen_size === "xs") ? textareaHeight = "132px" : "";
        let modal1 =  <div className={'ugsk-form-input-code'}>
            <div className={''}>
                <Heading size={"m"} style={{marginTop:0}} screen_size={this.props.screen_size}>Запрос на изменение личных данных</Heading>
                <div style={{marginBottom: spaces.m}}>
                    <TextElem screen_size={this.props.screen_size}> Для смены имени и фамилии обратитесь к своему менеджеру по персоналу или оставьте заявку в форме ниже и мы свяжемся с вами</TextElem>
                </div>
                <div className={"checkbox-cols-wrap"}>
                    <div><Checkbox>Фамилия</Checkbox></div>

                    <div><Checkbox>Отчество </Checkbox> </div>
                    <div> <Checkbox>Имя</Checkbox></div>
                    <div><Checkbox>Дата рождения</Checkbox></div>
                </div>
                <div>
                    <Input typeInput={"textarea"} height={textareaHeight} style={{width: "100%", marginBottom: spaces.m}} label={"Комментарий "} placeholder={""} />
                </div>
                <Button>Запросить</Button>
            </div>

        </div>;
            let modal2 = <div className={'ugsk-form-change-person-date'}>
                <div className={ 'ugsk-form-change-person-date__complete-request-change ugsk-complete-request-change' }>
                    <Heading size={"m"} style={{marginTop:0}} screen_size={this.props.screen_size}>Запрос на изменение личных данных</Heading>
                    <div className={'ugsk-complete-request-change__text-icon'}>
                        <Icon
                            name={'success'}
                            className={'ugsk-complete-request-change__icon'}
                            fill={ colors.green }
                        />
                        <TextElem
                            className={'ugsk-complete-request-change__text'}
                            color={colors.green}
                        >Запрос отправлен</TextElem>
                    </div>
                    <Caption
                        className={'ugsk-complete-request-change__caption'}
                       color={colors.gray}
                    >Ваш менеджер по персоналу Иванова Анна</Caption>
                    <Button className={'ugsk-complete-request-change__btn'} view={"button_bordered"}>Готово</Button>
                </div>
            </div>;

        return (
            <React.Fragment>
                <div className="page">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", modal1)}}>Восстановление пароля</Button>
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", modal2)}}>Восстановление пароля2</Button>
                    </div>
                </div>
                {flag === true ?
                    <RenderModal closeModal={this.closeModal} modal={this.state.modal} screen_size={this.props.screen_size}>
                    </RenderModal> : ""}
                {/*<SettingsWithModalRequest  screenSize={ this.props.screen_size }/>
                <SettingsWithModalCompleteRequest  screenSize={ this.props.screen_size }/>*/}
            </React.Fragment>
        );
    }
}

export default SettingsWithAllModals;