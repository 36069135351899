import React, { Component } from 'react';
import Heading from "./Heading";
import Icon from "../icon/Icon";
import "./text.css";


export default class HeadingIcon extends Component {

    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className={"headingIcon"} style={{...this.props.style}}>
                {this.props.icon ?
                    <div className="icon-wrap">
                        <Icon name={this.props.icon.name} fill={this.props.icon.fill} width={this.props.icon.width}
                              height={this.props.icon.height}/>
                    </div>
                    : null
                }
                {this.props.icon_url ? <div className="icon-wrap">
                    <img src={this.props.icon_url} width={20} height={20}/>
                </div> : null}
                <Heading color={this.props.color} size={this.props.heading.size} style={{marginBottom: 0, marginTop: 0}} screen_size = {this.props.screen_size}>{this.props.heading.text}</Heading>
            </div>
        )
    }
}
