import React from 'react';
import Button from "../../designSystem/elements/button/Button";
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";
import StyledLink from "../../designSystem/elements/links/Link";
import Caption from "../../designSystem/elements/text/Caption";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Heading from "../../designSystem/elements/text/Heading";
import {phoneMask} from "../../tools/mask-helper";
import CreateNewPassword from "../Authorization/CreateNewPassword/CreateNewPassword";

export function ConfirmModal({
     title = "Восстановление пароля",
     screen_size,
     type = "sms",
     errors = {},
     values = {},
     onChange = () => {},
     onBlur = () => {},
     onFocus = () => {},
     onSubmit = (e) => {e.preventDefault()},
     submitDisabled = false
}) {

    let inputAttributes = {
        className: 'ugsk-form-recovery-password__input',
        typeInput: "input",
        type: "text",
        onChange
    };

    return <form onSubmit={onSubmit} className={'ugsk-form-recovery-password'}>
        <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>{title}</Heading>
        {type === "sms" ? <Input {...inputAttributes}
               label={"Номер телефона"}
               placeholder={"+7 (___) ___-__-__"}
               name="phone_number"
               mask={phoneMask}
               type={"tel"}
               error_text={errors.phone_number}
               value={values.phone_number}
               onBlur={onBlur}
               onFocus={onFocus}
        /> : <Input {...inputAttributes}
                    label={"E-mail"}
                    name="email"
                    error_text={errors.email}
                    value={values.email}
                    onBlur={onBlur}
                    onFocus={onFocus}
        />}
        <Button className={'ugsk-form-recovery-password__btn'} onClick={onSubmit} style={{marginTop: spaces.m}} disabled={submitDisabled}>
            Запросить код
        </Button>
    </form>;
}

export class ConfirmCodeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            time: 0
        }
    }

    componentWillMount(){
        this.onSetTime();
    }

    onSetTime = () => {
        this.setState({time: 60}, () => {
            this.onGoTime();
        });
    };

    onGoTime = () => {
        if (this.state.time === 0) {
            clearInterval(this.timeInterval);
        }
        this.timeInterval = setInterval(() => {
            this.setState((prevState) => {
                return {
                    time: prevState.time > 0 ? prevState.time - 1 : 0
                }
            });
        }, 1000);
    };

    onSubmitPrev = (e) => {
        e.preventDefault();
        this.onSetTime();
        this.props.onSubmitPrev && this.props.onSubmitPrev(e);
    };

    render(){
        let {
            title = "Восстановление пароля",
            screen_size,
            type = "sms",
            errors = {},
            onChange = () => {},
            goBack = () => {},
            onSubmit = (e) => {e.preventDefault()},
            submitDisabled = false
        } = this.props;

        return <form onSubmit={onSubmit} className={'ugsk-form-input-code'}>
            <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>{title}</Heading>
            <div className={'ugsk-form-input-code__block'}>
                <Input className={'ugsk-form-input-code__input'}
                       typeInput={"input"}
                       type="tel"
                       label={type === "sms" ? "Код из SMS" : "Код из E-mail"}
                       placeholder={""}
                       name="code"
                       onChange={onChange}
                       error_text={errors.code}
                       errorStyle={{whiteSpace: "nowrap"}}
                />
                {this.state.time ?
                <div className={'ugsk-form-input-code__link-icon'} style={{width: "150px"}}>
                    <Caption style={{color: colors.middle_blue}}>Повторно запросить код можно через {this.state.time} сек</Caption>
                </div> :
                <div className={'ugsk-form-input-code__link-icon'}>
                    <Icon
                        className={'ugsk-form-input-code__icon'}
                        name={'rotate-ccw'}
                    />
                    <StyledLink className='ugsk-form-input-code__link' func={this.onSubmitPrev}>Выслать еще раз</StyledLink>
                </div>}
            </div>
            <Button className={'ugsk-form-input-code__ready'} onClick={onSubmit} style={{marginTop: spaces.m}} disabled={submitDisabled}>
                Отправить
            </Button>
            {/*<Button className={'ugsk-form-input-code__cancel'} view={"button_bordered"} onClick={goBack} style={{marginTop: spaces.m}}>*/}
            {/*    {type === "sms" ? "Изменить номер" : "Изменить e-mail"}*/}
            {/*</Button>*/}
        </form>;
    }
}

export function PasswordModal({
      title = "Восстановление пароля",
      screen_size,
      onChange = () => {},
      onSubmit = (e) => {e.preventDefault()},
      onBlur = () => {},
      onFocus = () => {},
      errors = {},
      submitDisabled = false,
      firstPasswordRule,
      secondPasswordRule,
      thirdPasswordRule,
      progressBarCount,
      newPasswordValue
}) {

    let inputProps = {
        className: 'ugsk-form-new-password__input',
        typeInput: "input",
        type: "password",
        onChange
    };

    return <form onSubmit={onSubmit} className={'ugsk-form-new-password'}>
        <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>{title}</Heading>
        <CreateNewPassword
            errors={errors}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            passwordValue={newPasswordValue}
            firstPasswordRule={firstPasswordRule}
            secondPasswordRule={secondPasswordRule}
            thirdPasswordRule={thirdPasswordRule}
            progressBarCount={progressBarCount}
            fieldsOnly={true}
        />
        <Button className={'ugsk-form-new-password__btn'} onClick={onSubmit} disabled={submitDisabled} style={{marginTop: spaces.m}}>Подтвердить</Button>
    </form>;
}
