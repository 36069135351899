import React, { Component } from 'react';

import StateMouse from "../StateMouse";
import {CheckBoxRadioStyle} from "../../DesignSystem";
import Icon from "../icon/Icon"
import RadioElem from "./RadioElem";
import './radio.css';


export default class Radio extends StateMouse {

    constructor(props) {
        super(props);
        let mode="normal_mode";
        let prevMode = "normal_mode";
        if (this.props.disabled) {
             mode = "disabled_mode";
             prevMode = "disabled_mode";
        }
        let items=this.props.items;
        let n=0;
        items ? (n = items.length) : "";
        let radios = [];
        for(let i=0; i< n; i++){
            radios[i] = false
        }

        this.state={
            mode: mode,
            prevMode: prevMode,
            radios: radios
        };
        this.inpRef = React.createRef();
    }

     changeRadios= (i)=>{
        let radios= Array(this.props.items.length).fill(false);
        radios[i] = true;
        this.setState({
          radios: radios
        })
    };

    onHover= ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    prevMode: prevState.mode,
                    mode: "hovered_mode"
                })

            });
        }
    };
    onMouseOut = ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    mode: "normal_mode",
                    prevMode: prevState.mode
                })
            });
        }
    };

    onChange = ()=>{

        if (this.state.mode != "disabled_mode") {
            this.setState((prevState) => {
                let mode = "selected_mode";
                let prevMode = prevState.mode;
                if (prevState.mode === mode) {
                    mode = prevState.prevMode;
                    prevMode = "selected_mode";
                }
                return ({
                    mode: mode,
                    prevMode: prevMode
                })
            })
        }

    };


    mouseEvents= {
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onMouseUp: this.onChange
    };

    renderItem=()=>{
        let {
            selected,
            label,
            name,
            onChange,
            layout,
            type
        } = this.props;
        if (!layout) {
            layout = "layout_default";
        }
        let elem ="";
        let stylesElem={},
            stylesText = {},
            stylesBtn = {};


        stylesText =  Object.assign({}, CheckBoxRadioStyle.default_attrs.text, CheckBoxRadioStyle.text_attrs[layout][this.state.mode]);
        stylesBtn = Object.assign({},  CheckBoxRadioStyle.default_attrs.icon.radio, CheckBoxRadioStyle.icon_attrs[layout][this.state.mode]);


        let items = this.props.items;
        elem= <div className={"radio__container"}>
            {items.map((item, index) =>
                        <RadioElem key={index} item={item} index={index} layout={layout} name={"radio__" + name} disabled={item.disabled ? item.disabled : false} selected={this.state.radios[index]} changeRadios={this.changeRadios}/>
            )}
        </div>;

        return elem;
    };

    render() {

        return (
           <React.Fragment>
               {this.renderItem()}
           </React.Fragment>
        )
    }
}

