import React, {Component} from "react";
import {connect} from "react-redux";

import ClinicsComponent from "../components/Clinics/Clinics";
import {clinicsList, clinicsAddressHints, clinicsServices, clinicsConditions, clinicsCoords} from "../actions/clinics";
import {prepareData} from "../tools/form-helper";
import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize(GA_COUNTER_ID)
};
export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
};

class Clinics extends Component {

    constructor(props){
        super(props);

        this.state = {
            clinics: this.prepareData(props.clinics),
            coords: this.prepareData(props.coords),
            searchedAddressHints: props.addressHints.slice(0,5),
            services: this.prepareCheckboxes(props.services),
            conditions: this.prepareCheckboxes(props.conditions),
            params: {},
            message: "К сожалению, у вас нет активных полисов."
        };
    }

    componentDidMount() {
        if (typeof GA_COUNTER_ID !== 'undefined') {
            initGA();
            logPageView();
        }
    }

    componentWillMount()
    {
        this.props.clinicsCoords();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.clinics !== nextProps.clinics) {
            this.setState({clinics: this.prepareData(nextProps.clinics)});
        }
        if (this.props.coords !== nextProps.coords) {
            this.setState({coords: this.prepareData(nextProps.coords)});
        }
        if (this.props.addressHints !== nextProps.addressHints) {
            this.setState({searchedAddressHints: nextProps.addressHints.slice(0,5)});
        }
        if (this.props.services !== nextProps.services) {
            this.setState({services: this.prepareCheckboxes(nextProps.services)});
        }
        if (this.props.conditions !== nextProps.conditions) {
            this.setState({conditions: this.prepareCheckboxes(nextProps.conditions)});
        }
    }

    getClinics = () => {
        let params = prepareData(this.state.params);
        this.setState({message: params ? "К сожалению, по вашему запросу ничего не найдено. Попробуйте изменить параметры поиска." : "У вас нет активных полисов."});
        this.props.clinicsList(params);
    };

    update = () => {

        this.getClinics();

        let addressParams = {...this.state.params};
        delete addressParams['city_id'];
        delete addressParams['region_id'];
        delete addressParams['address'];
        delete addressParams['metro_station'];
        delete addressParams['page'];
        delete addressParams['search'];

        this.props.clinicsAddressHints(prepareData(addressParams));
        this.props.clinicsServices(prepareData(this.state.params));
        this.props.clinicsConditions(prepareData(this.state.params));
        this.props.clinicsCoords(prepareData(this.state.params));
    };

    prepareData = (items) => {

        return items.map((item) => {

            return {
                id: item.id,
                title: item.title,
                metro: item.metro_station,
                coords: item.latitude && item.longitude ? [parseFloat(item.latitude), parseFloat(item.longitude)] : undefined,
                address: item.city && item.city.region ? (item.city.region.title == item.city.title ? item.city.title : item.city.region.title+", "+item.city.title)+", "+item.address : null,

                phone: item.phone_number,
                site: item.site,
                siteLink: item.site ? (item.site.indexOf("http") === 0 ? item.site : "http://"+item.site) : "#",
                workTime: item.is_nonstop ? (item.is_everyday ? "круглосуточно, ежедневно" : "круглосуточно") : item.work_time,
                services: item.services
            }
        });
    };

    prepareCheckboxes = (items) => {
        return items.map((item) => {

            return {
                id: item.id,
                title: item.title,
                showOnMap: item.showOnMap,
                disabled: !item.hasClinics
            }
        });
    };

    searchItemsMethod = (val) => {
        this.setState({
            searchedAddressHints: this.props.addressHints.filter((hint) => {
                return !val || hint.title.toLowerCase().indexOf(val.toLowerCase()) !== -1;
            }).slice(0,5)
        })
    };

    changeFilter = (name, value) => {
        switch (name) {
            case "services":
                this.setState((prevState) => {
                    return {
                        params: {...prevState.params, services: value, page: 0}
                    }
                }, () => {
                    this.update();
                });
                break;
            case "conditions":
                this.setState((prevState) => {
                    let params = prevState.params;

                    if (value.indexOf("is_nonstop") === -1) {
                        delete params["is_nonstop"];
                    } else {
                        params.is_nonstop = 1;
                    }

                    if (value.indexOf("is_everyday") === -1) {
                        delete params["is_everyday"];
                    } else {
                        params.is_everyday = 1;
                    }

                    params.page = 0;

                    return {
                        params
                    }
                }, () => {
                    this.update();
                });
                break;
        }
    };

    searchInputChange = (item) => {

        let params = {...this.state.params};
        // delete params['city_id'];
        // delete params['region_id'];
        // delete params['address'];
        // delete params['metro_station'];
        // delete params['title'];
        delete params['page'];

        // if (item && item.type) {
        //     delete params['search'];
        //     switch (item.type) {
        //         case 'city':
        //             params.city_id = item.id;
        //             break;
        //         case 'region':
        //             params.region_id = item.id;
        //             break;
        //         case 'address':
        //             params.address = item.id;
        //             break;
        //         case 'metro':
        //             params.metro_station = item.id;
        //             break;
        //         case 'title':
        //             params.title = item.id;
        //             break;
        //     }
        // } else {
        //     params.search = item.title;
        // }
        if (item) {
            params.search = item.title;
        } else {
            delete params['search']
        }

        this.setState({params}, () => {
            this.update();
        })
    };

    setPage = (num) => {
        this.setState((prevState) => {
            return {
                params: {...prevState.params, page: num}
            }
        }, () => {
            this.getClinics();
        });
    };

    render(){

        let conditionsValue = [];
        if (this.state.params.is_nonstop) {
            conditionsValue.push("is_nonstop");
        }
        if (this.state.params.is_everyday) {
            conditionsValue.push("is_everyday");
        }

        this.clinicsInfo = {
            title: "Медицинские учреждения",
            clinics: this.state.clinics,
            coords: this.state.coords,
            message: this.state.message,
            pagination: this.props.pagination,
            setPage: this.setPage,
            filterFields: [
                {
                    name: "services",
                    title: "Виды помощи",
                    icon: "apps",
                    options: this.state.services,
                    onChange: (value) => {
                        this.changeFilter("services", value);
                    },
                    value: this.state.params.services
                },
                {
                    name: "conditions",
                    title: "Условия",
                    icon: "clock",
                    options: this.state.conditions,
                    onChange: (value) => {
                        this.changeFilter("conditions", value);
                    },
                    value: conditionsValue
                }
            ],
            searchInputParams: {
                resultTips: this.state.searchedAddressHints,
                searchItemsMethod: this.searchItemsMethod,
                searchFunc: this.searchInputChange,
                value: this.state.params.city_id || this.state.params.region_id || this.state.params.address || this.state.params.metro_station || this.state.params.title || this.state.params.search
            }
        };

        return <ClinicsComponent {...this.clinicsInfo} screen_size={this.props.screen_size}/>
    }
}

export default connect(
    state => ({
        clinics: state.clinics.list || [],
        coords: state.clinics.coords || [],
        pagination: state.clinics.pagination || {},
        services: state.clinics.services || [],
        addressHints: state.clinics.addresses || [],
        conditions: state.clinics.conditions || []
    }),
    dispatch => () => {
        return {
            clinicsList: (params) => dispatch(clinicsList(params)),
            clinicsCoords: (params) => dispatch(clinicsCoords(params)),
            clinicsServices: (params) => dispatch(clinicsServices(params)),
            clinicsAddressHints: (params) => dispatch(clinicsAddressHints(params)),
            clinicsConditions: (params) => dispatch(clinicsConditions(params)),
        }
    }
)(Clinics)