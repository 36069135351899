import React from "react";
import Icon from "../../designSystem/elements/icon/Icon";
import Heading from "../../designSystem/elements/text/Heading";
import Checkbox from "../../designSystem/elements/checkbox/Checkbox";
import TextElem from "../../designSystem/elements/text/TextElem";
import Link from "../../designSystem/elements/links/Link";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Input from "../../designSystem/elements/input/Input";
import Caption from "../../designSystem/elements/text/Caption";

export default class CheckboxListFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            clear: false,
            selected: props.value || []
        }
    }

    reset = (e) => {

        if (e) e.preventDefault();

        this.setState({
            clear: true,
            selected: []
        }, () => {
            this.props.onChange && this.props.onChange(this.state.selected);
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.state.clear === true){
            this.setState({clear: false})
        }
    }

    componentWillReceiveProps() {
        let tabReset = this.props.tabReset;
        if (tabReset) {
            this.reset();
        }
    }

    onChange = (value) => {
        return (selected) => {
            if (selected) {
                this.setState((prevState) => {
                    return {
                        selected: [...prevState.selected, value]
                    }
                }, () => {
                    this.props.onChange && this.props.onChange(this.state.selected);
                })
            } else {
                this.setState((prevState) => {
                    return {
                        selected: prevState.selected.filter((item) => {
                            return item !== value;
                        })
                    }
                }, () => {
                    this.props.onChange && this.props.onChange(this.state.selected);
                })
            }
        }
    };

    render() {

        let {
            title,
            icon,
            options = [],
            screen_size,
            activeTab
        } = this.props;

        return <div className="checkbox_list_filter" style={{marginBottom: spaces.m}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                <Heading size="s" screen_size={screen_size} >
                    <span style={{display: "inline-block", verticalAlign: "middle", marginRight: spaces.s}}>
                        <Icon name={icon} width="22px" height="22px" fill={colors.middle_blue_opacity5} />
                    </span>
                    <span style={{display: "inline-block", verticalAlign: "middle"}}>
                        {title}
                    </span>
                </Heading>
                <div>
                    <Link to={()=>{return false}} func={this.reset}><Caption style={{color: colors.middle_blue}}>Сбросить</Caption></Link>
                </div>

            </div>
            {options.map((option) => <div key={option.id} style={{marginBottom: spaces.s}}>
                {activeTab === 1 && option.showOnMap === false ? null : <Checkbox clear={this.state.clear} mode={this.state.selected.indexOf(option.id) === -1 ? undefined : "selected_mode"} onChange={this.onChange(option.id)} disabled={option.disabled}>{option.title}</Checkbox>}
            </div>)}
        </div>
    }
}