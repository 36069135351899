export function prepareData(values, method = 'GET'){

    let data;

    switch (method) {

        case 'GET':
        case 'PUT':
            data = [];

            for (let attr in values) {
                let value = values[attr];
                if (attr === 'phone_number') {
                    value = value.replace(/\D/g, '');
                    value = value.replace(/^7/g, '');
                }

                if (Array.isArray(value)) {
                    value.map((item, key) => {
                        data.push(attr+"["+key+"]="+item);
                    })
                } else {
                    data.push(attr+"="+value);
                }

            }
            return (method === 'GET' ? '?' : '')+data.join("&");

        case 'POST':
            data = new FormData();

            for (let attr in values) {
                let value = values[attr] || "";

                if (attr === 'phone_number') {
                    value = value.replace(/\D/g, '');
                    value = value.replace(/^7/g, '');
                }
                if (attr === 'birthday') {
                    value = value.split('.').reverse().join('-');
                }

                if (Array.isArray(value)) {
                    value.map((item, key) => {
                        data.append(attr+"["+key+"]", item);
                    })
                } else {
                    data.append(attr, value);
                }

            }
            return data;
    }

}

export function parseParams(params) {
    let paramsObject = {};

    if (params) {
        params = params.replace( '?', '').split("&");
        params.map((param) => {
            param = param.split("=");
            paramsObject[param[0]] = param[1];
        })
    }

    return paramsObject;
}

export function changeField(state) {
    return (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState((prevState) => {
            return {
                [state]: {...prevState[state], [name]: value}
            }
        });
    }
}

export function passwordError(value) {
    if (!value) return "Необходимо заполнить «Пароль».";

    if (value.length < 6) {
        return "Минимум 6 символов";
    }

    let preventSymbols = /^[a-zA-Z\d]*$/;
    if (preventSymbols.test(value) === false) {
        return "Введен недопустимый символ";
    }
    return "";
}


export function phoneError(value) {
    if (!value) return "Необходимо заполнить «Номер телефона»."

    let re = /\d{10}/;
    return re.test(value.replace(/\D/g, '').replace(/^7/, '')) ? "" : "Введите корректный номер телефона";
}

export function smsCodeError(value, type) {
    if (!value) return "Введите «Код из SMS»."

    let re = /\d{6}/;
    return re.test(value.replace(/\D/g, '')) ? "" : "Введите корректный код";
}

export function policyNumberError(value) {
    if (!value) return "Необходимо заполнить «Номер полиса ДМС»."
}

export function surnameError(value) {
    if (!value) return "Необходимо заполнить «Фамилия»."

    let re = /^[А-ЯЁа-яё]*$/
    return re.test(value) ? "" : "Используйте только русские буквы";
}

export function nameError(value) {
    if (!value) return "Необходимо заполнить «Имя»."

    let re = /^[А-ЯЁа-яё]*$/
    return re.test(value) ? "" : "Используйте только русские буквы";
}

export function birthdayError(value) {
    if (!value) return "Необходимо заполнить «Дата рождения»."
}

export function isEmptyObj(obj) {
    return !Object.keys(obj).length > 0;
}

export function emailError(value) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value) ? "" : "Введите корректный e-mail";
}