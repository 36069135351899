import React, { Component } from 'react';
import './SimpleHeader.css';

class SimpleHeader extends Component {
    render() {
        const {size, children} = this.props;
        let header;

        switch (size) {
            case 'h2':
                header = <h2 className="ugsk-simple-header__h2">{children}</h2>
        }
        return header;
    }
}

export default SimpleHeader;