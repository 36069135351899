import React, { Component } from 'react';

export default class StateMouse extends Component {

    constructor(props) {
        super(props);
        this.state={
            mode: "normal_mode",
            prevMode: "normal_mode",
            x: undefined,
            y: undefined
        }
    }
    onHover= (event)=>{
        this.setState((prevState)=>{
            return({
                prevMode: prevState.mode,
                mode: "hovered_mode",
                event: event
            })

        })
    };
    onMouseOut = ()=>{
        this.setState((prevState)=>{

            return ({
                mode: "normal_mode",
                prevMode: prevState.mode,
                x: undefined,
                y: undefined
            })
        })
    };
    onMouseDown = (e)=>{
        e.preventDefault();
        this.setState((prevState)=>{
            return({
                mode: "clicked_mode",
                prevMode: prevState.mode
            })

        })
    };
    onMouseUp = ()=>{
        this.setState((prevState)=>{
            return({
                mode: prevState.prevMode,
                prevMode: "normal_mode"
            })

        })
    };

    onMouseMove = (event) => {
        this.setState({
            x: event.pageX,
            y: event.pageY
        })
    };

    mouseEvents= {
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onMouseDown: this.onMouseDown,
        onMouseUp: this.onMouseUp,
        onMouseMove: this.onMouseMove
    };

    render() {
        return "";
    }
}

