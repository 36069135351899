import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {CardStyle, colors, NotificationStyle} from '../../DesignSystem';
import Button from '../button/Button';
import Heading from '../text/Heading';
import TextElem from '../text/TextElem';
import Caption from '../text/Caption';
import StateMouse from "../StateMouse";
import Icon from '../icon/Icon';
import './cards.css';
import {spaces} from "../../DesignSystem";

const cache = {};

function requireAll (r) {
    r.keys().forEach(key => cache[key.substring(key.lastIndexOf("/") + 1, key.lastIndexOf('.'))] = r(key));
}

requireAll(require.context('./images/', true, /\.png$/));

export default class Cards extends StateMouse{
  constructor(props){
    super(props);
      this.size = 'normal';

  }
  renderCard=()=>{
    let card = "";
    
    let type=this.props.type;

    switch(type){
      case "newPolis":{
          if (this.props.screen_size === "xs"){
              this.size = "mobile";
          }
          let layout = "layout_default";
        let stylesCard = Object.assign({}, CardStyle.default_attrs, CardStyle.cardNewPolis[this.size].common_attrs, {boxShadow: CardStyle.cardPolis[this.size][layout][this.state.mode].boxShadow});
        card= <a href={this.props.info.to ? this.props.info.to : ()=>{return false}} onClick={this.props.info.onClick ? this.props.info.onClick : ()=>{}} target={this.props.info.blank ? "_blank" : "_self"} className="card card__newPolis" {...this.mouseEvents} style={stylesCard}>
          <div className="text"><TextElem color={colors.white}>{this.props.info.text}</TextElem> </div>        
          <Icon name={"arrRight"} width="19px" height="18px" fill={colors.white}/>   
        <div className="lay" style={CardStyle.cardNewPolis[this.size][this.state.mode].lay}></div>
        </a>
      }
      break;
      case "polis":{
          if (this.props.screen_size === "xs"){
              if(typeof document!= "undefined") {
                  this.size = "mobile"
              }
          }

        let stylesCard={};
        let layout = "layout_default";
        this.props.layout ? layout = this.props.layout : "";

        stylesCard=Object.assign({}, CardStyle.default_attrs, CardStyle.cardPolis[this.size][layout].common_attrs, {boxShadow: CardStyle.cardPolis[this.size][layout][this.state.mode].boxShadow}, this.props.style);
        card= <div className={`card card__polis ${this.props.info.nonactive ? 'card__polis__nonactive' : ''}`} style={stylesCard} {...this.mouseEvents}>


               {/*{this.size != "mobile" ?*/}
                <div className="icon__wrap" style={{backgroundColor: CardStyle.cardPolis[this.size][layout].common_attrs.bgIcon}}>
                   <Icon name={this.props.info.icon.name} width={this.props.info.icon.width} height={this.props.info.icon.height} fill={this.props.info.icon.fill}></Icon>
                </div>
              {/*: ""}*/}
                <div><TextElem color={colors.gray}>{this.props.info.name}</TextElem> </div>
                <Heading size={"m2"} screen_size={this.props.screen_size} color={this.props.info.nonactive ? colors.gray : CardStyle.cardPolis[this.size][layout][this.state.mode].titleColor} onClick={this.props.info.buttons.more_button.onClick}>{this.props.info.title}</Heading>
                <div className="row__text">
                  <TextElem color={colors.gray} style={{marginRight: spaces.xs}}>{this.props.info.label}</TextElem>
                  <TextElem {...this.props.info.nonactive ? {color: colors.gray} : {}}>{this.props.info.date}</TextElem>
                </div>
        {this.props.info.buttons ?
          <div className="row_buttons">

              {
                  this.props.info.buttons.action_button ?
                  <Button className={"action_button"} view={this.props.info.buttons.action_button.view} onClick={this.props.info.buttons.action_button.onClick}>{this.props.info.buttons.action_button.text}</Button>
              : ""
              }
              {
                  this.props.info.buttons.more_button ?
                      <Button className={"more_button"} view={this.props.info.buttons.more_button.view} onClick={this.props.info.buttons.more_button.onClick}>{this.props.info.buttons.more_button.text}</Button>
                      : ""
              }

                </div>
          : ""}
                
              </div>
      }
      break;
      case "newAdvert":{
          if (this.props.screen_size === "xs"){
              this.size = "mobile";
          }

        let recommdClass = this.props.recommdClass;

        let stylesCard = Object.assign({}, CardStyle.default_attrs, CardStyle.cardNewAdvert[this.size].common_attrs, CardStyle.cardNewAdvert[this.size][this.state.mode]);
        card= <div className={recommdClass ? "card card__newAdvert card__newAdvert-up" : "card card__newAdvert"} {...this.mouseEvents} style={stylesCard} onClick={this.props.recommendationsOnclick}>
          <div className="text"><TextElem color={colors.middle_blue}>{this.props.info.text}</TextElem> </div>
          <Icon name={"arrRight"} width="19px" height="18px" fill={colors.middle_blue}/>
        </div>
      }
      break;
      case "advert":{
          if (this.props.screen_size === "xs"){
              this.size = "mobile";
          }

        let recommdClass = this.props.recommdClass;

        let stylesCard = Object.assign({}, CardStyle.default_attrs, CardStyle.cardAdvert[this.size].common_attrs, {boxShadow: CardStyle.cardAdvert[this.size][this.state.mode].boxShadow} );

        card= <div className="card card__advert" {...this.mouseEvents} style={stylesCard} onClick={() => {this.props.recommendationsModalOnclick(this.props.info)}}>
          <div className="content__zone">
            <Heading size={"m"} style={{
                fontSize: this.props.screen_size !== "xs" ? '26px' : '20px',
                marginTop: 0,
                marginBottom: "10px",
                color: this.props.info.color ? this.props.info.color : colors.dark_gray
            }}
                screen_size={this.props.screen_size}
                dangerouslySetInnerHTML={{__html: this.props.info.title}} />
             <div className={"textCard"}>
                 {this.size==="mobile" ?
                    <Caption  style={{color: this.props.info.color ? this.props.info.color : colors.dark_gray}} dangerouslySetInnerHTML={{__html: this.props.info.short_description}} />
                 :  <TextElem
                         style={{
                             color: this.props.info.color ? this.props.info.color : colors.dark_gray
                         }}
                         dangerouslySetInnerHTML={{__html: this.props.info.short_description}} />
                 }
             </div>
          </div>
          <div className="image__block" style={{backgroundColor: this.props.info.background}}>
            <img src={this.props.info.image ? this.props.info.image : cache[this.props.info.image]} alt=""/>
          </div>
          <Icon name={"arrRight"} width="19px" height="18px" fill={ this.props.info.color ? this.props.info.color : colors.dark_gray}/>
          <div className="lay"/>
        </div>
      }
      break;
    }
    return card;
  };

    render() {
        return (
            <React.Fragment>
                {this.renderCard()}
            </React.Fragment>
        )
    }
}

