import {parseErrors} from "../tools/api-helper";

export default function client(state = {}, action) {
    switch (action.type) {
        case "ME_SUCCESS":
            return {...state, ...action.data};
        case "CHANGE_PASSWORD":
            return {...state, passwordErrors: undefined, changePasswordSuccess: undefined};
        case "CHANGE_PASSWORD_SUCCESS":
                return {...state, passwordErrors: undefined, changePasswordSuccess: true};

        case "CHANGE_PERSONAL_DATA":
            return {...state, dataChange: false, errorMessage: false};
        case "CHANGE_PERSONAL_DATA_FALSE":
            return {...state, errorMessage: action.data.errorMessage};
        case "CHANGE_PERSONAL_DATA_SUCCESS":
            return {...state, dataChange: true, errorMessage: false};

        case "CHANGE_ADDRESS":
            return {...state, changeAddress: false};
        case "CHANGE_ADDRESS_SUCCESS":
            return {...state, changeAddress: true};

        case "RESET_EMAIL_FORM":
            return {...state, changeEmail: false};
        case "CHANGE_EMAIL":
            return {...state, changeEmail: false, params: action.params};
        case "CHANGE_EMAIL_VALIDATION_ERROR":
            return {...state, changeEmail: false, params: action.params, errorEmailConfirmMessage: action.data};
        case "CHANGE_EMAIL_SUCCESS":
            return {...state, changeEmail: true};

        case "CHANGE_PHONE_CODE_SEND":
            return {...state, changeEmail: false, params: action.params, codeSend: 'notOk'};
        case "CHANGE_PHONE_CODE_SEND_SUCCESS":
            return {...state, changePhone: true, codeSend: 'Ok'};
        case "CHANGE_PHONE_CODE_SEND_REQUEST_SMS":
            return {...state, errorMesageRequestSms: action.data, codeSend: 'notOk'};

        case "CHANGE_PHONE_CODE_VALIDATE":
            return {...state, params: action.params, codeValidate: 'notOk'};
        case "CHANGE_PHONE_CODE_VALIDATE_SUCCESS":
            return {...state, smsToken: action.data.smsToken, codeValidate: 'Ok', reRequestSms: undefined};
        case "CHANGE_PHONE_CODE_VALIDATE_REQUEST_SMS":
            return {...state, errorMesageRequestSms: action.data, codeValidate: 'notOk'};

        case "CHANGE_PHONE_CODE_SEND_OLD":
            return {...state, params: action.params, codeSendOld: 'notOk'};
        case "CHANGE_PHONE_CODE_SEND_OLD_SUCCESS":
            return {...state, codeSendOld: 'Ok', reRequestSmsOld: undefined};
        case "CHANGE_PHONE_CODE_SEND_OLD_REQUEST_PHONE":
        case "CHANGE_PHONE_CODE_SEND_OLD_VALIDATION_ERROR":
        case "CHANGE_PHONE_CODE_SEND_VALIDATION_ERROR":
            return {...state, errorMesagePhoneOld: action.data, codeSendOld: 'notOk'};

        case "CHANGE_PHONE_CODE_VALIDATE_OLD":
            return {...state, params: action.params, codeValidateOld: 'notOk'};
        case "CHANGE_PHONE_CODE_VALIDATE_OLD_SUCCESS":
            return {...state, codeValidateOld: 'Ok'};
        case "CHANGE_PHONE_CODE_VALIDATE_OLD_REQUEST_VALIDATE":
            return {...state, errorMesageValidateOld: action.data, codeValidateOld: 'notOk'};

        case "CHANGE_PHONE_CODE_SEND_VALID_OLD_NUMBER":
            return {...state, errorAlreadyConfirmed: action.data};
        case "CHANGE_PHONE_CODE_SEND_RE_REQUEST_SMS":
            return {...state, reRequestSms: action.data};
        case "CHANGE_PHONE_CODE_SEND_OLD_RE_REQUEST_SMSOLD":
            return {...state, reRequestSmsOld: action.data};

        case "CHANGE_PERSONAL_DATA_CLEAR":
            return {...state, codeSend: undefined, changeEmail: undefined, params: undefined, errorAlreadyConfirmed: undefined};

        case "CHANGE_PHONE_DATA_CLEAR":
            return {
                ...state,
                dataChange: undefined,
                codeSend: undefined,
                errorMesageRequestSms: undefined,
                codeValidate: undefined,
                smsToken: undefined,
                codeSendOld: undefined,
                errorMesagePhoneOld: undefined,
                codeValidateOld: undefined,
                errorMesageValidateOld: undefined,
                reRequestSms: undefined,
                reRequestSmsOld: undefined
            };
        case "CHANGE_PASSWORD_BAD_REQUEST":
            return {
                ...state,
                passwordErrors: {
                    oldPassword: "Поле обязательно для заполнения",
                    newPassword: "Поле обязательно для заполнения",
                    repeatPassword: "Поле обязательно для заполнения"
                }
            };
        case "CHANGE_PASSWORD_VALIDATION_ERROR":
        case "CHANGE_PHONE_CODE_SEND_RECOVERY_VALIDATION_ERROR":
        case "CHANGE_PHONE_CODE_VALIDATE_RECOVERY_VALIDATION_ERROR":
            return {
                ...state,
                passwordErrors: parseErrors(action.data)
            };
        case "PASSWORD_CLEAR_VALIDATION":
            let passwordErrors = {...state.passwordErrors}
            delete passwordErrors[action.field]
            return {...state, passwordErrors};
        case "PASSWORD_VALIDATION_VALIDATION_ERROR":
            return {...state, passwordErrors: {...state.passwordErrors, ...parseErrors(action.data)}};
        case "SET_EMAIL":
            return {...state, email: action.email}

        case "SEND_REVIEW":
            return {...state, reviewStatus: "sending", reviewMessage: ""};
        case "SEND_REVIEW_SUCCESS":
            return {...state, reviewStatus: "success", reviewMessage: action.data.message};
        case "SEND_REVIEW_CLEAR":
        case "SEND_REVIEW_BAD_REQUEST":
            return {...state, reviewStatus: ""};

        case "LOGOUT":
        case "BLOCKED":
            return {};
        default:
            return state;
    }
}