import React from 'react';
import ReactDOM from 'react-dom';
import AppDls from './AppDls';
import AppMain from './AppMain';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import createStore from "./store";
import {allSaga} from "./actions/actions";
import "isomorphic-fetch";
import {Promise} from "es6-promise";
Promise.polyfill();

let Application;

switch (BUILD_MODE) {
    case "DLS": {
        Application = <BrowserRouter>
            <AppDls />
        </BrowserRouter>;
    }
    break;
    case "MAIN": {

        let store = createStore();
        store.runSaga(allSaga);

        Application = <Provider store={store}>
            <BrowserRouter>
                <AppMain />
            </BrowserRouter>
        </Provider>;
    }
    break;
    default: {
        Application = <BrowserRouter>
            <AppDls />
        </BrowserRouter>;
    }
    break;

}

ReactDOM.render(Application, document.getElementById('root'));

