import React, {Component} from "react";
import {connect} from "react-redux";

import SettingsComponent from "../components/Settings/Settings";
import {closeModal, isEmpty} from "../designSystem/elements/modal/Modal";
import RenderModal from "../designSystem/elements/modal/RenderModal";
import {downloadPolicy, getModal, prepareList, sendPolicy, setModalContent, SuccessSendModal} from "./Polices";
import {changePassword, changePersonalData, clearChangePersonalData, changeAddress, me, changeEmail, changePhoneRequest, clearChangePhoneData} from "../actions/client";
import {closeNotification} from "../actions/common";
import {prepareData, changeField, isEmptyObj, emailError, phoneError, smsCodeError} from "../tools/form-helper";

import {
    onCloseModal,
    onOpenModal,
    onSetPassword
} from "./Authorization";

import {clearError, confirm, setPassword, validate} from "../actions/authorization";
import {clearSendPolicy, policyDownload, policyDownloadMemo, policySend} from "../actions/polices";
import {ChangePersonalDataRequestModal, SucceedRequestModal, EmailConfirmationModal, EmailConfirmationSuccessfullySentModal} from "../components/SettingsWithModals/SettingsModals";
import {passwordCheckHandler, getProgressBarCount} from '../tools/tools';
import AuthorizationComponent from "../components/Authorization/Authorization";
import PasswordRecovery from "../components/Authorization/PasswordRecovery/PasswordRecovery";
import {ConfirmCodeModal, ConfirmModal, PasswordModal} from "../components/AuthorizationWithModals/AuthorizationModals";

class Settings extends Component {

    constructor(props){
        super(props);

        this.closeModal = closeModal.bind(this);
        this.prepareList = prepareList.bind(this);
        this.setModalContent = setModalContent.bind(this);
        this.downloadPolicy = downloadPolicy.bind(this);
        this.sendPolicy = sendPolicy.bind(this);
        this.getModal = getModal.bind(this);
        this.onChange = changeField.bind(this)('passwordValues');
        this.changeConfirmationField = changeField.bind(this)('confirmValues');
        this.onOpenModal = onOpenModal.bind(this);
        this.onCloseModal = onCloseModal.bind(this);

        this.onSetPassword = onSetPassword.bind(this);
        this.changeAddressField = changeField.bind(this)('valueInputAddress');
        this.changeSmsCodeField = changeField.bind(this)('smsCode');
        this.changeFieldConfirm = changeField.bind(this)('confirmValues');
        this.isEmptyObj = isEmptyObj.bind(this);
        this.checkEmailError = emailError.bind(this);
        this.checkPhoneError = phoneError.bind(this);
        this.checkSmsCodeError = smsCodeError.bind(this);

        this.passwordCheckHandler = passwordCheckHandler.bind(this);
        this.getProgressBarCount = getProgressBarCount.bind(this);

        this.state = {
            personalData: {
                surname: false,
                firstName: false,
                patronymic: false,
                birthDate: false,
            },
            valueInputAddress: {},
            comment: '',
            list: this.prepareList(props.list, props.client, this.setModalContent, true),
            modal: {},
            passwordValues: {},
            confirmValues: {},
            modalName: null,
            disabledBtnReady: true,
            disabledBtnConfirmMail: true,
            errorTxtEmailValidation: '',
            emailCode: '',
            errorMessageModal: false,
            errorTxtPhoneValidation: '',
            smsCode: {},
            errorTxtSmsCodeValidation: '',
            urlParamsMailConfirm: false,
            requestAction: 'code-send',
            smsToken: '',
            newPhoneNumber: '',
            firstPasswordRule: false,
            secondPasswordRule: false,
            thirdPasswordRule: false
        };
    }

    checkboxHandler = (value, name) => {
        const personalData = this.state.personalData;

        this.setState({
            personalData: {
                ...personalData,
                [name]: value,
            },
        })
        if (this.state.errorMessageModal){
            this.setState({
                errorMessageModal: false
            })
        }
    }

    isAnyOptionChecked = () => {
        const personalData = this.state.personalData;
        let isAnyOptionChecked = false;

        Object.keys(personalData).forEach(key => {
            if (personalData[key]) {
                isAnyOptionChecked = true;
            }
        })

        return isAnyOptionChecked;
    }

    clearItemForm = () => {
        this.setState({
            personalData: {
                surname: false,
                firstName: false,
                patronymic: false,
                birthDate: false
            },
            comment: '',
        })
    }

    componentDidMount() {
        const client = this.props.client;
        if (!!client && client.address) {
            this.setState({
                valueInputAddress: {
                    residenceAddress: client.address
                }
            })
        }

        let params = new URL(window.location.href).searchParams;
        let email = params.get('email');
        let code = params.get('code');

        if (!(email && code) && (!!client && !client.is_email_confirmed || !!client && client.email === '')) {
            this.onOpenModal('email-confirmation')();
        }

        let param = window.location && window.location.search;
        if (typeof window != 'undefined') {
            if (!!param && this.state.urlParamsMailConfirm === false) {
                this.setState({
                    urlParamsMailConfirm: true
                })
            }
        }

        if (!!param && !!this.state.urlParamsMailConfirm) {
            if (!!email && !!code){
                this.confirmEmailDataRequest('confirm', email, code);
                this.setState({confirmValues: {confirmEmailModal: email}, disabledBtnConfirmMail: false})
                history.replaceState({}, null, location.pathname);
            }

        }
    }

    componentWillReceiveProps(nextProps) {
        let {
            smsCodeSent,
            smsCodeValid,
            setPasswordSuccess
        } = nextProps.authorization;

        if (this.props.authorization.smsCodeSent !== smsCodeSent && smsCodeSent) {
            this.onOpenModal('validate-sms-code')();
        }
        if (this.props.authorization.smsCodeValid !== smsCodeValid && smsCodeValid) {
            this.onOpenModal('set-password')();
        }
        if (!this.props.authorization.smsConfirmed && nextProps.authorization.smsConfirmed) {
            this.setState((prevState) => {
                return {
                    passwordValues: {
                        ...prevState.passwordValues,
                        smsToken: nextProps.authorization.smsConfirmed
                    }
                }
            })
        }
        if (this.props.authorization.setPasswordSuccess !== setPasswordSuccess && setPasswordSuccess) {
            this.setState({values: {
                    phone_number: this.state.confirmValues.phone_number,
                    password: this.state.confirmValues.newPassword
                }}, () => {
                this.onCloseModal();
            })
        }
        if (this.props.changePasswordSuccess != nextProps.changePasswordSuccess && nextProps.changePasswordSuccess) {
            this.setState({
                passwordValues: {},
                firstPasswordRule: false,
                secondPasswordRule: false,
                thirdPasswordRule: false
            }, () => {
                this.props.clearError('password', 'newPassword');
                this.props.clearError('password', 'repeatPassword');
                this.onCloseModal()
            });
        }
        let changePersonalDataSucces = this.props.changePersonalDataSucces;

        if (changePersonalDataSucces !== nextProps.changePersonalDataSucces && !changePersonalDataSucces && !!nextProps.changePersonalDataSucces) {
            this.onOpenModal('succeed-request')();
        }

        if (this.props.changeAddressSuccess !== nextProps.changeAddressSuccess && nextProps.changeAddressSuccess){
            this.props.getMe();
        }

        const client = this.props.client;

        if ( !!client && !this.state.disabledBtnReady && this.props.client.address !== nextProps.client.address ) {
            this.setState({
                disabledBtnReady: true
            })
        }

        if ( !!client && client.changeEmail !== nextProps.client.changeEmail && nextProps.client.changeEmail && !!client.params && client.params === 'send-link') {
           this.onOpenModal('email-confirmation-successfully-sent')();

            if (!!this.state.confirmValues) {
                if (!!this.state.confirmValues.confirmEmailModal) {
                    this.setState({
                        ...this.state.confirmValues,
                        confirmValues: {
                            confirmEmailModal: ''
                        }
                    })
                }
                if (!!this.state.confirmValues.confirmEmail) {
                    this.setState({
                        ...this.state.confirmValues,
                        confirmValues: {
                            confirmEmail: ''
                        }
                    })
                }
            }
            this.props.clearChangePersonalData();
        }

        if ( !!client.errorAlreadyConfirmed !== !!nextProps.client.errorAlreadyConfirmed && !!nextProps.client.errorAlreadyConfirmed ||
        !!client.errorAlreadyConfirmed === !!nextProps.client.errorAlreadyConfirmed && !!nextProps.client.errorAlreadyConfirmed ) {
            this.setState({
                errorTxtPhoneValidation: nextProps.client.errorAlreadyConfirmed.phone_number
            })
            this.props.clearChangePersonalData();
        }

        if ( !!client.errorAlreadyConfirmed !== !!nextProps.client.errorAlreadyConfirmed && nextProps.client.errorAlreadyConfirmed && nextProps.client.errorAlreadyConfirmed.code ) {
                this.setState({
                    errorTxtSmsCodeValidation: nextProps.client.errorAlreadyConfirmed.code
                })
                this.props.clearChangePersonalData();
        }

        if ( client && client.smsToken !== nextProps.client.smsToken && !!nextProps.client.smsToken ) {
            this.setState({
                smsToken: nextProps.client.smsToken
            })
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const client = this.props.client;
        let valueInputAddress = this.state.valueInputAddress;
        let confirmValues = this.state.confirmValues;

        if ( this.state.disabledBtnReady && this.isEmptyObj(valueInputAddress) === false && valueInputAddress !== nextState.valueInputAddress ) {
            this.setState({
                disabledBtnReady: false
            })
        }

        if ( !!client && this.props.client.address === nextState.valueInputAddress.residenceAddress && !this.state.disabledBtnReady ) {
            this.setState({
                disabledBtnReady: true
            })
        }

        if ((
                (!confirmValues && nextState.confirmValues) ||
                (confirmValues && confirmValues.confirmEmailModal !== nextState.confirmValues.confirmEmailModal)
            ) && nextState.confirmValues.confirmEmailModal !== '') {
            this.setState({
                disabledBtnConfirmMail: false,
                errorTxtEmailValidation: ''
            })
        }

        if (!!confirmValues && !!confirmValues.confirmEmailModal && confirmValues.confirmEmailModal !== nextState.confirmValues.confirmEmailModal && nextState.confirmValues.confirmEmailModal === '') {
            this.setState({
                disabledBtnConfirmMail: true
            })
        }

        if (!!client && client.errorMessage !== nextProps.client.errorMessage && !!nextProps.client.errorMessage){
            this.setState({
                errorMessageModal: true
            })
        }

        if (this.state.modalName !== nextState.modalName && nextState.modalName === 'change-personal-data-request'){
            if (this.state.errorMessageModal){
                this.setState({
                    errorMessageModal: false
                })
            }
        }

        let param = window.location && window.location.search;
        if (typeof window != 'undefined') {
            if (!!param && this.state.urlParamsMailConfirm === false) {
                this.setState({
                    urlParamsMailConfirm: true
                })
            }
        }

        if (!!param && this.state.urlParamsMailConfirm !== nextState.urlParamsMailConfirm && !!nextState.urlParamsMailConfirm) {
            let params = new URL(window.location.href).searchParams;
            let email = params.get('email');
            let code = params.get('code');

            if (!!email && !!code){
                this.confirmEmailDataRequest('confirm', email, code);
                this.setState({confirmValues: {confirmEmailModal: email}, disabledBtnConfirmMail: false})
                history.replaceState({}, null, location.pathname);
            }

        }

        if (!!client && !client.changeEmail && client.errorEmailConfirmMessage !== nextProps.client.errorEmailConfirmMessage && nextProps.client.errorEmailConfirmMessage) {
            this.setState({
                errorTxtEmailValidation: nextProps.client.errorEmailConfirmMessage[0].message
            }, () => {
                if (this.state.confirmValues.confirmEmailModal) {
                    this.onOpenModal("email-confirmation")()
                }
            })
        }

        if (client && !client.email && nextProps.client.email) {
            this.setState({confirmValues: {confirmEmailModal: ''}, disabledBtnConfirmMail: true})
        }

        if (this.state.newPhoneNumber === '' && this.state.requestAction !== nextState.requestAction && nextState.requestAction === 'code-validate') {
            this.setState({
                newPhoneNumber: nextState.confirmValues.confirmPhoneNumber
            })
        }

        if (!!nextProps.client.codeSend && client.codeSend !== nextProps.client.codeSend && nextProps.client.codeSend === 'Ok' && nextProps.client.params === "code-send") {
            this.setState({
                requestAction: 'code-validate'
            })
        }

        if (!!nextProps.client.codeValidate && client.codeValidate !== nextProps.client.codeValidate && nextProps.client.codeValidate === 'Ok' && nextProps.client.params === "code-validate") {
            this.setState({
                requestAction: 'code-send-old'
            })
        }

        if (!!nextProps.client.codeSendOld && client.codeSendOld !== nextProps.client.codeSendOld && nextProps.client.codeSendOld === 'Ok' && nextProps.client.params === "code-send-old") {
            this.setState({
                requestAction: 'code-validate-old'
            })
        }

        if (client.errorMesageRequestSms !== nextProps.client.errorMesageRequestSms && !!nextProps.client.errorMesageRequestSms && !!nextProps.client.codeValidate && nextProps.client.codeValidate === 'notOk') {
            this.setState({
                errorTxtSmsCodeValidation: nextProps.client.errorMesageRequestSms[0].message
            })
        }
        if (client.errorMesagePhoneOld !== nextProps.client.errorMesagePhoneOld && !!nextProps.client.errorMesagePhoneOld && !!nextProps.client.codeSendOld && nextProps.client.codeSendOld === 'notOk') {
            this.setState({
                errorTxtPhoneValidation: nextProps.client.errorMesagePhoneOld[0].message
            })
        }
        if (client.errorMesageValidateOld !== nextProps.client.errorMesageValidateOld && !!nextProps.client.errorMesageValidateOld && !!nextProps.client.codeValidateOld && nextProps.client.codeValidateOld === 'notOk') {
            this.setState({
                errorTxtSmsCodeValidation: nextProps.client.errorMesageValidateOld[0].message
            })
        }
        if (client.codeValidateOld !== nextProps.client.codeValidateOld && nextProps.client.codeValidateOld === 'Ok') {
            this.setState({
                requestAction: 'code-send'
            })
            this.clearItemData();
            setTimeout(() => {
                this.props.getMe();
            }, 1500)
        }

        if (client.reRequestSms !== nextProps.client.reRequestSms && !!nextProps.client.reRequestSms && !! nextProps.client.reRequestSms.message) {
            this.setState({
                errorTxtSmsCodeValidation: nextProps.client.reRequestSms.message
            })
        }
        if (client.reRequestSmsOld !== nextProps.client.reRequestSmsOld && !!nextProps.client.reRequestSmsOld && !! nextProps.client.reRequestSmsOld.message) {
            this.setState({
                errorTxtSmsCodeValidation: nextProps.client.reRequestSmsOld.message
            })
        }

    }

    componentWillUnmount() {
        this.props.clearChangePersonalData();
    }

    changeTextArea = (value) => {
        this.setState({comment: value});
    }

    onChangePersonalDataRequest = () => {
        let personalData = this.state.personalData;
        let comment = this.state.comment;
        let data = new FormData();
        data.append('is_surname', !!personalData.surname ? 1 : 0);
        data.append('is_name', !!personalData.firstName ? 1 : 0);
        data.append('is_patronymic', !!personalData.patronymic ? 1 : 0);
        data.append('is_birthday', !!personalData.birthDate ? 1 : 0);
        data.append('comment', comment);

        this.props.changePersonalData(data);
        this.clearItemForm();
    }

    changePhoneRequest = (type) => {
        let phone = !!this.state.confirmValues.confirmPhoneNumber && this.state.confirmValues.confirmPhoneNumber.replace(/\D/gim, '').slice(1);

        let data = new FormData();
        data.append('phone_number', phone);
        if (this.validationPhoneNumber(this.state.confirmValues.confirmPhoneNumber)!== '') {
            if (type === 'code-send') {
                this.props.changePhoneRequest(data, 'code-send');
            }
            if (type === 'code-send-old') {
                this.props.changePhoneRequest(data, 'code-send-old');
            }
        }
    }

    sendSmsCode = (e) => {
        e.preventDefault()
        let phone = !!this.state.passwordValues.phone_number && this.state.passwordValues.phone_number.replace(/\D/gim, '').slice(1);

        let data = new FormData();
        data.append('phone_number', phone);
        if (this.validationPhoneNumber(this.state.passwordValues.phone_number) !== '') {
            this.props.changePhoneRequest(data, 'code-send-recovery');
        }
    }

    onChangeConfirmSmsRequest = (type) => {
        let smsCode = !!this.state.smsCode && this.state.smsCode.code ? this.state.smsCode.code : null;
        let phone = !!this.state.confirmValues.confirmPhoneNumber && this.state.confirmValues.confirmPhoneNumber.replace(/\D/gim, '').slice(1);

        let data = new FormData();
        data.append('phone_number', phone);
        data.append('code', smsCode);
        this.validationSmsCode(smsCode, 'confirmSms');
        this.validationPhoneNumber(phone);

        if (this.checkSmsCodeError(smsCode, 'confirmSms') === '' && !!phone) {
            if (type === 'code-validate') {
                this.props.changePhoneRequest(data, 'code-validate');
            }
            if (type === 'code-validate-old') {
                this.props.changePhoneRequest(data, 'code-validate-old');
            }
        }
    }

    validateSmsCode = (e) => {
        e.preventDefault()
        let smsCode = this.state.passwordValues.code;
        let phone = !!this.state.passwordValues.phone_number && this.state.passwordValues.phone_number.replace(/\D/gim, '').slice(1);

        let data = new FormData();
        data.append('phone_number', phone);
        data.append('code', smsCode);

        if (!this.validationSmsCode(smsCode)) {
            this.props.changePhoneRequest(data, 'code-validate-recovery');
        }
    }

    sendConfirmPhoneRequest = () => {
        let token = this.state.smsToken;
        let client = this.props.client;
        let oldPhone = client.phone_number;
        let smsCode = this.state.smsCode && this.state.smsCode.code;
        let newPhoneNumber = this.state.newPhoneNumber.replace(/\D/gim, '').slice(1);

        let data = new FormData();
        data.append('phone_number', oldPhone);
        data.append('code', smsCode);
        data.append('new_phone_number', +newPhoneNumber);
        data.append('sms_token', token);

        this.props.changePhoneRequest(data, 'code-validate-old');
    }

    clearItemData =()=> {
        this.setState({
            smsToken: '',
            newPhoneNumber: ''
        })
    }

    onChangeEmailDataRequest = (action, typeVal) => {
        let email = '';
        if (typeVal === 'modal') {
            email = this.state.confirmValues && this.state.confirmValues.confirmEmailModal;
        }
        if (typeVal === 'lk') {
            email = this.state.confirmValues && this.state.confirmValues.confirmEmail;
        }
        let data = new FormData();
        data.append('email', email);
        this.props.changeEmailData(data, action);
    }

    confirmEmailDataRequest = (action, email, code) => {
        let data = new FormData();
        data.append('email', email);
        data.append('code', code);

        this.props.changeEmailData(data, action);

        if (!!this.state.urlParamsMailConfirm) {
            this.setState({
                urlParamsMailConfirm: false
            })
        }
    }

    onChangeAddressRequest = () => {
        let newAddress = this.state.valueInputAddress.residenceAddress;
        let data = new URLSearchParams();
        data.append('address', newAddress);
        this.props.changeAddress(data);
    }

    onConfirmEmail = (type) => {
        let email = '';
        if (type === 'modal') {
            email = this.state.confirmValues && this.state.confirmValues.confirmEmailModal;
        }
        if (type === 'lk') {
            email = this.state.confirmValues && this.state.confirmValues.confirmEmail;
        }

        if (this.checkEmailError(email) !== '') {
            this.setState({
                errorTxtEmailValidation: this.checkEmailError(email)
            })
        } else {
            if (type === 'modal') {
                this.onChangeEmailDataRequest('send-link', 'modal');
            }
            if (type === 'lk') {
                this.onChangeEmailDataRequest('send-link', 'lk');
            }
        }
    }

    inputOnKeyDown = (e, type) => {
        let value = '';
        if (type === 'confirmEmailModal'){
            value = this.state.confirmValues.confirmEmailModal;
        };
        if (type === 'confirmPhoneNumber'){
            value = this.state.confirmValues.confirmPhoneNumber;
        }
        if (type === 'confirmOldPhoneNumber'){
            value = this.state.confirmValues.confirmOldPhoneNumber;
        }

        if (e) {
            if ((e.key === 'Enter') || (e.code === 'Enter') || (e.which == '13')) {
                if (type === 'confirmEmailModal' || type === 'confirmEmail'){
                    if (this.checkEmailError(value) !== '') {
                        this.setState({
                            errorTxtEmailValidation: this.checkEmailError(value)
                        })
                    }
                }
                if (type === 'confirmPhoneNumber' || type === 'confirmOldPhoneNumber'){
                    this.validationPhoneNumber(value);
                }
            }
        };

    };

    validationPhoneNumber =(value)=> {
        if (this.checkPhoneError(value) !== '') {
            this.setState({
                errorTxtPhoneValidation: this.checkPhoneError(value)
            })
        } else {
            this.setState({
                errorTxtPhoneValidation: ''
            })
        }
    }

    validationSmsCode =(value, type)=> {
        if (this.checkSmsCodeError(value, type) !== '') {
            this.setState({
                errorTxtSmsCodeValidation: this.checkSmsCodeError(value, type)
            })
        } else {
            this.setState({
                errorTxtSmsCodeValidation: ''
            })
        }
    }

    setActionDefault =(item)=> {
        this.setState({
            requestAction: item
        })
    }

    modalComponent = (type) => {

        let {
            passwordErrors,
            submitDisabled,
            passwordValues
        } = this.props;

        switch (type) {
            case 'change-personal-data-request':
                return <ChangePersonalDataRequestModal
                    onChange={this.checkboxHandler}
                    onChangeTextArea={this.changeTextArea}
                    isChecked={this.isAnyOptionChecked()}
                    onClick={this.onChangePersonalDataRequest}
                    errorMessage={this.state.errorMessageModal}
                    screenSize={this.props.screen_size} />;
            case 'succeed-request':
                return <SucceedRequestModal onClick={this.onCloseModal} screenSize={this.props.screen_size} />;
            case 'email-confirmation':
                return <EmailConfirmationModal
                    onChange={this.changeFieldConfirm}
                    onClick={this.onConfirmEmail}
                    isChecked={this.state.disabledBtnConfirmMail}
                    value={this.state.confirmValues.confirmEmailModal}
                    error_text={this.state.errorTxtEmailValidation}
                    onBlur={this.onBlurValues}
                    onFocus={this.onFocus}
                    inputOnKeyDown={this.inputOnKeyDown}
                    screenSize={this.props.screen_size} />;
            case 'email-confirmation-successfully-sent':
                return <EmailConfirmationSuccessfullySentModal screenSize={this.props.screen_size} onClick={this.onCloseModal} />;
            case 'send-sms-code':
                return <ConfirmModal onChange={this.onChange}
                                     onSubmit={this.sendSmsCode}
                                     onBlur={(e) => {
                                         this.props.validate('confirmation', e.target.name, e.target.value)
                                     }}
                                     onFocus={(e) => {
                                         this.props.clearError('confirmation', e.target.name)
                                     }}
                                     errors={passwordErrors}
                                     values={passwordValues}
                                     submitDisabled={submitDisabled}
                                     title="Подтверждение телефона"
                />;
            case 'validate-sms-code':
                return <ConfirmCodeModal onChange={this.onChange}
                                         onSubmit={this.validateSmsCode}
                                         onSubmitPrev={this.sendSmsCode}
                                         goBack={this.onOpenModal('send-sms-code')}
                                         errors={passwordErrors}
                                         values={passwordValues}
                                         submitDisabled={submitDisabled}
                                         title="Подтверждение телефона"
                />;
            case 'set-password':
                return <PasswordModal onChange={(e) => {
                                         this.onChange(e);
                                         if (e.target.name == 'newPassword') {
                                             this.passwordCheckHandler(e.target.value)
                                         }
                                      }}
                                      onSubmit={(e) => {this.changePassword(e, true)}}
                                      errors={passwordErrors}
                                      submitDisabled={submitDisabled}
                                      title="Установка пароля"
                                      firstPasswordRule={this.state.firstPasswordRule}
                                      secondPasswordRule={this.state.secondPasswordRule}
                                      thirdPasswordRule={this.state.thirdPasswordRule}
                                      progressBarCount={this.getProgressBarCount()}
                                      newPasswordValue={this.state.passwordValues.newPassword}
                                      onBlur={(e) => {
                                          this.props.validate('password', e.target.name, e.target.value)
                                      }}
                                      onFocus={(e) => {
                                          this.props.clearError('password', e.target.name)
                                      }}
                />;
        }
        return null;
    };

    changePassword = (e, isRecovery) => {
        if (e) e.preventDefault();
        this.props.changePassword(prepareData(this.state.passwordValues, 'PUT'), isRecovery)
    };

    onBlur = (e) => {
        this.props.validate('password', e.target.name, e.target.value)
    };

    onFocus = (e) => {
        this.props.clearError('password', e.target.name)

        this.clearErrorTxt();
    };

    onBlurValues = (e, type) => {
        let confirmValues = this.state.confirmValues;

        if (type === 'confirmEmail') {
            if (!!confirmValues.confirmEmailModal && this.checkEmailError(e.target.value) !== '' || !!confirmValues.confirmEmail && this.checkEmailError(e.target.value) !== '') {
                this.setState({
                    errorTxtEmailValidation: this.checkEmailError(e.target.value)
                })
            }
        }

        if (type === 'onBlurPhone') {
            this.validationPhoneNumber(e.target.value);
        }

        if (type === 'confirmSms') {
            this.validationSmsCode(e.target.value, 'confirmSms');
        }

    };

    clearErrorTxt = () => {
        if (this.state.errorTxtEmailValidation !== ''){
            this.setState({
                errorTxtEmailValidation: ''
            })
        }
        if (this.state.errorTxtPhoneValidation !== '') {
            this.setState({
                errorTxtPhoneValidation: ''
            })
        }
    }

    render() {

        let {
            client,
            screen_size,
            passwordErrors,
            notifications = [],
            changePasswordSuccess,
            closeNotification,
            changeAddressSuccess
        } = this.props;

        let {
            modal,
            list,
            modalName,
            valueInputAddress,
            disabledBtnReady,
            errorTxtPhoneValidation,
            errorTxtEmailValidation,
            errorTxtSmsCodeValidation,
            confirmValues,
            requestAction
        } = this.state;

        this.settingsInfo = {
            personalProps: {
                data: client,
                valueInputAddress: valueInputAddress
            },
            passwordProps: {
                onSubmit: this.changePassword,
                onChange: (e) => {
                    this.onChange(e);
                    if (e.target.name == 'newPassword') {
                        this.passwordCheckHandler(e.target.value)
                    }
                },
                errors: modalName ? {} : passwordErrors,
                changePasswordSuccess,
                onClickLink: () => {
                    this.setState({
                        passwordValues: {},
                        firstPasswordRule: false,
                        secondPasswordRule: false,
                        thirdPasswordRule: false
                    }, () => {
                        this.props.clearError('password', 'newPassword');
                        this.props.clearError('password', 'repeatPassword');
                        this.onOpenModal('send-sms-code')()
                    });
                },
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                firstPasswordRule: this.state.firstPasswordRule,
                secondPasswordRule: this.state.secondPasswordRule,
                thirdPasswordRule: this.state.thirdPasswordRule,
                progressBarCount: this.getProgressBarCount(),
                newPasswordValue: this.state.passwordValues.newPassword
            },
            policiesProps: list && list.length ? {
                infoPolis: list
            } : null,
            closeNotification
        };

        return <SettingsComponent
            onClickLink={this.onOpenModal('change-personal-data-request')}
            screenSize={screen_size} {...this.settingsInfo}
            onChange={this.changeAddressField}
            onChangeEmail={this.changeFieldConfirm}
            disabledBtnReady={disabledBtnReady}
            onChangeAddress={this.onChangeAddressRequest}
            changeAddressSuccess={changeAddressSuccess}
            error_text={errorTxtEmailValidation}
            clearErrorTxt={this.clearErrorTxt}
            onBlurEmail={this.onBlurValues}
            onFocusEmail={this.onFocus}
            changeEmailSuccess={client}
            onChangeEmailDataRequest={this.onConfirmEmail}
            inputOnKeyDown={this.inputOnKeyDown}
            onChangePhone={this.changeFieldConfirm}
            inputPhoneOnKeyDown={this.inputOnKeyDown}
            errorTxtPhoneValidation={errorTxtPhoneValidation}
            onBlurPhone={this.onBlurValues}
            changePhoneRequest={this.changePhoneRequest}
            onChangePhoneOld={this.changeFieldConfirm}
            onChangeSmsCode={this.changeSmsCodeField}
            errorTxtSmsCodeValidation={errorTxtSmsCodeValidation}
            onChangeConfirmSmsRequest={this.onChangeConfirmSmsRequest}
            onBlurValues={this.onBlurValues}
            confirmPhoneNumber={confirmValues}
            requestAction={requestAction}
            setActionDefault={this.setActionDefault}
            sendConfirmPhoneRequest={this.sendConfirmPhoneRequest}
            clearChangePhoneData={this.props.clearChangePhoneData}
            >
            {!isEmpty(modal) ?
                <RenderModal closeModal={this.closeModal}
                             modal={this.props.policySendSuccess ? {
                                 type: "common",
                                 data: <SuccessSendModal email={this.props.client.email}
                                                         screen_size={this.props.screen_size}
                                                         closeModal={this.closeModal}
                                                         sendPolicy={this.sendPolicy}
                                                         clear={this.props.clearSendPolicy}
                                 />
                             } : {
                                 type: "rightModal",
                                 data: this.getModal()}
                             }
                             screen_size={this.props.screen_size}/>: null}
            {modalName ?
                <RenderModal closeModal={this.onCloseModal} modal={{type: "common", data: this.modalComponent(modalName)}} screen_size={screen_size}/> : null}
        </SettingsComponent>;
    }
}

export default connect(
    state => ({
        authorization: state.authorization,
        list: state.polices.archive || [],
        client: state.client || {},
        passwordErrors: state.client.passwordErrors || {},
        changePasswordSuccess: state.client.changePasswordSuccess,
        notifications: state.notifications,
        policySendSuccess: state.polices.policySend,
        changePersonalDataSucces: state.client.dataChange,
        changeAddressSuccess: state.client.changeAddress,
        changeEmail: state.client.changeEmail
    }),
    dispatch => () => {
        return {
            changePassword: (data, isRecovery) => dispatch(changePassword(data, isRecovery)),
            setPassword: (data, count) => dispatch(setPassword(data, count,true)),
            confirm: (action, data) => dispatch(confirm(action, data, null, null, true)),
            closeNotification: (key) => dispatch(closeNotification(key)),
            policySend: (policyNumber) => dispatch(policySend(policyNumber)),
            clearSendPolicy: () => dispatch(clearSendPolicy()),
            download: (number) => dispatch(policyDownload(number)),
            downloadMemo: (number) => dispatch(policyDownloadMemo(number)),
            validate: (type, field, val) => dispatch(validate(type, field, val)),
            clearError: (type, field) => dispatch(clearError(type, field)),
            changePersonalData: (data) => dispatch(changePersonalData(data)),
            changeAddress: (data) => dispatch(changeAddress(data)),
            clearChangePersonalData: () => dispatch(clearChangePersonalData()),
            clearChangePhoneData: () => dispatch(clearChangePhoneData()),
            getMe: () => dispatch(me()),
            changeEmailData: (data, params) => dispatch(changeEmail(data, params)),
            changePhoneRequest: (data, params) => dispatch(changePhoneRequest(data, params)),
        }
    }
)(Settings)