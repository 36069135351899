import React, { Component } from 'react';
import Heading from '../../designSystem/elements/text/Heading';
import Button from '../../designSystem/elements/button/Button'
import '../../designSystem/common.css';
import { closeModal, openModal, isEmpty} from "../../designSystem/elements/modal/Modal";
import RenderModal from "../../designSystem/elements/modal/RenderModal"
import PolisPage from "./PolisPage"
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import HeadingIcon from "../../designSystem/elements/text/HeadingIcon";


class AllModals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:{}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }


    render() {
        screen_size= this.props.screen_size;
       let modalSend=
            <React.Fragment>
                <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>Отправить полис на почту</Heading>
                <div className="row-form">
                    <Input  typeInput={"input"} type="mail" label={"Email"} width={"100%"} />
                    <Button>Отправить</Button>
                </div>
            </React.Fragment>;
        let modalSuccess=
            <React.Fragment>
                <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>Отправить полис на почту</Heading>
                <div className="row-form">
                    <HeadingIcon style={{alignItems: "flex-start"}} color={colors.green} heading={{size: "s",  text: "Полис отправлен на belousov@buroburo.ru", color: colors.green }} icon={{name: "success", fill: colors.green, width:"22px", height:"22px"}}   screen_size={screen_size}> </HeadingIcon>
                </div>
                <div className="row-form row-2btn" style={{marginTop: "30px"}}>
                    <Button view={"button_bordered"}>Готово</Button>
                    <Button>Отправить</Button>
                </div>
            </React.Fragment>;


        let flag = !isEmpty(this.state.modal);

        let screen_size = this.props.screen_size;

        return (
            <React.Fragment>
                    <div className="page">
                        <div className="page_container">
                            <Heading size={"l"} screen_size={this.props.screen_size}>Страница полиса</Heading>
                            <Button onClick={()=>{this.openModal("rightModal",  <PolisPage exceptions={true} screen_size={screen_size}/>)}}> Открыть полис</Button>
                            <br/> <br/>
                            <Button view={"button_bordered"} onClick={()=>{this.openModal("common", modalSend)}}> Открыть модалку 1</Button> <br/> <br/>
                            <Button view={"button_bordered"} onClick={()=>{this.openModal("common", modalSuccess)}}> Открыть модалку 2</Button>

                        </div>
                        {flag === true ?
                            <RenderModal  closeModal={this.closeModal}  modal={this.state.modal} screen_size={this.props.screen_size}/>: ""}
                    </div>
            </React.Fragment>
        );
    }
}

export default AllModals;
