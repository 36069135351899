import {parseErrors} from "../tools/api-helper";

export default function authorization (state = {}, action) {
    switch (action.type) {

        case "AUTH":
            return {
                ...state,
                errors: undefined
            };
        case "AUTH_SUCCESS":
            return {
                ...state,
                successfulAuth: action.data
            };

        case "AUTH_VALIDATION_ERROR":
            return {
                ...state,
                errors: action.data.errors
            };
        case "AUTH_VALIDATION_VALIDATION_ERROR":
            return {
                ...state,
                errors: {...state.errors, ...parseErrors(action.data)}
            };

        case "AUTH_CLEAR_VALIDATION":
            let errors = {...state.errors};
            delete errors[action.field];
            return {
                ...state,
                errors
            };

        case "REGISTER":
            return {
                ...state,
                errors: undefined
            };
        case "REGISTER_SUCCESS":
            return {
                ...state,
                successfulRegistration: action.data
            };

        case "REGISTER_VALIDATION_ERROR":
            return {
                ...state,
                registerErrors: parseErrors(action.data)
            };

        case "REGISTER_VALIDATION_VALIDATION_ERROR":
            return {
                ...state,
                registerErrors: {...state.registerErrors, ...parseErrors(action.data)}
            };

        case "REGISTER_CLEAR_VALIDATION":
            let registerErrors = {...state.registerErrors};
            delete registerErrors[action.field];
            return {
                ...state,
                registerErrors
            };

        case "INVITATION":
            return {
                ...state,
            };
        case "INVITATION_SUCCESS":
            return {
                ...state,
                token: action.data.step || "",
            };
        case "INVITATION_CLEAR":
            return {
                ...state,
                token: "",
            };

        case "CONFIRMATION":
            return {
                ...state,
                confirmationErrors: undefined,
                emailCodeSent: undefined,
                smsCodeSent: undefined,
                smsCodeValid: undefined,
                submitDisabled: true,
                closeModal: undefined
            };

        case "CONFIRM_CLEAR_VALIDATION":
            let confirmationErrors = {...state.confirmationErrors};
            delete confirmationErrors[action.field];
            return {
                ...state,
                confirmationErrors
            };

        case "CONFIRMATION_SEND-EMAIL-CODE_VALIDATION_ERROR":
        case "CONFIRMATION_SEND-SMS-CODE_VALIDATION_ERROR":
            return {
                ...state,
                confirmationErrors: parseErrors(action.data),
                submitDisabled: false
            };
        case "CONFIRMATION_VALIDATE-EMAIL-CODE_VALIDATION_ERROR":
        case "CONFIRMATION_VALIDATE-SMS-CODE_VALIDATION_ERROR":
            return {
                ...state,
                confirmationErrors: action.data.errors,
                submitDisabled: false
            };
        case "CONFIRMATION_VALIDATION_VALIDATION_ERROR":
            return {
                ...state,
                confirmationErrors: {...state.confirmationErrors, ...parseErrors(action.data)},
                submitDisabled: false
            };

        case "CONFIRMATION_SEND-EMAIL-CODE_BAD_REQUEST":
        case "CONFIRMATION_SEND-SMS-CODE_BAD_REQUEST":
        case "CONFIRMATION_VALIDATE-EMAIL-CODE_BAD_REQUEST":
        case "CONFIRMATION_VALIDATE-SMS-CODE_BAD_REQUEST":
            return {
                ...state,
                submitDisabled: false,
                closeModal: true
            };

        case "CONFIRMATION_SEND-EMAIL-CODE_SUCCESS":
            return {
                ...state,
                emailCodeSent: true,
                submitDisabled: false
            };
        case "CONFIRMATION_SEND-SMS-CODE_SUCCESS":
        case "CHANGE_PHONE_CODE_SEND_RECOVERY_SUCCESS":
            return {
                ...state,
                smsCodeSent: true,
                submitDisabled: false
            };
        case "CONFIRMATION_VALIDATE-EMAIL-CODE_SUCCESS":
            return {
                ...state,
                emailCodeValid: true,
                emailConfirmed: action.data.emailToken,
                submitDisabled: false
            };
        case "CONFIRMATION_VALIDATE-SMS-CODE_SUCCESS":
        case "CHANGE_PHONE_CODE_VALIDATE_RECOVERY_SUCCESS":
            return {
                ...state,
                smsCodeValid: true,
                smsConfirmed: action.data.smsToken,
                emailCodeValid: !action.data.needEmailToken || state.emailCodeValid,
                submitDisabled: false
            };

        case "SET_PASSWORD":
            return {
                ...state,
                passwordErrors: undefined,
                setPasswordSuccess: false
            };

        case "PASSWORD_CLEAR_VALIDATION":
            let passwordErrors = {...state.passwordErrors};
            delete passwordErrors[action.field];
            return {
                ...state,
                passwordErrors
            };

        case "SET_PASSWORD_VALIDATION_ERROR":
            return {
                ...state,
                passwordErrors: parseErrors(action.data)
            };

        case "PASSWORD_VALIDATION_VALIDATION_ERROR":
            return {
                ...state,
                passwordErrors: {...state.passwordErrors, ...parseErrors(action.data)}
            };

        case "SET_PASSWORD_BAD_REQUEST":
            return {
                ...state,
                passwordErrors: {
                    newPassword: "Поле обязательно для заполнения",
                    repeatPassword: "Поле обязательно для заполнения"
                }
            };

        case "SET_PASSWORD_SUCCESS":
            return {
                ...state,
                passwordErrors: undefined,
                setPasswordSuccess: true
            };

        case "BLOCKED":
            return {
                submitDisabled: false,
                closeModal: true
            };
        case "LOGOUT":
            return {};
        default:
            return state;
    }
}
