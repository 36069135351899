import React, { Component } from 'react';

import Icon from '../icon/Icon';
import {NotificationStyle, colors} from '../../DesignSystem';
import TextElem from "../text/TextElem";
import Button from "../button/Button";
import './notification.css';

const TEN_MINUTES = 60000;//600000;
const NOTIFICATIONS_SUCCESS_TYPES = ["successPassword", "success"];

export default class NotificationsContainer extends Component{

    constructor(props) {
        super(props);
        this.icons = {
            authorizeError:  {name: "clock", fill: colors.white, width: "22px", height: "22px"},
            error:  {name: "triangle", fill: colors.white,  width: "22px", height: "20px"},
            success: {name: "success", fill: colors.white,  width: "22px", height: "22px"},
            successPassword: {name: "success", fill: colors.white,  width: "22px", height: "22px"}
        };
        this.bgColor = {
            authorizeError: colors.red,
            error:  colors.red,
            success: colors.green,
            successPassword: colors.green
        };

        let leftTopCards=[];

        if (this.props.leftTopCards) {
            for (let i=0; i < this.props.leftTopCards.length; i++){
                leftTopCards[i] = this.props.leftTopCards[i];
                leftTopCards[i]["icon"] = this.icons[leftTopCards[i].type];
                leftTopCards[i]["bgColor"] = this.bgColor[leftTopCards[i].type];
            }
        }

        let rightBottomCards=[];

        if (this.props.rightBottomCards) {
            for (let i=0; i < this.props.rightBottomCards.length; i++){
                rightBottomCards[i] = this.props.rightBottomCards[i];
                rightBottomCards[i]["bgColor"] = colors.white;
            }
        }
        let h = 70;

        if (this.props.clinicsListFlag === false && this.props.pathname === "/clinics" || this.props.pathname === "/" && this.props.screen_size !== "xs") h = 35;
        if (this.props.screen_size === "xs") h = 50;

        this.state={
            maxWidthLeft: 0,
            maxWidthRight: 0,
            leftTopCards: leftTopCards,
            rightBottomCards: rightBottomCards,
            styleLeftTopContainer: {
                position: "fixed",
                top: h*2 + "px",
            }
        };

        this._notificationsLeft = [];
        this._notificationsRight = [];

        this.timeouts = [];
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.leftTopCards) !== JSON.stringify(nextProps.leftTopCards)) {
            let leftTopCards=[];

            if (nextProps.leftTopCards) {
                for (let i=0; i < nextProps.leftTopCards.length; i++){
                    leftTopCards[i] = nextProps.leftTopCards[i];
                    leftTopCards[i]["icon"] = this.icons[leftTopCards[i].type];
                    leftTopCards[i]["bgColor"] = this.bgColor[leftTopCards[i].type];

                    if (NOTIFICATIONS_SUCCESS_TYPES.includes(leftTopCards[i].type)) {
                        this.addNotificationClosingTimeout(leftTopCards[i].key, 'leftTopCards');
                    }
                }
            }

            this.setState({leftTopCards});
        }
        if (JSON.stringify(this.props.rightBottomCards) !== JSON.stringify(nextProps.rightBottomCards)) {
            let rightBottomCards=[];

            if (nextProps.rightBottomCards) {
                for (let i=0; i < nextProps.rightBottomCards.length; i++){
                    rightBottomCards[i] = nextProps.rightBottomCards[i];
                    rightBottomCards[i]["bgColor"] = colors.white;
                }
            }

            this.setState({rightBottomCards});
        }
    }

    addNotificationClosingTimeout = (key, container) => {
        this.timeouts.push(setTimeout(() => {
            this.closeFunc(key, container);
        }, TEN_MINUTES));
    }

    closeFunc=(i, container)=>{

        this.props.closeNotification && this.props.closeNotification(i);

        this.setState((prevState)=>{
            let s = {...prevState};
            s[container] = s[container].filter((el, index)=> { return index != i});
            return s;
        });
    };

    funcScroll=()=>{
        let h = 70;
        if (this.props.screen_size === "xs") h = 10;
        if (this.props.pathname === "/" && this.props.screen_size === "xs" || this.props.clinicsListFlag === false && this.props.pathname === "/clinics" && this.props.screen_size === "xs") h = 20;
        if (this.props.clinicsListFlag === false && this.props.pathname === "/clinics") h = 70;

        if (this.props.pathname === "/" || this.props.clinicsListFlag === false && this.props.pathname === "/clinics") {
            this.setState({
                styleLeftTopContainer: {
                    position: "fixed",
                    top: h + "px",
                }
            });
        } else if (this.state.styleLeftTopContainer.top !== h*2 +  "px" ) {
            this.setState({
                styleLeftTopContainer: {
                    position: "fixed",
                    top: h * 2 + "px",
                }
            });
        }

        if(this.state.styleLeftTopContainer.top !== h +  "px" ) {
            if (window.pageYOffset >= h) {

                this.setState({
                    styleLeftTopContainer: {
                        position: "fixed",
                        top: h + "px",
                    }
                });
            }
        }

    };

    componentDidMount() {
        window.addEventListener("scroll", this.funcScroll);
    }

    componentWillUnmount() {
        this.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    stylesForNotification = (index, side) => {

        if (side === 'left') {
            setTimeout(() => {
                this._notificationsLeft[index].style.left = '0';
                this._notificationsLeft[index].style.maxHeight = '500px';
                this._notificationsLeft[index].style.overflow = 'visible';
            }, 500);
            setTimeout(() => {
                this._notificationsLeft[index].style.transition = 'left .2s cubic-bezier(1, 0.01, 0, 1.22), max-height .3s ease-in-out .3s, overflow .3s ease-in-out .3s';
            }, 1000)
        }

        if (side === 'right') {
            setTimeout(() => {
                this._notificationsRight[index].style.right = '0';
                this._notificationsRight[index].style.maxHeight = '500px';
                this._notificationsRight[index].style.overflow = 'visible';
            }, 500);
            setTimeout(() => {
                this._notificationsLeft[index].style.transition = 'left .2s cubic-bezier(1, 0.01, 0, 1.22), max-height .3s ease-in-out .3s, overflow .3s ease-in-out .3s';
            }, 1000);

        }

        return {};
    };

    stylesForHideNotification = (index, side, key) => {
        if (side === 'left') {
            this._notificationsLeft[index].style.left = '-500px';
            this._notificationsLeft[index].style.maxHeight = '0';
            this._notificationsLeft[index].style.overflow = 'hidden';
            //this._notificationsLeft[index].style.transition = 'left .4s cubic-bezier(0.75, 0.24, 0.66, 1.64) .6s,max-height .3s ease-in-out,overflow .3s ease-in-out';
        }

        if (side === 'right') {
            this._notificationsLeft[index].style.right = '-350px';
            this._notificationsLeft[index].style.maxHeight = '0';
            this._notificationsLeft[index].style.overflow = 'hidden';
            //this._notificationsLeft[index].style.transition = 'right .4s cubic-bezier(0.75, 0.24, 0.66, 1.64) .6s,max-height .3s ease-in-out,overflow .3s ease-in-out';
        }

        setTimeout(() => {
            if (side === 'left') {
                this.closeFunc(key, "leftTopCards" )
            }
            if (side === 'right') {
                this.closeFunc(key, "rightBottomCards" )
            }
        }, 500);
    };

    render() {
        return (
            <React.Fragment>

                {
                    this.state.leftTopCards
                        ?
                        <div className={"containerLeftTop " + this.props.classNameLeftTopContainer} style={{...this.state.styleLeftTopContainer, ...this.props.style || {}}}>
                            { this.state.leftTopCards.map( (item, key) =>

                                <div
                                    className={"wrapNote wrapNote_left"}
                                    key={key}
                                    style={ this.stylesForNotification(key, 'left') }
                                    ref={ ref => { this._notificationsLeft[key] = ref; return true; } }
                                >
                                    <div className={item.shouldClose === true ? "notification SuccessErrorNotification closeNote" : "notification SuccessErrorNotification"} style={Object.assign({backgroundColor: item.bgColor}, NotificationStyle.common_attrs)}>
                                        <div className="row-content">
                                            <Icon {...item.icon}/>
                                            <div className="text"> <TextElem color={colors.white} dangerouslySetInnerHTML={{__html: item.text}}/></div>
                                        </div>
                                        {item.shouldClose === true ?
                                            <div className={"close"} onClick={()=>{ this.stylesForHideNotification(key, "left", item.key)}}><Icon onClick={()=>{ this.stylesForHideNotification(key, "left", item.key)}} name={"close"} fill={this.props.screen_size === "xs" ? colors.white_opacity7 : colors.dark_gray_opacity3} width={"12px"} height={"12px"}/></div> : ""
                                        }
                                    </div>
                                </div>

                            )}
                        </div>

                        : null
                }

                {
                    this.state.rightBottomCards
                        ?
                        <div className={"containerRightBottom " + this.props.classNameRightBottomContainer}>
                            { this.state.rightBottomCards.map( (item, key) =>

                                <div
                                    key={ key }
                                    className={ 'wrapNote wrapNote_right' }
                                    style={ this.stylesForNotification(key, 'right') }
                                    ref={ ref => { this._notificationsRight[key] = ref; return true; } }
                                >
                                    <div className={item.shouldClose === true ? "notification rightNotification closeNote" : "notification rightNotification"} style={Object.assign({backgroundColor: item.bgColor}, NotificationStyle.common_attrs, NotificationStyle.rightBottomCards)}>
                                        <div className="row-content">
                                            <Icon {...item.icon}/>
                                            <div className="content">
                                                <div className="text"> <TextElem color={colors.dark_gray}  dangerouslySetInnerHTML={{__html: item.text}}/></div>
                                            </div>
                                        </div>
                                        {item.button ?
                                            <div className="buttons">
                                                <Button {...item.button}>{item.button.text}</Button>
                                            </div>
                                            :""}
                                        {item.shouldClose === true ?
                                            <div className={"close"} onClick={()=>{this.stylesForHideNotification(key, "right", item.key)}}><Icon onClick={()=>{this.stylesForHideNotification(key, "right", item.key)}} name={"close"} fill={ colors.dark_gray_opacity3} width={"12px"} height={"12px"}/></div> : ""
                                        }
                                    </div>
                                </div>

                            )}
                        </div>
                        : null
                }
            </React.Fragment>

        )
    }
}