import {parseErrors} from "../tools/api-helper";

let initState = {
    pageLoaded: null,
    siteLink: "https://www.ugsk.ru/"
};

let allowBadRequests = [
    "AUTH_BAD_REQUEST",
    "CONFIRMATION_SEND-EMAIL-CODE_BAD_REQUEST",
    "CONFIRMATION_SEND-SMS-CODE_BAD_REQUEST",
    "CONFIRMATION_VALIDATE-EMAIL-CODE_BAD_REQUEST",
    "CONFIRMATION_VALIDATE-SMS-CODE_BAD_REQUEST",
    "SET_PASSWORD_BAD_REQUEST",
    "CHANGE_PASSWORD_BAD_REQUEST",
    "POLICY_ADD_BAD_REQUEST",
    "SEND_REVIEW_BAD_REQUEST"
];

let allowNotFounds = [

];

export default function common(state = initState, action) {

    switch (action.type) {
        case "LOAD_PAGE":
            return {...state, pageLoading: true};
        case "LOAD_PAGE_SUCCESS":
            return {...state, pageLoaded: action.pathname, pageLoading: false};
        case "GET_APPLICATION":
            return {...state, application: []};
        case "GET_APPLICATION_SUCCESS":
            return {...state, application: action.data};
        case "PROMOTION_CONFIRMATION":
            return {...state, confirmed: false};
        case "PROMOTION_CONFIRMATION_SUCCESS":
            return {...state, confirmed: true};
        case "POLICY_DOWNLOAD":
            return {...state, contentLink: null};
        case "POLICY_DOWNLOAD_SUCCESS":
            return {...state, contentLink: action.data.contentLink};
        case "POLICY_DOWNLOAD_MEMO":
            return {...state, contentMemoLink: null};
        case "POLICY_DOWNLOAD_MEMO_SUCCESS":
            return {...state, contentMemoLink: action.data.contentMemoLink};
        case "CLEAR_ERROR":
            return {...state, error: undefined};
        case "LOGOUT":
        case "BLOCKED":
            return {pageLoaded: "/auth", pageLoading: false, siteLink: "https://www.ugsk.ru/"};
        default:
            if (action.type.indexOf("_ERROR") !== -1 && action.type.indexOf("_VALIDATION") === -1) {
                return {...state, error: {title: "500 Внутренняя ошибка сервера"}, pageLoading: false, pageLoaded: location.pathname};
            }
            if (
                (action.type.indexOf("_BAD_REQUEST") !== -1 && allowBadRequests.indexOf(action.type) === -1) ||
                (action.type.indexOf("_NOT_FOUND") !== -1 && allowNotFounds.indexOf(action.type) === -1)
            ) {
                return {...state, error: {title: action.data.status+" "+action.data.name, text: action.data.message}, pageLoading: false, pageLoaded: location.pathname};
            }
    }
    return state;
}