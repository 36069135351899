import {parseErrors} from "../tools/api-helper";

export default function notifications(state = [], action) {
    switch (action.type) {
        case "AUTH_BAD_REQUEST":
        case "CONFIRMATION_SEND-EMAIL-CODE_BAD_REQUEST":
        case "CONFIRMATION_SEND-SMS-CODE_BAD_REQUEST":
        case "CONFIRMATION_VALIDATE-EMAIL-CODE_BAD_REQUEST":
        case "CONFIRMATION_VALIDATE-SMS-CODE_BAD_REQUEST":
        case "POLICY_ADD_BAD_REQUEST":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "error",
                    text: action.data.message,
                    shouldClose: true
                }
            ];
        case "BLOCKED":
            return state.filter((item) => {return item.key === "blocked"}).length ? state : [
                {
                    key: "blocked",
                    type: "error",
                    text: action.data.message,
                    shouldClose: true
                }
            ];
        case "CHANGE_PASSWORD_SUCCESS":
        case "SET_PASSWORD_SUCCESS":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "success",
                    text: "Пароль успешно изменен",
                    shouldClose: true
                }
            ];
        case "AUTH":
        case "CONFIRMATION_SEND-EMAIL-CODE":
        case "CONFIRMATION_SEND-SMS-CODE":
        case "CONFIRMATION_VALIDATE-EMAIL-CODE":
        case "CONFIRMATION_VALIDATE-SMS-CODE":
        case "CONFIRMATION":

            var newState = state.filter((item) => {
                return item.key !== action.type.toLowerCase() + "_bad_request" && item.key !== "blocked";
            });
            return newState;

        case "CHANGE_PASSWORD":
        case "SET_PASSWORD":

            var newState = state.filter((item) => {
                return item.key !== action.type.toLowerCase() + "_success";
            });
            return newState;

        case "CLOSE_NOTIFICATION":
            var newState = state.filter((item) => {
                return item.key !== action.key;
            });
            return newState;

        case "AUTH_SUCCESS":
            var newState = state.filter((item) => {
                return item.type !== "error";
            });
            return newState;

        case "POLICY_ADD_CODE-VALIDATE_SUCCESS":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "success",
                    text: "Полис успешно добавлен",
                    shouldClose: true
                }
            ];

        case "CHANGE_PHONE_CODE_VALIDATE_OLD_SUCCESS":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "success",
                    text: "Номер телефона успешно изменен",
                    shouldClose: true
                }
            ];

        case "SET_EMAIL":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "success",
                    text: "Адрес электронной почты успешно изменен",
                    shouldClose: true
                }
            ];

        case "CHANGE_EMAIL_RESEND":
            if (action.data[0].field === 'code') {
                return [
                    {
                        key: action.type.toLowerCase(),
                        type: "error",
                        text: action.data[0].message,
                        shouldClose: true
                    }
                ];
            }
            break;

        case "AUTH_VALIDATION_ERROR":
        case "CONFIRMATION_VALIDATE-SMS-CODE_VALIDATION_ERROR":
            if (action.data.blockMessage) {
                return [
                    {
                        key: "blocked",
                        type: "error",
                        text: action.data.blockMessage,
                        shouldClose: true
                    }
                ];
            }
            break;

        case "SEND_REVIEW_BAD_REQUEST":
            return [
                {
                    key: action.type.toLowerCase(),
                    type: "error",
                    text: action.data.message,
                    shouldClose: true
                }
            ];

        case "FAILED_DEPENDENCY":
            return [
                {
                    key: "failed",
                    type: "error",
                    text: action.data.message,
                    shouldClose: true
                }
            ];

        case "LOGOUT":
            return [];
    }

    return state;
}
