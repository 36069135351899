import React, {Component} from 'react';
import HeadingIcon from "../../designSystem/elements/text/HeadingIcon";
import {spaces, fontSizes} from "../../designSystem/DesignSystem";
import "./listBlock.css"
import TextElem from "../../designSystem/elements/text/TextElem"
import Caption from "../../designSystem/elements/text/Caption";
import Link from "../../designSystem/elements/links/Link";

export default class ListBlock extends Component{

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    };

    handlerShowAll = () => {
        this.setState(prevState => {
            return {
                opened: !prevState.opened
            }
        })
    };

    render(){
        let n = this.props.list.items.length;
        let numberInCol = Math.ceil(n/2);

        let firstColumnLength = 0;
        let secondColumnLength = 0;

        // length alignment
        this.props.list.items.map((item, key) => {
            if (!item.doctors) {
                if (key <= numberInCol) {
                    firstColumnLength += item.length;
                } else {
                    secondColumnLength += item.length;
                    if (secondColumnLength >= firstColumnLength) {
                        secondColumnLength -= item.length;
                        // firstColumnLength += item.length;
                        numberInCol++;
                    }
                }
            }
        });

        let firstCol = [], secondCol = [];

        this.props.list.items.map((item, key) => {
                if (item.doctors) {

                    // partion doctors array

                    let subArr = [];
                    let sizeArr = Math.ceil(item.doctors.length / 4);

                    for (let i = 0; i < Math.ceil(item.doctors.length/sizeArr); i++){
                        subArr[i] = item.doctors.slice((i*sizeArr), (i*sizeArr) + sizeArr);
                    }

                    firstCol.push(<li style={{marginBottom: spaces.xs}}>
                        <TextElem screen_size={this.props.screen_size}>{item.title}</TextElem>
                    </li>);

                    firstCol.push(
                        <div className="list" style={{marginBottom: spaces.xs}}>
                            {
                                <React.Fragment>
                                    {/*<ul className={"ul-col-list ul-col-list_no-style"} style={{order: 1}}>*/}
                                    {/*    {subArr[0].map((doctor, index) => <li style={{listStyle: "none"}} key={index}><Caption>{doctor}</Caption></li>)}*/}
                                    {/*</ul>*/}
                                    {/*<ul className={"ul-col-list ul-col-list_no-style"} style={{order: 2}}>*/}
                                    {/*    {subArr[1].map((doctor, index) => <li style={{listStyle: "none"}} key={index}><Caption>{doctor}</Caption></li>)}*/}
                                    {/*</ul>*/}

                                    {/*//Uncomment if needed to show programs with wrong loaded info*/}
                                    {subArr[0] ? <ul className={"ul-col-list ul-col-list_no-style"} style={{order: 1}}>
                                        {subArr[0].map((doctor, index) => <li style={{listStyle: "none", marginBottom: "0"}} key={index}><Caption style={{lineHeight: "24px"}}>{doctor}</Caption></li>)}
                                    </ul> : ""}
                                    {subArr[1] ? <ul className={"ul-col-list ul-col-list_no-style"} style={{order: 2}}>
                                        {subArr[1].map((doctor, index) => <li style={{listStyle: "none", marginBottom: "0"}} key={index}><Caption style={{lineHeight: "24px"}}>{doctor}</Caption></li>)}
                                    </ul> : ""}
                                </React.Fragment>
                            }
                        </div>
                    );

                    secondCol.push(
                        <div className="list" style={{marginBottom: spaces.xs, marginTop: this.props.screen_size == 'xs' ? 0 : "27px", paddingLeft: 0}}>
                            {
                                <React.Fragment>
                                    {/*<ul className={"ul-col-list ul-col-list_no-style"}>*/}
                                    {/*    {subArr[2].map((doctor, index) => <li style={{listStyle: "none"}} key={index}><Caption>{doctor}</Caption></li>)}*/}
                                    {/*</ul>*/}
                                    {/*<ul className={"ul-col-list ul-col-list_no-style"}>*/}
                                    {/*    {subArr[3].map((doctor, index) => <li style={{listStyle: "none"}} key={index}><Caption>{doctor}</Caption></li>)}*/}
                                    {/*</ul>*/}

                                    {/*//Uncomment if needed to show programs with wrong loaded info*/}
                                    {subArr[2] ? <ul className={"ul-col-list ul-col-list_no-style"}>
                                        {subArr[2].map((doctor, index) => <li style={{listStyle: "none", marginBottom: "0"}} key={index}><Caption style={{lineHeight: "24px"}}>{doctor}</Caption></li>)}
                                    </ul> : ""}
                                    {subArr[3] ? <ul className={"ul-col-list ul-col-list_no-style"}>
                                        {subArr[3].map((doctor, index) => <li style={{listStyle: "none", marginBottom: "0"}} key={index}><Caption style={{lineHeight: "24px"}}>{doctor}</Caption></li>)}
                                    </ul> : ""}
                                </React.Fragment>
                            }
                        </div>
                    )

                } else {
                    firstCol.push(<li><TextElem screen_size={this.props.screen_size}>{item}</TextElem></li>)
                }
            }
        );
        return(

            <div className={"listBlock"}>
                <HeadingIcon style={{marginBottom: spaces.m}} screen_size={this.props.screen_size}{...this.props.headingIcon}/>

                <div className="list" style={{marginBottom: spaces.m}} >
                    <ul
                        className={secondCol.length > 0 ? "ul-col-list" : "ul-col-list items-service-programs"}
                        style= {
                            this.props.list.items[0] &&
                            this.props.list.items[0].doctors &&
                            this.props.list.items[0].doctors.length > 16 &&
                            this.state.opened === false
                            ?
                                { maxHeight: '120px', overflow: 'hidden' }
                            :
                                {}
                        }
                    >
                        {firstCol}
                    </ul>
                    {secondCol.length > 0 ? <ul
                        className={"ul-col-list"}
                        style= {
                            this.props.list.items[0] &&
                            this.props.list.items[0].doctors &&
                            this.props.list.items[0].doctors.length > 16 &&
                            this.state.opened === false
                            ?
                                { maxHeight: '120px', overflow: 'hidden' }
                            :
                                {}
                        }
                    >
                        {secondCol}
                    </ul> : null}
                    {
                        this.props.list.items[0] &&
                        this.props.list.items[0].doctors &&
                        this.props.list.items[0].doctors.length > 16
                        ?
                        <div style={{marginTop: '5px', order: 3, flexBasics: '100%', paddingLeft: '10px'}} onClick={ this.handlerShowAll }>
                            <Link style={{fontSize: fontSizes.small.default.size, lineHeight: fontSizes.small.default.lh}} className={"ul-list__show-all"} >
                                { this.state.opened ? 'Свернуть' : 'Показать всё' }
                            </Link>
                        </div>
                        : null
                    }
                </div>

            </div>
        )
    }
}