import React, {Component } from "react";

import "./Footer.css";
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Link from "../../designSystem/elements/links/Link";
import Caption from "../../designSystem/elements/text/Caption";
import Phones from "./Phones";
import Modal, {closeModal} from "../../designSystem/elements/modal/Modal";
import ReviewModal from "./ReviewModal";
import Layout from "./Layout";

export default class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showReviewModal: false,
            showClosingConfirmation: false
        }
    }

    showModal = (e) => {
        if (e) {
            e.preventDefault()
        }
        this.setState({showReviewModal: true})
    }

    hideModal = () => {
        this.setState({showReviewModal: false})
        closeModal()
        this.props.clearReview()
    }

    hideModalWithConfirm = () => {
        this.setState({showClosingConfirmation: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reviewStatus === "sending" && !this.props.reviewStatus) {
            this.hideModal()
        }
    }

    render(){
        let {
            isGuest,
            phone,
            additionalPhone,
            email,
            copyright,
            onlyInfo,
            links = [],
            screen_size,
            client,
            reviewStatus,
            reviewMessage,
            sendReview = () => {}
        } = this.props;

        let copyrightCopy = copyright.props.children;
        let mas = copyrightCopy.split(" ");
        let first = mas[0] + " ";
        mas.splice(0, 1);
        let second =mas.join(" ");
        let c = "";
        ((this.props.screen_size === "l") || (this.props.screen_size === "xl")) ?
            c = first + second
            : c = <React.Fragment> {first} {second}</React.Fragment>;
        // c = <React.Fragment> {first} <br/> {second}</React.Fragment>;

        return <footer className="footer" style={{padding: "20px 0 60px", borderTop: "1px solid "+colors.grayBg_color}}>
            <div className="page_container footer_container">
                {onlyInfo ? null : <div className="footer_left_block">
                    {links.map((item) => <div key={item.to} style={{marginBottom: spaces.xs}}>
                        <Link func={item.externalLink ? () => {} : null} targetBlank={item.targetBlank} to={item.to}>{item.title}</Link>
                    </div>)}
                    <div><Caption className="copyright" color={colors.lightGray_opacity_color6}>©
                        {c}

                    </Caption></div>
                </div>}
                <div className="footer_right_block">
                    <div className="footer_email">
                        <div style={{marginBottom: spaces.xs}}><TextElem color={colors.lightGray_color}>Обратная связь</TextElem></div>
                        <Link func={() => {}} to={"mailto:"+email}>{email}</Link>
                        {isGuest ? null : <div style={{marginTop: spaces.sm}}><Link func={this.showModal} to={"#"}>Оставить отзыв</Link></div>}
                    </div>
                    <Phones additionalPhone={additionalPhone} phone={phone} className="footer_phones"/>
                </div>
            </div>
            {this.state.showReviewModal ? <Modal type={"common"}
                                                 closeModal={this.hideModal}
                                                 overlayClick={this.hideModalWithConfirm}
                                                 className={"modal__common"}
                                                 screen_size={screen_size}
            >
                <ReviewModal screen_size={screen_size}
                             reviewStatus={this.state.showClosingConfirmation ? "closing" : reviewStatus}
                             reviewMessage={reviewMessage}
                             onSubmit={sendReview}
                             onClose={() => {this.setState({showClosingConfirmation: false}); this.hideModal()}}
                             onReturn={() => this.setState({showClosingConfirmation: false})}
                             email={client.is_email_confirmed ? client.email : ""}
                             phone={client.phone_number ? ""+client.phone_number : ""}
                />
            </Modal> : null}
        </footer>
    }

}