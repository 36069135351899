import React, { Component } from 'react';

import Email from "./forms/Email/Email";
import PhoneGetCode from "./forms/PhoneGetCode/PhoneGetCode";
import PhoneInputCode from "./forms/PhoneInputCode/PhoneInputCode";
import Address from "./forms/Address/Address";

import "./FormEditPersonalEdit.css";

class FormEditPersonalData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            getCode: false,
            time: {},
            seconds: 60,
            timer: 0
        };
    }

    secondsToTime =(secs)=>{
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    startTimer=()=> {
        if (this.state.timer == 0 && this.state.seconds > 0) {
          this.state.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown=()=> {
        let seconds = this.state.seconds - 1;
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });

        if (seconds == 0) {
          clearInterval(this.state.timer);
          this.setState({
              timer: 0,
              seconds: 60
          })
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.requestAction === 'code-validate-old' && nextProps.requestAction === 'code-send' ) {
            this.props.closeFormEdit();
        }

        if (this.props.requestAction !== nextProps.requestAction && this.props.requestAction === 'code-send' && nextProps.requestAction === 'code-validate' ||
        this.props.requestAction !== nextProps.requestAction && this.props.requestAction === 'code-validate' && nextProps.requestAction === 'code-send-old' ||
        this.props.requestAction !== nextProps.requestAction && this.props.requestAction === 'code-send-old' && nextProps.requestAction === 'code-validate-old') {
            this.handlerGetCodeBtn();
        }

        if (this.props.requestAction !== nextProps.requestAction && this.props.requestAction === 'code-send' && nextProps.requestAction === 'code-validate' ||
        this.props.requestAction !== nextProps.requestAction && this.props.requestAction === 'code-validate' && nextProps.requestAction === 'code-send-old') {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer =()=> {
        if (this.state.seconds > 0) {
            clearInterval(this.state.timer);
            this.setState({
                timer: 0,
                seconds: 60,
                time: {}
            })
        }
    }

    handlerGetCodeBtn = () => {
        this.setState(prevState => {
            return {
                getCode: !prevState.getCode
            }
        })
    };

    render() {
        let form;
        let {
            closeFormEdit,
            valueImput,
            onChange,
            screenSize,
            disabledBtnReady,
            onChangeAddress,
            onChangeEmail,
            error_text,
            onBlurEmail,
            onFocusEmail,
            onChangeEmailDataRequest,
            inputOnKeyDown,
            onChangePhone,
            inputPhoneOnKeyDown,
            errorTxtPhoneValidation,
            onBlurPhone,
            changePhoneRequest,
            onChangePhoneOld,
            onChangeSmsCode,
            errorTxtSmsCodeValidation,
            onChangeConfirmSmsRequest,
            onBlurValues,
            confirmPhoneNumber,
            requestAction,
            setActionDefault,
            sendConfirmPhoneRequest,
            clearChangePhoneData
        } = this.props;

        switch (this.props.typeFormEdit) {
            case 'editEmail': {
                form = <Email
                    screenSize={ screenSize }
                    closeFormEdit={closeFormEdit}
                    onChangeEmail={onChangeEmail}
                    error_text={error_text}
                    onBlurEmail={onBlurEmail}
                    onFocusEmail={onFocusEmail}
                    onChangeEmailDataRequest={onChangeEmailDataRequest}
                    inputOnKeyDown={inputOnKeyDown}
                    />;
                break;
            }
            case 'editPhone': {
                form =  <React.Fragment>
                            {
                                !this.state.getCode
                                ? <PhoneGetCode
                                    screenSize={ screenSize }
                                    handlerGetCodeBtn={changePhoneRequest}
                                    requestAction={requestAction}
                                    closeFormEdit={closeFormEdit}
                                    onChangePhone={onChangePhone}
                                    inputPhoneOnKeyDown={inputPhoneOnKeyDown}
                                    errorTxtPhoneValidation={errorTxtPhoneValidation}
                                    onBlurPhone={onBlurPhone}
                                    setActionDefault={setActionDefault}
                                    clearChangePhoneData={clearChangePhoneData}
                                />
                                : <PhoneInputCode
                                    screenSize={ screenSize }
                                    closeFormEdit={closeFormEdit}
                                    onChangePhoneOld={onChangePhoneOld}
                                    inputPhoneOnKeyDown={inputPhoneOnKeyDown}
                                    errorTxtPhoneValidation={errorTxtPhoneValidation}
                                    onBlurPhone={onBlurPhone}
                                    onChangeSmsCode={onChangeSmsCode}
                                    errorTxtSmsCodeValidation={errorTxtSmsCodeValidation}
                                    onChangeConfirmSmsRequest={onChangeConfirmSmsRequest}s
                                    onBlurValues={onBlurValues}
                                    confirmPhoneNumber={confirmPhoneNumber}
                                    // startTimer={this.startTimer}
                                    // stopTimer={this.stopTimer}
                                    // timerValue={this.state.time.s}
                                    setActionDefault={setActionDefault}
                                    requestAction={requestAction}
                                    handlerGetCodeBtn={changePhoneRequest}
                                    sendConfirmPhoneRequest={sendConfirmPhoneRequest}
                                    clearChangePhoneData={clearChangePhoneData}
                                />
                            }
                        </React.Fragment>;
                break;
            }
            case 'editAddress': {
                form = <Address
                    screenSize={ screenSize }
                    closeFormEdit={closeFormEdit}
                    valueImput={valueImput}
                    onChange={onChange}
                    disabledBtnReady={disabledBtnReady}
                    onChangeAddress={onChangeAddress}
                    />;
                break;
            }
        }

        return (
            <div className={'ugsk-form-edit' + (this.props.typeFormEdit == 'editPhone' && this.state.getCode ? 'ugsk-form-edit_send_code' : '')}>
                { form }
            </div>
        )
    }
}

export default FormEditPersonalData;