import {
    takeLatest,
    takeEvery,
    take,
    put,
    all
} from 'redux-saga/effects';
import {
    fetchAuth,
    fetchConfirm,
    fetchSetPassword,
    fetchLogout,
    fetchValidate,
    fetchInvitation,
    fetchRegister
} from "./authorization";
import {
    fetchPolicesActiveList,
    fetchPolicesArchiveList,
    fetchPolicyDownload,
    fetchPolicyDownloadMemo,
    fetchPolicySend,
    fetchPolicyAdd,
    fetchAdditionalServices
} from "./polices";
import {
    fetchClinicsList,
    fetchClinicsPrograms,
    fetchClinicsAddressHints,
    fetchClinicsServices,
    fetchClinicsConditions,
    fetchClinicsListCoords
} from "./clinics";
import {
    fetchMe,
    fetchChangePassword,
    fetchChangePersonalData,
    fetchChangeAddress,
    fetchChangeEmail,
    fetchChangePhone,
    fetchSendReview
} from "./client";
import {fetchGetApplication, fetchLoadPage,fetchPromotionConfirmation} from "./common";
import {fetchPromotionsList} from "./promotions";
import {fetchRecaptcha} from "./recaptcha";

export function* allSaga() {
    yield all([
        yield takeLatest("LOAD_PAGE", fetchLoadPage),
        yield takeLatest("GET_APPLICATION", fetchGetApplication),
        yield takeLatest("AUTH", fetchAuth),
        yield takeLatest("CONFIRMATION", fetchConfirm),
        yield takeLatest("INVITATION", fetchInvitation),
        yield takeLatest("REGISTER", fetchRegister),
        yield takeLatest("RECAPTCHA", fetchRecaptcha),
        yield takeLatest("SET_PASSWORD", fetchSetPassword),
        yield takeLatest("CHANGE_PASSWORD", fetchChangePassword),
        yield takeLatest("POLICES_ACTIVE_LIST", fetchPolicesActiveList),
        yield takeLatest("POLICES_ARCHIVE_LIST", fetchPolicesArchiveList),
        yield takeEvery("POLICY_DOWNLOAD", fetchPolicyDownload),
        yield takeEvery("POLICY_DOWNLOAD_MEMO", fetchPolicyDownloadMemo),
        yield takeLatest("POLICY_SEND", fetchPolicySend),
        yield takeLatest("POLICY_ADD", fetchPolicyAdd),
        yield takeLatest("ME", fetchMe),
        yield takeLatest("CLINICS_LIST", fetchClinicsList),
        yield takeLatest("CLINICS_LIST_COORDS", fetchClinicsListCoords),
        yield takeLatest("CLINICS_PROGRAMS", fetchClinicsPrograms),
        yield takeLatest("CLINICS_SERVICES", fetchClinicsServices),
        yield takeLatest("CLINICS_ADDRESSES", fetchClinicsAddressHints),
        yield takeLatest("CLINICS_CONDITIONS", fetchClinicsConditions),
        yield takeLatest("LOGOUT", fetchLogout),
        yield takeLatest("BLOCKED", fetchLogout),
        yield takeLatest("AUTH_VALIDATION", fetchValidate),
        yield takeLatest("CONFIRMATION_VALIDATION", fetchValidate),
        yield takeLatest("REGISTER_VALIDATION", fetchValidate),
        yield takeLatest("PASSWORD_VALIDATION", fetchValidate),
        yield takeLatest('PROMOTIONS_LIST',fetchPromotionsList),
        yield takeLatest('PROMOTION_CONFIRMATION',fetchPromotionConfirmation),
        yield takeLatest('ADDITIONAL_SERVICES',fetchAdditionalServices),
        yield takeLatest('CHANGE_PERSONAL_DATA',fetchChangePersonalData),
        yield takeLatest('CHANGE_ADDRESS',fetchChangeAddress),
        yield takeLatest('CHANGE_EMAIL',fetchChangeEmail),
        yield takeLatest('CHANGE_PHONE_CODE_SEND',fetchChangePhone),
        yield takeLatest('CHANGE_PHONE_CODE_VALIDATE',fetchChangePhone),
        yield takeLatest('CHANGE_PHONE_CODE_SEND_OLD',fetchChangePhone),
        yield takeLatest('CHANGE_PHONE_CODE_VALIDATE_OLD',fetchChangePhone),
        yield takeLatest('CHANGE_PHONE_CODE_SEND_RECOVERY',fetchChangePhone),
        yield takeLatest('CHANGE_PHONE_CODE_VALIDATE_RECOVERY',fetchChangePhone),
        yield takeLatest('SEND_REVIEW',fetchSendReview),
    ]);
}
