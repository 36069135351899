import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

import {
    auth,
    confirm,
    setPassword,
    validate,
    clearError,
    invite,
    register,
    clearInvitation
} from "../actions/authorization";
import {getKey} from "../actions/recaptcha";
import { prepareData, parseParams, changeField } from "../tools/form-helper";

import AuthorizationComponent from "../components/Authorization/Authorization";
import {closeModal} from "../designSystem/elements/modal/Modal";
import {ConfirmModal, ConfirmCodeModal, PasswordModal} from "../components/AuthorizationWithModals/AuthorizationModals";
import NotificationsContainer from "../designSystem/elements/notification/NotificationsContainer";
import {closeNotification} from "../actions/common";
import Preloader from "../designSystem/elements/preloader/Preloader";
import {isMobile, passwordCheckHandler, getProgressBarCount} from '../tools/tools';

class Authorization extends Component {

    constructor(props){
        super(props);

        let paramsObject = parseParams(window.location.search);

        this.state = {
            rememberMe: false,
            checkboxValue: 0,
            recaptchaToken: "",
            progressBarStep: 0,
            passwordValue: '',
            firstPasswordRule: false,
            secondPasswordRule: false,
            thirdPasswordRule: false,
            componentType: 'formAuth',
            formAuthValues: {
                phone_number: "",
                password: "",
            },
            signUpValues: {
                phone_number: "",
                policy_number: "",
                surname: 0,
                name: "",
                patronymic: "",
                birthday: undefined,
            },
            confirmValues: paramsObject,
            modalName: null,
            pagePreloading: false,
            phoneFormTitle: "Регистрация",
            type: null
        };
        this.changeConfirmationField = changeField.bind(this)('confirmValues');
        this.timeouts = [];
        this.inviteHandler = inviteHandler.bind(this);
        this.changeField = changeField.bind(this);
        this.closeModal = closeModal.bind(this);
        this.passwordCheckHandler = passwordCheckHandler.bind(this);
        this.getProgressBarCount = getProgressBarCount.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let {
            token,
        } = nextProps.authorization;

        if (token) {
            switch (token) {
                case 'login':
                    this.setState({
                        componentType: 'formAuth',
                    }, nextProps.clearInvitation)
                    break;
                case 'phone':
                    this.setState({
                        componentType: 'forgotPassword',
                    }, nextProps.clearInvitation)
                    break;
                case 'register':
                    this.setState({
                        componentType: 'signUp',
                    }, nextProps.clearInvitation)
                    break;
                default:
                    return;
            }
        }
    }

    componentDidMount() {
        this.props.getKey()
        if (!this.state.confirmValues.token) return;
        this.inviteHandler();
    }

    componentDidUpdate(prevProps) {
        const { history } = this.props;
        if (this.props.authorization.successfulAuth) {
            !this.props.authorization.successfulAuth.is_email_confirmed ? history.push('/settings') : history.push('/');
        }
        if (this.props.authorization.setPasswordSuccess && !prevProps.authorization.setPasswordSuccess) {
            this.onSubmit(null, "authAfterRegister")
        }
        if (this.props.recaptcha.siteKey !== prevProps.recaptcha.siteKey) {
            loadReCaptcha(this.props.recaptcha.siteKey)
        }
    }

    componentWillUnmount() {
        this.timeouts.forEach(timeout => clearTimeout(timeout));
        if (this.script) {
            document.body.removeChild(this.script);
        }
    }

    rememberMeHandler = () => {
        this.setState((prevState) => ({
                rememberMe: !prevState.rememberMe,
            })
        );
    }

    componentHandler = (componentType) => {
        let newState = {
            componentType: componentType
        }
        if (['formAuth', 'forgotPassword', 'signUp'].indexOf(componentType) !== -1) {
            newState = {
                ...newState,
                signUpValues: {},
                confirmValues: {},
                checkboxValue: 0
            }
        }

        if (componentType === 'forgotPassword') {
            newState.phoneFormTitle = "Восстановление пароля"
        }

        this.setState(newState)
    };

    goToAuth() {
        const { history } = this.props;
        history.push('/auth');
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({
            recaptchaToken: recaptchaToken
        }, () => {
            if (this.state.type) {
                this.submitByType(this.state.type);
                this.setState({type: null})
            }
        })
    }

    onCheckboxChange = (val) => {
        if (val) {
            this.props.clearError('confirm', 'agreement');
            this.props.clearError('register', 'agreement');
        }
        this.setState({
            checkboxValue: val ? 1 : 0,
        })
    }

    onChange = (ev, type, isNewPassword) => {
        this.changeField(type)(ev);
        isNewPassword ? this.passwordCheckHandler(ev.target.value) : null;
    }
    onBlur = (e, type) => {
        this.props.validate(type, e.target.name, e.target.value);
    };

    onFocus = (e, type) => {
        if (type === 'register' && ['surname', 'name', 'birthday'].indexOf(e.target.name) !== -1 && this.props.authorization.registerErrors &&
            this.props.authorization.registerErrors.surname === this.props.authorization.registerErrors.name &&
            this.props.authorization.registerErrors.surname === this.props.authorization.registerErrors.birthday) {
            this.props.clearError(type, 'surname');
            this.props.clearError(type, 'name');
            this.props.clearError(type, 'birthday');
        } else {
            this.props.clearError(type, e.target.name);
        }
    };

    onSubmit = (e, type) => {
        if (e) e.preventDefault();
        this.setState({type}, () => {
            this.recaptcha.execute()
        })
    };

    submitByType = (type) => {
        let values;

        switch (type) {
            case 'formAuth':
                values = {
                    ...this.state.formAuthValues,
                    recaptcha: this.state.recaptchaToken,
                }

                this.props.auth(prepareData(values, 'POST'), this.state.rememberMe);
                break;
            case 'authAfterRegister':
                values = {
                    phone_number: this.state.confirmValues.phone_number || this.state.signUpValues.phone_number,
                    password: this.state.confirmValues.newPassword,
                    recaptcha: this.state.recaptchaToken,
                }
                this.props.auth(prepareData(values, 'POST'));
                break;
            case 'signUp':
                values = {
                    ...this.state.signUpValues,
                    recaptcha: this.state.recaptchaToken,
                    agreement: this.state.checkboxValue
                }

                this.props.register(prepareData(values, 'POST'), this.componentHandler);
                break;
            case 'forgotPassword':
                values = {
                    phone_number: this.state.confirmValues.phone_number,
                    agreement: this.state.checkboxValue,
                    recaptcha: this.state.recaptchaToken,
                    link_token: this.state.confirmValues.token
                };
                this.props.confirm('send-sms-code', prepareData(values, 'POST'), this.componentHandler, 'SMSCode');
                break;
            case 'SMSCode':
                values = {
                    phone_number: this.state.confirmValues.phone_number ? this.state.confirmValues.phone_number : this.state.signUpValues.phone_number,
                    code: this.state.confirmValues.code,
                    recaptcha: this.state.recaptchaToken
                };
                this.props.confirm('validate-sms-code', prepareData(values, 'POST'), this.componentHandler, 'createNewPassword');
                break;
            case 'createNewPassword':
                values = {
                    phone_number: this.state.confirmValues.phone_number || this.state.signUpValues.phone_number,
                    smsToken: this.props.authorization.smsConfirmed,
                    newPassword: this.state.confirmValues.newPassword,
                    repeatPassword: this.state.confirmValues.repeatPassword,
                    recaptcha: this.state.recaptchaToken
                };
                this.props.setPassword(prepareData(values, 'POST'), this.getProgressBarCount());
                break;
            default:
                return null;
        }
    }

    render(){

        const progressBarCount = this.getProgressBarCount();
        return this.state.pagePreloading ? <div className="page">
            <Preloader shown={true}/>
        </div> : <div>
            <AuthorizationComponent
                passwordErrors={this.props.authorization.passwordErrors}
                confirmationErrors={this.props.authorization.confirmationErrors}
                registerErrors={this.props.authorization.registerErrors}
                errors={this.props.authorization.errors}
                notificationsError={this.props.notificationsError}
                passwordValue={this.state.confirmValues.newPassword}
                confirmedPhoneNumber={this.state.confirmValues.phone_number}
                firstPasswordRule={this.state.firstPasswordRule}
                secondPasswordRule={this.state.secondPasswordRule}
                thirdPasswordRule={this.state.thirdPasswordRule}
                type={this.state.componentType}
                onClick={this.componentHandler}
                rememberMeAction={this.rememberMeHandler}
                screenSize={this.props.screen_size}
                onChange={this.onChange}
                onCheckboxChange={this.onCheckboxChange}
                onSubmit={this.onSubmit}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                logoLink={this.props.siteLink}
                progressBarCount={progressBarCount}
                phoneFormTitle={this.state.phoneFormTitle}
            />
            {this.props.recaptcha.siteKey && (
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={this.props.recaptcha.siteKey}
                    action='submit'
                    verifyCallback={this.verifyCallback}
                />
            )}
        </div>
    }
}

export default withRouter(connect(
    state => ({
        recaptcha: state.recaptcha,
        authorization: state.authorization,
        notifications: state.notifications,
        siteLink: state.common.siteLink
    }),
    dispatch => ({
        auth: (data, remember) => dispatch(auth(data, remember)),
        register: (data, method) => dispatch(register(data, method)),
        getKey: () => dispatch(getKey()),
        setPassword: (data, isStrong) => dispatch(setPassword(data, isStrong)),
        confirm: (action, data, method, component) => dispatch(confirm(action, data, method, component)),
        invite: (action, data) => dispatch(invite(action, data)),
        closeNotification: (key) => dispatch(closeNotification(key)),
        validate: (type, name, val) => dispatch(validate(type, name, val)),
        clearError: (type, field) => dispatch(clearError(type, field)),
        clearInvitation: () => dispatch(clearInvitation())
    })
)(Authorization))

export function onOpenModal(type) {
    return () => {
        this.setState({modalName: type});
    }
}

export function onCloseModal() {
    if (this.state.modalName === "email-confirmation") {
        this.setState({confirmValues: {confirmEmailModal: ''}, disabledBtnConfirmMail: true})
    }
    this.setState({modalName: null});
    this.closeModal();
}

export function sendSmsCode (e) {
    if (e) e.preventDefault();

    let values = {
        phone_number: this.state.confirmValues.phone_number
    };
    this.props.confirm('send-sms-code', prepareData(values, 'POST'));
}

export function inviteHandler() {
    let values = {
        token: this.state.confirmValues.token
    }

    this.props.invite(prepareData(values, 'GET'));
    this.goToAuth();
}

export function validateSmsCode(e) {
    if (e) e.preventDefault();

    let values = {
        phone_number: this.state.confirmValues.phone_number,
        code: this.state.confirmValues.code
    };
    this.props.confirm('validate-sms-code', prepareData(values, 'POST'));
}

export function onSetPassword(e) {
    if (e) e.preventDefault();

    let values = {
        phone_number: this.state.confirmValues.phone_number,
        email: this.state.confirmValues.email,
        smsToken: this.props.authorization.smsConfirmed,
        emailToken: this.props.authorization.emailConfirmed,
        newPassword: this.state.confirmValues.newPassword,
        repeatPassword: this.state.confirmValues.repeatPassword,
        is_from_link: this.state.confirmValues.is_from_link
    };

    this.props.setPassword(prepareData(values, 'POST'));
}