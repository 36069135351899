import React, { Component } from 'react';

import DataLabled from '../../designSystem/elements/dataLabled/DataLabled'
import TextElem from "../../designSystem/elements/text/TextElem";
import Link from "../../designSystem/elements/links/Link";
import  {closeModal, openModal, isEmpty} from "../../designSystem/elements/modal/Modal";
import Button from "../../designSystem/elements/button/Button";
import InfoBlock from "./InfoBlock"
import {colors, spaces} from "../../designSystem/DesignSystem";

import RenderModal from "../../designSystem/elements/modal/RenderModal";
import ListBlock from "./ListBlock";
import ToggleHeading from "../../designSystem/elements/text/ToggleHeading"
import '../Polis/polisPage.css';

export default class PolisPage extends Component{


    constructor(props) {
        super(props);

        this.state = {
            modal: {},
            screen_size: this.props.screen_size ? this.props.screen_size : "xs",
            data: props.item && props.client ? this.getInfoblockData(props) : {}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.screen_size != this.state.screen_size) {
            this.setState(
                {screen_size: nextProps.screen_size}
            )
        }
    }
    icon_defaults={
        fill: colors.middle_blue_opacity5,
        width: "20px",
        height: "20px"
    };

    getInfoblockData = (props) => {
        let infoBlocksData = [], exceptionBlocksData = [];

        let {
            item,
            client,
            onClickDownload,
            onClickPrint,
            onClickSend,
            screen_size
        } = props;

        //common info
        infoBlocksData.push({
            heading: {size: "l", text: item.policy_type+" "+item.policy_number, styleHeading: {marginTop: spaces.l}},
            data: <React.Fragment>
                <div className="rowColumnsLabled4">
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={item.is_active ? "Выдан" : "Начнет действовать"} text={<TextElem>{item.date_start}</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={item.date_end_label} text={<TextElem>{item.date_end}</TextElem>}/>
                    </div>
                </div>
                <div className="rowColumnsAdaptive"  style={{marginBottom: spaces.mNegative}}>
                    {onClickDownload ? <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.sm}}>
                        <Button icon={ { name: "cloud", width: 22, height: 18 }} onClick={onClickDownload}>Скачать полис и памятку</Button>
                    </div> : null}
                    {onClickPrint ? <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.sm}}>
                        <Button icon={ { name: "print", width: 20, height: 20 }} onClick={onClickPrint}>Распечатать</Button>
                    </div> : null}
                    {onClickSend ? <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.sm}}>
                        <Button icon={ { name: "send", width: 20, height: 20 }} onClick={onClickSend}>Отправить на почту</Button>
                    </div> : null}
                </div>
            </React.Fragment>
        });
        //client info
        infoBlocksData.push({
            heading: {size: "m", text:"Застрахованное лицо"},
            data: <React.Fragment>
                <div className="rowColumnsLabled4" style={{marginBottom: screen_size === "xs" ? 0 : spaces.m}}>
                    <div className="column" style={screen_size === "xs" ? {marginBottom: spaces.m} : {marginRight: spaces.m}}>
                        <DataLabled label={"Фамилия"} text={<TextElem>{client.surname}</TextElem>}/>
                    </div>
                    <div className="column" style={screen_size === "xs" ? {marginBottom: spaces.m} : {marginRight: spaces.m}}>
                        <DataLabled label={"Имя"} text={<TextElem>{client.name}</TextElem>}/>
                    </div>
                    <div className="column" style={screen_size === "xs" ? {marginBottom: spaces.m} : {marginRight: spaces.m}}>
                        <DataLabled label={"Отчество"} text={<TextElem>{client.patronymic ? client.patronymic : "-"}</TextElem>}/>
                    </div>
                    <div className="column" style={screen_size === "xs" ? {marginBottom: spaces.m} : {marginRight: spaces.m}}>
                        <DataLabled label={"Дата рождения"} text={<TextElem>{client.birthday}</TextElem>}/>
                    </div>
                </div>
            </React.Fragment>
        });

        //program
        if (Array.isArray(item.program.content)) {
            item.program.content.map((content) => {
                if (content.title === "Исключения страхового покрытия") {
                    exceptionBlocksData = Array.isArray(content.subsections) ? content.subsections.map((subsection) => {
                        return {
                            headingIcon:{
                                icon: subsection.icon ? Object.assign({}, this.icon_defaults, {name: subsection.icon, width: 19}) : null,
                                icon_url: subsection.icon_url,
                                heading:{
                                    size: "s",
                                    text: subsection.title
                                }
                            },
                            list:{
                                items: subsection.services.map((service) => {
                                    return service.doctors && service.doctors.length ? {
                                            title: service.title + (service.comment ? " ("+service.comment+")" : ""),
                                            doctors: service.doctors.map((doctor) => doctor.name + (doctor.comment ? " ("+doctor.comment+")" : ""))
                                        } :
                                        service.title + (service.comment ? " ("+service.comment+")" : "")
                                })
                            }
                        }
                    }) : []
                } else {
                    infoBlocksData.push({
                        heading: {size: "m", text: content.title},
                        listBlocks : Array.isArray(content.subsections) ? content.subsections.map((subsection) => {
                            return {
                                headingIcon:{
                                    icon: subsection.icon ? Object.assign({}, this.icon_defaults, {name: subsection.icon, width: 19}) : null,
                                    icon_url: subsection.icon_url,
                                    heading:{
                                        size: "s",
                                        text: subsection.title
                                    }
                                },
                                list:{
                                    items: subsection.services.map((service) => {
                                        return service.doctors && service.doctors.length ? {
                                                title: service.title + (service.comment ? " ("+service.comment+")" : ""),
                                                doctors: service.doctors.map((doctor) => doctor.name + (doctor.comment ? " ("+doctor.comment+")" : ""))
                                            } :
                                            service.title + (service.comment ? " ("+service.comment+")" : "")
                                    })
                                }
                            }
                        }) : []
                    })
                }

            });
        }

        return {infoBlocksData: infoBlocksData.length ? infoBlocksData : undefined, exceptionBlocksData: exceptionBlocksData.length ? exceptionBlocksData : undefined};
    };

    infoBlocksData=[
         {
            heading: {size: "l", text:"ДМС 56341290", styleHeading: {marginTop: spaces.l}},
            data: <React.Fragment>
                <div className="rowColumnsLabled4">
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Выдан"} text={<TextElem>10.04.2016</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Истекает"} text={<TextElem>10.04.2019</TextElem>}/>
                    </div>
                </div>
                <div className="rowColumnsAdaptive"  style={{marginBottom: spaces.mNegative}}>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <Button icon={ { name: "cloud", width: 22, height: 18 }} onClick={()=>{}}>Скачать полис и памятку</Button>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <Button icon={ { name: "print", width: 20, height: 20 }} onClick={()=>{}}>Распечатать</Button>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <Button icon={ { name: "send", width: 20, height: 20 }} onClick={()=>{}}>Отправить на почту</Button>
                    </div>
                </div>
            </React.Fragment>
        },
        {
         heading: {size: "m", text:"Застрахованное лицо"},
            data: <React.Fragment>
                <div className="rowColumnsLabled4" style={{marginBottom: spaces.mNegative}}>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Фамилия"} text={<TextElem>Стародубский- Ромодановский</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Имя"} text={<TextElem>Константин</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Отчество"} text={<TextElem>Викторович</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Дата рождения"} text={<TextElem>22.03.1952</TextElem>}/>
                    </div>
                </div>
            </React.Fragment>
        },
        {heading: {size: "m", text:"Врач-куратор "},
            data: <React.Fragment>
                <div className="rowColumnsLabled4" style={{marginBottom: spaces.mNegative}}>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"ФИО"} text={<TextElem>Рыбина Ирина Семеновна</TextElem>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Телефон"} text={<Link>+7 (495) 223-70-56</Link>}/>
                    </div>
                    <div className="column" style={{marginRight: spaces.m, marginBottom: spaces.m}}>
                        <DataLabled label={"Email "} text={<Link> rybina@buroburo.ru </Link>}/>
                    </div>

                </div>
            </React.Fragment>

        },
        {
            heading: {size: "m", text: "Амбулаторно-поликлиническое обслуживание"},
            listBlocks : [

                {
                    headingIcon:{
                        icon: Object.assign({}, this.icon_defaults, {name: "geo"}),
                        heading:{
                            size: "s",
                            text: "Медицинская документация"
                        }
                    },
                    list:{
                        items: [
                            "Экспертиза временной нетрудоспособности (оформление листков временной нетрудоспособности)",
                            "Выдача медицинских справок",
                            "Оформление рецептов на лекарственные препараты (кроме льготных)",
                        ]
                    },
                    subList: {

                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({}, this.icon_defaults, {name: "vary", width: 19}),
                        heading:{
                            size: "s",
                            text: "Лабораторная диагностика"
                        }
                    },
                    list:{
                        items: [
                            "Общеклинические исследования",
                            "Биохимические исследования",
                            "Гормональные исследования",
                            "Иммунологические",
                            "Коагулогические исследования",
                            "Микробиологические исследования",
                            "Определение специфических иммуноглобулинов",
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({}, this.icon_defaults, {name: "graph"}),
                        heading:{
                            size: "s",
                            text: "Инструментальные методы диагностики"
                        }
                    },
                    list:{
                        items: [
                            "Рентгенологические исследования",
                            "Компьютерная томография",
                            "Магнитно-резонансная томография",
                            "Радиоизотопные исследования",
                            "Денситометрия",
                            "Функциональная диагностика",
                            "Электрокардиография",
                            "Электроэнцефалография",
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({}, this.icon_defaults, {name: "lamp", width: 14}),
                        heading:{
                            size: "s",
                            text: "Процедуры и лечебные манипуляции "
                        }
                    },
                    list:{
                        items: [
                            "Услуги, выполняемые средним медицинским персоналом по назначению врача",
                            "Выполняемые врачами процедуры и оперативные вмешательства, не требующие госпитализации и проводимые под местной анестезией",
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({name: "apps"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: "Дополнительные услуги"
                        }
                    },
                    list:{
                        items: [
                            "Стационарозамещающие медицинские услуги: в условиях стационара одного дня и дневного стационара (при наличии в программе Застрахованного плановой стационарной помощи)",
                            "Догоспитальное обследование (при условии наличия в программе Застрахованного плановой стационарной помощи)",
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({name: "cross"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: "Лечение заболеваний"
                        }
                    },
                    list:{
                        items: [
                            "Амбулаторные услуги (консультации, диагностические исследования, лечебные процедуры) по поводу сахарного диабета 2 типа (кроме осложнений сахарного диабета)",
                            "Лечение заболеваний, передающихся половым путем (ЗППП) без ограничений при условии наличия медицинских показаний",
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({}, this.icon_defaults, {name: "pony", height: "21px"},),
                        heading:{
                            size: "s",
                            text: "Дополнительные опции для детей "
                        }
                    },
                    list:{
                        items: [
                            "Составление плана профилактических прививок ",
                            "Проведение профилактической вакцинации согласно Национальному календарю профилактических прививок с использованием вакцин, разрешенных к применению на территории РФ, преимущественно импортными ",
                        ]
                    }
                }
            ]
        },
        {
         heading: {size: "m", text:"Помощь на дому "},
            listBlocks: [
                {
                    headingIcon:{
                        icon: Object.assign({name: "house"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: "Помощь на дому"
                        }
                    },
                    list:{
                        items: [
                            " Первичный, повторный осмотр врачом-терапевтом на дому Застрахованного, который по состоянию здоровья не может посетить поликлинику, нуждается в постельном режиме и наблюдении врача",
                            "Экспертиза временной нетрудоспособности (оформление листков временной нетрудоспособности), оформление рецептов на лекарственные препараты (кроме льготных) ",
                            "На дому по медицинским показаниям по назначению врача оказываются услуги: забор материалов для анализов и доставка их в медицинское учреждение в пределах МКАД",
                            "Плановые профилактические осмотры педиатра на дому"
                        ]
                    }
                },
            ]
        },
        {
            heading: {size: "m", text:"Стоматологическое обслуживание для взрослых и детей старше 1 года "},
            listBlocks: [
                {
                    headingIcon:{
                        icon: Object.assign({name: "tooth"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: " Приемы врачей-стоматологов "
                        }
                    },
                    list:{
                        items: [
                            "Консультация хирурга",
                            "Консультация пародонтолога"
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({name: "search"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: " Диагностические исследования "
                        }
                    },
                    list:{
                        items: [
                            "Рентгенография и радиовизиография зубов ",
                            "Ортопантомография (не более 1 раза в год)"
                        ]
                    }
                },
                {
                    headingIcon:{
                        icon: Object.assign({name: "sleep"}, this.icon_defaults),
                        heading:{
                            size: "s",
                            text: " Анестезия "
                        }
                    },
                    list:{
                        items: [
                            "Аппликационная ",
                            "Инфильтрационная"
                        ]
                    }
                },
            ]
        }

    ];



    render() {
        let flag = !isEmpty(this.state.modal);
        let screen_size = this.state.screen_size;

        let exceptions = this.state.data.exceptionBlocksData, contentHidden;

        if (exceptions !== undefined) {
            if (exceptions.length) {
                contentHidden = [];
                exceptions.map((exception, key) => {
                    contentHidden.push(<ListBlock screen_size={screen_size} key={key} {...exception} />)
                });

            } else {
                contentHidden = <ListBlock screen_size={screen_size} headingIcon={{heading: {size: "m", text: "Помощь на дому"}}} list={{

                    items: [
                        " Первичный, повторный осмотр врачом-терапевтом на дому Застрахованного, который по состоянию здоровья не может посетить поликлинику, нуждается в постельном режиме и наблюдении врача",
                        "Экспертиза временной нетрудоспособности (оформление листков временной нетрудоспособности), оформление рецептов на лекарственные препараты (кроме льготных) ",
                        "На дому по медицинским показаниям по назначению врача оказываются услуги: забор материалов для анализов и доставка их в медицинское учреждение в пределах МКАД",
                        "Плановые профилактические осмотры педиатра на дому"
                    ]
                }}/>
            }
        }


        let data = this.state.data.infoBlocksData || this.infoBlocksData;

        return (
            <React.Fragment>
                {data.map( (infoBlock, index)=>
                    <InfoBlock key={index}  screen_size={this.state.screen_size} heading={infoBlock.heading}>
                        {infoBlock.listBlocks ?
                            infoBlock.listBlocks.map((block, idx)=>
                                    <ListBlock screen_size={this.props.screen_size} headingIcon={block.headingIcon} list={block.list}/>
                            ) :
                            <div>
                                {infoBlock.data}
                            </div>

                        }
                    </InfoBlock>

                )}

               {/* <div style={{display: "flex", alignItems: "center", position: "relative"}}>
                    <Heading size={"m"} color={colors.middle_blue} style={{marginRight: "8px"}} screen_size={this.props.screen_size}>Исключения страхового покрытия </Heading>
                    <Icon style={
                        this.state.screen_size === "xs" ? {flexShrink: 0, position: "absolute",
                        bottom: "24px", left: "114px"} :
                        {
                        position: "relative", top: "12px", flexShrink: 0
                    }} name={"chevron-down"} fill={colors.middle_blue} width={"9px"} height={"14px"}></Icon>
                </div>*/}
                {contentHidden ?
                <div style={{paddingTop: "50px"}}>
                    <ToggleHeading screen_size={this.state.screen_size} content={contentHidden}>Исключения страхового покрытия</ToggleHeading>
                </div>
                    : null }
                {flag === true ?
                    <RenderModal closeModal={this.closeModal} modal={this.state.modal} heading={"Hello"} screen_size={this.props.screen_size}>
                        Hello!!
                    </RenderModal> : ""}


            </React.Fragment>
        )
    }
}

