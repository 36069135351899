import React, { Component } from 'react';
import Heading from "../../designSystem/elements/text/Heading"
import {spaces, colors} from "../../designSystem/DesignSystem";
import './infoBlock.css';


export default class InfoBlock extends Component{

    render() {

        let {
            size = "m",
            text,
            styleHeading = {}
        } = this.props.heading;

        let styles= {
            paddingBottom: spaces.l,
            borderBottom: "1px solid " + colors.light_gray,
        };

        return (
            <React.Fragment>
                <div className={"infoBlockPolis"} style={styles}>
                    {this.props.heading ?
                        <Heading style={{...styleHeading}} screen_size={this.props.screen_size ? this.props.screen_size : "s"} size={size}>{text} </Heading>
                        :""
                    }
                    {this.props.children}
                </div>
            </React.Fragment>
        )
    }
}

