import React, {Component} from "react";
import { YMaps, Map, Clusterer, Placemark, GeolocationControl, ZoomControl, ObjectManager } from 'react-yandex-maps';

import clinicIconDefault from "./images/clinicDefault.png";
import clinicIconDisabled from "./images/clinicDisabled.png";
import clinicIconClick from "./images/clinicClick.png";
import clinicIconClickRed from "./images/clinicClickRed.png";
import clinicIconHover from "./images/clinicHover.png";

import clusterDefault from "./images/clusterDefault.png";
import clusterDisabled from "./images/clusterDisabled.png";
import clusterClick from "./images/clusterClick.png";

export default class YMap extends Component{

    constructor(props){
        super(props);
        this.state = {
            // screen_size: props.screen_size ? props.screen_size : "xs",
            bounds: this.props.points ? this.getBounds(this.props.points, 0.02) : [],
            center: props.shownClinic && props.shownClinic.length ? props.shownClinic[0].coords : null,
            zoom: 16,
            features: this.getFeatures(props.points, props.shownClinic)
        };

        this.setSelected();

        this._ymap = null;
        this._manager = null;
        this._map = null;

        this.clusterIcons = [{
            href: clusterDefault,
            size: [40, 40],
            offset: [-20, -20]
        }];

        this.clusterIconsDisabled = [{
            href: clusterDisabled,
            size: [40, 40],
            offset: [-20, -20]
        }];
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.points !== this.props.points) {
            this.setState({
                // bounds: this.getBounds(nextProps.points, 0.02),
                // features: this.getFeatures(nextProps.points, nextProps.shownClinic, nextProps.screen_size)
                features: this.getFeatures(nextProps.points, nextProps.shownClinic)
            });
            setTimeout(() => {
                this.setState({
                    bounds: this.getBounds(nextProps.points, 0.005)
                });
            }, 10)
        }

        if (nextProps.shownClinic !== this.props.shownClinic) {

            if (nextProps.shownClinic && (!this.props.shownClinic || this.props.shownClinic[0].id !== nextProps.shownClinic[0].id)) {
                this.setState({center: nextProps.shownClinic[0].coords});
                this.setSelected();
            }

            if (this.props.shownClinic && !nextProps.shownClinic) {
                if(this._manager) {
                    this._manager.objects.each((item) => {
                        this._manager.objects.setObjectOptions(item.id, {iconImageHref: clinicIconDefault});
                    });
                    this._manager.clusters.each((item) => {
                        this._manager.clusters.setClusterOptions(item.id, {clusterIcons: this.clusterIcons});
                    });
                }
            }
        }

        // this.setState({
        //     screen_size: nextProps.screen_size,
        // })

        let centerPoint = this.state.center;

        if (!!centerPoint) {
            this.setState({
                bounds: this.getPointBounds(centerPoint)
            })
        }

        if (this.props.activeTab === 0 && !!centerPoint) {
            this.setState({
                bounds: this.getBounds(this.props.points, 0.02),
                center: null
            })
        }

        if (this.props.shownBounds === true && this.props.activeTab === 1 && centerPoint === null){
            this.setState({
                bounds: this.getBounds(this.props.points, 0.02),
                center: null
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if (this.props.shownClinic && !nextProps.shownClinic || nextProps.shownClinic && (!this.props.shownClinic || this.props.shownClinic[0].id !== nextProps.shownClinic[0].id)) {
            return true;
        }

        if (this.props.points !== nextProps.points) {
            return true;
        }

        if (this.state.bounds !== nextState.bounds) {
            return true;
        }

        return false;
    }

    setSelected = () => {

        if (this._manager && this.props.shownClinic && this.props.shownClinic.length) {
            let id = this.props.shownClinic[0].id;
            // let screen_size = this.state.screen_size;
            this._manager.objects.each((item) => {
                this._manager.objects.setObjectOptions(item.id, {iconImageHref: id === item.id ? clinicIconClick : clinicIconDisabled});
                // this._manager.objects.setObjectOptions(item.id, {iconImageHref: id === item.id ? ( screen_size === "xs" ? clinicIconClickRed : clinicIconClick ): clinicIconDisabled});
            });
            this._manager.clusters.each((item) => {
                let features = item.features.filter((feature) => {return feature.id === id});
                this._manager.clusters.setClusterOptions(item.id, {clusterIcons: features.length ? this.clusterIcons : this.clusterIconsDisabled});
            });
        }
    };

    getBounds = (items = [], delta = 0.02) => {
        let minLon = 1000000, minLat = 1000000, maxLon = 0, maxLat = 0;
        items.map((item) => {

            if (item.coords) {
                let coords = item.coords;

                if (coords) {
                    if (coords[0] < minLon) minLon = coords[0];
                    if (coords[0] > maxLon) maxLon = coords[0];
                    if (coords[1] < minLat) minLat = coords[1];
                    if (coords[1] > maxLat) maxLat = coords[1];
                }
            }
        });

        // const delta = 0.02;

        if (minLon === 1000000 && minLat === 1000000 && maxLon === 0 && maxLat === 0) {
            return null;
        }

        return [[minLon - delta, minLat - delta], [maxLon + delta, maxLat + delta]];
    };

    getPointBounds = (point) => {
        let delta = 0.005;
        return [[point[0] - delta, point[1] - delta], [point[0] + delta, point[1] + delta]];
    };

    getFeatures = (items = [], shownClinic) => {

        let features = [];
        items.map((item) => {

            if (item.coords) {
                let coords = item.coords;

                if (coords && coords.length === 2) {
                    features.push({
                        type: 'Feature',
                        id: item.id,
                        geometry: {
                            type: 'Point',
                            coordinates: [parseFloat(coords[0]), parseFloat(coords[1])]
                        },
                        options: {
                            iconImageHref: shownClinic && shownClinic.length ? (shownClinic[0].id === item.id ? clinicIconClick : clinicIconDisabled) : clinicIconDefault
                            // iconImageHref: shownClinic && shownClinic.length ? (shownClinic[0].id === item.id ? ( screen_size === "xs" ? clinicIconClickRed : clinicIconClick) : clinicIconDisabled) : clinicIconDefault
                        }
                    })
                }
            }
        });

        return features;
    };

    handlerInitMap = () => {
        // this._manager.add(this.state.features);
        if (!this._manager && this._map) {
            if (!this.props.shownClinic) {
                this._map.setZoom(4);
            }
        }

    };

    clearSelectedIcon = () => {
        this.props.onClick()
    };

    onClick = (ids) => {
        let points = this.props.points.filter((item) => {
            return ids.indexOf(item.id) !== -1
        });
        if (points.length && this.props.onClick) {
            this.props.onClick(points);
            this.props.onClick(points);
        }
    };

    render(){

        let {
            showZoom = false,
            showGeolocation = false,
            children,
            style = {},
            shownClinic,
            clusterer = false
        } = this.props;

        let {
            bounds,
            features,
            minZoom,
            center,
            zoom
        } = this.state;

        return <div className="map" style={{width: "100%", height: "100%", ...style}}>
            {
                clusterer
                    ?
                    (bounds ? <YMaps instanceRef={ref => {this._ymap = ref;}}>
                        <Map
                            // state={center ? {center, zoom} : {bounds}}
                            state={{bounds}}
                            options={{ restrictMapArea: [[84.789365, -141.328125],[-37.205200, 165.937500]]}}
                            width="100%"
                            height="100%"
                            onBoundsChange={() => {
                                this.setSelected()
                            }}
                            // instanceRef={ref => {this._map = ref; this.handlerInitMap();}}
                        >
                            {showZoom ? <ZoomControl options={{ position: {bottom: 80, right: 20} }} /> : null}
                            {showGeolocation ? <GeolocationControl options={{ position: {bottom: 30, right: 20}}} /> : null}
                            <ObjectManager
                                instanceRef={ref => this._manager = ref}
                                options={{
                                    clusterize: true,
                                    gridSize: 64,
                                }}
                                objects={{
                                    iconLayout: 'default#image',
                                    iconImageSize: [40, 45],
                                    iconImageOffset: [-20, -20],
                                    hasBalloon: false
                                }}
                                clusters={{
                                    clusterize: true,
                                    preset: 'islands#invertedNightClusterIcons',
                                    groupByCoordinates: false,
                                    minClusterSize: 2,
                                    clusterIcons: shownClinic ? this.clusterIconsDisabled : this.clusterIcons
                                }}
                                features={features}
                                onClick={(e) => {
                                    let id = e.get('objectId');
                                    if (id.indexOf("-") === -1) {

                                        let cluster = this._manager.clusters.getById(id);

                                        if (cluster && cluster.features.length) {
                                            let hasUnique = false, featureIds = [];
                                            for (let i = 0; i < cluster.features.length; i++) {
                                                if (cluster.features[i].geometry.coordinates[0] !== cluster.features[0].geometry.coordinates[0] || cluster.features[i].geometry.coordinates[1] !== cluster.features[0].geometry.coordinates[1]) {
                                                    hasUnique = true;
                                                    break;
                                                }
                                                featureIds.push(cluster.features[i].id)
                                            }

                                            if (!hasUnique) {
                                                this.onClick(featureIds);
                                            }
                                        }
                                    } else {

                                        this.onClick([id]);
                                    }
                                }}
                                onMapChange = {() => {
                                    this.setSelected()
                                }}
                            />
                        </Map>
                    </YMaps> : null)
                    :
                    <YMaps>
                        <Map
                            state={{ center: [55.744536, 37.665309], zoom: 9 }}
                            width="100%"
                            height="100%"
                        >
                        </Map>
                    </YMaps>
            }

            {children}
        </div>
    }
}