import React, {Component} from 'react';
import Heading from "../designSystem/elements/text/Heading";
import Link from "../designSystem/elements/links/Link";

export default class PagesList extends Component {

    render(){
        return <div className="page">
            <div className="page_container">
                <Heading size="l" screen_size={this.props.screen_size}>Список страниц</Heading>
                <ol>
                    <li><Link to="/polices" targetBlank={true}>Мои полисы</Link></li>
                    <li><Link to="/modals" targetBlank={true}>Детальная полиса</Link></li>
                    <li><Link to="settings" targetBlank={true}>Настройки</Link></li>
                    {/*<li><Link to="settings-with-notification" targetBlank={true}>Настройки (с уведомлениями)</Link></li>*/}
                    <li><Link to="SettingsWithAllModals" targetBlank={true}>Настройки с модальными окнами</Link></li>
                    {/*<li><Link to="settings-with-modals-one" targetBlank={true}>Настройки (с модальным окном 1)</Link></li>
                    <li><Link to="settings-with-modals-two" targetBlank={true}>Настройки (с модальным окном 2)</Link></li>*/}
                    <li><Link to="clinics" targetBlank={true}>Клиники</Link></li>
                    <li><Link to="athorization" targetBlank={true}>Авторизация</Link></li>
                    <li><Link to="athorization-with-notification" targetBlank={true}>Авторизация (с уведомлениями)</Link></li>

                    <li><Link to="AuthorizationWithAllModals">Авторизация с модальными окнами</Link></li>
                   {/* <li><Link to="athorization-with-modals-one" targetBlank={true}>Авторизация (с модальными окнами) 1</Link></li>
                    <li><Link to="athorization-with-modals-two" targetBlank={true}>Авторизация (с модальными окнами) 2</Link></li>
                    <li><Link to="athorization-with-modals-three" targetBlank={true}>Авторизация (с модальными окнами) 3</Link></li>*/}
                    <li><Link to="error-authorization" targetBlank={true}>Ошибка авторизации</Link></li>
                    <li><Link to="error" targetBlank={true}>Ошибка</Link></li>
                    <li><Link to="textPage">Текстовая страница</Link></li>


                    <li><Link to="dls" targetBlank={true}>UI-Kit</Link></li>


                </ol>
            </div>
        </div>
    }

}