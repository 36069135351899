import React, {Component} from "react";

import ErrorComponent from "../components/Error/Error";
import {connect} from "react-redux";
import {clearError} from "../actions/common";

class Error extends Component {

    componentWillUnmount() {
        this.props.clearError();
    }

    render(){

        return <ErrorComponent {...this.props}/>;
    }
}

export default connect(
    state => ({}),
    dispatch => () => {
        return {
            clearError: () => dispatch(clearError())
        }
    }
)(Error)