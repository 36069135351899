import React, { Component } from 'react';

import Modal from "../modal/Modal";
import Heading from "../text/Heading";


class RenderModal extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        switch(this.props.modal.type){
            case "rightModal":
                return <Modal closeModal={this.props.closeModal} screen_size={this.props.screen_size} type={this.props.modal.type} className={"modal__" + this.props.modal.type}  >
                    {this.props.modal.data}
                </Modal>;

            case "common":
                return <Modal closeModal={this.props.closeModal}  screen_size={this.props.screen_size} type={this.props.modal.type} className={"modal__" + this.props.modal.type + " modal__" + this.props.modal.className} >
                    {this.props.heading ?
                    <Heading size={"m"} style={{marginTop: 0}} screen_size={this.props.screen_size}>{this.props.heading}</Heading> :""}
                    {this.props.children ? this.props.children : this.props.modal.data}
                </Modal>;
        }

    }
}

export default RenderModal;
