import Icon from "../icon/Icon";
import {CheckBoxRadioStyle} from "../../DesignSystem";
import React from 'react';
import StateMouse from "../StateMouse";
import './radio.css';

export default class RadioElem extends StateMouse {

    constructor(props) {
        super(props);
        let mode="normal_mode";
        let prevMode = "normal_mode";
        if (this.props.disabled) {
            mode = "disabled_mode";
            prevMode = "disabled_mode";
        }
        this.state={
            mode: mode,
            prevMode: prevMode
        };
        this.inpRef = React.createRef();
        this._innerIcon = null;
    }
    onHover= ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    prevMode: prevState.mode,
                    mode: "hovered_mode"
                })
            });
        }
    };
    onMouseOut = ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    mode: "normal_mode",
                    prevMode: prevState.mode
                })
            });
        }
    };

    static getDerivedStateFromProps(nextProps, prevState){
        if ((nextProps.selected === true)) {
            return {
                mode: "selected_mode",
                prevMode: "hovered_mode"
            };
        }
        else if ((nextProps.selected === false)&&(prevState.mode === "selected_mode")){
            return {
                mode: "normal_mode",
                prevMode: "selected_mode"
            }
        }
        else {
            return null;
        }
    }

    onChange = ()=>{
        this._innerIcon.style.width = '6px';
        this._innerIcon.style.height = '6px';

        this.props.changeRadios(this.props.index);
    };

    mouseEvents= {
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onChange: this.onChange
    };


    render() {
        let item=this.props.item;
        let layout=this.props.layout;
        let stylesText =  Object.assign({}, CheckBoxRadioStyle.default_attrs.text, CheckBoxRadioStyle.text_attrs[layout][this.state.mode]);
        let stylesBtn = Object.assign({},  CheckBoxRadioStyle.default_attrs.icon.radio, CheckBoxRadioStyle.icon_attrs[layout][this.state.mode]);

        return (
                <label className={this.state.mode==="disabled_mode" ? "radio__label disabled" : "radio__label"}  style={stylesText} {...this.mouseEvents}>
                    <input id={"radio__" + item.value} type="radio" disabled={this.props.disabled} name={this.props.name}
                           className="radio__input" value={item.value} ref={this.inpRef} onChange={this.onChange}
                    />
                    <span className={"radio__icon"} style={stylesBtn}>
                        <div
                            className={"radio__icon-inner"}
                            ref={ ref => this._innerIcon = ref }
                            style={
                                this.props.selected ?
                                {
                                    width: '6px',
                                    height: '6px',
                                    background: CheckBoxRadioStyle.icon_attrs[layout][this.state.mode].fill
                                } :
                                {
                                    width: '22px',
                                    height: '22px',
                                    background: CheckBoxRadioStyle.icon_attrs[layout][this.state.mode].fill
                                }
                            }
                        />
                        {/*<Icon*/}
                        {/*    //style={ this.props.selected ? { display: 'block' } : { display: 'none' } }*/}
                        {/*    name={"radio"}*/}
                        {/*    width={"0"}*/}
                        {/*    height={"0"}*/}
                        {/*    fill={CheckBoxRadioStyle.icon_attrs[layout][this.state.mode].fill}*/}
                        {/*    className={"radio__icon-inner"}*/}
                        {/*    ref={ ref => { this._innerIcon = ref; }}*/}
                        {/*/>*/}
                    </span>
                    <span className={"radio__text"} style={stylesText}>{item.label} </span>
                </label>

        )
    }
}