import React, { Component } from 'react';
import svg4everybody from 'svg4everybody';

import {BreakPoints, colors} from './designSystem/DesignSystem';
import './designSystem/common.css';

import {Switch, Route, withRouter, BrowserRouter} from 'react-router-dom';
import PagesList from "./containers/PagesList";
import Polices from "./components/Polis/Polices";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Dls from "./components/Dls";
import Authorization from "./components/Authorization/Authorization";
import AuthorizationWithNotification from "./components/AuthorizationWithNotification/AuthorizationWithNotification";
import AuthorizationWithModalsRecoveryPassword from "./components/AuthorizationWithModals/AuthorizationWithModalsRecoveryPassword";
import AuthorizationWithModalsInputNewPassword from "./components/AuthorizationWithModals/AuthorizationWithModalsInputNewPassword";
import Error from "./components/Error/Error";
import ErrorAuthorization from "./components/ErrorAuthorization/ErrorAuthorization";
import Settings from "./components/Settings/Settings";
import AllModals from "./components/Polis/AllModals"
import TextPage from "./components/TextPage/TextPage";
import SettingsWithNotification from "./components/SettingsWithNotifiction/SettingsWithNotification";
import AuthorizationWithModalsInputCode from "./components/AuthorizationWithModals/AuthorizationWithModalsInputCode";
import SettingsWithModalCompleteRequest from "./components/SettingsWithModals/SettingsWithModalCompleteRequest";
import SettingsWithModalRequest from "./components/SettingsWithModals/SettingsWithModalRequest";

import lake from "./images/policies.jpg";
import img0 from "./images/recommendations/bg_image.jpg";
import img4 from "./images/recommendations/bg_image-1.jpg";
import img1 from "./images/recommendations/bg_image-2.jpg";
import img2 from "./images/recommendations/bg_image-3.jpg";
import img3 from "./images/recommendations/bg_image-4.jpg";
import Clinics from "./components/Clinics/Clinics";
import AuthorizationWithAllModals from "./components/AuthorizationWithModals/AuthorizationWithAllModals";
import SettingsWithAllModals from "./components/SettingsWithModals/SettingsWithAllModals"

class AppDls extends Component {


    constructor(props) {
        super(props);
        this.state = {
            screen_size: "l",
        };
        this.getScreenSize = this.getScreenSize.bind(this);

        this.headerInfo = {
            phone: "8 800 100-82-00",
            additionalPhone: "8 495 970-10-70",
            email: "dms@ugsk.ru",
            client: {
                name: "Мария"
            },
            menuLinks: [
                {
                    name: "Личные данные",
                    to: "/personal",
                    icon:{
                        name: "lock",
                        width: "13px",
                        height: "17px"
                    }
                },
                {
                    name: "Выйти из аккаунта",
                    to: "/auth",
                    icon:{
                        name: "exit",
                        width: "15px",
                        height: "17px"
                    }
                }
            ],
            tabLinks: [
                {
                    title: "Полисы",
                    name: "Полисы",
                    to: "/personal",
                    icon:{
                        name: "note-text",
                        width: "20px",
                        height: "20px"
                    }
                },
                {
                    title: "Медицинские учреждения",
                    name: "Медицинские учреждения",
                    to: "/auth",
                    icon:{
                        name: "pin-round",
                        width: "20px",
                        height: "20px"
                    }
                }
            ]
        };

        this.footerInfo = {
            phone: "8 800 100-82-00",
            additionalPhone: "8 495 970-10-70",
            email: "dms@ugsk.ru",
            copyright: <span>Акционерное общество «Группа страховых компаний «Югория»», 1997</span>,
            links: [
                {
                    to: "/textPage",
                    title: "Юридическая информация",
                    targetBlank: false,
                    externalLink: false
                },
                {
                    to: "#",
                    title: "Политика конфиденциальности",
                    targetBlank: true,
                    externalLink: false
                }
            ],
        };

        this.policesInfo = {
            title: "Мои полисы",
            bgImage: lake,
            polices: [
                {
                    id: 1,
                    name: "Мария Воробьева",
                    title: "ДМС 683400210",
                    label: "Истекает",
                    date: "10 апреля 2020",
                    buttons: {
                        action_button: null,

                        more_button:{
                            text: "Подробнее",
                            view: "button_bordered",
                            onClick: this.onClickPolis
                        }
                    },

                    icon: {
                        name: "cross",
                        width: "22px",
                        height: "22px",
                        fill: colors.middle_blue_opacity5
                    }
                },
                {
                    id: 2,
                    name: "Мария Воробьева",
                    title: "Полис путешественника",
                    label: "Истекает",
                    date: "10 апреля 2019",
                    buttons: {
                        action_button: {
                            text: "Продлить",
                            view: "button_filled",
                        },

                        more_button:{
                            text: "Подробнее",
                            view: "button_bordered",
                            onClick: this.onClickPolis
                        }
                    },

                    icon: {
                        name: "card1",
                        width: "22px",
                        height: "22px",
                        fill: colors.middle_blue_opacity5
                    }
                }
            ],
            infoNewPolis: {
                to: "#",
                text: <span>Оформить новый&nbsp;полис путешественника</span>,
            },
            infoNewAdvert: {
                to: "#",
                text: "Показать ещё рекомендации",
            },
            recommendationsTitle: "Рекомендации",
            recommendations: [
                {
                    id: 1,
                    to: "#",
                    title: "Лето – время скидок",
                    text: "Скидки до 50% на страхование с 1 июня по 25 июля ",
                    image: {
                        name: "bgCard2",
                        bg: img0
                    }
                },
                {
                    id: 2,
                    to: "#",
                    title: "Путешествие налегке",
                    text: "Скидки на полный страховой пакет для путешествий до 25%",
                    image:{
                        name: "bgCard",
                        bg: img1
                    }
                },
                {
                    id: 3,
                    to: "#",
                    color: colors.white,
                    title: "Поездки без ограничений",
                    text: "Программа страхования в путешествиях “мульти” без ограничения на количество поездок",
                    image:{
                        name: "bgCard",
                        bg: img2
                    }
                },
                {
                    id: 4,
                    to: "#",
                    color: colors.white,
                    title: "Программа ”бизнес”",
                    text: "Путешествуйте с одним полисом в течение года и не тратьте время на переоформление",
                    image:{
                        name: "bgCard",
                        bg: img3
                    }
                },
                {
                    id: 5,
                    to: "#",
                    color: colors.white,
                    title: "Безопасность – это не дорого",
                    text: "Программа страхования “В” позволит экономно путешествовать",
                    image:{
                        name: "bgCard",
                        bg: img4
                    }
                },
            ],
        };

        this.clinicsInfo = {
            title: "Медицинские учреждения",
            clinics: [
                {
                    id: 1,
                    title: "Клиника МЕДСИ в Благовещенском переулке",
                    link: "https://yandex.ru/maps/-/CCrSn-Yt",
                    metro: "м. Чеховская",
                    address: "г. Москва, Благовещенский переулок, дом 2/16, строение 1 ",
                    coords: [55.75222, 37.61556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    id: 2,
                    title: "Клиника МЕДСИ в Бутово",
                    metro: "м. Бутово",
                    link: "https://yandex.ru/maps/-/CCrWUSkP",
                    address: "г. Москва, ул. Старокачаловская, дом 3, корпус 3",
                    coords: [55.65222, 37.41556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    id: 3,
                    title: "Клиника МЕДСИ в Красногорске",
                    metro: null,
                    link: "https://yandex.ru/maps/-/CCrWUSkP",
                    address: "г. Москва, ул. Старокачаловская, дом 3, корпус 3",
                    coords: [56.75222, 37.91556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    id: 4,
                    title: "Клиника МЕДСИ в Благовещенском переулке",
                    metro: "м. Чеховская",
                    link: "https://yandex.ru/maps/-/CCrW4UYy",
                    address: "г. Москва, Благовещенский переулок, дом 2/16, строение 1 ",
                    coords: [55.75222, 37.51556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    id: 5,
                    title: "Клиника МЕДСИ в Бутово",
                    metro: "м. Бутово",
                    link: "https://yandex.ru/maps/-/CCrWUSkP",
                    address: "г. Москва, ул. Старокачаловская, дом 3, корпус 3",
                    coords: [55.88222, 36.61556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    id: 6,
                    title: "Клиника МЕДСИ в Красногорске",
                    metro: null,
                    link: "https://yandex.ru/maps/-/CCrWUSkP",
                    address: "г. Москва, ул. Старокачаловская, дом 3, корпус 3",
                    coords: [55.45222, 37.41556],
                    phone: "+7 (495) 223-70-56",
                    site: "medsi.ru/clinics/46883",
                    workTime: "08:00 — 21:00, ежедневно",
                    services: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                }
            ],
            filterFields: [
                {
                    title: "Виды помощи",
                    icon: "apps",
                    options: [
                        {
                            id: 1,
                            title: "Амбулаторно-поликлиническое обслуживание"
                        },
                        {
                            id: 2,
                            title: "Помощь на дому"
                        },
                        {
                            id: 3,
                            title: "Стоматологическое обслуживание"
                        },
                        {
                            id: 4,
                            title: "Скорая медицинская помощь"
                        },
                        {
                            id: 5,
                            title: "Плановая и экстренная госпитализация"
                        }
                    ]
                },
                {
                    title: "Условия",
                    icon: "clock",
                    options: [
                        {
                            id: 1,
                            title: "Круглосуточно"
                        },
                        {
                            id: 2,
                            title: "Без выходных"
                        }
                    ]
                }
            ]
        };

        this.errorContent = {
            title: "Название ошибки",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            buttonTitle: "Действие"
        };


        this.settingsInfo = {
            //for rendering on pageproofs
            personalProps: {
                data: {
                    id: "d7ff48ab-aa96-4296-8bba-abc5cf49ba5f",
                    surname: "Воробьева",
                    name: "Мария",
                    patronymic: "Викторовна",
                    gender: {
                        id: 2,
                        title: "Женский"
                    },
                    birthday: "22.03.1970",
                    address: "Московская область, г. Красногорск, ул. Успенская, д. 5",
                    phone_number: "79993561204",
                    email: "vorobieva@buroburo.ru"
                },
                canEdit: true
            },
            passwordProps: {
                dlsOnly: true
            },
            policiesProps: {
                infoPolis: [{
                    name: "Мария Воробьева",
                    title: "Полис путешественника",
                    label: "Истекает",
                    date: "10 апреля 2019",
                    buttons: {
                        more_button:{
                            text: "Подробнее",
                            view: "button_bordered",
                            onClick: this.onClickPolis
                        }
                    },

                    icon: {
                        name: "card1",
                        width: "22px",
                        height: "22px",
                        fill: colors.middle_blue_opacity5
                    }
                }]
            },
            leftTopCards: [
                {
                    type: "successPassword",
                    text: "Пароль успешно изменен",
                    shouldClose: true,
                }
            ]
        };
    }

    componentDidMount() {
        this.getScreenSize();
        window.addEventListener("resize", this.getScreenSize);
        svg4everybody();
    }

    getScreenSize(){
        if (typeof document != 'undefined'){
            let width = document.documentElement.clientWidth;
            let size = "";
            BreakPoints.map((item)=>{
                if ((width >= item.from)&&(width<= item.to)){
                    size = item.name;
                }
            });

            this.setState(
                {screen_size: size}
            )
        }
    }

    render() {

        let notShowHead;

        if (window.location.pathname == '/athorization' ||
            window.location.pathname == '/athorization-with-notification' ||
            window.location.pathname == '/athorization-with-modals-one' ||
            window.location.pathname == '/athorization-with-modals-two' ||
            window.location.pathname == '/athorization-with-modals-three' ||
            window.location.pathname == '/error-authorization')  {
            notShowHead = true;
        }

        return (
            <React.Fragment>
                <div className={"App App_dls " + "App__screen_size_" + this.state.screen_size}  id={"App"} >
                    {
                        !notShowHead
                        ? <Header {...this.headerInfo} screen_size={this.state.screen_size} />
                        : null
                    }

                    <Switch>
                        <Route path="/" render={() => <PagesList screen_size={this.state.screen_size} />} exact={true}/>
                        <Route path="/dls" component={Dls} />
                        <Route path="/polices" render={() => <Polices screen_size={this.state.screen_size} {...this.policesInfo} />}/>
                        <Route path="/modals" render={() => <AllModals screen_size={this.state.screen_size}/>}/>
                        <Route path='/athorization' render={() => <Authorization screenSize={ this.state.screen_size } />} />
                        <Route path='/athorization-with-notification' render={() => <AuthorizationWithNotification screenSize={ this.state.screen_size } />} />
                        <Route path='/athorization-with-modals-one' render={() => <AuthorizationWithModalsRecoveryPassword screenSize={ this.state.screen_size } />} />
                        <Route path='/athorization-with-modals-two' render={() => <AuthorizationWithModalsInputCode screenSize={ this.state.screen_size } />} />
                        <Route path='/athorization-with-modals-three' render={() => <AuthorizationWithModalsInputNewPassword screenSize={ this.state.screen_size } />} />
                        <Route path='/error-authorization' render={() => <ErrorAuthorization screenSize={ this.state.screen_size } />} />
                        <Route path='/error' render={() => <Error screenSize={ this.state.screen_size } {...this.errorContent} />} />
                        <Route path="/clinics" render={() => <Clinics screen_size={this.state.screen_size} {...this.clinicsInfo} />}/>
                        <Route path='/settings' render={() => <Settings {...this.settingsInfo} screenSize={ this.state.screen_size } />} />
                        <Route path='/settings-with-notification' render={() => <SettingsWithNotification screenSize={ this.state.screen_size } />} />
                        <Route path='/settings-with-modals-one' render={() => <SettingsWithModalCompleteRequest screenSize={ this.state.screen_size } />} />
                        <Route path='/settings-with-modals-two' render={() => <SettingsWithModalRequest screenSize={ this.state.screen_size } />} />
                        <Route path='/textPage' render={() => <TextPage screen_size={ this.state.screen_size } />} />
                        <Route path='/AuthorizationWithAllModals' render={() => <AuthorizationWithAllModals screen_size={ this.state.screen_size } />} />
                        <Route path='/SettingsWithAllModals' render={() => <SettingsWithAllModals  screen_size={ this.state.screen_size } />} />
                    </Switch>
                    <Footer {...this.footerInfo} screen_size = {this.state.screen_size}/>
                </div>
                <div id={"overlay"} style={{backgroundColor: colors.middle_blue_opacity8}}></div>
            </React.Fragment>
        );
    }
}

export default AppDls;
