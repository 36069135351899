import React, {Component} from 'react'
import Heading from "../../designSystem/elements/text/Heading"
import Button from "../../designSystem/elements/button/Button"
import Tabs from "../../designSystem/elements/tabs/Tabs"
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";

class TextPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
        this.tabItems = [
            {title: "Юридическая информация", icon: {name: "page", width: "15px", height: "18px"}},
            {title: "Политика конфиденциальности", icon:{name: "secure", width: "15px", height: "18px"}}
        ];

        this.tabStyles = {


            zIndex: 1000,

        };



    }

    render() {

        let content = [


                <div className="page_container textPage_container" style={{paddingTop: spaces.l, paddingBottom: spaces.xl}}>
                   <Heading size={"l"} style={{marginTop: spaces.m}} screen_size={this.props.screen_size}>Юридическая информация</Heading>
                </div>,

                <div className="page_container textPage_container" style={{paddingTop: spaces.l, paddingBottom: spaces.xl}}>

                    <Button icon={{name: "print", width: "20px", height: "20px", fill: colors.middle_blue_opacity5 }}>Распечатать</Button>
                    <Heading size={"l"} style={{marginTop: spaces.m}} screen_size={this.props.screen_size}>Политика конфиденциальности</Heading>
                    <div>
                        <TextElem screen_size={this.props.screen_size}>В целом, конечно, реализация намеченных плановых заданий обеспечивает широкому кругу (специалистов) участие в формировании направлений прогрессивного развития. Безусловно, курс на социально-ориентированный национальный проект предполагает независимые способы реализации новых принципов формирования материально-технической и кадровой базы. Не следует, однако, забывать, что постоянное информационно-пропагандистское обеспечение нашей деятельности предоставляет широкие возможности для кластеризации усилий.</TextElem>
                    </div>
                    <Heading size={"m"} screen_size={this.props.screen_size}>Заголовок 2 </Heading>
                    <Heading size={"s"} screen_size={this.props.screen_size}>Буллеты </Heading>
                    <ul>
                        <li><TextElem>Разнообразный и богатый опыт</TextElem></li>
                        <li><TextElem>Повседневная практика показывает, что экономическая повестка</TextElem></li>
                        <li><TextElem>Предварительные выводы неутешительны: укрепление и развитие внутренней структуры</TextElem></li>
                        <li><TextElem>Выдача медицинских справок</TextElem></li>
                    </ul>
                    <Heading size={"s"} screen_size={this.props.screen_size}> Нумерованный список</Heading>
                    <ol>
                        <li><TextElem>Задача организации</TextElem></li>
                        <li><TextElem>Имеется спорная точка зрения</TextElem></li>
                        <li><TextElem>Являясь всего лишь частью общей картины</TextElem></li>
                        <li><TextElem>Современные технологии достигли такого уровня</TextElem>
                            <ol>
                                <li><TextElem>Также как убежденность некоторых оппонентов требует определения</TextElem></li>
                                <li><TextElem>Вот вам яркий пример современных тенденций</TextElem></li>
                            </ol>
                        </li>
                    </ol>

                </div>,






        ];

        return(

            <div className={"page"}>
                <Tabs styleSwitcher={"iconed"}

                      items={this.tabItems}
                      activeTab={this.state.activeTab}
                      screen_size={this.props.screen_size}

                      style={this.tabStyles} >

                    {content}


                </Tabs>
            </div>




        );
    }
}

export default TextPage;
