import React, { Component } from 'react';

import './SettingWithModals.css'
import {closeModal, isEmpty, openModal} from "../../designSystem/elements/modal/Modal";
import Button from "../../designSystem/elements/button/Button";
import RenderModal from "../../designSystem/elements/modal/RenderModal";
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";
import StyledLink from "../../designSystem/elements/links/Link";

class SettingsWithModalRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:{},
            modal2: {},
            modal3: {}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    render() {
        let flag = !isEmpty(this.state.modal);

        let screen_size = this.props.screenSize;
        return (
            <React.Fragment>
                <div className="page page-noheight">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", "222")}}>Восстановление пароля</Button>

                        {flag === true ?
                            <RenderModal closeModal={this.closeModal} modal={this.state.modal} heading={"Восстановление пароля"} screen_size={this.props.screenSize}>
                                <div className={'ugsk-form-input-code'}>
                                    <div className={'ugsk-form-input-code__block'}>
                                        <Input className={'ugsk-form-input-code__input'} typeInput={"input"} type="text" label={"Код из SMS"} placeholder={""} />
                                        <div className={'ugsk-form-input-code__link-icon'}>
                                            <Icon
                                                className={'ugsk-form-input-code__icon'}
                                                name={'rotate-ccw'}
                                            />
                                            <StyledLink className='ugsk-form-input-code__link'>Выслать еще раз</StyledLink>
                                        </div>
                                    </div>
                                    <Button className={'ugsk-form-input-code__ready'} onClick={()=>{this.openModal("common", "222")}}>Запросить код</Button>
                                    {/*<Button className={'ugsk-form-input-code__cancel'} view={"button_bordered"}>Изменить номер</Button>*/}
                                </div>
                            </RenderModal> : ""}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SettingsWithModalRequest;