import React, { Component } from 'react';

import Icon from "../../designSystem/elements/icon/Icon";
import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import FormRequestAccess from "../../components/ErrorAuthorization/FormRequestAccess/FormRequestAccess";

import {colors, spaces} from '../../designSystem/DesignSystem';

import './ErrorAuthorization.css';

class ErrorAuthorization extends Component {

    getStylesTitle = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            case 'm':
            case 's':
            case 'xs': {
                return {
                    marginTop: spaces.m,
                }
            }
        }
    };

    getStylesText = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            case 'm':
            case 's': {
                return {
                    marginBottom: spaces.l
                }
            }

            case 'xs': {
                return {
                    marginBottom: spaces.m
                }
            }
        }
    };

    render() {

        let { screenSize } = this.props;

        return (
            <div className='page ugsk-page-error-authorization'>
                <div className='page_container'>
                    <div className='ugsk-page-error-authorization__container'>
                        <Icon
                            className='ugsk-page-error-authorization__icon'
                            name='lock'
                            fill={ colors.red }
                        />
                        <Heading
                            className='ugsk-page-error-authorization__title'
                            size='l'
                            style={ this.getStylesTitle(screenSize) }
                            screen_size={screenSize}
                        >
                            Ошибка авторизации
                        </Heading>
                        <TextElem
                            className='ugsk-page-error-authorization__text'
                            style={ this.getStylesText(screenSize) }
                            screen_size={screenSize}
                        >
                            Срок действия ссылки авторизации истек. Чтобы получить ссылку заново,
                            свяжитесь с сотрудником Югории или заполните форму
                        </TextElem>
                        <FormRequestAccess
                            screenSize={ screenSize }
                            className='ugsk-page-error-authorization__form'
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ErrorAuthorization;