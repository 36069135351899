import {
    call,
    put,
    select,
    take,
    fork
} from 'redux-saga/effects';

import {fetchApi, checkErrors, errorMessage} from "../tools/api-helper";

export function clinicsList(params){
    return {
        type: "CLINICS_LIST",
        params
    }
}

export function* fetchClinicsList(action) {
    try {
        const { status, data, error, pagination } = yield call( () => {
            return fetchApi("/clinics"+(action.params ? action.params : ""), {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, {list: data, pagination}, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clinicsCoords(params){
    return {
        type: "CLINICS_LIST_COORDS",
        params
    }
}

export function* fetchClinicsListCoords(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/clinics"+(action.params ? action.params+"&mode=map" : "?mode=map"), {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clinicsPrograms(){
    return {
        type: "CLINICS_PROGRAMS"
    }
}

export function* fetchClinicsPrograms(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/clinics/programs", {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clinicsServices(params){
    return {
        type: "CLINICS_SERVICES",
        params
    }
}

export function* fetchClinicsServices(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/clinics/services"+(action.params ? action.params : ""), {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clinicsAddressHints(params = null){
    return {
        type: "CLINICS_ADDRESSES",
        params
    }
}

export function* fetchClinicsAddressHints(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/clinics/address-hints"+(action.params ? action.params : ""), {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clinicsConditions(params = null){
    return {
        type: "CLINICS_CONDITIONS",
        params
    }
}

export function* fetchClinicsConditions(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/clinics/conditions"+(action.params ? action.params : ""), {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}