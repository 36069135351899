let initState = {};

export default function clinics(state = initState, action) {
    switch (action.type) {
        case "CLINICS_LIST_SUCCESS":
            return {...state, ...action.data};
        case "CLINICS_LIST_COORDS_SUCCESS":
            return {...state, coords: action.data.filter((item) => {
                return item.latitude && item.longitude;
            })};
        case "CLINICS_PROGRAMS_SUCCESS":
            return {...state, programs: action.data};
        case "CLINICS_SERVICES_SUCCESS":
            return {...state, services: action.data};
        case "CLINICS_ADDRESSES_SUCCESS":
            return {...state, addresses: action.data};
        case "CLINICS_CONDITIONS_SUCCESS":
            return {...state, conditions: action.data};
        case "LOGOUT":
        case "BLOCKED":
            return {};
    }
    return state;
}