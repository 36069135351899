import React, { Component } from 'react';

import {closeModal, isEmpty, openModal} from "../../designSystem/elements/modal/Modal";
import Button from "../../designSystem/elements/button/Button";
import RenderModal from "../../designSystem/elements/modal/RenderModal";
import {ConfirmModal, ConfirmCodeModal, PasswordModal} from "./AuthorizationModals";

class AuthorizationWithAllModals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:{}
        };
        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    render() {
        let flag = !isEmpty(this.state.modal);
        let modal1 = <ConfirmModal screen_size={this.props.screen_size} onSubmit={()=>{this.openModal("common", "222")}}/>;
        let modal2 = <ConfirmCodeModal screen_size={this.props.screen_size} onSubmit={()=>{this.openModal("common", "222")}}/>;
        let modal3 = <PasswordModal/>;
        return (
            <React.Fragment>
                <div className="page">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", modal1)}}>Восстановление пароля</Button>
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", modal2)}}>Восстановление пароля2</Button>
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", modal3)}}>Восстановление пароля3</Button>
                    </div>
                </div>
                {flag === true ?
                    <RenderModal closeModal={this.closeModal} modal={this.state.modal} screen_size={this.props.screen_size}>
                    </RenderModal> : ""}

               {/* <AuthorizationWithModalsRecoveryPassword screenSize={this.props.screen_size}/>
                <AuthorizationWithModalsInputCode screenSize={this.props.screen_size}/>
                <AuthorizationWithModalsInputNewPassword screenSize={this.props.screen_size}/>*/}
            </React.Fragment>
        );
    }
}

export default AuthorizationWithAllModals;