import React, { Component } from 'react';

import Heading from "../../designSystem/elements/text/Heading";
import Button from "../../designSystem/elements/button/Button";
import TextElem from "../../designSystem/elements/text/TextElem";
import ComponentWithCaption from "../../components/Settings/ComponentWithCaption/ComponentWithCaption";
import StyledLink from "../../designSystem/elements/links/Link"
import Cards from "../../designSystem/elements/cards/Cards";
import {colors} from "../../designSystem/DesignSystem";
import Tabs from "../../designSystem/elements/tabs/Tabs";

import './SettingsWithNotification.css';
import NotificationsContainer from "../../designSystem/elements/notification/NotificationsContainer";

class SettingsWithNotification extends Component {

    constructor(props) {
        super(props);

        this.state = {
            infoPolis: {
                name: "Мария Воробьева",
                title: "Полис путешественника",
                label: "Истекает",
                date: "10 апреля 2019",
                buttons: {
                    more_button:{
                        text: "Подробнее",
                        view: "button_bordered",
                        onClick: this.onClickPolis
                    }
                },

                icon: {
                    name: "card1",
                    width: "22px",
                    height: "22px",
                    fill: colors.middle_blue_opacity5
                }
            }
        }
    }

    getStylesTitle = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
                return {
                    marginTop: 0
                }
        }
    };

    getStylesName = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
                return {
                    marginTop: 0
                }
        }
    };

    getStylesLinkEdit = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
                return {
                    display: 'block'
                }
        }
    };

    getStylesPolis = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
                return {
                    width: '100%',
                }
        }
    };

    render() {

        const { screenSize } = this.props;

        const stylesTitle = this.getStylesTitle(screenSize);
        const stylesName = this.getStylesName(screenSize);
        const styleLinkEdit = this.getStylesLinkEdit(screenSize);
        const stylePolis = this.getStylesPolis(screenSize);

        let leftTopCards = [
            {
                type: "successPassword",
                text: "Пароль успешно изменен",
                shouldClose: true,
            }
        ];

        return (
            <div className='ugsk-settings-page'>
                <div className='ugsk-settings-page__menu'>
                    <div className='header_container'>
                        <Tabs typeSwitcher={"url"} styleSwitcher={"iconed"} items={[{title: "Личные данные", icon: {name: "user", width: "20px", height: "20px"}}, {title: "Пароль", icon:{name: "lock", width: "20px", height: "20px"}}, {title: "Архивные полисы", icon:{name: "box", width: "20px", height: "20px"}}]}>
                        </Tabs>
                    </div>
                </div>
                <div className='page_container'>
                    <div className='ugsk-settings-page__container ugsk-settings-page__container_personal-data'>
                        <Heading
                            size='l'
                            className='ugsk-settings-page__title'
                            style={ stylesTitle }
                            screen_size={screenSize}
                        >
                            Личные данные
                        </Heading>
                        <Heading
                            size='m'
                            className='ugsk-settings-page__name'
                            style={ stylesName }
                        >
                            Воробьева Мария Викторовна
                        </Heading>
                        <div className='ugsk-settings-page__user-data-block'>
                            <div className='ugsk-settings-page__date-birth'>
                                <ComponentWithCaption
                                    title={"Дата рождения"}
                                    screenSize={ screenSize }
                                >
                                    <TextElem>22 марта 1970</TextElem>
                                </ComponentWithCaption>
                            </div>
                            <div className="ugsk-settings-page__email">
                                <StyledLink
                                    typeLink={"link_edit"}
                                    label={"Email"}
                                    classNmae={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_email"}
                                    style={ styleLinkEdit }
                                >vorobieva@buroburo.ru</StyledLink>
                            </div>
                        </div>
                        <div className='ugsk-settings-page__user-data-block'>
                            <div className='ugsk-settings-page__address'>
                                <StyledLink
                                    typeLink={"link_edit"}
                                    label={"Адрес проживания"}
                                    className={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_address"}
                                    style={ styleLinkEdit }
                                >Московская область, г. Красногорск, ул. Успенская, д. 5</StyledLink>
                            </div>
                        </div>
                        <div className='ugsk-settings-page__user-data-block'>
                            <div className='ugsk-settings-page__phone'>
                                <StyledLink
                                    typeLink={"link_edit"}
                                    label={"Номер телефона"}
                                    className={"ugsk-settings-page__link-edit ugsk-settings-page__link-edit_phone"}
                                    style={ styleLinkEdit }
                                >+7 (999) 356-12-04</StyledLink>
                            </div>
                        </div>
                    </div>
                    <div className='ugsk-settings-page__container ugsk-settings-page__container_password'>
                        <Heading
                            size='l'
                            className='ugsk-settings-page__title'
                            style={ stylesTitle }
                        >
                            Пароль
                        </Heading>
                        <Button>Сменить пароль</Button>
                        <NotificationsContainer
                            leftTopCards={leftTopCards}
                            classNameLeftTopContainer='ugsk-page-authorization__container-left-top'
                            classNameRightBottomContainer='ugsk-page-authorization__container-right-bottom'
                        />
                    </div>
                    <div className='ugsk-settings-page__container ugsk-settings-page__container_archive-policies'>
                        <Heading
                            size='l'
                            className='ugsk-settings-page__title'
                            style={ stylesTitle }
                        >
                            Архивные полисы
                        </Heading>
                        <div className='ugsk-settings-page__list-polises'>
                            <div className='ugsk-settings-page__item-polis'>
                                <Cards
                                    type="polis"
                                    info={  this.state.infoPolis }
                                    screen_size={this.state.screen_size}
                                    style={ stylePolis } />
                            </div>
                            <div className='ugsk-settings-page__item-polis'>
                                <Cards
                                    type="polis"
                                    info={  this.state.infoPolis }
                                    screen_size={this.state.screen_size}
                                    style={ stylePolis } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsWithNotification;