import React from "react";
import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import Link from "../../designSystem/elements/links/Link";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Map from "../../designSystem/elements/map/Map";

export default function List({items, screen_size, showItem, openOnMap, message = "К сожалению, по вашему запросу ничего не найдено. Попробуйте изменить параметры поиска."}) {
    return <div className="clinics_list" style={{marginBottom: spaces.m}}>
        {items.length ?
            items.map((item, key) => <Item item={item} screen_size={screen_size} isLast={key === items.length - 1} showItem={showItem} openOnMap={(e) => {
                if (e) {
                    e.preventDefault();
                }
                openOnMap([item])
            }}/>) :
            <TextElem>{message}</TextElem>}
    </div>
}

function Item({item, isLast, screen_size, showItem, openOnMap}) {

    let blockStyle = {display: "inline-block", verticalAlign: "top", marginRight: spaces.m, minWidth: "180px"};
    let signStyle = {color: colors.lightGray_color, display: "block", marginBottom: spaces.xs};

    let isLarge = ["s", "xs"].indexOf(screen_size) === -1;

    return <div className="clinics_list_item" key={"clinic-"+item.id} style={{
        padding: screen_size === "xs" ? spaces.m + " 0" : spaces.l + " 0",
        borderBottom: "1px solid "+(isLast ? "transparent" : colors.grayBg_color)
    }}>
        {isLarge ?
            (openOnMap && item.coords ? <Link disabled={(screen_size === "xs")||(screen_size === "s")} func={openOnMap}><Heading size="m2" screen_size={screen_size}>{item.title}</Heading></Link> : <Heading size="m2" screen_size={screen_size}>{item.title}</Heading>)
            : item.coords ?
            <Link func={openOnMap}>
                <Heading size="m2" screen_size={screen_size} style={{color: colors.middle_blue}}>{item.title}</Heading>
            </Link> : <Heading size="m2" screen_size={screen_size}>{item.title}</Heading>
        }
        <TextElem style={{display: "block"}}>{item.metro}</TextElem>
        {item.link ? <div> <Link typeLink={"external"} disabled={(screen_size === "xs")||(screen_size === "s")}  to={item.link}> {item.address}</Link> </div> :
            (openOnMap && item.coords ? <div> <Link disabled={(screen_size === "xs")||(screen_size === "s")} func={openOnMap}> {item.address}</Link> </div> : <TextElem style={{color: colors.text_color, display: "block"}}>{item.address}</TextElem>)}
        {isLarge ? <div style={{margin: spaces.m + " 0"}}>
            {item.phone ? <div style={blockStyle}>
                <TextElem style={signStyle}>Телефон</TextElem>

                {item.phone.split(',').map((phone) =>
                    <Link className={"phoneLink"} typeLink="external" targetBlank={false} to={phone ? "tel:"+phone.replace(/\D/, '') : "#"}>{phone};</Link>
                )}

            </div> : null}
            {item.site ? <div style={blockStyle}>
                <TextElem style={signStyle}>Сайт</TextElem>
                <Link typeLink="external" to={item.siteLink}>{item.site}</Link>
            </div> : null}
            {item.workTime ? <div style={{...blockStyle, maxWidth: "180px"}}>
                <TextElem style={signStyle}>Время работы</TextElem>
                <TextElem dangerouslySetInnerHTML={{__html: item.workTime}} />
            </div> : null}
        </div> : null}
        {isLarge ? <div>
            {item.services.map((service, key) => <TextElem key={service.id} style={{color: colors.lightGray_color}}>
                {service.title}{key !== item.services.length - 1 ? <TextElem style={{color: colors.lightGray_color, marginLeft: "5px", marginRight: "5px", fontSize: "20px"}}>·</TextElem> : null}
            </TextElem>)}
        </div> : null}
    </div>
}

export function LeftItem({screen_size, item, isOnMap, openOnMap}) {
    let blockStyle = {marginBottom: spaces.m};
    let signStyle = {color: colors.lightGray_color, display: "block", marginBottom: spaces.xs};

    let isLarge = ["s", "xs"].indexOf(screen_size) === -1;

    return <div className="clinics_left_item">
        <div style={{padding: spaces.m}}>
            {isLarge ?
                (openOnMap && item.coords ? <Link disabled={(screen_size === "xs")||(screen_size === "s")} func={(e) => {
                    if (e) {
                        e.preventDefault();
                    }
                    openOnMap([item])
                }}><Heading size="m2" screen_size={screen_size}>{item.title}</Heading></Link> : <Heading size="m2" screen_size={screen_size}>{item.title}</Heading>)
                : item.coords ?
                    <Link func={openOnMap}>
                        <Heading size="m2" screen_size={screen_size} style={{color: colors.middle_blue}}>{item.title}</Heading>
                    </Link> : <Heading size="m2" screen_size={screen_size}>{item.title}</Heading>
            }
            <TextElem style={{display: "block"}}>{item.metro}</TextElem>
            {openOnMap && item.coords ? <div> <Link disabled={(screen_size === "xs")||(screen_size === "s")} func={(e) => {
                if (e) {
                    e.preventDefault();
                }
                openOnMap([item])
            }}> {item.address}</Link> </div> : <TextElem style={{color: colors.text_color, display: "block"}}>{item.address}</TextElem>}
        </div>
            {screen_size === "xs" ? <div style={{width: "100%", height: "150px"}}>
                <Map points={ [item] } clusterer={true} shownClinic={ [item] } screen_size={ screen_size }/>
            </div> : null}
        <div style={{padding: spaces.m}}>
            {item.phone ? <div style={blockStyle}>
                <TextElem style={signStyle}>Телефон</TextElem>
                {item.phone.split(',').map((phone) =>
                    <Link className={"phoneLink"} typeLink="external" targetBlank={false} to={phone ? "tel:"+phone.replace(/\D/, '') : "#"}>{phone};</Link>
                )}
            </div> : null}
            {item.site ? <div style={blockStyle}>
                <TextElem style={signStyle}>Сайт</TextElem>
                <Link typeLink="external" to={item.siteLink}>{item.site}</Link>
            </div> : null}
            {item.workTime ? <div style={blockStyle}>
                <TextElem style={signStyle}>Время работы</TextElem>
                <TextElem dangerouslySetInnerHTML={{__html: item.workTime}} />
            </div> : null}
        </div>
        <div style={{padding: spaces.m, borderTop: "1px solid "+colors.grayBg_color}}>
            {item.services.map((service, key) => <TextElem key={service.id} style={{color: colors.lightGray_color}}>
                {service.title}{key !== item.services.length - 1 ? <TextElem style={{color: colors.lightGray_color, marginLeft: spaces.s, marginRight: spaces.s, fontSize: "20px"}}>·</TextElem> : null}
            </TextElem>)}
        </div>
    </div>
}