import React, { Component } from 'react';

import {BreakPoints, colors} from './designSystem/DesignSystem';
import {Switch, Route, Redirect, withRouter} from "react-router-dom";
import './designSystem/common.css';
import Authorization from "./containers/Authorization";
import {connect} from "react-redux";
import Polices from "./containers/Polices";
import PdfViewer from "./containers/PdfViewer";
import Error from "./containers/Error";
import Information from "./containers/Information";
import {logout} from "./actions/authorization";
import {closeNotification, loadPage} from "./actions/common";
import Layout from "./components/Layout/Layout";
import Clinics from "./containers/Clinics";
import Preloader from "./designSystem/elements/preloader/Preloader";
import Settings from "./containers/Settings";
import NotificationsContainer from "./designSystem/elements/notification/NotificationsContainer";
import {YMInitializer} from "react-yandex-metrika";
import CookieNotification from "./components/CookieNotification/CookieNotification";
import SettingsWithAllModals from "./components/SettingsWithModals/SettingsWithAllModals";
import {getCookie, setCookie} from "./tools/tools";
import {sendReview, sendReviewClear} from "./actions/client";

class AppMain extends Component {

    constructor(props) {
        super(props);

        this.cookieClick = this.cookieClick.bind(this);

        let showMessage = true;

        if (typeof getCookie('showMessage') !== 'undefined') {
                showMessage = getCookie('showMessage');
        } else {
            showMessage = true;
        }

        this.state = {
            screen_size: "l",
            isGuest: !localStorage.getItem("ugskdmsAccessToken"),
            params: props.history.location.search,
            showMessage: showMessage,
            clinicsListFlag: true,
        };
        this.getScreenSize = this.getScreenSize.bind(this);

        props.loadPage(props.history.location.pathname);

        this.info = {
            phone: "8-800-700-50-03",
            additionalPhone: "8-495-197-75-03",
            email: "dms@ugsk.ru",
            copyright: <span> Акционерное общество «Группа страховых компаний «Югория»», 1997</span>,
            links: [
                {
                    to: "/information",
                    title: "Юридическая информация",
                    targetBlank: false,
                    externalLink: false
                },
                {
                    to: "https://www.ugsk.ru/about/personaldata/",
                    title: "Политика конфиденциальности",
                    targetBlank: true,
                    externalLink: true
                }
            ],
            menuLinks: [
                {
                    name: "Личные данные",
                    to: "/settings",
                    icon:{
                        name: "lock",
                        width: "13px",
                        height: "17px"
                    }
                },
                {
                    name: "Выйти из аккаунта",
                    to: "/auth",
                    func: props.logout,
                    icon:{
                        name: "exit",
                        width: "15px",
                        height: "17px"
                    }
                }
            ],
            tabLinks: [
                {
                    title: "Полисы",
                    name: "Полисы",
                    to: "/",
                    icon:{
                        name: "note-text",
                        width: "20px",
                        height: "20px"
                    }
                },
                {
                    title: "Медицинские учреждения",
                    name: "Медицинские учреждения",
                    to: "/clinics",
                    icon:{
                        name: "pin-round",
                        width: "20px",
                        height: "20px"
                    }
                }
            ]
        };
    }

    componentDidMount() {
        this.getScreenSize();
        window.addEventListener("resize", this.getScreenSize);
    }

    cookieClick(){
        setCookie('showMessage', false, { path: '/' });
        this.setState({showMessage: false});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.client !== this.props.client) {
            this.setState({isGuest: !(nextProps.client && nextProps.client.token)});
        }

        if (this.props.clinics.length > 0){
            this.setState({
                clinicsListFlag: true
            });
        } else {
            this.setState({
                clinicsListFlag: false
            });
        }
    }

    getScreenSize(){
        if (typeof document != 'undefined'){
            let width = document.documentElement.clientWidth;
            let size = "";
            BreakPoints.map((item)=>{
                if ((width >= item.from)&&(width<= item.to)){
                    size = item.name;
                }
            });

            this.setState(
                {screen_size: size}
            )
        }
    }

    render() {

        let {
            isGuest,
            screen_size,
            params,
            showMessage,
            clinicsListFlag
        } = this.state;

        let {
            pageLoaded,
            pageLoading,
            history,
            loadPage,
            client,
            error,
            notifications,
            closeNotification,
            sendReview,
            clearReview
        } = this.props;

        return <React.Fragment>
            {pageLoaded ? <Layout id="App" key="App"
                    className={"App " + "App__screen_size_" + screen_size}
                    info={{...this.info, client}}
                    screen_size={screen_size}
                    isGuest={isGuest}

                    pageLoaded={pageLoaded}
                    pageLoading={pageLoading}
                    loadPage={loadPage}
                    history={history}

                    reviewStatus={client.reviewStatus}
                    reviewMessage={client.reviewMessage}
                    sendReview={sendReview}
                    clearReview={clearReview}
            >
                {error ? <Error {...error} history={history} screenSize = {screen_size}/> : <Switch>
                    {isGuest ? null : <Route path="/" render={() => {return <Polices screen_size = {screen_size} />}} exact={true}/>}
                    {isGuest ? null : <Route path="/clinics" render={() => {return <Clinics screen_size = {screen_size} />}} exact={true}/>}
                    {isGuest ? null : <Route path="/settings" render={() => {return <Settings screen_size = {screen_size} />}} exact={true}/>}
                    {isGuest ? null : <Route path="/pdf" render={() => {return <PdfViewer/>}} exact={true}/>}

                    <Route path='/application/download' render={() =><PdfViewer/>} exact={true}/>
                    <Route path="/information" render={() => {return <Information screen_size = {screen_size} />}} exact={true}/>
                    <Route path="/auth" render={() => {return isGuest ? <Authorization screen_size={screen_size} notificationsError={notifications}/> : <Redirect to="/"/>}} />
                    <Route path="*" render={() => {return isGuest ? <Redirect to={{pathname: "/auth", search: params}}/> : <Error title="404 Not Found" text="К сожалению такая страница не существует" screenSize = {screen_size}/>}}/>
                </Switch>}
                { window.location.pathname === '/settings' || window.location.pathname === '/auth' ? null :
                    <React.Fragment>
                        {YA_COUNTER_ID !== "undefined" ?
                            <YMInitializer
                                accounts={[YA_COUNTER_ID]}
                                options={{clickmap:true, rackLinks:true, accurateTrackBounce:true, webvisor: true}} />
                        : null}
                    </React.Fragment>}

                <CookieNotification show={showMessage} cookieClick={this.cookieClick}/>
            </Layout> : <div key="App" id="App"/>}
            <div id="overlay" key="overlay" style={{backgroundColor: colors.middle_blue_opacity8}}/>
            <Preloader key="preloader" shown={pageLoading}/>
            <NotificationsContainer
                leftTopCards={notifications}
                classNameLeftTopContainer='ugsk-page-authorization__container-left-top'
                classNameRightBottomContainer='ugsk-page-authorization__container-right-bottom'
                closeNotification={closeNotification}
                pathname={history.location.pathname}
                screen_size={screen_size}
                clinicsListFlag={clinicsListFlag}
            />
        </React.Fragment>
    }
}

export default withRouter(connect(
    state => ({
        clinics: state.clinics.list || [],
        client: state.client,
        pageLoaded: state.common.pageLoaded,
        pageLoading: state.common.pageLoading,
        error: state.common.error,
        notifications: state.notifications
    }),
    dispatch => ({
        logout: () => dispatch(logout()),
        loadPage: (location) => dispatch(loadPage(location)),
        closeNotification: (key) => dispatch(closeNotification(key)),
        sendReview: (data) => dispatch(sendReview(data)),
        clearReview: () => dispatch(sendReviewClear())
    })
)(AppMain));
