import React, { Component } from 'react';
import Button from '../../designSystem/elements/button/Button'
import '../../designSystem/common.css';
import { closeModal, openModal, isEmpty} from "../../designSystem/elements/modal/Modal";
import RenderModal from "../../designSystem/elements/modal/RenderModal"
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";

import './AuthorizationWithModals.css';
import Link from "react-router-dom";

class AuthorizationWithModalsRecoveryPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:{},
            modal2: {},
            modal3: {}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    render() {
        let flag = !isEmpty(this.state.modal);
        let flag2 = !isEmpty(this.state.modal2);
        let flag3 = !isEmpty(this.state.modal3);

        let screen_size = this.props.screenSize;
        return (
            <React.Fragment>
                <div className="page page-noheight">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", "222")}}>Восстановление пароля</Button>

                        {flag === true ?
                            <RenderModal closeModal={this.closeModal} modal={this.state.modal} heading={"Восстановление пароля"} screen_size={this.props.screenSize}>
                                <div className={'ugsk-form-recovery-password'}>
                                    <Input className={'ugsk-form-recovery-password__input'} typeInput={"input"} type="tel" label={"Номер телефона"} placeholder={""}/>
                                    <Button className={'ugsk-form-recovery-password__btn'} onClick={()=>{this.openModal("common", "222")}}>Запросить код</Button>
                                </div>
                            </RenderModal> : ""}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AuthorizationWithModalsRecoveryPassword;