import {
    call,
    put,
    select,
    take,
    fork
} from 'redux-saga/effects';

import {fetchApi, checkErrors, errorMessage} from "../tools/api-helper";

import fileDownload from "js-file-download";
import {prepareData} from "../tools/form-helper";

export function policesActiveList(){
    return {
        type: "POLICES_ACTIVE_LIST"
    }
}

export function* fetchPolicesActiveList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/insurance-policies?is_active=1", {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function policesArchiveList(){
    return {
        type: "POLICES_ARCHIVE_LIST"
    }
}

export function* fetchPolicesArchiveList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/insurance-policies?is_active=0", {
                method: 'GET'
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function policyDownload(policyNumber){
    return {
        type: "POLICY_DOWNLOAD",
        policyNumber
    }
}

export function* fetchPolicyDownload(action) {
    try {
        const { status, data, headers, error } = yield call( () => {
            return fetchApi("/insurance-policies/pdf?policy_number="+action.policyNumber, {
                method: 'GET'
            }, true, 'blob')
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            if (status === 200) {
                let filename = action.policyNumber+".pdf";

                fileDownload(data, filename);

            } else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function policyDownloadMemo(policyNumber){
    return {
        type: "POLICY_DOWNLOAD_MEMO",
        policyNumber
    }
}

export function* fetchPolicyDownloadMemo(action) {
    try {
        const { status, data, headers, error } = yield call( () => {
            return fetchApi("/insurance-policies/pdf-memo?policy_number="+action.policyNumber, {
                method: 'GET'
            }, true, 'blob')
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            if (status === 200) {
                let filename = action.policyNumber+"-memo.pdf";

                fileDownload(data, filename);

            } else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function clearSendPolicy(){
    return {
        type: "POLICY_SEND_CLEAR"
    }
}

export function policySend(policyNumber){
    return {
        type: "POLICY_SEND",
        policyNumber
    }
}

export function* fetchPolicySend(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/insurance-policies/mail?policy_number="+action.policyNumber, {
                method: 'GET'
            }, true)
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            if (status === 424) {
                yield put(clearSendPolicy())
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function policyAdd(action, data){
    return {
        type: "POLICY_ADD",
        action,
        data
    }
}

export function policyAddStart(){
    return {
        type: "POLICY_ADD_START"
    }
}

export function policyAddClear(){
    return {
        type: "POLICY_ADD_CLEAR"
    }
}

export function policyAddClearError(name){
    return {
        type: "POLICY_ADD_CLEAR_VALIDATION",
        name
    }
}

export function* fetchPolicyAdd(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/insurance-policies?action="+action.action, {
                method: 'POST',
                body: prepareData(action.data, 'POST')
            }, true)
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type+(status === 200 ? "_"+action.action.toUpperCase() : ""), data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}

export function additionalServices(){
    return {
        type: "ADDITIONAL_SERVICES"
    }
}

export function* fetchAdditionalServices(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchApi("/additional-services", {
                method: 'GET'
            }, true)
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}