import React, {Component} from "react";

import LegalInformation from "../components/LegalInformation/LegalInformation";
import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize(GA_COUNTER_ID)
};
export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
};

export default class Information extends Component {

    componentDidMount() {
        if (typeof GA_COUNTER_ID !== 'undefined') {
            initGA();
            logPageView();
        }
    }

    render(){

        return <LegalInformation {...this.props}/>;
    }
}