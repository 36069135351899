import React, { Component } from 'react';
import Input from "../../../designSystem/elements/input/Input";
import {phoneMask} from "../../../tools/mask-helper";
import AuthActions from "../AuthActions/AuthActions";
import './PasswordRecovery.css';
import Checkbox from "../../../designSystem/elements/checkbox/Checkbox";
import SimpleHeader from "../../SimpleHeader/SimpleHeader";
import Caption from "../../../designSystem/elements/text/Caption";
import {InputStyle} from "../../../designSystem/DesignSystem";

class PasswordRecovery extends Component {
    render() {
        const {
            onClick = () => {},
            onSubmit = (e, type) => {e.preventDefault()},
            onChange = (e, type) => {},
            onBlur = (e, type) => {},
            onFocus = (e, type) => {},
            onCheckboxChange,
            errors = {},
            title = "Восстановление пароля"
        } = this.props;

        return (
            <form>
                <div className="ugsk-password-recovery__header_container" onSubmit={(ev) => onSubmit(ev, 'confirmValues')}>
                    <SimpleHeader size="h2">{title}</SimpleHeader>
                </div>
                <div className="ugsk-password-recovery__input_container">
                    <Input
                        errorAction={onClick}
                        typeInput='input'
                        type='tel'
                        label='Номер телефона'
                        width='100%'
                        placeholder='+7 (___) ___-__-__'
                        name="phone_number"
                        mask={phoneMask}
                        onChange={(ev) => onChange(ev, 'confirmValues')}
                        onBlur={(ev) => onBlur(ev, 'confirmation')}
                        onFocus={(ev) => onFocus(ev, 'confirm')}
                        error_text={errors.phone_number  || errors.recaptcha}
                    />
                </div>
                <div className='ugsk-password-recovery__checkbox__container'>
                    <Checkbox error_text={errors.agreement} onChange={onCheckboxChange}>Согласие на обработку персональных данных</Checkbox>
                </div>
                {errors.recaptcha ? <div className='ugsk-sign-up__checkbox__container'>
                    <Caption className="input__error" style={{color: InputStyle.layout_default.error_mode.errColor}}>
                        <div dangerouslySetInnerHTML={{__html: errors.recaptcha}} />
                    </Caption>
                </div> : null}
                <div className='ugsk-password-recovery__actions_container'>
                    <AuthActions
                        whiteBtnLabel="Назад"
                        onSubmit={onSubmit}
                        typeForBlueBtn="forgotPassword"
                        type="formAuth"
                        blueBtnLabel="Далее"
                        onClick={onClick}
                    />
                </div>
            </form>
        )
    }
}

export default PasswordRecovery;