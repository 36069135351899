import React, { Component } from 'react';

import './help.css';

class Help extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let { x, y, text } = this.props;

        return (
            <div className='help' style={
                x && y
                    ?
                    { left: x, top: y, opacity: 0.8 }
                    : {}
            }>
                { text }
            </div>
        )
    }
}

export default Help;