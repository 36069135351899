import React, {Component, Fragment} from 'react';
import Input from "../../../designSystem/elements/input/Input";
import './CreateNewPassword.css';
import Button from "../../../designSystem/elements/button/Button";
import icon from '../../../images/password-icon.png';
import SimpleHeader from "../../SimpleHeader/SimpleHeader";
import { passwordMask} from "../../../tools/mask-helper";
import Caption from "../../../designSystem/elements/text/Caption";
import {InputStyle} from "../../../designSystem/DesignSystem";

const getProgressBarColor = (count, index, value) => {
    if (!value) return;
    if ((value.length > 0 && value.length < 6 && index === 1)) return "ugsk-create-new-password__progress__red";
    if (index > count) return '';

    if (count === 1) return "ugsk-create-new-password__progress__red";
    if (count === 2) return "ugsk-create-new-password__progress__yellow";
    if (count === 3) return "ugsk-create-new-password__progress__green";

    return ''
}

const getProgressBarText = (count, value) => {
    if (!value) return;
    if ((value.length > 0 && value.length < 6) || count === 1) return "Ненадежный пароль";
    if (count === 2) return "Слабый пароль";
    if (count === 3) return "Надежный пароль";

    return ''
}

const getProgressBarTextColor = (count, value) => {
    if (!value) return;
    if ((value.length > 0 && value.length < 6) || count === 1) return "ugsk-create-new-password__progress__text_red";
    if (count === 2) return "ugsk-create-new-password__progress__text_yellow";
    if (count === 3) return "ugsk-create-new-password__progress__text_green";

    return ''
}

class CreateNewPassword extends Component {

    renderFields = () => {
        const {
            errors = {},
            onChange = (e, type, isNewPassword) => {},
            passwordValue,
            firstPasswordRule,
            secondPasswordRule,
            thirdPasswordRule,
            onBlur = (e, type) => {},
            onFocus = (e, type) => {},
            progressBarCount
        } = this.props;

        return <Fragment>
            <div className="ugsk-create-new-password__input_container">
                <Input
                    mask={passwordMask}
                    typeInput='input'
                    type='password'
                    label='Новый пароль'
                    width='100%'
                    name="newPassword"
                    onChange={(ev) => onChange(ev, 'confirmValues', true)}
                    onBlur={(ev) => onBlur(ev, 'password')}
                    onFocus={(ev) => onFocus(ev, 'password')}
                    error_text={errors.newPassword}
                />
            </div>
            <div className="ugsk-create-new-password__input_container">
                <Input
                    onChange={(ev) => onChange(ev, 'confirmValues', false)}
                    mask={passwordMask}
                    typeInput='input'
                    type='password'
                    label='Повторите новый пароль'
                    width='100%'
                    name="repeatPassword"
                    onBlur={(ev) => onBlur(ev, 'password')}
                    onFocus={(ev) => onFocus(ev, 'password')}
                    error_text={errors.repeatPassword}
                />
            </div>
            <div className="ugsk-create-new-password__rules_container">
                <div className="ugsk-create-new-password__rule">
                    <p>Длина пароля от 6 до 14 символов. Пароль должен содержать:</p>
                </div>
                <div className="ugsk-create-new-password__rule">
                    {firstPasswordRule ?
                        <div className="ugsk-create-new-password__rule_sign">
                            <img src={icon} alt="Успешно"/>
                        </div> : <p className="ugsk-create-new-password__rule_sign_p">•</p>}
                    <p>использованы буквы латинского алфавита, хотя бы одна строчная буква (а-z)</p>
                </div>
                <div className="ugsk-create-new-password__rule">
                    {secondPasswordRule ?
                        <div className="ugsk-create-new-password__rule_sign">
                            <img src={icon} alt="Успешно"/>
                        </div> : <p className="ugsk-create-new-password__rule_sign_p">•</p>}
                    <p>использованы буквы латинского алфавита, хотя бы одна прописная (заглавная) буква (A-Z)</p>
                </div>
                <div className="ugsk-create-new-password__rule">
                    {thirdPasswordRule ?
                        <div className="ugsk-create-new-password__rule_sign">
                            <img src={icon} alt="Успешно"/>
                        </div> : <p className="ugsk-create-new-password__rule_sign_p">•</p>}
                    <p>использованы арабские цифры (0-9)</p>
                </div>
            </div>
            <div className="ugsk-create-new-password__progress_bar_container">
                <div className="ugsk-create-new-password__progress_bar">
                    <div className={`ugsk-create-new-password__progress_bar_item ${getProgressBarColor(progressBarCount, 1, passwordValue)}`}></div>
                    <div className={`ugsk-create-new-password__progress_bar_item ${getProgressBarColor(progressBarCount, 2, passwordValue)}`}></div>
                    <div className={`ugsk-create-new-password__progress_bar_item ${getProgressBarColor(progressBarCount, 3, passwordValue)}`}></div>
                </div>
                <div className={`ugsk-create-new-password__progress_bar_text ${getProgressBarTextColor(progressBarCount, passwordValue)}`}>
                    {getProgressBarText(progressBarCount, passwordValue)}
                </div>
            </div>
        </Fragment>
    }

    render() {
        const {
            errors = {},
            onSubmit = (e, type) => {e.preventDefault()},
            fieldsOnly
        } = this.props;

        return (
            fieldsOnly ? this.renderFields() : <form>
                <div className="ugsk-create-new-password_container">
                    <SimpleHeader size="h2">Установка постоянного пароля</SimpleHeader>
                </div>
                {this.renderFields()}
                {errors.recaptcha ? <div className='ugsk-sign-up__checkbox__container'>
                    <Caption className="input__error" style={{color: InputStyle.layout_default.error_mode.errColor}}>
                        <div dangerouslySetInnerHTML={{__html: errors.recaptcha}} />
                    </Caption>
                </div> : null}
                <div className="ugsk-create-new-password__btn_container">
                    <Button onClick={ (ev) => onSubmit(ev, 'createNewPassword')} className="ugsk-create-new-password__btn">Далее</Button>
                </div>
            </form>
        )
    }
}

export default CreateNewPassword;