import React, {Component} from "react";
import {connect} from "react-redux";
import PolicesComponent from "../components/Polis/Polices";
import PolisPage from "../components/Polis/PolisPage";
import RecomModal from "../components/Polis/RecomModal";
import {colors} from "../designSystem/DesignSystem";
import {closeModal, openModal, isEmpty} from "../designSystem/elements/modal/Modal";
import RenderModal from "../designSystem/elements/modal/RenderModal";
import {prettyDateMask} from "../tools/mask-helper";
import {
    policySend,
    clearSendPolicy,
    policyDownload,
    policyDownloadMemo,
    policyAdd,
    policyAddClear, policyAddClearError, policyAddStart
} from "../actions/polices";
import Heading from "../designSystem/elements/text/Heading";
import HeadingIcon from "../designSystem/elements/text/HeadingIcon";
import TextElem from "../designSystem/elements/text/TextElem";
import Button from "../designSystem/elements/button/Button";
import ReactGA from 'react-ga';
import {PromotionsList} from "../actions/promotions";
import {promotionConfirmation} from "../actions/common";
import NewPolisModal from "../components/Polis/NewPolisModal";
import ServiceModal from "../components/Polis/ServiceModal";

export const initGA = () => {
    ReactGA.initialize(GA_COUNTER_ID)
};
export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
};


const imgBg = [];

function requireAll (r) {
    r.keys().forEach(key => imgBg[key.substring(key.lastIndexOf("/") + 1, key.lastIndexOf('.'))] = r(key));
}

requireAll(require.context('../images/imgBG', true, /\.jpg$/));

let arr = imgBg.filter(element => element !== null);

const lake = Math.floor(Math.random() * arr.length);

class Polices extends Component {

    constructor(props){
        super(props);

        this.closeModal = closeModal.bind(this);
        this.prepareList = prepareList.bind(this);
        this.setModalContent = setModalContent.bind(this);
        this.setServiceModalContent = setServiceModalContent.bind(this);
        this.downloadPolicy = downloadPolicy.bind(this);
        this.sendPolicy = sendPolicy.bind(this);
        this.getModal = getModal.bind(this);
        this.getModalRecomm = getModalRecomm.bind(this);
        this.recommendationsOnclick = this.recommendationsOnclick.bind(this);
        this.recommendationsModalOnclick = this.recommendationsModalOnclick.bind(this);
        this.sendModalRecommendationOnclick = this.sendModalRecommendationOnclick.bind(this);
        this.prepareAdditionalServices = prepareAdditionalServices.bind(this);

        this.state = {
            list: this.prepareList(props.list, props.client, this.setModalContent),
            modal: {},
            serviceModal: {},
            recommdClass: false,
            sendModalRecommendation: false,
            additionalServices: this.prepareAdditionalServices(props.additionalServices, this.setServiceModalContent, props.client),
            newPolicyData: {}
        };

    }

    componentDidMount() {
        if (typeof GA_COUNTER_ID !== 'undefined') {
            initGA();
            logPageView();
        }

        this.props.PromotionsList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.list.length !== this.props.list.length) {
            this.setState({list: this.prepareList(this.props.list, this.props.client, this.setModalContent)})
        }
        if (prevProps.policySendSuccess === false && this.props.policySendSuccess === undefined) {
            this.setModalContent(null)
            this.closeModal()
        }
    }

    recommendationsOnclick(data){
        this.setState(prevState => ({recommdClass: !prevState.recommdClass}));
    }

    recommendationsModalOnclick(data){
        this.setState({modalAdvert: data});
    }

    sendModalRecommendationOnclick(){
        this.setState({sendModalRecommendation: true});
        let data = new FormData();
        data.append('clientId', this.props.client.id);
        data.append('promotionId', this.state.modalAdvert.id);
        this.props.promotionConfirmation(data);
    }

    setNewPolicyData = (e) => {
        this.props.policyAddClearError(e.target.name)
        let newPolicyData = {...this.state.newPolicyData};
        newPolicyData[e.target.name] = e.target.value
        this.setState({newPolicyData})
    }

    sendNewPolicyData = (action) => {
        this.props.policyAdd(action, {...this.state.newPolicyData})
    }

    render(){
        let {
            list,
            modal,
            modalAdvert,
            recommdClass,
            sendModalRecommendation,
            additionalServices,
            serviceModal
        } = this.state;

        let recommendations = this.props.recommendations;

        let title = "Мои полисы",
            bgImage = arr[lake],
            infoNewTravelPolis = {
                to: "https://vzr.ugsk.ru",
                blank: true,
                text: <span>Оформить новый&nbsp;полис путешественника</span>,
            },
            infoNewPolis = {
                to: "#",
                blank: false,
                onClick: (e) => {
                    if (e) {
                        e.preventDefault()
                    }
                    this.props.policyAddStart();
                },
                text: <span>Добавить полис ДМС</span>,
            },
            infoNewAdvert = {
                to: "#",
                text: recommdClass ? "Скрыть рекомендации" : "Показать ещё рекомендации",
            },
            recommendationsTitle = "Рекомендации";

        let {
            screen_size
        } = this.props;

        return <PolicesComponent title={title}
                                 infoNewPolis={infoNewPolis}
                                 bgImage={bgImage}
                                 polices={list}
                                 infoNewTravelPolis={infoNewTravelPolis}
                                 infoNewAdvert={infoNewAdvert}
                                 recommendationsTitle={recommendationsTitle}
                                 recommendations={recommendations}
                                 screen_size={screen_size}
                                 recommdClass={recommdClass}
                                 recommendationsOnclick={this.recommendationsOnclick}
                                 recommendationsModalOnclick={this.recommendationsModalOnclick}
                                 additionalServices={additionalServices}
        >
            {!isEmpty(modal) ?
                <RenderModal closeModal={this.closeModal}
                             modal={this.props.policySendSuccess ? {
                                 type: "common",
                                 data: <SuccessSendModal email={this.props.client.email}
                                                         screen_size={this.props.screen_size}
                                                         closeModal={this.closeModal}
                                                         sendPolicy={this.sendPolicy}
                                                         clear={this.props.clearSendPolicy}
                                 />
                             } : {
                                 type: "rightModal",
                                 data: this.getModal()}
                             }
                             screen_size={this.props.screen_size}/>: null}
            {

                !isEmpty(modalAdvert) ?
                    <RenderModal closeModal={this.closeModal}
                                 className={"recomm"}
                                 modal={sendModalRecommendation ? {
                                     type: "common",
                                     data: <SuccessSendModalRecommendation
                                         screen_size={this.props.screen_size}
                                         closeModal={this.closeModal}
                                         titleModal={modalAdvert}
                                         // sendPolicy={this.sendPolicy}
                                         // clear={this.props.clearSendPolicy}
                                     />
                                 } : {
                                     type: "rightModal",
                                     data: this.getModalRecomm()}
                                 }
                                 screen_size={this.props.screen_size}/>: null

            }
            {
                this.props.policyAddStep ?
                    <RenderModal closeModal={() => {
                        this.props.policyAddClear()
                        this.setState({newPolicyData: {}})
                        this.closeModal()
                    }}
                         modal={{
                             type: "common",
                             data: <NewPolisModal
                                 screen_size={this.props.screen_size}
                                 onSubmit={this.sendNewPolicyData}
                                 onChange={this.setNewPolicyData}
                                 onFocus={(e) => {this.props.policyAddClearError(e.target.name)}}
                                 data={this.state.newPolicyData}
                                 errors={this.props.newPolicyDataErrors}
                                 step={this.props.policyAddStep}
                                 onUnmount={() => {
                                     this.setState({newPolicyData: {}})
                                     this.closeModal()
                                 }}
                             />
                         }}
                         screen_size={this.props.screen_size}/> : null

            }
            {

                !isEmpty(serviceModal) ?
                    <RenderModal closeModal={this.closeModal}
                                 className={"common"}
                                 modal={{
                                     type: "rightModal",
                                     data: <ServiceModal {...serviceModal} screen_size={this.props.screen_size}/>
                                 }}
                                 screen_size={this.props.screen_size}/>: null

            }
        </PolicesComponent>
    }
}

export default connect(
    state => ({
        list: state.polices.active || [],
        policySendSuccess: state.polices.policySend,
        client: state.client || {},
        siteLink: state.common.siteLink,
        recommendations: state.promotions.promotionsList,
        newPolicyDataErrors: state.polices.newPolicyDataErrors || {},
        policyAddStep: state.polices.policyAddStep,
        additionalServices: state.polices.additionalServices || {}
    }),
    dispatch => () => {
        return {
            PromotionsList: () => dispatch(PromotionsList()),
            policySend: (policyNumber) => dispatch(policySend(policyNumber)),
            clearSendPolicy: () => dispatch(clearSendPolicy()),
            download: (number) => dispatch(policyDownload(number)),
            downloadMemo: (number) => dispatch(policyDownloadMemo(number)),
            promotionConfirmation: (data) => dispatch(promotionConfirmation(data)),
            policyAdd: (action, data) => dispatch(policyAdd(action, data)),
            policyAddStart: () => dispatch(policyAddStart()),
            policyAddClear: () => dispatch(policyAddClear()),
            policyAddClearError: (name) => dispatch(policyAddClearError(name))
        }
    }
)(Polices);

export function prepareList(list, client, onClick, isArchive = false) {
    let clientName = (client.name ? client.name : "") + " " + (client.surname ? client.surname : "");

    return list.map((item) => {
        return {
            id: item.policy_number,
            name: clientName,
            title: item.policy_type+" "+item.policy_number,
            label: isArchive ? "Истек" : (item.is_active ? "Истекает" : "Начнет действовать"),
            date: prettyDateMask(!isArchive && !item.is_active ? item.date_start : item.date_end),
            buttons: {
                action_button: null,

                more_button:{
                    text: "Подробнее",
                    view: "button_bordered",
                    onClick: () => {onClick({...item, date_end_label: isArchive ? "Истек" : "Истекает"})}
                }
            },
            icon: {
                name: "cross",
                width: "22px",
                height: "22px",
                fill: colors.middle_blue_opacity5
            },
            nonactive: !isArchive && !item.is_active
        }
    })
}

export function prepareAdditionalServices(data, onClick, client) {
    let result = [];
    let clientName = (client.name ? client.name : "") + " " + (client.surname ? client.surname : "");

    const getIcon = (key) => {
        switch (key) {
            case "telemedicine":
                return {
                    name: "telemedicine",
                    width: "24px",
                    height: "24px",
                };
            default:
                return {
                    name: "cross",
                    width: "22px",
                    height: "22px",
                }
        }
    }

    for (let key in data) {
        result.push({
            alias: data[key].alias,
            info: {
                id: key,
                name: clientName,
                title: data[key].title,
                label: "Истекает",
                date: data[key].date_end,
                buttons: {
                    action_button: null,

                    more_button:{
                        text: "Подробнее об услуге",
                        view: "button_bordered",
                        onClick: (e) => {
                            if (e) {
                                e.preventDefault();
                            }
                            onClick(data[key])
                        }
                    }
                },
                icon: {
                    ...getIcon(key),
                    fill: colors.middle_blue_opacity5
                },
            }
        })
    }

    return result
}

export function setModalContent(item){
    this.setState({modal: item});
}

export function setServiceModalContent(item){
    this.setState({serviceModal: item});
}

export function downloadPolicy(){
    this.props.download(this.state.modal.policy_number);
    this.props.downloadMemo(this.state.modal.policy_number);
}

export function sendPolicy(){
   this.props.policySend(this.state.modal.policy_number);
}

export function getModal(){
    return <PolisPage screen_size={this.props.screen_size}
                      item={this.state.modal}
                      client={this.props.client}
                      onClickDownload={this.downloadPolicy}
                      onClickSend={this.sendPolicy}
    />;
}

export function getModalRecomm(){
    return <RecomModal screen_size={this.props.screen_size}
                       item={this.state.modalAdvert}
                       sendModalRecommendationOnclick={this.sendModalRecommendationOnclick}
    />;
}

export class SuccessSendModal extends Component {

    componentWillUnmount() {
        this.props.clear && this.props.clear();
    }

    render(){
        let {
            email,
            screen_size,
            closeModal,
            sendPolicy
        } = this.props;

        return <React.Fragment>
            <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>Отправить полис на почту</Heading>
            <div className="row-form">
                <HeadingIcon style={{alignItems: "flex-start"}} color={colors.green} heading={{size: "s",  text: "Полис отправлен на "+email, color: colors.green }} icon={{name: "success", fill: colors.green, width:"22px", height:"22px"}}   screen_size={screen_size}> </HeadingIcon>
            </div>
            <div className="row-form row-2btn" style={{marginTop: "30px"}}>
                <Button onClick={closeModal} view={"button_bordered"}>Готово</Button>
                <Button onClick={sendPolicy}>Отправить еще</Button>
            </div>
        </React.Fragment>
    }

}

export class SuccessSendModalRecommendation extends Component {

    componentWillUnmount() {
        this.props.clear && this.props.clear();
    }

    getStyleText = () => {
        return {
            color: colors.lightGray_color,
            fontSize: '15px',
            lineHeight: '23px',
            marginLeft: '28px'
        }
    };

    render(){
        let {
            screen_size,
            closeModal,
            titleModal
        } = this.props;

        return <React.Fragment>
            <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>Рекомендация {titleModal.title}</Heading>
            <div className="row-form">
                <HeadingIcon
                    style={{alignItems: "flex-start"}}
                    color={colors.green}
                    heading={{size: "s",  text: "Запрос отправлен", color: colors.green }}
                    icon={{name: "success", fill: colors.green, width:"22px", height:"22px"}}
                    screen_size={screen_size}> </HeadingIcon>
            </div>
            <div className="row-form">
                <TextElem
                    type={"caption"}
                    screen_size = {this.props.screen_size}
                    style={ this.getStyleText() }
                >Ваш запрос отправлен. В ближайшее время мы перезвоним вам</TextElem>
            </div>
            <div className="row-form row-2btn" style={{marginTop: "50px"}}>
                <Button onClick={closeModal} view={"button_bordered"}>Готово</Button>
            </div>
        </React.Fragment>
    }

}