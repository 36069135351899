import {parseErrors} from "../tools/api-helper";

let initState = {};

export default function polices(state = initState, action) {
    switch (action.type) {
        case "POLICES_ACTIVE_LIST_SUCCESS":
            return {...state, active: action.data};
        case "POLICES_ARCHIVE_LIST_SUCCESS":
            return {...state, archive: action.data};
        case "POLICY_SEND":
            return {...state, policySend: false};
        case "POLICY_SEND_CLEAR":
            return {...state, policySend: undefined};
        case "POLICY_SEND_SUCCESS":
            return {...state, policySend: true};
        case "POLICY_ADD":
            return {...state, newPolicyDataErrors: undefined};
        case "POLICY_ADD_START":
            return {...state, policyAddStep: 1};
        case "POLICY_ADD_CLEAR":
        case "POLICY_ADD_BAD_REQUEST":
            return {...state, newPolicyDataErrors: undefined, policyAddStep: 0};
        case "POLICY_ADD_CLEAR_VALIDATION":
            let errors = {...state.newPolicyDataErrors};
            delete errors[action.name];
            return {...state, newPolicyDataErrors: errors};
        case "POLICY_ADD_VALIDATION_ERROR":
            return {...state, newPolicyDataErrors: parseErrors(action.data)};
        case "POLICY_ADD_CODE-SEND_SUCCESS":
            return {...state, newPolicyDataErrors: undefined, policyAddStep: 2};
        case "POLICY_ADD_CODE-VALIDATE_SUCCESS":
            return {...state, newPolicyDataErrors: undefined, active: [...state.active, ...action.data], policyAddStep: 0};
        case "ADDITIONAL_SERVICES_SUCCESS":
            return {...state, additionalServices: action.data};

        case "LOGOUT":
        case "BLOCKED":
            return {...initState};
    }
    return state;
}