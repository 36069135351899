import React, {Component, Fragment} from 'react'
import Heading from "../../designSystem/elements/text/Heading";
import Input from "../../designSystem/elements/input/Input";
import Button from "../../designSystem/elements/button/Button";
import Icon from "../../designSystem/elements/icon/Icon";
import Link from "../../designSystem/elements/links/Link";
import {innMask, smsCodeMask} from "../../tools/mask-helper";
import Timer from "../Timer/Timer";

import "./newPolisModal.css"
import '../Authorization/SMSCode/SMSCode.css';

export default class NewPolisModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTimer: true
        }
    }

    hideTimer = () => {
        this.setState({showTimer: false})
    }

    onSubmit = (action) => {
        return (e) => {
            e.preventDefault();

            if (this.props.step === 2) {
                this.setState({showTimer: true})
            }

            this.props.onSubmit(action)
        }
    }

    getFieldProps = (name) => {

        let {
            onChange = () => {},
            onFocus = () => {},
            data = {},
            errors = {}
        } = this.props;

        return {
            typeInput: 'input',
            type: 'text',
            width: '100%',
            name: name,
            onChange: onChange,
            onFocus: onFocus,
            value: data[name],
            error_text: errors[name]
        }
    }

    componentWillUnmount() {
        this.props.onUnmount()
    }

    render(){

        let {
            screen_size,
            step = 1,
            errors = {}
        } = this.props;

        return <Fragment>
            <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>Добавление полиса</Heading>
            {step === 2 ? <form className="new-policy-code-form" onSubmit={this.onSubmit('code-validate')}>
                    <div className="ugsk-sms-code__block__container">
                        <div className="ugsk-sms-code__input__container">
                            <Input
                                label='Код из SMS'
                                mask={smsCodeMask}
                                {...this.getFieldProps("code")}
                            />
                        </div>
                        <div className={"ugsk-sms-code__container"+(errors.code ? ' has-error' : '')}>
                            <Icon
                                className={'ugsk-form-input-code__icon'}
                                name={'rotate-ccw'}
                            />
                            {this.state.showTimer ? (
                                <Timer text="Повторно запросить код можно через" onTimerEnd={this.hideTimer} />
                            ) : (
                                <Link func={this.onSubmit('code-send')} className='ugsk-form-input-code__link'>
                                    Выслать еще раз
                                </Link>
                            )}
                        </div>
                    </div>
                    <Button onClick={this.onSubmit}>
                        Отправить
                    </Button>
                </form> : <form className="new-policy-form" onSubmit={this.onSubmit('code-send')}>
                    <Input
                        label='Номер полиса'
                        {...this.getFieldProps("policy_number")}
                    />
                    <Input
                        label='ИНН организации'
                        mask={innMask}
                        {...this.getFieldProps("inn")}
                    />
                    <Button onClick={this.onSubmit('code-send')}>
                        Подтвердить
                    </Button>
                </form>}
        </Fragment>
    }
}

