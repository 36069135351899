import React, { Component } from 'react';

import Input from '../../../designSystem/elements/input/Input';
import Link from "../../../designSystem/elements/links/Link";
import Checkbox from "../../../designSystem/elements/checkbox/Checkbox";

import './FormAuthorization.css';

import AuthActions from "../AuthActions/AuthActions";

import {phoneMask} from "../../../tools/mask-helper";
import {isMobile} from "../../../tools/tools";
import Caption from "../../../designSystem/elements/text/Caption";
import {InputStyle} from "../../../designSystem/DesignSystem";

class FormAuthorization extends Component {
    render() {
        const {
            rememberMeAction,
            onClick = () => {},
            onChange = (e) => {},
            onBlur = (e) => {},
            onFocus = (e, type) => {},
            onSubmit = (e) => {e.preventDefault()},
            errors = {},
            notificationsError
        } = this.props;

        return (
            <form onSubmit={onSubmit}>
                <div className="ugsk-form-authorization__input_login">
                    <Input
                        typeInput='input'
                        type='tel'
                        label='Номер телефона'
                        width='100%'
                        placeholder='+7 (___) ___-__-__'
                        name="phone_number"
                        mask={phoneMask}
                        onChange={(ev) => onChange(ev, 'formAuthValues')}
                        onBlur={(ev) => onBlur(ev, 'auth')}
                        onFocus={(ev) => onFocus(ev, 'auth')}
                        error_text={errors.phone_number}
                    />
                </div>
                <div className="ugsk-form-authorization__input_password">
                    <Input
                        typeInput='input'
                        type='password'
                        label='Пароль'
                        width='100%'
                        placeholder=''
                        name="password"
                        onChange={(ev) => onChange(ev, 'formAuthValues')}
                        onBlur={(ev) => onBlur(ev, 'auth')}
                        onFocus={(ev) => onFocus(ev, 'auth')}
                        error_text={errors.password}
                        errorStyle={{position: "relative", bottom: 0}}
                        notificationsError={notificationsError}
                    />
                </div>
                <div className='ugsk-form-authorization__link'>
                    <Link
                        func={(e) => {e.preventDefault(); onClick('forgotPassword')}}
                    >Забыли пароль?</Link>
                </div>
                <div className='ugsk-form-authorization__checkbox__container'>
                    <Checkbox onChange={rememberMeAction}>Запомнить меня</Checkbox>
                </div>
                {errors.recaptcha ? <div className='ugsk-sign-up__checkbox__container'>
                    <Caption className="input__error" style={{color: InputStyle.layout_default.error_mode.errColor}}>
                        <div dangerouslySetInnerHTML={{__html: errors.recaptcha}} />
                    </Caption>
                </div> : null}
                <div className='ugsk-form-authorization__actions__container'>
                    <AuthActions
                        whiteBtnLabel="Регистрация"
                        withReverse
                        type="signUp"
                        blueBtnLabel="Войти"
                        onSubmit={onSubmit}
                        onClick={onClick}
                        typeForBlueBtn="formAuth"
                    />
                </div>
            </form>
        )
    }
}

export default FormAuthorization;