import React, {Component} from "react";
import Heading from "../../designSystem/elements/text/Heading";
import ClinicsList from "./List";
import Pagination from "../../designSystem/elements/pagination/Pagination";
import {colors, spaces} from "../../designSystem/DesignSystem";
import FilterPanel from "./FilterPanel";
import Tabs from "../../designSystem/elements/tabs/Tabs";
import Map from "../../designSystem/elements/map/Map";

export default class Clinics extends Component {

    constructor(props){
        super(props);

        this.state = {
            onMap: false,
            filterHidden: true,
            shownClinic: null,
            activeTab: 0,
            hasMap: this.getHasMap(props.clinics),
            shownBounds: false,
            tabReset: false,
            tabBlock: false
        };

        this.tabItems = this.state.hasMap ? [
            {title: "Список", icon: {name: "list", width: "17px", height: "14px"}},
            {title: "Карта", icon:{name: "geo", width: "17px", height: "14px"}}
        ] : [];
    }

    componentDidUpdate() {
        if (this.props.screen_size === "xs" && this.state.activeTab === 1 && !!this.state.shownClinic) {
            document.querySelector("footer").setAttribute('class', 'footer visible--hidden');
            document.querySelector("header").setAttribute('class', 'header visible--hidden');
        } else {
            document.querySelector("footer").setAttribute('class', 'footer');
            document.querySelector("header").setAttribute('class', 'header');
        }

        let tabReset = this.state.tabReset;
        if (tabReset === true){
            this.setState({tabReset: false});
        }
    }

    componentWillReceiveProps() {
        let itemsCoords = this.props.clinics;
        itemsCoords.map((item) => {
            if (typeof item.coords !== "undefined") {
                this.setState({
                    tabBlock: true
                })
            } else {
                this.setState({
                    tabBlock: false
                })
            }
        })
    }

    getHasMap = () => {
        let hasMap = false;

        for (let i = 0; i < this.props.clinics.length; i++) {
            if (this.props.clinics[i].coords) {
                hasMap = true;
                break;
            }
        }

        return hasMap;
    };

    getOffset = () => {
        switch (this.props.screen_size) {
            case "m":
                return "- 320px";
            case "s":
                return "- 290px";
            case "xs":
                return "- 0px";
            case "xl":
                return "- calc(379px + 50vw - 770px)";
            default:
                return window.innerWidth >= 1440 ? "- calc(379px + 50vw - 700px)" : "- 380px";
        }
    }

    setPage = (num) => {
        this.props.setPage && this.props.setPage(num);
    };

    onShowPoint = (points) => {
        this.setState({shownClinic: points}, () => {
            if(this.props.screen_size === "xs" && !points){
                this.onToggleTab(0);
            }
        });
    };

    openOnMap = (item) => {
        this.onToggleTab(1);
        this.onShowPoint(item);
    };

    onToggleFilter = () => {
        this.setState((prevState) => {
            return {
                filterHidden: !prevState.filterHidden
            }
        })
    };

    onToggleTab = (i) => {
        let tabBlock = this.state.tabBlock;
        if (tabBlock) {
            this.setState({
                activeTab: i,
                onMap: i === 1,
                shownClinic: i === 0 && ["xs", "s"].indexOf(this.props.screen_size) === -1 ? null : this.state.shownClinic,
                shownBounds: true,
                tabReset: false
            });
            this.props.setMode && this.props.setMode(i === 1 ? "map" : "list");

            setTimeout(() => {
                this.setState({
                    shownBounds: false
                });
            }, 500);

        } else {
            this.setState({
                tabReset: true
            });
        }
    };

    renderPagination = () => {
        let pagination = this.props.pagination;

        if (pagination && pagination.pageCount > 1) {
            let paginators = [];
            let visibleIndexes = [0, pagination.pageCount - 1, pagination.currentPage - 1, pagination.currentPage - 2, pagination.currentPage - 3, pagination.currentPage - 0, pagination.currentPage - 0 + 1];

            for (let i = 0; i < pagination.pageCount; i++) {
                paginators.push(pagination.pageCount <= 5 || visibleIndexes.indexOf(i) !== -1 ? <Pagination key={i} active={pagination.currentPage == i + 1} onClick={() => {this.setPage(i)}}>
                    {i + 1}
                </Pagination> : (pagination.pageCount <= 5 || visibleIndexes.indexOf(i - 1) !== -1 ? <a href={() => {return false}} style={{lineHeight: "48px"}} className="page_number">...</a> : null))
            }

            return <div style={{display: "flex", marginBottom: spaces.xl}}>
                {paginators}
            </div>
        }

        return null;
    };

    render(){

        let {
            title,
            screen_size,
            filterFields,
            searchInputParams,
            clinics = [],
            message,
            coords = []
        } = this.props;

        let {
            onMap,
            filterHidden,
            shownClinic,
            activeTab,
            hasMap,
            tabReset,
            tabBlock
        } = this.state;

        let mapWidth = "auto";

        if (onMap && screen_size === "s" || onMap && screen_size === "xs"){
            mapWidth = "100%";
        }

        let blockWidth = "calc(100vw " + this.getOffset() + ")";
        return <div className="page">
            {hasMap ? <div style={{
                height: screen_size === "xs" && activeTab === 1 && !!shownClinic ? '0' : screen_size === "xs" ? spaces.l : spaces.xl
            }}>

                    <Tabs styleSwitcher={"iconed"}
                      typeSwitcher="url"
                      items={this.tabItems}
                      activeTab={activeTab}
                      onToggleTab={this.onToggleTab}
                      screen_size={this.props.screen_size}
                      tabBlock={tabBlock}
                       />

            </div> : null}
            {screen_size === "xs" && !onMap ? <Heading size="l" screen_size={screen_size} style={{marginTop: spaces.l, marginBottom: 0, paddingLeft: spaces.m}}>{title}</Heading> : null}
            <div style={{display: "flex", alignItems: "stretch", justifyContent: "space-between", overflow: "hidden", flexDirection: screen_size === "xs" ? "column-reverse" : "row", position: "relative", minHeight: screen_size === "xs" ? 0 : "calc(100vh - 226px)"}}>
                    <div style={{
                        width: blockWidth,
                        paddingRight: screen_size === "xs" ? spaces.m : spaces.l,
                        paddingLeft: getListPadding(screen_size),
                        display: activeTab === 0 ? "block" : "none"
                    }}>
                        {screen_size !== "xs" ? <Heading size="l" nameClass={"clinic-heading"} screen_size={screen_size}>{title}</Heading> : null}
                        <ClinicsList message={message} items={clinics} screen_size={screen_size} showItem={this.onShowPoint} openOnMap={this.openOnMap} />
                        {this.renderPagination()}
                    </div>
                    {!shownClinic || activeTab === 1 && screen_size !== "xs" ?
                        <div style={{
                            width: onMap && screen_size === "s" || onMap && screen_size === "xs" ? "100%" : screen_size === "m" ? "calc(100% - 320px)" : "calc(100% - 380px)",
                            height: "calc(100vh - 226px)",
                            display: activeTab === 1 ? "block" : "none"
                        }}>
                            {coords.length ?
                                <Map
                                    shownBounds={this.state.shownBounds}
                                    activeTab={this.state.activeTab}
                                    clusterer={true}
                                    showZoom={true}
                                    showGeolocation={true}
                                    points={coords}
                                    shownClinic={this.state.shownClinic}
                                    onClick={this.onShowPoint}/> :
                                <span
                                    style={{
                                        display: "block",
                                        paddingLeft: screen_size === "xxl" ? "calc((45vw - 720px) + 130px)" : screen_size === "l" ? "130px" : screen_size === "m" ? "38px" : screen_size === "s" ? "34px" : "20px",
                                        marginTop: "70px",
                                        color: colors.text_color,
                                        fontSize: "15px",
                                        lineHeight: "23px",
                                    }}>К сожалению, по вашему запросу ничего не найдено. Попробуйте изменить параметры поиска.</span>}
                        </div> : null}

                    <FilterPanel screen_size={screen_size}
                                 isOnMap={onMap}
                                 hasMap={hasMap}
                                 shownDetail={shownClinic}
                                 resetDetail={() => {this.onShowPoint()}}
                                 hidden={filterHidden}
                                 toggleHidden={this.onToggleFilter}
                                 toggleTab={this.onToggleTab}
                                 fields={filterFields}
                                 searchInputParams={searchInputParams}
                                 openOnMap={this.openOnMap}
                                 points={coords}
                                 tabBlock={tabBlock}
                                 tabReset={tabReset}
                                 activeTab={activeTab}
                    />
            </div>
        </div>
    }

}

function getListPadding(screen_size) {
    switch (screen_size) {
        case "m":
            return window.innerWidth > 1050 ? "calc(50vw - 512px + 72px)" : "38px";
        case "s":
            return "34px";
        case "xs":
            return "20px";
        default:
            return window.innerWidth > 1440 ? "calc(45vw - 720px + 130px)" : "130px";
    }
}