import React, {Component } from "react";

import "./Header.css";
import Icon from "../../designSystem/elements/icon/Icon";
import Tabs from "../../designSystem/elements/tabs/Tabs";
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Link from "../../designSystem/elements/links/Link";
import {Link as ReactLink} from "react-router-dom";
import Footer from "./Footer";
import Phones from "./Phones";

export default class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            showMenu: false,
        }
    }

    toggleMenu = () => {
        this.setState((prevState) => {
            if (prevState.showMenu) {
                document.body.classList.remove("unscrollable");
            } else {
                document.body.classList.add("unscrollable");
            }
            return {showMenu: !prevState.showMenu}
        })
    };

    render(){
        let {
            phone,
            additionalPhone,
            client = {},
            screen_size,
            menuLinks,
            tabLinks,
            isGuest
        } = this.props;

        return <header className="header">
            <div className="header_container">
                <div className="header_left_block">
                    <div className="header_logo">
                        <ReactLink to={isGuest ? "/auth" : "/"}><Icon name="logo" width={screen_size === "xs" ? "122px" : "170px"} height={screen_size === "xs" ? "35px" : "50px"} fill={colors.middle_blue}/></ReactLink>
                    </div>
                    {screen_size !== "s" && screen_size !== "xs" && !isGuest ? <Tabs
                        typeSwitcher={"url"}
                        styleSwitcher={"underlined"}
                        items={tabLinks}
                    >
                    </Tabs> : null}
                </div>
                <div className="header_right_block">
                    {screen_size !== "xs" ? <Phones additionalPhone={additionalPhone} phone={phone} className="header_phones"/> : null}
                    {!isGuest ? <Link typeLink={"menu"} list={menuLinks} noArrow={screen_size === "xs"}>
                        <Icon name="user" width="24px" height="24px" fill={screen_size === "xs" ? colors.middle_blue : colors.middle_blue_opacity5} className="header_user_icon"/>
                        {screen_size !== "xs" ? client.name : null}
                    </Link> : null}
                    {screen_size === "s" && !isGuest ? <Link typeLink="menu" list={tabLinks} noArrow={true} className="burger">
                        <Icon name="menu" width={"24px"} height={"24px"} fill={colors.middle_blue}/>
                    </Link> : null}
                    {screen_size === "xs" && !isGuest ? <span onClick={this.toggleMenu} style={{height: "24px"}}>
                        <div className="menu-icons" style={{
                            width: "24px",
                            height: "24px",
                            display: "inline-flex",
                            alignItems: "center",
                            marginLeft: spaces.m
                        }}>
                        {this.state.showMenu ?
                            <Icon key="close-icon" name="close-light" width="28px" height="28px" fill={colors.middle_blue}/> :
                            <Icon key="menu-icon" name="menu" width="24px" height="24px" fill={colors.middle_blue}/>}
                        </div>
                        {this.state.showMenu ? <MobileMenu links={tabLinks} footerInfo={this.props}/> : null}
                    </span> : null}
                </div>
            </div>
        </header>
    }

}

class MobileMenu extends Component {
    componentWillUnmount() {
        document.body.classList.remove("unscrollable");
    }

    render() {

        let {
            links,
            footerInfo
        } = this.props;

        return <div className="header_menu">
            <div style={{padding: spaces.l+" 0"}}>
                {links.map((item, key) => <div key={key}><Link typeLink="link_iconed" {...item} className="link__dropdown_item" /></div>)}
            </div>

            <Footer {...footerInfo} onlyInfo={true}/>
        </div>
    }
}