export function phoneMask(value) {
    var next = value.replace(/[^+0-9]/gim, '');
    var arr = next.split('');

    if (arr[0] && arr[0] == 8) {
        arr.splice(0, 1, '+');
        arr.splice(1, 0, 7);
    }
    if (arr[0] && arr[0] != '+') {
        arr.splice(0, 0, '+');
    }
    if (arr[1] && arr[1] != 7) {
        arr.splice(1, 0, 7);
    }
    arr = arr.slice(0,12);
    var str = '';
    for (var i = 0; i < arr.length; i++) {
        switch (i) {
            case 2:
                str += ' ('+arr[i];
                break;
            case 5:
                str += ') '+arr[i];
                break;
            case 8:
            case 10:
                str += '-'+arr[i];
                break;
            default:
                str += arr[i];
        }
    }
    return str;

}

export function birthDateMask(value) {
    let result = value.replace(/[^0-9.]/g, '').split('');

    if (result[2] && result[2] !== '.') {
        result.splice(2, 0, '.');
    }
    if (result[5] && result[5] !== '.') {
        result.splice(5, 0, '.');
    }

    return result.slice(0, 10).join('');
}

export function smsCodeMask(value) {
    return value.replace(/\D/g, '').slice(0, 6);
}

export function innMask(value) {
    return value.replace(/\D/g, '').slice(0, 12);
}

export function passwordMask(value) {
    return value.slice(0, 14);
}

export function prettyDateMask(date) {

    if (/[\d]{2}\.[\d]{2}\.[\d]{4}/.test(date)) {
        date = date.split(".").reverse().join("-");
    }
    if (/[\d]\.[\d]{2}\.[\d]{4}/.test(date)) {
        date = ("0" + date).split(".").reverse().join("-");
    }
    date = date.replace(/-/g, "/");
    date = new Date(date);

    let months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря"
    ];

    return date.getDate()+" "+months[date.getMonth()]+" "+date.getFullYear();

}