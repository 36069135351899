import React, { Component } from 'react';

import './Settings.css';
import {colors} from "../../designSystem/DesignSystem";
import Tabs from "../../designSystem/elements/tabs/Tabs";
import NotificationsContainer from "../../designSystem/elements/notification/NotificationsContainer";

import Personal from "./Personal";
import Password from "./Password";
import Policies from "./Policies";
import AuthorizationComponent from "../Authorization/Authorization";


class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            changePassword: false
        };

        this.tabStyles = {
            zIndex: 1001,

        };

        let {
            personalProps,
            passwordProps,
            policiesProps
        } = this.props;

        this.tabItems = [];

        if (personalProps) {
            this.tabItems.push({title: "Личные данные ", icon: {name: "page", width: "15px", height: "18px"}})
        }

        if (passwordProps) {
            this.tabItems.push({title: "Пароль", icon:{name: "lock", width: "14px", height: "17px"}})
        }

        if (policiesProps) {
            this.tabItems.push({title: "Архивные полисы", icon:{name: "box", width: "20px", height: "18px"}})
        }
    }

    getStylesTitle = (screenSize) => {
        return {
            marginTop: 0
        }
    };

    getStylesName = (screenSize) => {
        return {
            marginTop: 0
        }
    };

    getStylesLinkEdit = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
                return {
                    display: 'block'
                }
        }
    };

    getStylesCaption = (screenSize) => {

        return {
            display: 'block',
            color: colors.gray,
            marginTop: '5px'
        }
    };

    getStylesInput = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l': {
                return {
                    width: '100%'
                }
            }
        }
    };

    getStylesPolis = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            default:
                return {
                    width: '100%',
                }
        }
    };

    render() {
        let {
            onClickLink,
            personalProps,
            passwordProps,
            policiesProps,
            leftTopCards,
            closeNotification,
            children,
            onChange = (e, type) => {},
            valueInputAddress,
            disabledBtnReady,
            onChangeAddress,
            changeAddressSuccess,
            onChangeEmail,
            error_text,
            clearErrorTxt,
            onBlurEmail,
            onFocusEmail,
            onChangeEmailDataRequest,
            changeEmailSuccess,
            inputOnKeyDown,
            onChangePhone,
            inputPhoneOnKeyDown,
            errorTxtPhoneValidation,
            onBlurPhone,
            changePhoneRequest,
            onChangePhoneOld,
            onChangeSmsCode,
            errorTxtSmsCodeValidation,
            onChangeConfirmSmsRequest,
            onBlurValues,
            confirmPhoneNumber,
            requestAction,
            setActionDefault,
            sendConfirmPhoneRequest,
            clearChangePhoneData
        } = this.props;

        const { screenSize } = this.props;

        const stylesTitle = this.getStylesTitle(screenSize);
        const stylesName = this.getStylesName(screenSize);
        const styleLinkEdit = this.getStylesLinkEdit(screenSize);
        const stylePolis = this.getStylesPolis(screenSize);
        const styleInput = this.getStylesInput(screenSize);
        const styleCaption = this.getStylesCaption(screenSize);


        let content = [];

        if (personalProps) {

            content.push(<Personal
                onClickLink={onClickLink}
                key="personal"
                screenSize={screenSize}
                stylesTitle={stylesTitle}
                stylesName={stylesName}
                styleLinkEdit={styleLinkEdit}
                onChange={onChange}
                onChangeEmail={onChangeEmail}
                valueInputAddress={valueInputAddress}
                disabledBtnReady={disabledBtnReady}
                onChangeAddress={onChangeAddress}
                changeAddressSuccess={changeAddressSuccess}
                error_text={error_text}
                clearErrorTxt={clearErrorTxt}
                onBlurEmail={onBlurEmail}
                onFocusEmail={onFocusEmail}
                onChangeEmailDataRequest={onChangeEmailDataRequest}
                changeEmailSuccess={changeEmailSuccess}
                inputOnKeyDown={inputOnKeyDown}
                onChangePhone={onChangePhone}
                inputPhoneOnKeyDown={inputPhoneOnKeyDown}
                errorTxtPhoneValidation={errorTxtPhoneValidation}
                onBlurPhone={onBlurPhone}
                changePhoneRequest={changePhoneRequest}
                onChangePhoneOld={onChangePhoneOld}
                onChangeSmsCode={onChangeSmsCode}
                errorTxtSmsCodeValidation={errorTxtSmsCodeValidation}
                onChangeConfirmSmsRequest={onChangeConfirmSmsRequest}
                onBlurValues={onBlurValues}
                confirmPhoneNumber={confirmPhoneNumber}
                requestAction={requestAction}
                setActionDefault={setActionDefault}
                sendConfirmPhoneRequest={sendConfirmPhoneRequest}
                clearChangePhoneData={clearChangePhoneData}
                {...personalProps}/>)
        }

        if (passwordProps) {
            content.push(<Password key="password" screenSize={screenSize} stylesTitle={stylesTitle} stylesInput={styleInput} stylesCaption={styleCaption} {...passwordProps}/>)
        }

        if (policiesProps) {
            if (policiesProps.infoPolis.length !== 0) {
                content.push(<Policies key="policies" screenSize={screenSize} stylesTitle={stylesTitle} stylePolis={stylePolis} {...policiesProps}/>)
            }
        }

        return (

            <div className={"page ugsk-settings-page"}>
                <Tabs styleSwitcher={"iconed"}
                      typeSwitcher ={"scrollContent"}
                      items={this.tabItems}
                      activeTab={this.state.activeTab}
                      style={this.tabStyles}
                      screen_size={this.props.screenSize}
                      tabBlock={true}>

                    {content}


                </Tabs>
                {children}
            </div>




        )
    }
}

export default Settings;