import React from 'react';

import StateMouse from "../StateMouse";
import {CheckBoxRadioStyle, InputStyle} from "../../DesignSystem";
import './checkBox.css';
import Caption from "../text/Caption";


export default class Checkbox extends StateMouse {

    constructor(props) {
        super(props);

        let mode="normal_mode";
        let prevMode = "normal_mode";

        if (this.props.disabled) {
             mode = "disabled_mode";
             prevMode = "disabled_mode";
        }

        let items=this.props.items;
        let n=0;
        items ? (n = items.length) : "";

        this.state={
            mode: props.mode || mode,
            prevMode: prevMode,
        };
        this.inpRef = React.createRef();

        this.pathIcon = null;
    }

    componentDidMount() {
        if (this.props.value) {
            this.onChange()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled != this.props.disabled) {
            this.setState((prevState) => {
                return {
                    prevMode: prevState.mode,
                    mode: nextProps.disabled ? "disabled_mode" : "normal_mode"
                }
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if(this.state.mode != "disabled_mode") {
            if ((nextProps.clear === true) && (this.state.mode === "selected_mode")) {
                this.setState({mode: "normal_mode", prevMode: "normal_mode"})
            }
        }
        return true;

    }

    onHover= ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    prevMode: prevState.mode,
                    mode: "hovered_mode"
                })

            });
        }
    };
    onMouseOut = ()=>{
        if ((this.state.mode != "selected_mode")&&(this.state.mode != "disabled_mode")) {
            this.setState((prevState) => {
                return ({
                    mode: "normal_mode",
                    prevMode: prevState.mode
                })
            });
        }
    };

    componentDidUpdate() {
        if (this.state.mode == 'selected_mode') {
            setTimeout(() => {
                this.pathIcon.style.strokeDashoffset = 0;
                this.pathIcon.style.transition = 'all .3s ease-in-out'
            }, 10)
        }
    }

    onChange = () => {

        if (this.pathIcon.style.strokeDashoffset == 0) {
            this.pathIcon.style.strokeDashoffset = 100;
        }

        setTimeout(() => {
            if (this.state.mode != "disabled_mode") {
                this.setState((prevState) => {
                    let mode = "selected_mode";
                    let prevMode = prevState.mode;
                    if (prevState.mode === mode) {
                        mode = prevState.prevMode;
                        prevMode = "selected_mode";
                    }
                    if (mode == 'hovered_mode') {
                        mode = 'normal_mode';
                    }
                    return ({
                        mode: mode,
                        prevMode: prevMode
                    })
                }, () => {
                    this.props.onChange && this.props.onChange(this.state.mode === "selected_mode", this.props.name);
                })
            }
        }, 500);

    };

     mouseEvents= {
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onMouseUp: this.onChange
    };

    renderItem=()=>{
        let {
            selected,
            label,
            name,
            onChange,
            layout,
            type,
            error_text
        } = this.props;
        if (!layout) {
            layout = "layout_default";
        }
        let elem ="";
        let stylesElem={},
            stylesText = {},
            stylesBtn = {};

        stylesText =  Object.assign({}, CheckBoxRadioStyle.default_attrs.text, CheckBoxRadioStyle.text_attrs[layout][this.state.mode]);
        stylesBtn = Object.assign({},  CheckBoxRadioStyle.default_attrs.icon.checkbox, CheckBoxRadioStyle.icon_attrs[layout][this.state.mode]);

        elem =
            <div className="ugsk-checkbox__container">
                <label className={this.props.disabled ? "checkbox__wrap disabled" : "checkbox__wrap"} {...this.mouseEvents}>
                    <input type="checkbox" className="checkbox__input" id="inp1" ref={this.inpRef} disabled={this.props.disabled} checked={this.state.mode === "selected_mode"} />
                    <span className="checkbox__icon" style={stylesBtn}>
                    <svg  width="14" height="11" viewBox="0 0 14 11" fill="none" stroke={ CheckBoxRadioStyle.icon_attrs[layout][this.state.mode].fill }
                          stroke-width="1" stroke-dasharray="100" xmlns="http://www.w3.org/2000/svg">
                        <path className="path-ok" style={{ strokeDashoffset: 100 }}  id="check" d="M 3,5 L 7,9 L 12,1" ref={ref => this.pathIcon = ref}></path>
                    </svg>
                </span>
                    <span className="checkbox__text" style={stylesText}>{this.props.children}</span>
                </label>
                {error_text ?
                    <Caption className="checkbox__error" style={{color: InputStyle[layout].error_mode.errColor}}>
                        {error_text}
                    </Caption> : ""}
            </div>

        return elem;
    };

    render() {

        return (
           <React.Fragment>
               {this.renderItem()}
           </React.Fragment>
        )
    }
}

