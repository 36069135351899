import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware, { END } from 'redux-saga';

import authorization from "./reducers/authorization";
import client from "./reducers/client";
import notifications from "./reducers/notifications";
import polices from "./reducers/polices";
import clinics from "./reducers/clinics";
import common from "./reducers/common";
import promotions from "./reducers/promotions";
import recaptcha from "./reducers/recaptcha";

const reducer = combineReducers({
    authorization,
    client,
    notifications,
    polices,
    clinics,
    common,
    promotions,
    recaptcha
});

export default ( initialState ) => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        reducer,
        initialState,
        applyMiddleware(
            sagaMiddleware
        )
    );

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);
    return store
}
