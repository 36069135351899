import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {checkErrors, fetchApi, getActionsByRoute} from "../tools/api-helper";


export function loadPage(location) {

    return {
        type: "LOAD_PAGE",
        location
    }
}

export function* fetchLoadPage(action) {

    let actions = getActionsByRoute(action.location);

    if (actions.length) {
        let takeActions = [];

        for (let i = 0; i < actions.length; i++) {
            yield put(actions[i]);
            takeActions.push(actions[i].type + "_SUCCESS");
        }

        while (takeActions.length > 0) {
            var act = yield take(takeActions);
            var ind = takeActions.indexOf(act.type);
            takeActions.splice(ind, 1);
        }
    }


    yield put({type: action.type + "_SUCCESS", pathname: action.location});

}

export function getApplication(location) {
    return {
        type: "GET_APPLICATION"
    }
}

export function* fetchGetApplication(action) {

    const {status, data, error} = yield call(() => {
        return fetchApi("/application", {
            method: 'GET'
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

export function promotionConfirmation(data) {
    return {
        type: "PROMOTION_CONFIRMATION",
        data
    }
}

export function* fetchPromotionConfirmation(action) {

    const {status, data, error} = yield call(() => {
        return fetchApi("/promotions/request", {
            method: 'POST',
            body: action.data
        })
    });

    if (error) {
        yield put(checkErrors(action.type, {message: error}, 500));
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

export function closeNotification(key) {
    return {
        type: "CLOSE_NOTIFICATION",
        key
    }
}


export function clearError() {
    return {
        type: "CLEAR_ERROR"
    }
}
