import React from 'react';

import Input from "../../../../../designSystem/elements/input/Input";
import Button from "../../../../../designSystem/elements/button/Button";

import './Address.css';

const formEditAddress = ({ closeFormEdit, valueImput, onChange, disabledBtnReady, onChangeAddress }) => {

    return (
        <React.Fragment>
            <div className='ugsk-address-form-edit__input'>
                <Input
                    width='100%'
                    typeInput={"input"}
                    type="text"
                    label={"Адрес проживания"}
                    name="residenceAddress"
                    placeholder={"Введите адрес"}
                    onChange={onChange}
                    value={valueImput ? valueImput : ''}
                    addressInput={true}
                />
            </div>
            <Button className='ugsk-address-form-edit__btn-done' disabled={disabledBtnReady} onClick={onChangeAddress} >Готово</Button>
            <Button className='ugsk-address-form-edit__btn-cancel' view={"button_bordered"} onClick={closeFormEdit}>Отменить</Button>
        </React.Fragment>
    );
};

export default formEditAddress;