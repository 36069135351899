import React, { Component } from 'react';
import TabSwitchItem from "./TabSwitchItem"
import './tabs.css';
import {colors} from "../../DesignSystem";
import ReactDOM from "react-dom";

export default class TabsSwitcher extends Component{


    constructor(props){
        super(props);

        this.refList = React.createRef();

        this.state={
            scrolled: false
        };

        this._menuHover = null;
        this._menuItems = [];
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScrollFunc);

        if (this.props.styleSwitcher == 'iconed') this.getStyleMenuBlock();
    }

    componentDidUpdate() {
        if (this.props.styleSwitcher == 'iconed') this.getStyleMenuBlock();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        this.refList.current.scrollLeft =  nextProps.tabOffsetLeft -20 ;

        return true;
    }

    onScrollFunc = ()=>{
        let h = 70;
        if (this.props.screen_size === "xs") h = 50;
        if(window.pageYOffset >= h) {
            if (this.state.scrolled === false){
                this.setState({
                    scrolled: true
                })
            }
        }
        else{
            if (this.state.scrolled === true){
                this.setState({
                    scrolled: false
                })
            }
        }
    };

    getStyleMenuBlock = () => {

        let menuItems = this.props.items.slice(),
            activeIndex = 0;

        let leftMenuBlock,
            leftMenuItem;

        menuItems.map((item, index) => {
            if (index === this.props.activeTab) {
                activeIndex = index;
            }
        });

        leftMenuItem = ReactDOM.findDOMNode(this._menuItems[activeIndex]).getBoundingClientRect().left;
        leftMenuBlock = ReactDOM.findDOMNode(this.refList.current).getBoundingClientRect().left;

        this._menuHover.style.transform = `translate3d(${ leftMenuItem - leftMenuBlock  }px, 0, 0)`;
        this._menuHover.style.width = ReactDOM.findDOMNode(this._menuItems[activeIndex]).getBoundingClientRect().width + 'px';
        this._menuHover.style.height = ReactDOM.findDOMNode(this._menuItems[activeIndex]).getBoundingClientRect().height + 'px';
    };

    render() {
        let hh = "70px";
        if (this.props.screen_size === "xs") hh = "50px";
        let {
            activeTab,
            items,
            styleSwitcher,
            typeSwitcher,
            onToggleTab,
            scrollTabsLeft,
            style = {}
        } = this.props;

        let classSwitcher = "tabs__items-list";

        (this.state.scrolled === true) ? classSwitcher = "tabs__items-list "+ " tabs_fixed" : "";

        return (

                <div className={"tabs-inner__" + styleSwitcher}  style={{borderBottom: style.borderBottom ? style.borderBottom : "none", height: hh }}>
                    <div className={classSwitcher}  style={Object.assign({}, style, {borderBottom: "1px solid rgba(0, 0, 0, 0.13)"})} >
                        <div className={"inner-wrapper-items-list"} ref={this.refList} >
                            {items.map((item, key) =>
                                <TabSwitchItem
                                    key={key}
                                    index={key}
                                    active={activeTab==key}
                                    onToggleTab={onToggleTab}
                                    typeSwitcher={typeSwitcher}
                                    styleSwitcher={styleSwitcher}
                                    ref={ref => {this._menuItems[key] = ref; return true;}}
                                >
                                    {item}
                                </TabSwitchItem>
                            )}
                            {
                                styleSwitcher == 'iconed'
                                    ?
                                    <div
                                        className="tabSwitchBlock"
                                        ref={ref => this._menuHover = ref}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

