import React, { Component } from 'react';

import Icon from '../../designSystem/elements/icon/Icon';
import FormAuthorization from '../../components/Authorization/FormAuthorization/FormAuthorization';
import NotificationsContainer from '../../designSystem/elements/notification/NotificationsContainer';

import {colors} from '../../designSystem/DesignSystem';

import AuthorizationImg from '../../images/authorization-page.png';

import './AuthorizationWithNotification.css';

class Authorization extends Component {
    render() {
        let { screenSize } = this.props;

        let leftTopCards = [
            {
                type: "authorizeError",
                text: "Авторизация заблокирована на 60 минут после 10 неудачных попыток входа. Осталось 42 минуты.",
                shouldClose: false,
            }
        ];

        return (
            <div className='page ugsk-page-authorization'>
                <div className='ugsk-page-authorization__left'>
                    <div className='ugsk-page-authorization__form'>
                        <Icon
                            name='logougory'
                            className='ugsk-page-authorization__logo'
                            fill={ screenSize === 'xs' ? colors.white : colors.middle_blue }
                        />
                        <FormAuthorization
                            className='ugsk-page-authorization__form-authorization'
                        />
                    </div>
                    <NotificationsContainer
                        leftTopCards={leftTopCards}
                        classNameLeftTopContainer='ugsk-page-authorization__container-left-top'
                        classNameRightBottomContainer='ugsk-page-authorization__container-right-bottom'
                    />
                </div>
                <div
                    className='ugsk-page-authorization__right'
                    style={{
                        background: `linear-gradient(0deg, rgba(12, 19, 45, 0.15), rgba(12, 19, 45, 0.15)), url(${ AuthorizationImg })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                />
            </div>
        )
    }
}

export default Authorization;