import React, {Component, Fragment} from 'react'
import Heading from "../../designSystem/elements/text/Heading";
import Checkbox from "../../designSystem/elements/checkbox/Checkbox";
import {phoneMask} from "../../tools/mask-helper";
import Input from "../../designSystem/elements/input/Input";
import Button from "../../designSystem/elements/button/Button";

import "./ReviewForm.css"
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Mark from "../../designSystem/elements/mark/mark";
import {emailError, phoneError} from "../../tools/form-helper";
import {getCounter, isMobile} from "../../tools/tools";
import Caption from "../../designSystem/elements/text/Caption";
import {SucceedRequestModal} from "../SettingsWithModals/SettingsModals";
import Icon from "../../designSystem/elements/icon/Icon";

export default class ReviewModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            is_by_phone: {
                name: "is_by_phone",
                value: 0,
                error: "",
                type: "checkbox",
                disabled: false,
                label: "Номер телефона"
            },
            is_by_email: {
                name: "is_by_email",
                value: 0,
                error: "",
                type: "checkbox",
                disabled: false,
                label: "E-mail"
            },
            phone_number: {
                name: "phone_number",
                value: phoneMask(props.phone),
                error: "",
                type: "input",
                disabled: false,
                label: "Номер телефона",
                placeholder: "Введите номер телефона",
                mask: phoneMask
            },
            email: {
                name: "email",
                value: props.email,
                error: "",
                type: "input",
                disabled: false,
                label: "E-mail",
                placeholder: "Введите e-mail"
            },
            comment: {
                name: "comment",
                value: "",
                error: "",
                type: "textarea",
                disabled: false,
                label: "Текст отзыва"
            },
            mark: {
                name: "mark",
                value: 0,
                error: "",
                type: "mark",
                disabled: false,
                label: "Оцените уровень сервиса и удобство личного кабинета"
            },
            isValid: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.scrollToFocused)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.scrollToFocused)
    }

    onChange = (value, name) => {
        let fields = {...this.state}

        fields[name].value = value;

        if (name === "is_by_phone") {
            fields.is_by_email.disabled = value
        }

        if (name === "is_by_email") {
            fields.is_by_phone.disabled = value
        }

        fields.isValid = this.validate()

        this.setState(fields, () => {
            if (["is_by_phone", "is_by_email"].indexOf(name) !== -1) {
                window.dispatchEvent(new Event('resize'))
            }
        })
    }

    getError = (name) => {
        let fields = {...this.state}
        let err = "";

        switch (name) {
            case "email":
                if (fields.is_by_email.value) {
                    if (!fields.email.value) {
                        err = "Поле обязательно для заполнения"
                    } else {
                        err = emailError(fields.email.value)
                    }
                }
                break;
            case "phone_number":
                if (fields.is_by_phone.value) {
                    if (!fields.phone_number.value) {
                        err = "Поле обязательно для заполнения"
                    } else {
                        err = phoneError(fields.phone_number.value)
                    }
                }
                break;
            case "comment":
                if (!fields.comment.value) {
                    err = "Поле обязательно для заполнения"
                }
                if (fields.comment.value.length > 2000) {
                    err = "Максимум 2000 символов"
                }
                break;
        }

        return err;
    }

    validate = (name = null) => {
        let fields = {...this.state}
        let errors = {
            email: this.getError("email"),
            phone_number: this.getError("phone_number"),
            comment: this.getError("comment"),
        }

        if (name) {
            fields[name].error = errors[name];
            this.setState(fields)
        } else {
            return !(errors.email || errors.phone_number || errors.comment);
        }
    }

    clearError = (name) => {
        let fields = {...this.state}

        fields[name].error = "";
        this.setState(fields)
    }

    onChangeInput = (e) => {
        this.onChange(e.target.value, e.target.name)
    }

    scrollToFocused = () => {
        if (document.activeElement) {
            setTimeout(function () {
                let el = document.activeElement.closest(".review-form__field");
                if (el) {
                    el.scrollIntoView()
                }
            }, 200)
        }
    }

    scrollTo = (name) => {
        if (isMobile()) {
            setTimeout(function () {
                let el = document.getElementsByName(name)
                if (el.length) {
                    el = el[0]
                    el.closest(".review-form__field").scrollIntoView()
                }
            }, 200)
        }
    }

    renderField = (field) => {

        switch (field.type) {
            case "checkbox":
                return <Checkbox
                     onChange={this.onChange}
                     name={field.name}
                     screen_size={this.props.screen_size}
                     disabled={field.disabled}
                     value={field.value}
                >{field.label}</Checkbox>
            case "input":
            case "textarea":
                return <Input
                    name={field.name}
                    mask={field.mask}
                    typeInput={field.type}
                    width={"100%"}
                    label={field.label}
                    value={field.value}
                    placeholder={field.placeholder}
                    onBlur={(e) => {this.validate(e.target.name)}}
                    onFocus={(e) => {this.clearError(e.target.name); this.scrollTo(e.target.name)}}
                    error_text={field.error}
                    {...field.type === "textarea" ? {onChangeTextArea: (val) => {this.onChange(val, field.name)}} : {onChange: this.onChangeInput}}
                />
            case "mark":
                return <Mark label={field.label}
                             name={field.name}
                             value={field.value}
                             onChange={this.onChange}
                />;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        let {
            is_by_phone,
            phone_number,
            is_by_email,
            email,
            comment,
            mark
        } = this.state;

        let data = new FormData();
        if (is_by_email.value) {
            data.append(is_by_email.name, 1);
            data.append(email.name, email.value);
        }
        if (is_by_phone.value) {
            data.append(is_by_phone.name, 1);
            data.append(phone_number.name, phone_number.value.replace(/\D/g, '').substr(1));
        }
        data.append(comment.name, comment.value)
        data.append(mark.name, mark.value)

        this.setState({isValid: false})
        this.props.onSubmit && this.props.onSubmit(data)
    }

    getTextCaption = () => {
        let cnt = 2000 - this.state.comment.value.length;

        return (cnt < 0 ?
            "Допустимое число символов превышено на "+(Math.abs(cnt)) :
            getCounter(cnt, "Остался", "Осталось", "Осталось") + " " + cnt) +
            " " + getCounter(cnt, "символ", "символа", "символов")
    }

    render(){

        let {
            screen_size,
            title = "Форма обратной связи",
            reviewStatus,
            reviewMessage,
            onClose = () => {},
            onReturn = () => {}
        } = this.props;

        let {
            is_by_phone,
            phone_number,
            is_by_email,
            email,
            comment,
            mark,
            isValid
        } = this.state;

        switch (reviewStatus) {
            case "success":
                return <SucceedRequestModal title={"Спасибо за Ваше обращение!"}
                                            caption={reviewMessage}
                                            screenSize={screen_size}
                                            onClick={onClose}
                />
            case "closing":
                return <Fragment>
                    <Heading size={"m"} style={{marginTop: 0}} screenSize={screen_size}>Вы уверены, что хотите закрыть окно?</Heading>
                    <Caption color={colors.gray}>Заполненные данные будут утеряны</Caption>
                    <div className={"review-btn-container"}>
                        <Button onClick={onClose}>Закрыть</Button>
                        <Button onClick={onReturn} view={"button_bordered"}>Отмена</Button>
                    </div>
                </Fragment>
            default:
                return <Fragment>
                    <Heading size={"m"} style={{marginTop: 0}} screen_size={screen_size}>{title}</Heading>
                    <form className={"review-form"} onSubmit={this.onSubmit}>
                        <div className="review-form__field">
                            <TextElem style={{marginBottom: spaces.xs, display: "block"}}>Выберите желаемый способ связи</TextElem>
                            <div className="review-form__field-double">
                                {this.renderField(is_by_email)}
                                {this.renderField(is_by_phone)}
                            </div>
                        </div>
                        <div className="review-form__field">
                            {is_by_email.value ? this.renderField(email) : null}
                        </div>
                        <div className="review-form__field">
                            {is_by_phone.value ? this.renderField(phone_number) : null}
                        </div>
                        <div className="review-form__field">
                            {this.renderField(comment)}
                            {comment.error ? null : <Caption style={{color: colors.lightGray_color}}>{this.getTextCaption()}</Caption>}
                        </div>
                        <div className="review-form__field">
                            {this.renderField(mark)}
                        </div>
                        <Button onClick={this.onSubmit} disabled={!isValid}>Отправить</Button>
                    </form>
                </Fragment>

        }

    }
}