import React, { Component } from 'react';
import TabSwitcher from './TabSwitcher';

import StateMouse from "../StateMouse";
import './tabs.css';

class Tabs extends StateMouse {

    constructor(props) {
        super(props);
        this.state = {
            // activeTab: this.props.activeTab ? this.props.activeTab : 0,
            scrollTabsLeft: 0,
            tabOffsetLeft: 20,
            flag: 0,
            activeTab: this.props.activeTab !== undefined ? this.props.activeTab : (props.typeSwitcher === "url" ? null : 0)
        };

        if (this.props.children){
            let n = this.props.children.length;
            this.masRef=[];
            for (let i = 0; i<n; i++){
                this.masRef[i] = React.createRef();
            }
        }
        this.containerTabsRef = React.createRef();
        this.masCoords = [];
    }

    findElem=(mas)=>{

        let min = mas[0];
        let index = 0;

        for(let i=1; i<mas.length; i++){
            if ( ((mas[i].top < min.top)&&(mas[i].top >= 0)) || (min.top < 0) ){
                min = mas[i];
                index = i
            }
        }

        if ((index > 0)&&( mas[index -1].bottom > document.documentElement.clientHeight*0.6 )){
            index = index -1;
            min = mas[index];
        }

        let scrollHeight=document.documentElement.scrollHeight;
        let clientHeight=document.documentElement.clientHeight;
        let height=scrollHeight;

       let scrollTop=window.pageYOffset + clientHeight;

        if (  (height - 150) < scrollTop  ){
            /*------riched--bottom--page-----*/
            index = mas.length - 1;
            min = mas[index]
        }
        return index;

    };

    onScrollTabs = ()=>{

        if (this.props.typeSwitcher === "scrollContent"){

            if (this.masRef[0].current){

                let n = this.masRef.length;
                for(let i =0; i<n; i++) {
                    this.masCoords[i] = { top: this.masRef[i].current.getBoundingClientRect().top,
                                          bottom: this.masRef[i].current.getBoundingClientRect().bottom
                                        };
                }
                /*---find--elem--on--screen--to--toggle--tab---*/
                let elemIndex = this.findElem(this.masCoords);
                /*---------------------------------------------*/
                this.onToggleTab(elemIndex);
            }
        }

    };

    componentDidMount() {
        window.addEventListener("wheel", this.onScrollTabs);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.containerTabsRef.current) {
            if (this.state.flag === 0) {
                let el = this.containerTabsRef.current.getElementsByClassName("inner-wrapper-items-list")[0].getElementsByClassName("tabSwitchItem")[nextState.activeTab ? nextState.activeTab : 0];
                this.setState({
                    flag: 1,
                    tabOffsetLeft: el ? el.offsetLeft : 0
                })
            }
        }
        return true
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.activeTab !== this.props.activeTab) {
            this.onToggleTab(nextProps.activeTab);
        }

    }

    onToggleTab = (i)=>{
        let tabBlock = this.props.tabBlock;
        if (tabBlock){
            this.setState({
                activeTab: i
            });
        }

        if(this.containerTabsRef.current) {
            if(this.containerTabsRef.current.getElementsByClassName("inner-wrapper-items-list")[0].getElementsByClassName("tabSwitchItem")[i]) {
                let left = this.containerTabsRef.current.getElementsByClassName("inner-wrapper-items-list")[0].getElementsByClassName("tabSwitchItem")[i].offsetLeft;
                if(this.state.tabOffsetLeft != left) {
                    this.setState({
                        tabOffsetLeft: left
                    });
                }
            }
        }
        this.props.onToggleTab && this.props.onToggleTab(i);
    };
    onToggleTabScroll = (i)=>{
        this.onToggleTab(i);

        let elem = this.masRef[i].current;

        if ((elem.getBoundingClientRect().top < 0) || (elem.getBoundingClientRect().top>25)){
            this.scrollPage(elem, 20);
        }
    };
    scrollBottom=(elem, h)=>{
        let body = document.body,
            html = document.documentElement;
        let height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

        let param = h;


        let heightTopTabs = 70;
        if (this.props.screen_size === "xs") heightTopTabs = 50;
        if(window.pageYOffset >= h) {
            param = heightTopTabs + 10;
        }

        window.scrollTo(0, window.pageYOffset + h);
        if ((elem.getBoundingClientRect().top > param )&&( height > window.pageYOffset + window.innerHeight + h )){
            setTimeout(()=>{this.scrollBottom(elem, h)}, 10);
        }
    };
    scrollTop=(elem, h)=>{

        let param = h;


        let heightTopTabs = 70;
        if (this.props.screen_size === "xs") heightTopTabs = 50;
        if(window.pageYOffset >= h) {
            param = heightTopTabs + 10;
        }


        window.scrollTo(0, window.pageYOffset - h);
        if ( (elem.getBoundingClientRect().top < param)&& (window.pageYOffset>h) ) {
            setTimeout(()=>{this.scrollTop(elem, h)}, 10);
        }
    };

    scrollPage = (elem, h)=>{

        let top = elem.getBoundingClientRect().top;
        let wh = window.innerHeight;

        if ((top>wh)|| ((top > 25)&&(top<wh))){
            this.scrollBottom(elem, h);
        }
        else if ((top<0)){
           this.scrollTop(elem, h);
        }
    };

    renderTabs = () => {

        let {
            items, // {title: "dd"} || {title: "dd", link: "/ddd"}
            typeSwitcher, //url, scrollContent, toggleContent
            styleSwitcher, //underlined, iconed,
            style
        } = this.props;

        let {
            activeTab
        } = this.state;

        let onToggleTabFunc = ()=>{};
        switch(typeSwitcher){
            case "scrollContent":{
                onToggleTabFunc= this.onToggleTabScroll;
            }
            break;
            case "url":{
                onToggleTabFunc = this.onToggleTab;
            }
            break;
            default:{
                onToggleTabFunc = this.onToggleTab;
            }
            break;
        }
        let params={
            typeSwitcher: typeSwitcher,
            styleSwitcher: styleSwitcher,
            activeTab: activeTab,
            onToggleTab: onToggleTabFunc,
            style
        };
        let result="";

        switch (typeSwitcher) {
            case "url":{
                result =
                    <div className={"tabs tabs__" + styleSwitcher} ref={this.containerTabsRef}>
                        <TabSwitcher tabOffsetLeft={this.state.tabOffsetLeft} screen_size={this.props.screen_size} items={items} {...params} />
                    </div>
            }
            break;
            case "scrollContent": {
                result = <div className={"tabs tabs__" + styleSwitcher} ref={this.containerTabsRef}>
                    <TabSwitcher  tabOffsetLeft={this.state.tabOffsetLeft}  screen_size={this.props.screen_size} items={items} {...params} />
                    <ul>
                        {this.props.children.map((tabContent, idx)=>
                            <li key={idx} ref={this.masRef[idx]} className={"tab tab__active"}>
                                {tabContent}
                            </li>
                        )}
                    </ul>
                </div>
            }
            break;
            default: {
                result = <div className={"tabs tabs__" + styleSwitcher}  ref={this.containerTabsRef}>
                    <TabSwitcher tabOffsetLeft={this.state.tabOffsetLeft} screen_size={this.props.screen_size} items={items} {...params}/>
                    <ul>
                        {this.props.children.map((tabContent, idx)=>
                            <li key={idx} className={"tab " + (activeTab===idx ? "tab__active" : "")}>
                                {tabContent}
                            </li>
                        )}
                    </ul>
                </div>
            }
                break;
        }
        return result;
    };
    render() {

        return (
            <React.Fragment>
                {this.renderTabs()}
            </React.Fragment>

        )
    }
}

export default Tabs;
/*
Examples:

<Tabs typeSwitcher={"toggleContent"} styleSwitcher={"underlined"} items={[{title: "Первый таб"}, {title: "Второй таб"}, {title: "Третий таб"}]}>
    <div>
        Контент первого таба
    </div>
    <div>
        Контент второго таба
    </div>
    <div>
        Контент третьего таба
    </div>
</Tabs>

<Tabs  typeSwitcher={"url"} styleSwitcher={"underlined"} items={[{title: "Первый таб", icon: {name: "list", width: "17px", height: "14px"}}, {title: "Второй таб", icon:{name: "list", width: "17px", height: "14px"}}, {title: "Третий таб", icon:{name: "list", width: "17px", height: "14px"}}]}></Tabs>

<Tabs typeSwitcher={"toggleContent"} styleSwitcher={"iconed"} items={[{title: "Первый таб", icon: {name: "list", width: "17px", height: "14px"}}, {title: "Второй таб", icon:{name: "list", width: "17px", height: "14px"}}, {title: "Третий таб", icon:{name: "list", width: "17px", height: "14px"}}]}>
      <div> 1 </div>
      <div> 2 </div>
      <div> 3 </div>
</Tabs>

<Tabs  typeSwitcher={"scrollContent"} styleSwitcher={"iconed"} items={[{title: "Первый таб", icon: {name: "list", width: "17px", height: "14px"}}, {title: "Второй таб", icon:{name: "list", width: "17px", height: "14px"}}, {title: "Третий таб", icon:{name: "list", width: "17px", height: "14px"}}]}>
    <div style={{height: "100px", border: "1px solid black"}}> 1 </div>
    <div style={{height: "150px", border: "1px solid blue"}}> 2 </div>
    <div style={{height: "200px", border: "1px solid red"}}> 3 </div>
</Tabs>

<Tabs typeSwitcher={"toggleContent"} styleSwitcher={"iconed"} items={[{title: "Первый таб", icon: {name: "list", width: "17px", height: "14px"}}, {title: "Второй таб", icon:{name: "list", width: "17px", height: "14px"}}, {title: "Третий таб", icon:{name: "list", width: "17px", height: "14px"}}]}>
   <div> Контент первого таба </div>
   <div> Контент второго таба </div>
   <div> Контент третьего таба</div>
</Tabs>

*/