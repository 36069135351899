import React, { Component } from 'react';
import TextElem from './TextElem';

export default class Caption extends Component {
    constructor(props){
        super(props);
    }

    render() {

          return (
              <TextElem {...this.props}  screen_size = {this.props.screen_size} type={"caption"}>{this.props.children}</TextElem>
        )
    }
}

