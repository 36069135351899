import React from 'react';

import Input from "../../../../../designSystem/elements/input/Input";
import Caption from "../../../../../designSystem/elements/text/Caption";
import {colors} from "../../../../../designSystem/DesignSystem";
import Button from "../../../../../designSystem/elements/button/Button";

import './Email.css';

const getStylesCaption = (screenSize) => {
    return {
        display: 'block',
        color: colors.gray,
        marginTop: '5px'
    }
};

const formEditEmail = (props) => {

    const { screenSize, onChangeEmail, error_text, onBlurEmail, onFocusEmail, onChangeEmailDataRequest, inputOnKeyDown } = props;

    return (
        <React.Fragment>
            <div className='ugsk-email-form-edit__input'>
                <Input
                    name={'confirmEmail'}
                    placeholder="email@mail.ru"
                    typeInput={"input"}
                    type="text"
                    label={"Email"}
                    onChange={onChangeEmail}
                    onBlur={(e)=>{onBlurEmail(e, 'confirmEmail')}}
                    onFocus={onFocusEmail}
                    inputOnKeyDown={(e)=>{inputOnKeyDown(e, 'confirmEmail')}}
                    error_text={error_text} />
                <Caption
                    className='ugsk-component-with-caption__caption'
                    style={getStylesCaption(screenSize)}
                >Вам будет необходимо подтвердить новый адрес</Caption>
            </div>
            <Button className='ugsk-email-form-edit__btn-ready' onClick={()=> {onChangeEmailDataRequest('lk')}} >Запросить ссылку</Button>
            <Button className='ugsk-email-form-edit__btn-cancel' onClick={props.closeFormEdit}  view={"button_bordered"}>Отменить</Button>
        </React.Fragment>
    );
};

export default formEditEmail