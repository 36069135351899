import {
    call,
    put,
    select,
    take,
    fork
} from 'redux-saga/effects';

import {fetchApi, checkErrors, errorMessage} from "../tools/api-helper";

export function PromotionsList(params) {
    return {
        type: "PROMOTIONS_LIST"
    }
}

export function* fetchPromotionsList(action) {
    try {
        const {status, data, error, pagination} = yield call(() => {
            return fetchApi("/promotions", {
                method: 'GET',
            }, true)
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 500));
        } else {
            yield put(checkErrors(action.type, {list: data, pagination}, status));
        }

    } catch (e) {
        yield put(checkErrors(action.type, {message: errorMessage}, 500));
    }
}