import React, { Component } from 'react';

import Icon from "../../designSystem/elements/icon/Icon";

import Heading from "../../designSystem/elements/text/Heading";
import TextElem from "../../designSystem/elements/text/TextElem";
import Button from '../../designSystem/elements/button/Button'

import {colors, spaces} from '../../designSystem/DesignSystem';

import './Error.css';

class Error extends Component {

    getStylesTitle = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            case 'm':
            case 's':
            case 'xs': {
                return {
                    marginTop: spaces.m
                }
            }
        }
    };

    getStylesText = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            case 'm':
            case 's':
            case 'xs': {
                return {
                    marginBottom: spaces.m
                }
            }
        }
    };

    render() {

        let { screenSize, title, text, buttonTitle, buttonAction } = this.props;

        return (
            <div className='page ugsk-page-error'>
                <div className='page_container'>
                    <div className='ugsk-page-error__container'>
                        <Icon
                            className='ugsk-page-error__icon'
                            name='triangle'
                            fill={ colors.red }
                        />
                        {title ? <Heading
                            className='ugsk-page-error__title'
                            size='l'
                            style={ this.getStylesTitle(screenSize) }
                            screen_size={screenSize}
                        >
                            {title}
                        </Heading> : null}
                        {text ? <TextElem
                            className='ugsk-page-error__text'
                            style={ this.getStylesText(screenSize) }
                            screen_size={screenSize}
                        >
                            {text}
                        </TextElem> : null}
                        {buttonTitle ? <Button
                            className='ugsk-page-error__btn'
                            view={"button_bordered"}
                            onClick={buttonAction ? buttonAction : () => {}}
                        >
                            {buttonTitle}
                        </Button> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default Error;