import React, { Component } from 'react';
import Heading from "../../designSystem/elements/text/Heading"
import {fontSizes} from "../../designSystem/DesignSystem";
import './recomModal.css';
import TextElem from "../../designSystem/elements/text/TextElem"
import Button from "../../designSystem/elements/button/Button";

export default class RecomModal extends Component{

    render() {

        let {
            item,
            screen_size,
            sendModalRecommendationOnclick
        } = this.props;

        let topBlockStyle= {
            marginLeft: "-40px",
            marginRight: "-40px",
            marginTop: "-1px",
            paddingLeft: "40px",
            backgroundPosition: "right center",
            backgroundSize: "contain"
        };

        let headingStyle = {
            marginTop: "54px",
            marginBottom: "55px",
        };

        let buttonsBlockStyle = {
            marginTop: "25px"
        };

        let headingSize = "l";

        if (screen_size === "s"){
            headingStyle = {
                width: "70%",
                marginTop: "27px",
                marginBottom: "32px",
            };
        }
        if (screen_size === "xs"){
            headingStyle = {
                width: "80%",
                marginTop: "39px",
                marginBottom: "40px"
            };
            headingSize = "m1";
            topBlockStyle = {
                marginTop: "-1px",
                marginLeft: "-20px",
                marginRight: "-20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                backgroundPosition: "right bottom",
                backgroundSize: "contain"
            }
        }

        return (
            <div className={"recom-modal"} >
                <div style={{
                    ...topBlockStyle,
                    backgroundColor: item.background,
                    backgroundImage: 'url("'+item.image+'")',
                    backgroundRepeat: "no-repeat",
                }}>
                    <Heading size={ headingSize } style={ headingStyle } dangerouslySetInnerHTML={{ __html: item.title }} />
                </div>

                <div className="recom-modal__description" dangerouslySetInnerHTML={{ __html: item.description }}/>

                <div className="row_buttons" style={ buttonsBlockStyle }>
                    <Button className={"more_button"} view={"button_filled"} onClick={sendModalRecommendationOnclick}>Получить предложение</Button>
                </div>

            </div>
        )
    }
}