import React from "react";

import {colors} from "../../DesignSystem";

export default function Preloader({shown = false, color = colors.blue_color}) {

    let styles = {
        width: "100%",
        height: "3px",
        position: "fixed",
        top: 0,
        left: 0,
        background: "transparent",
        zIndex: 2000
    };

    let blockStyles = {
        position: "absolute",
        top: 0,
        left: 0,
        height: "3px",
        width: 0,
        background: "linear-gradient(90deg, " + color + ", transparent)"
    };

    let activeBlockStyles = {
        width: "100%",
        transition: "width 2s"
    };

    return <div style={styles}><div style={shown ? {...blockStyles, ...activeBlockStyles} : blockStyles}/></div>

}