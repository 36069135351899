import React, { Component } from 'react';
import Button from '../../designSystem/elements/button/Button'
import '../../designSystem/common.css';
import { closeModal, openModal, isEmpty} from "../../designSystem/elements/modal/Modal";
import RenderModal from "../../designSystem/elements/modal/RenderModal"
import Input from "../../designSystem/elements/input/Input";
import Icon from "../../designSystem/elements/icon/Icon";

import './AuthorizationWithModals.css';
import Link from "react-router-dom";
import Caption from "../../designSystem/elements/text/Caption";
import {colors} from "../../designSystem/DesignSystem";

class AuthorizationWithModalsInputNewPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:{},
            modal2: {},
            modal3: {}
        };

        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
    }

    getStylesCaption = (screenSize) => {
        switch (screenSize) {
            case 'xl':
            case 'l':
            default: {
                return {
                    display: 'block',
                    color: colors.gray,
                    marginTop: '5px'
                }
            }
        }
    };

    render() {
        let flag = !isEmpty(this.state.modal);
        let flag2 = !isEmpty(this.state.modal2);
        let flag3 = !isEmpty(this.state.modal3);

        let screen_size = this.props.screenSize;

        let { screenSize } = this.props;

        return (
            <React.Fragment>
                <div className="page">
                    <div className="page_container">
                        <Button className={'ugsk-form-btn-open'} onClick={()=>{this.openModal("common", "222")}}>Восстановление пароля</Button>

                        {flag === true ?
                            <React.Fragment>
                                <RenderModal closeModal={this.closeModal} modal={this.state.modal} heading={"Восстановление пароля"} screen_size={this.props.screenSize}>
                                    <div className={'ugsk-form-new-password'}>
                                        <Input className={'ugsk-form-new-password__input'} typeInput={"input"} type="text" label={"Номер телефона"} placeholder={""}/>
                                        <Caption
                                            className='ugsk-form-new-password__caption'
                                            style={this.getStylesCaption(screenSize)}
                                        >Не менее 6 символов, без использования кириллических букв</Caption>
                                        <Input className={'ugsk-form-new-password__input'} typeInput={"input"} type="text" label={"Номер телефона"} placeholder={""}/>
                                        <Button className={'ugsk-form-new-password__btn'} onClick={()=>{this.openModal("common", "222")}}>Подтвердить</Button>
                                    </div>
                                </RenderModal> : ""}
                            </React.Fragment> : ""}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AuthorizationWithModalsInputNewPassword;