import React, { Component } from 'react';

const cache = {};

function requireAll (r) {
    r.keys().forEach(key => cache[key.substring(key.lastIndexOf("/") + 1, key.lastIndexOf('.'))] = r(key));
}

requireAll(require.context('./svg/', true, /\.svg$/));

export default class Icon extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <svg fill={this.props.fill}  className={'icon icon__' + this.props.name + ' ' + this.props.className} width = {this.props.width} height={this.props.height} style={this.props.style}>
                <use xlinkHref={cache[this.props.name].default.url}></use>
            </svg>

        )
    }
}

