import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {InputStyle, InputListStyle} from '../../DesignSystem';
import './inputList.css';


export default class Input extends Component {

    constructor(props) {
        super(props);

        this.state={
            clickedUlChild: -1
        }
    }

    onHover=(i)=>{
       this.props.changeState({"activeUlChild": i});
    };
    onMouseOut = ()=>{
        this.props.changeState({"activeUlChild": -1});
    };
    onMouseDown=(i)=>{
        this.setState({
            clickedUlChild: i
        })
    };
    onMouseUp=(i)=>{
        if (this.props.type != "scrollbarSelect") {
            this.props.changeState({"search": this.props.items[i].title});
            this.props.searchFunc(this.props.items[i]);
        }
        if (this.props.type === "scrollbarSelect") {
            this.props.selectFunc(this.props.activeUlChild);
        }
        this.props.changeState({mode: "normal_mode"});
        this.setState({
            clickedUlChild: -1
        })
    };

    stylesElem = {};

    render() {

        let {
            items,
            openSelect
        } = this.props;

        this.stylesElem={
            ulStyles: {
                borderRadius: InputStyle.default_attrs.borderRadius,
                boxShadow: InputStyle.default_attrs.listShadow,
                border: InputStyle.default_attrs.listBorder,
                fontSize: InputStyle.default_attrs.fontSize
            }

        };
        let active = this.props.activeUlChild;
        let click = this.state.clickedUlChild;

        return (
            <React.Fragment>
                {this.props.type==="scrollbarSelect" ?
                        <div className="input-list__wrapper" style={ this.props.style }>
                            <ul className="input-list__list" style={this.stylesElem.ulStyles}>
                                <Scrollbars style={openSelect ? {height: 200} : {height: 0}} >
                                {items.map((item, key) =>
                                    <li style={InputListStyle.itemStyle[((active===key)&&click!=key) ? "active" :( click===key? "selected" :"normal")]}  onMouseOver={()=>{this.onHover(key)}} onMouseOut={this.onMouseOut} onMouseDown={()=>{this.onMouseDown(key)}} onMouseUp={()=>{this.onMouseUp(key)}} className={active === key ? "input-list__item active" : "input-list-result__item "}  key={key}>
                                        <span dangerouslySetInnerHTML={{__html: item.title}}/>
                                    </li>
                                )}
                                </Scrollbars>
                            </ul>
                        </div>

                    :
                    <div className="input-list__wrapper" style={ this.props.style }>
                        <ul className="input-list__list" style={this.stylesElem.ulStyles}>
                            {items.map((item, key) =>
                                <li style={InputListStyle.itemStyle[((active===key)&&click!=key) ? "active" :( click===key? "selected" :"normal")]}  onMouseOver={()=>{this.onHover(key)}} onMouseOut={this.onMouseOut} onMouseDown={()=>{this.onMouseDown(key)}} onMouseUp={()=>{this.onMouseUp(key)}} className={active === key ? "input-list__item active" : "input-list-result__item "}  key={key}>
                                    <span dangerouslySetInnerHTML={{__html: item.title}}/>
                                </li>
                            )}
                        </ul>
                    </div>
                    }

            </React.Fragment>
        )

    }
}
