
import React, {Component} from "react";

import MarkerIcon from "./images/Clinic2.png";
import MarkerIcon2 from "./images/clinicHover.png";
import MarkerIcon3 from "./images/ClinicClicked2.png";
import MarkerIconDisabled from "./images/clinicDisabled.png";
import "./map.css";

import {Map, InfoWindow, Marker,GoogleApiWrapper} from 'google-maps-react';

const style = {
    width: '100%',
    height: '250px'
};

export class MapContainer extends Component {
    onMouseoverMarker(props, marker, e) {
        marker.setIcon(MarkerIcon2);
    }
    onMouseOutMarker(props, marker, e) {
        marker.setIcon(MarkerIcon);
    }
    onMouseDown(props, marker, e) {
        marker.setIcon(MarkerIcon3);
    }
    onMouseUp(props, marker, e) {
        marker.setIcon(MarkerIcon2);
    }
    render() {
        let icon = MarkerIcon;
        return (
            <div className="wrap-map" >
                    <Map google={this.props.google} width={this.props.width ? this.props.width : "100%"} height={this.props.height ? this.props.height : "240px"} zoom={8} initialCenter={{
                        lat: 54.748732,
                        lng: -7.457806
                    }}>

                        <Marker icon={icon} onMousedown={this.onMouseDown} onMouseup={this.onMouseUp} onMouseout={this.onMouseOutMarker} onMouseover={this.onMouseoverMarker} position={{lat: 54.748732, lng: -7.457806}}  />
                        <Marker icon={MarkerIconDisabled}  position={{lat: 54.548732, lng: -7.657806}}  />

                       { <InfoWindow >
                            <div>
                                <h1>Заголовок карты</h1>
                            </div>
                        </InfoWindow>}
                    </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyCtb0kWJJSSJIg_K1ug9HulRrnXhD6kE9A')
})(MapContainer)