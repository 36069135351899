import React, { Component } from 'react';
import InputList from './InputList';
import Icon from '../icon/Icon';
import Caption from '../text/Caption';
import { Resizable, ResizableBox } from 'react-resizable';

import {InputStyle} from '../../DesignSystem';
import './input.css';

export default class Input extends Component {

    constructor(props) {
        super(props);

        this.state={
            openedSearch: false,
            openedSelect: false,
            mode: props.error_text ? "error_mode" : "normal_mode",
            activeUlChild: -1,
            selectedItem: {title:""},
            resultTips: this.props.resultTips,
            selectItems: this.props.selectItems,
            valueSearch: props.value,
            errorTextFront: "Неверный номер телефона и/или пароль. Использовано три возможных попытки входа.",
            notificationsFalse: false
        };
        this.inpRef = React.createRef();
        this.inpRefSearch = React.createRef();
        this.inpRefSelect = React.createRef();
        this.inpRefTextarea = React.createRef();

        this.refSelectInput = React.createRef();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.error_text !== this.props.error_text || prevState.mode !== this.state.mode) {
            this.setState({mode: this.props.error_text ? "error_mode" : "normal_mode"});
            if (this.props.error_text) {
                const registerLinkElement = document.getElementsByClassName('go-register')[0];
                if (registerLinkElement) {
                    registerLinkElement.addEventListener('click', (e) => {e.preventDefault(); this.props.errorAction('signUp')})
                }

                const authLinkElement = document.getElementsByClassName('go-auth')[0];
                if (authLinkElement) {
                    authLinkElement.addEventListener('click', (e) => {e.preventDefault(); this.props.errorAction('formAuth')})
                }
            }
        }

        if (this.state.notificationsError && this.state.notificationsError.length !== 0) {
            this.state.mode === "normal_mode" ? this.setState({mode: "error_mode"}) : "";
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            resultTips: nextProps.resultTips,
            selectItems: nextProps.selectItems
        });
    }

    componentDidMount() {
        if(this.props.typeInput === "select") {
            document.addEventListener("click", ()=>{this.closeList(event, this.inpRefSelect)});
        }
        if(this.props.typeInput === "search") {
            document.addEventListener("click", ()=>{this.closeList(event, this.inpRefSearch)})
        }

    }

    closeList=(event, ref)=>{
        let node = event.target.parentNode;

        let elem = ref.current;

        let children = elem ? elem.getElementsByTagName('*') : [];

        let shouldClose = true;

        if (node == elem) {
            shouldClose = false
        }
        else {
            for (let i = 0; i < children.length; i++) {
                if (node == children[i]) {
                    shouldClose = false;
                    break;
                }
            }
        }
        if (shouldClose === true){
            this.closeListFunc()
        }
    };

    onHover= ()=>{
        if((this.state.mode != "active_mode")&&(this.state.mode != "error_mode")) {
            this.setState({
                mode: "hovered_mode"
            })
        }
    };
    onMouseOut = ()=>{
        if((this.state.mode != "active_mode")&&(this.state.mode != "error_mode")&&(this.state.openedSearch === false)) {
            this.setState({
                mode: "normal_mode"
            })
        }
    };

    onFocus = (e)=> {
        if (this.state.mode != "active_mode") {
            this.setState({
                mode: "active_mode"
            })
        }

        this.props.onFocus && this.props.onFocus(e);
    };

    onInputBlur=(e)=>{

        this.setState({
            mode: this.state.mode === "error_mode" ? "error_mode" : "normal_mode",
            valueInput: this.inpRef.current.value
        });

        this.props.onBlur && this.props.onBlur(e);
    };

    onTextareaBlur=(e)=>{
        this.setState({
            mode: "normal_mode",
            valueTextarea: this.inpRefTextarea.current.value
        })

        this.props.onBlur && this.props.onBlur(e);
    };


    handleSearchChange= (event)=>{
        let value = event.target.value;

        let open = false;
        if (this.inpRefSearch.current.getElementsByTagName('input')[0].value.length > 0) { // and tips are found
                    open = true;
                }
        this.setState({
            valueSearch: value,
            openedSearch: open
        });
        if (value.length) {
            this.props.searchItemsMethod && this.props.searchItemsMethod(value);
            /* do search: this.props.searchItemsMethod(value);*/
        }
    };

    searchFunc = (item)=>{
        /*--do search item--*/
        this.setState({
            openedSearch: false
        });
        this.setState({valueSearch: item ? item.title : ""});
        this.props.searchFunc && this.props.searchFunc(item);
    };


    searchOnKeyDown = (event) => {

        if (event) {
                if ((event.key === 'Enter') || (event.code === 'Enter') || (event.which == '13')) {
                    if (!(this.state.activeUlChild === -1)) {
                        this.setState({
                            valueSearch: this.state.resultTips[this.state.activeUlChild].title
                        });
                        this.searchFunc(this.state.resultTips[this.state.activeUlChild]);
                    } else  {
                        this.searchFunc({title: this.state.valueSearch});
                    }
                    this.setState({activeUlChild: -1});
                }

                if ((event.keyCode === 40) || (event.key === 'ArrowDown') || (event.code === 'ArrowDown') || (event.which === 40)) {
                    event.preventDefault();
                    this.setState((prevState) => {
                        let n = prevState.activeUlChild;
                        n++;
                        if ((n) >= this.state.resultTips.length) {
                            n = 0;
                        }
                        return ({
                            activeUlChild: n
                        })
                    });
                }

                if ((event.keyCode === 38) || (event.key === 'ArrowUp') || (event.code === 'ArrowUp') || (event.which === 38)) {
                    event.preventDefault();
                    this.setState((prevState) => {
                        let n = prevState.activeUlChild;
                        n--;
                        if ((n) < 0) {
                            n = this.state.resultTips.length - 1;
                        }
                        return ({
                            activeUlChild: n
                        })
                    });
                }

            }
    };

    searchOnClick = (event) => {
        if (event) {
            this.searchFunc({title: this.state.valueSearch});
        }
    }

    selectOnKeyDown = (event) =>
    {
        if ((event.key === 'Enter') || (event.code === 'Enter') || (event.which == '13')){
           this.selectFunc(this.state.activeUlChild);
        }

        if ((event.keyCode === 40) || (event.key === 'ArrowDown') || (event.code === 'ArrowDown') || (event.which === 40)) {
          event.preventDefault();
            this.setState( (prevState) => {
                let n = prevState.activeUlChild;
                n++;
                if ( (n) >= this.state.selectItems.length ){
                    n = 0;
                }
                return({
                    activeUlChild: n
                })
            });
        }

        if ((event.keyCode === 38) || (event.key === 'ArrowUp') || (event.code === 'ArrowUp') || (event.which === 38)) {
            event.preventDefault();
            this.setState( (prevState) => {
                let n = prevState.activeUlChild;
                n--;
                if ( (n) < 0 ){
                    n = this.state.selectItems.length - 1;
                }
                return({
                    activeUlChild: n
                })
            });
        }
    };

    closeListFunc=()=>{
        this.setState({
            openedSearch: false,
            openedSelect: false,
            activeUlChild: -1,
            mode: "normal_mode",
            valueSearch: this.props.value || ""
        });
    };

    toggleSelectFunc=()=>{
        this.setState((prevState)=>{
            this.refSelectInput.current.blur();
            let mode = "active_mode";
            if (prevState.mode === "active_mode") mode = "hovered_mode";
            return({openedSelect: !prevState.openedSelect, mode: mode})
        });
    };

    selectFunc=(i)=>{
      this.setState({
          selectedItem: this.state.selectItems[i],
          openedSelect: false,
          activeUlChild: -1
      })
    };

    changeState = (obj)=>{
        this.setState(obj);
    };

    onChange = (e) => {

        let {
            mask,
            onChange
        } = this.props;

        if (mask) {
            e.target.value = mask(e.target.value);
        }

        onChange && onChange(e);
    };

    inputEvents={
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onFocus: this.onFocus,
        onBlur: this.onInputBlur,
        onChange: this.onChange,
        onKeyDown: !!this.props.inputOnKeyDown ? this.props.inputOnKeyDown : ()=>{}
    };
    searchEvents={
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onFocus: this.onFocus,
        onChange: this.handleSearchChange,
        onKeyDown: this.searchOnKeyDown
    };
    selectEvents={
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onClick: this.toggleSelectFunc,
        onKeyDown: this.selectOnKeyDown
    };
    textareaEvents= {
        onMouseOver : this.onHover,
        onMouseOut: this.onMouseOut,
        onFocus: this.onFocus,
        onBlur: this.onTextareaBlur
    };

    renderInput=()=>{
        let elem = "";
        let typeInput=this.props.typeInput;
        if (!typeInput) typeInput = "input";
        let layout = this.props.layout;
        if (!layout) layout = "layout_default";
        let errorStyle = this.props.errorStyle || {};
        let addressInput = this.props.addressInput;

        switch(typeInput){
            case "input":{

                let InputProps = {
                    id: this.props.id ? this.props.id : null,
                    type: this.props.type,
                    placeholder: this.props.placeholder,
                    className: "input input__type_" + this.props.type + " " + this.props.className,
                    value: !!addressInput ? this.props.value.residenceAddress : this.props.value,
                    name: this.props.name ? this.props.name : null,
                };
                if (this.props.inputmode) {
                    InputProps.inputmode = this.props.inputmode;
                }
                let labelStyles=Object.assign({}, {color: InputStyle[layout][this.state.mode].labelColor}, {fontSize: InputStyle.default_attrs.fontSize});
                let error_text = this.props.error_text;
                if (!error_text) error_text = "Ошибка";
                let stylesElem = Object.assign(this.props.disabled ? {opacity: .7} : {},InputStyle.default_attrs, InputStyle[layout][this.state.mode]);
                const errorHtml = { __html: error_text}

                elem = <div className="input-wrap" style={{width: this.props.width ? this.props.width : "auto", maxWidth: "100%", ...this.props.style}}>
                    {this.props.label ?
                        <div className="label" style={labelStyles}>{this.props.label}</div>
                        : ""}
                    <input {...InputProps} {...this.inputEvents} style={stylesElem} ref={this.inpRef}/>
                    {this.state.mode==="error_mode" ?
                        <Caption className="input__error" style={{color: InputStyle[layout].error_mode.errColor, ...errorStyle}}>
                            <div dangerouslySetInnerHTML={errorHtml} />
                        </Caption> : ""}
                </div>
            }
            break;
            case "search":{
                let stylesElem = Object.assign({},InputStyle.default_attrs, InputStyle[layout][this.state.mode], {paddingRight: "35px"}, {paddingTop: "8px"}, {paddingBottom: "8px"});
                elem =  <div className="input-wrap" onMouseOver={this.searchEvents.onMouseOver} onMouseOut={this.searchEvents.onMouseOut} style={this.props.style || {}}>
                            <div className="input__search_row" ref={this.inpRefSearch} >
                                <input className={"Input__search"} type={"text"} placeholder={this.props.placeholder} value={this.state.valueSearch} autoComplete="new-password" onFocus={this.searchEvents.onFocus} onChange={this.searchEvents.onChange} onKeyDown={this.searchEvents.onKeyDown} style={stylesElem} />
                                <div className={"icon__search_wrap"} onClick={this.searchOnClick}>
                                    <Icon name={"search"} width="20px" height="20px" fill={InputStyle[layout][this.state.mode].fillSearch}/>
                                </div>
                            </div>

                    {(this.state.openedSearch && this.state.resultTips) ?  (this.state.resultTips.length>0 ?
                            <InputList items={this.state.resultTips} activeUlChild={this.state.activeUlChild} searchFunc={this.searchFunc} changeState={this.changeState}  closeListFunc={this.closeListFunc}/> : ""
                    )  : ""

                    }
                        </div>
            }
            break;
            case "select":{
                let stylesElem = Object.assign({},InputStyle.default_attrs, InputStyle[layout][this.state.mode]);
                let labelStyles=Object.assign({}, {color: InputStyle[layout][this.state.mode].labelColor}, {fontSize: InputStyle.default_attrs.fontSize});
                elem =  <div className="input-wrap">
                    {this.props.label ?
                        <div className="label" style={labelStyles}>{this.props.label}</div>
                        : ""}
                    <div className="input__select_row" tabIndex={0} onMouseOver={this.selectEvents.onMouseOver} onMouseOut={this.selectEvents.onMouseOut} onClick={this.selectEvents.onClick} onKeyDown={this.selectEvents.onKeyDown}  ref={this.inpRefSelect}>
                        <input type={"text"} className={"Input__select"} value={this.state.selectedItem.title} readonly="true" autocomplete="new-password"  onFocus={()=>{ }}  style={stylesElem} ref={this.refSelectInput}/>
                        <div className={"icon__select"}  onFocus={()=>{}} onMouseDown={()=>{}} style={ this.state.openedSelect ? InputStyle[layout].selectIcon : {} } >
                            <Icon name={"arrSelect"} onFocus={()=>{}} width="10px" height="15px" fill={InputStyle[layout][this.state.mode].fillSelect}  />
                        </div>
                    </div>
                    <InputList openSelect={ this.state.openedSelect } style={ this.state.openedSelect ? { height: '212px' } : {overflow: 'auto'} } type={"scrollbarSelect"} items={this.state.selectItems} activeUlChild={this.state.activeUlChild} selectFunc={this.selectFunc} changeState={this.changeState}  closeListFunc={this.closeListFunc}/>
                </div>
            }
            break;
            case "textarea":{
                let TextareaProps = {
                    id: !!this.props.id ? this.props.id : null,
                    placeholder: this.props.placeholder,
                    name: this.props.name,
                    value: this.props.value,
                    className: "input input__type_textarea",
                    maxlength: this.props.textareaMaxLength ? this.props.textareaMaxLength : "",
                };
                let stylesElem = Object.assign({},InputStyle.default_attrs, InputStyle[layout][this.state.mode], {"resize": "none" },  {height: this.props.style && this.props.style.height ?  this.props.style.height : "100%"});
                let labelStyles=Object.assign({}, {color: InputStyle[layout][this.state.mode].labelColor}, {fontSize: InputStyle.default_attrs.fontSize});
                let { onChangeTextArea = (e) => {} } = this.props;

                elem=
                    <div className={"textarea-wrap input-wrap"} style={{...this.props.style}}>
                        {this.props.label ?
                            <div className="label" style={labelStyles}>{this.props.label}</div>
                            : ""}
                        {this.props.resizable ?
                            <div className="resize-wrap" {...this.textareaEvents}>
                                <ResizableBox width={240} height={60} minConstraints={[100, 60]}
                                              maxConstraints={[500, 200]}>
                                    <textarea  {...TextareaProps} style={stylesElem}
                                               ref={this.inpRefTextarea}> </textarea>
                                </ResizableBox>
                            </div>
                            :
                            <textarea  {...this.textareaEvents} {...TextareaProps} style={Object.assign({}, stylesElem, {height: this.props.height})}
                                       ref={this.inpRefTextarea} onChange={(e) => {onChangeTextArea(e.target.value)}}/>
                        }
                        {this.state.mode==="error_mode" ?
                            <Caption className="input__error" style={{color: InputStyle[layout].error_mode.errColor, ...errorStyle}}>
                                <div dangerouslySetInnerHTML={{ __html: this.props.error_text}} />
                            </Caption> : ""}
                    </div>
            }
            break;

        }
        return elem;
    };

    render() {
        return (
            <React.Fragment>
                {this.renderInput()}
            </React.Fragment>
        )
    }
}



