import React from "react";
import TextElem from "../../designSystem/elements/text/TextElem";
import {colors, spaces} from "../../designSystem/DesignSystem";
import Link from "../../designSystem/elements/links/Link";

export default function Phones({phone, additionalPhone, className}) {
    return <div style={{display: "flex"}} className={className}>
        <div style={{minWidth: "60px", marginBottom: spaces.xs}}>
            <TextElem color={colors.lightGray_color} className="phone_sign">Россия</TextElem>
            <TextElem color={colors.lightGray_color} className="phone_sign">Москва</TextElem>
        </div>
        <div>
            <Link style={{color: colors.text_color, borderBottom: 'none'}} className="phone" func={() => {}} to={"tel:"+phone.replace(/-|\s/g,"")}>{phone}</Link>
            <Link style={{color: colors.text_color, borderBottom: 'none'}} className="phone" func={() => {}} to={"tel:"+additionalPhone.replace(/-|\s/g,"")}>{additionalPhone}</Link>
        </div>
    </div>
}