import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';
import Icon from "../../../designSystem/elements/icon/Icon"
import {colors} from "../../DesignSystem";

export function openModal(type, data) {

    this.setState((prevState)=>{
        let s = {...prevState};
        s.modal = {type: type, data: data };
        return s;
    })
}

export function closeModal(){
    if (document.getElementById("modalStack").getElementsByClassName("modalItem").length === 1){
        document.getElementById("modalStack").classList.add("modalStack-close");
        document.getElementById("overlay").classList.add("notVisible");
        document.getElementById("App").classList.add("notBlur");

        setTimeout(() => {
            document.body.classList.remove("no-scroll");
            document.getElementById("modalStack").classList.remove("opened");
            document.getElementById("overlay").classList.remove("visible");
            document.getElementById("App").classList.remove("blur");
            document.getElementById("overlay").classList.remove("notVisible");
            document.getElementById("App").classList.remove("notBlur");

        }, 600)
    }
    else {
        setTimeout(() => {
            try {
                document.getElementById("modalStack").getElementsByClassName("modalItem")[ document.getElementById("modalStack").getElementsByClassName("modalItem").length -2].classList.remove("no-scroll", "blur");
                document.getElementById("modalStack").getElementsByClassName("modalItem")[ document.getElementById("modalStack").getElementsByClassName("modalItem").length -2].getElementsByClassName("modal__overlay")[0].classList.remove("visible");
            } catch (e) { }
        }, 600)

    }

    setTimeout(() => {
        if (this) {
            this.setState({modal: {}});
            this.setState({modalAdvert: {}});
            this.setState({serviceModal: {}});
            this.setState({sendModalRecommendation: false});
        }
        document.getElementById("modalStack").classList.remove("modalStack-close");
    }, 600)
}

export function isEmpty(obj){
        for (let key in obj) {
            return false;
        }
        return true;
}

export default class Modal extends Component {

    constructor(props){
        super(props);

        this.state = {
            scrollable: false
        }

        this.modalWindow = React.createRef()
    }

    checkNeedScroll = () => {
        let scrollable = window.innerHeight < this.modalWindow.current.clientHeight;
        if (this.modalWindow.current && this.state.scrollable !== scrollable) {
            this.setState({scrollable})
        }
    }

    componentDidMount(){
        if (typeof document !== 'undefined') {
            let numberInStack = document.getElementById("modalStack").getElementsByClassName("modalItem").length;
            if (numberInStack > 1) {
                document.getElementById("modalStack").getElementsByClassName("modalItem")[0].classList.add( "blur");
                document.getElementById("modalStack").getElementsByClassName("modalItem")[0].getElementsByClassName("modal__overlay")[0].classList.add("visible");
            }
            else{
              /*  this.hideScroll();*/
            }

            this.checkNeedScroll()
            window.addEventListener('resize', this.checkNeedScroll)
        }
    }

    componentDidUpdate() {
        this.checkNeedScroll()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkNeedScroll)
    }

    render() {

        if (typeof document !== 'undefined') {
            let {
                wrapperClassName = "u-modal-wrapper",
                innerClassName = "u-modal-inner",
                closeBtnClassName = "modal__close",
                children,

            } = this.props;

            let className = this.props.className + " modalWindow";

            let numberInStack = document.getElementById("modalStack").getElementsByClassName("modalItem").length;

            if (numberInStack===0){
                document.body.classList.add("no-scroll");
                document.getElementById("modalStack").classList.add("opened");
                document.getElementById("overlay").classList.add("visible");
                document.getElementById("App").classList.add("blur");
            }

            this.closeProps={};

            if(this.props.type === "rightModal"){
                if (this.props.screen_size === "xs") {
                    this.closeProps = {
                        fill: colors.gray,
                        style: {top: "10px", right: "10px"}
                    }
                }
                else{
                    this.closeProps = {
                        fill: colors.white,
                        style: {top: "22px", left: "-45px"}
                    }
                }
            }
            else if(this.props.type === "common"){
                    if (this.props.screen_size === "xs") {
                        this.closeProps = {
                            fill: colors.gray,
                            style: {top: "13px", right: "13px"}
                        }
                    }
                    else{
                        this.closeProps = {
                            fill: colors.white_opacity7,
                            style: {top: "0px", right: "-22px"}
                        }
                    }
                }

            return ReactDOM.createPortal(
                <div className={"modalItem modalItem__" + this.props.type + (this.state.scrollable ? " modalItem__scrollable" : "")} onClick={this.close}>
                    {/*<div className={"modal__overlay"} style={{backgroundColor: colors.middle_blue_opacity8}}></div>*/}
                    <div className="modal__overlay" onClick={this.props.overlayClick ? this.props.overlayClick : this.props.closeModal} />
                    <div ref={this.modalWindow}  className={className}>
                        <div className={wrapperClassName}>
                            <div className={innerClassName}>
                                {children}
                            </div>
                            <div onClick={this.props.closeModal} style={this.closeProps.style} className={"close_modal close_modal__"+ this.props.type}><Icon name={"close"} width={"15px"} height={"15px"} fill={this.closeProps.fill}/></div>
                        </div>
                    </div>
                </div>, document.getElementById('modalStack'));
        } else return "nothing to return";
    }
}
