import React from 'react';

import Input from "../../../../../designSystem/elements/input/Input";
import Caption from "../../../../../designSystem/elements/text/Caption";
import {colors} from "../../../../../designSystem/DesignSystem";
import Button from "../../../../../designSystem/elements/button/Button";
import {phoneMask} from "../../../../../tools/mask-helper";

import './PhoneGetCode.css';

const getStylesCaption = (screenSize) => {

    return {
        display: 'block',
        color: colors.gray,
        marginTop: '5px'
    }
};

const getStylesBtnGetCode = (screenSize) => {

    return {
        padding: '5px 10px'
    }
};

const getStylesInput = (screenSize) => {

    return {
        width: '100%'
    }
};

const formEditPhone = (props) => {

    const { screenSize, handlerGetCodeBtn, onChangePhone, inputPhoneOnKeyDown, errorTxtPhoneValidation, onBlurPhone, requestAction, closeFormEdit, setActionDefault, clearChangePhoneData } = props;

    let label = "Номер телефона"
    if (requestAction === 'code-send') {
        label = "Новый номер телефона, который Вы хотите установить";
    } else if (requestAction === 'code-send-old') {
        label = "Старый номер телефона, по которому Вы входили в личный кабинет"
    }

    return (
        <React.Fragment>
            <div className='ugsk-phone-form-edit__container'>
                <div className={'ugsk-phone-form-edit__actions_container'+(requestAction === 'code-send-old' ? ' ugsk-phone-form-edit_old__actions_container' : '')}>
                    <div className='ugsk-phone-form-edit__input'>
                        <Input
                            style={ getStylesInput(screenSize) }
                            type='text'
                            label={label}
                            mask={phoneMask}
                            placeholder="+7 (___) ___-__-__"
                            onChange={onChangePhone}
                            name={'confirmPhoneNumber'}
                            inputOnKeyDown={(e)=>{inputPhoneOnKeyDown(e, 'confirmPhoneNumber')}}
                            error_text={errorTxtPhoneValidation}
                            onBlur={(e)=>{onBlurPhone(e, 'onBlurPhone')}}
                            />
                    </div>
                    <div className='ugsk-phone-form-edit__btn-with-caption'>
                        <Button
                            className='ugsk-phone-form-edit__btn-get-code'
                            onClick={()=> {handlerGetCodeBtn(requestAction);}}
                            style={ getStylesBtnGetCode(screenSize) }>Запросить SMS-код</Button>
                        <Caption
                            className='ugsk-phone-form-edit__caption'
                            style={ getStylesCaption(screenSize) }
                        >Вы получите 6-значный SMS-код для подтверждения</Caption>
                    </div>
                    <Button className='ugsk-phone-form-edit__btn-cancel' view={"button_bordered"} onClick={()=>{closeFormEdit(); setActionDefault('code-send'); clearChangePhoneData();}}>Отменить</Button>
                </div>
                <Caption className='ugsk-phone-form-edit__caption' style={ getStylesCaption(screenSize) }>
                    <p>Для изменения, пожалуйста, подтвердите:</p>
                    <p><span style={{marginRight: '8px'}}>•</span>новый номер телефона, который Вы хотите установить</p>
                    <p><span style={{marginRight: '8px'}}>•</span>старый номер телефона, по которому Вы входили в личный кабинет</p>
                </Caption>
            </div>
        </React.Fragment>
    );
};

export default formEditPhone;