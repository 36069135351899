import React, { Component } from 'react';

import Icon from '../../designSystem/elements/icon/Icon';
import FormAuthorization from '../../components/Authorization/FormAuthorization/FormAuthorization';

import {colors} from '../../designSystem/DesignSystem';

import AuthorizationImg from '../../images/authorization-page.png';

import './Authorization.css';
import SignUp from "./SignUp/SignUp";
import PasswordRecovery from "./PasswordRecovery/PasswordRecovery";
import SMSCode from "./SMSCode/SMSCode";
import CreateNewPassword from "./CreateNewPassword/CreateNewPassword";
import TosComponent from "../TosComponent/TosComponent";

class Authorization extends Component {

    render() {
        const sizes = ['xs', 's', 'm', 'l', 'xl', 'xxl'];
        let componentToRender = '';
        let elementsPositionClassName = "";

        let {
            rememberMeAction,
            registerErrors,
            confirmedPhoneNumber,
            passwordErrors,
            confirmationErrors,
            onCheckboxChange,
            passwordValue,
            firstPasswordRule,
            secondPasswordRule,
            thirdPasswordRule,
            type,
            screenSize,
            onChange,
            onSubmit,
            onBlur,
            onFocus,
            errors,
            logoLink,
            notificationsError,
            onClick,
            progressBarCount,
            phoneFormTitle
        } = this.props;

        switch (type) {
            case 'formAuth':
                componentToRender = <FormAuthorization
                    rememberMeAction={rememberMeAction}
                    onCheckboxChange={onCheckboxChange}
                    onClick={onClick}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                    errors={errors}
                    notificationsError={notificationsError}
                />
                elementsPositionClassName = "ugsk-page-authorization__form_auth"
                break;
            case 'signUp':
                componentToRender = <SignUp
                    onCheckboxChange={onCheckboxChange}
                    errors={registerErrors}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onClick={onClick}
                />
                elementsPositionClassName = "ugsk-page-authorization__sign_up"
                break;
            case 'forgotPassword':
                componentToRender = <PasswordRecovery
                    onCheckboxChange={onCheckboxChange}
                    onSubmit={onSubmit}
                    errors={confirmationErrors}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    onClick={onClick}
                    title={phoneFormTitle}
                />
                elementsPositionClassName = "ugsk-page-authorization__password_recovery"
                break;
            case 'SMSCode':
                componentToRender = <SMSCode
                    onBlur={onBlur}
                    confirmedPhoneNumber={confirmedPhoneNumber}
                    errors={confirmationErrors}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    onClick={onClick}
                />
                elementsPositionClassName = "ugsk-page-authorization__sms_code"
                break;
            case "createNewPassword":
                componentToRender = <CreateNewPassword
                    errors={passwordErrors}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                    passwordValue={passwordValue}
                    firstPasswordRule={firstPasswordRule}
                    secondPasswordRule={secondPasswordRule}
                    thirdPasswordRule={thirdPasswordRule}
                    progressBarCount={progressBarCount}
                    onClick={onClick}
                />
                elementsPositionClassName = "ugsk-page-authorization__create_password"
                break;
            default:
                componentToRender = <FormAuthorization
                    onCheckboxChange={onCheckboxChange}
                    rememberMeAction={rememberMeAction}
                    onClick={onClick}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onSubmit={onSubmit}
                    errors={errors}
                    notificationsError={notificationsError}
                />;
                elementsPositionClassName = "ugsk-page-authorization__form_auth"
        }

        return (
            <div className='page ugsk-page-authorization'>
                <div className={`ugsk-page-authorization__left ${elementsPositionClassName}`}>
                    <div
                        className='ugsk-page-authorization__form'>
                            {screenSize === 'xs' || (type !== 'formAuth' && sizes.includes(screenSize)) ? null :
                            logoLink ? <a className="ugsk-page-authorization__logo_link" style={{width: "165px", height: "49px"}} href={logoLink} target="_blank">
                                <Icon
                                    style={{width: "165px"}}
                                    name='logougory'
                                    className='ugsk-page-authorization__logo'
                                    fill={ screenSize === 'xs' ? colors.white : colors.middle_blue }
                                />
                            </a> : <Icon
                                name='logougory'
                                className='ugsk-page-authorization__logo'
                                fill={ screenSize === 'xs' ? colors.white : colors.middle_blue }
                            />}
                        { componentToRender }
                    </div>
                    <div className='ugsk-page-authorization__tos__container'>
                        <TosComponent />
                    </div>
                </div>
                <div
                    className="ugsk-page-authorization__right"
                    style={{
                        background: `linear-gradient(0deg, rgba(12, 19, 45, 0.15), rgba(12, 19, 45, 0.15)), url(${ AuthorizationImg })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                    {screenSize === 'xs' || type === 'formAuth' ? null :
                        logoLink ? <a style={{width: "198px", height: "60px"}}
                                      className="ugsk-page-authorization__logo_link ugsk-page-authorization__logo_absolute"
                                      href={logoLink} target="_blank">
                            <Icon
                                name='logougory'
                                style={{width: "198px"}}
                                className='ugsk-page-authorization__logo'
                                fill={colors.white}
                            />
                        </a> : <Icon
                            name='logougory'
                            className='ugsk-page-authorization__logo'
                            fill={colors.white}
                        />}
                        {screenSize !== 'xs' ? null :
                        logoLink ? <a style={{width: "198px", height: "60px"}}
                                      className="ugsk-page-authorization__logo_link ugsk-page-authorization__logo_mobile"
                                      href={logoLink} target="_blank">
                            <Icon
                                name='logougory'
                                style={{width: "198px"}}
                                className='ugsk-page-authorization__logo'
                                fill={colors.white}
                            />
                        </a> : <Icon
                            name='logougory'
                            className='ugsk-page-authorization__logo'
                            fill={colors.white}
                        />}
                </div>
            </div>
        )
    }
}

export default Authorization;