import React, { Component } from 'react';
import './AuthActions.css';
import Button from "../../../designSystem/elements/button/Button";

class AuthActions extends Component {
    render () {
        const signUpClassName = "ugsk-auth-actions__buttons__container_sign_up"
        const signUpClassNameBtn = "ugsk-auth-actions__btn__container_sign_up"
        const {
            onClick,
            typeForBlueBtn,
            withReverse,
            type,
            whiteBtnLabel,
            blueBtnLabel,
            onSubmit = (e) => {e.preventDefault()},
        } = this.props;

        return (
            <div>
                <div className={withReverse ? signUpClassName : "ugsk-auth-actions__buttons__container"}>
                    <div className={withReverse ? signUpClassNameBtn : "ugsk-auth-actions__btn__container"}>
                        <Button onClick={ (ev) => onSubmit(ev, typeForBlueBtn)}>
                            {blueBtnLabel}
                        </Button>
                    </div>
                    <div className={withReverse? signUpClassNameBtn : "ugsk-auth-actions__btn__container"}>
                        <Button className={whiteBtnLabel === 'Регистрация' ? "ugsk-auth-actions__btn": null} onClick={() => onClick(type)} view={"button_bordered"}>
                            {whiteBtnLabel}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthActions;