import React, {Component} from "react";
import {connect} from "react-redux";
import {policyDownload, policyDownloadMemo} from "../actions/polices";
import Preloader from "../designSystem/elements/preloader/Preloader";
import {parseParams} from "../tools/form-helper";

class PdfViewer extends Component {

    componentWillMount(){
        let paramsObject = parseParams(window.location.search);
        if (paramsObject.memo) {
            this.props.downloadMemo(paramsObject.number);
        } else {
            this.props.download(paramsObject.number);
        }

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.contentLink != nextProps.contentLink && nextProps.contentLink) {
            location.href = nextProps.contentLink;
        }
        if (this.props.contentMemoLink != nextProps.contentMemoLink && nextProps.contentMemoLink) {
            location.href = nextProps.contentMemoLink;
        }
    }

    render(){
        return <div className="page" ><Preloader shown={true}/></div>;
    }
}

export default connect(
    state => ({
        contentLink: state.common.contentLink,
        contentMemoLink: state.common.contentMemoLink
    }),
    dispatch => () => {
        return {
            download: (number) => dispatch(policyDownload(number)),
            downloadMemo: (number) => dispatch(policyDownloadMemo(number))
        }
    }
)(PdfViewer)
