
export function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1,
        path: '/',
        domain: window.location.hostname
    })
}

export function isMobile() { return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) }

export function passwordCheckHandler(value) {
    const regExpFirstRule = /^(?=.*[a-z])[a-zA-Z\d]{6,14}$/;
    const regExpSecondRule = /^(?=.*[A-Z])[a-zA-Z\d]{6,14}$/;
    const regExpThirdRule = /^(?=.*\d)[a-zA-Z\d]{6,14}$/;

    this.setState({
        firstPasswordRule: regExpFirstRule.test(value),
        secondPasswordRule: regExpSecondRule.test(value),
        thirdPasswordRule: regExpThirdRule.test(value),
    })
}

export function getProgressBarCount() {
    let count = 0;

    if (this.state.firstPasswordRule) count += 1;
    if (this.state.secondPasswordRule) count += 1;
    if (this.state.thirdPasswordRule) count += 1;

    return count;
}

export function getCounter(cnt, one, two, many)
{
    switch (cnt % 10) {
        case 1:
            return cnt % 100 === 11 ? many : one;
        case 2:
        case 3:
        case 4:
            return [12, 13, 14].indexOf( cnt % 100) !== -1 ? many : two;
        default:
            return many;
    }
}