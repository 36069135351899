import React, {Component} from 'react';
import Heading from "../../designSystem/elements/text/Heading";
import Cards from "../../designSystem/elements/cards/Cards";
import {spaces} from "../../designSystem/DesignSystem";
import Tabs from "../../designSystem/elements/tabs/Tabs";
import {connect} from "react-redux";
import {PromotionsList} from "../../actions/promotions";
import {clearSendPolicy, policyDownload, policyDownloadMemo, policySend} from "../../actions/polices";
import {initGA, logPageView} from "../../containers/Polices";

import {isAndroid} from 'react-device-detect';

export default class Polices extends Component {

    constructor(props) {
        super(props);
        this.mobileTabs = [
            {title: props.title, icon: {name: "file-text", width: "17px", height: "14px"}}
        ];

        if (props.recommendations && props.recommendations.length) {
            // this.mobileTabs.append({title: props.recommendationsTitle, icon: {name: "apps", width: "17px", height: "14px"}});
            this.mobileTabs.push({
                title: props.recommendationsTitle,
                icon: {name: "apps", width: "17px", height: "14px"}
            });
        }

        this.tabStyles = {
            zIndex: 1000,
        };

    }

    render() {

        let {
            infoNewTravelPolis,
            title,
            bgImage,
            polices,
            screen_size,
            infoNewPolis,
            recommendationsTitle,
            // recommendations,
            infoNewAdvert,
            children,
            recommendationsOnclick,
            recommendationsModalOnclick,
            recommdClass,
            additionalServices
        } = this.props;

        let recommendations = this.props.recommendations ? this.props.recommendations.list : [];
        // let recommendations = this.state.recommendations;

        let shownRecommendations = [...recommendations];
        // let shownRecommendations = recommendations.slice(0, 8);
        if (screen_size === "xs") {
            // shownRecommendations.splice(3);
        }
        let content = [

            <div className={isAndroid === true ?  "polices android" : "polices"} style={{
                backgroundImage: 'url("' + bgImage + '")',
                backgroundSize: 'cover!important',
                WebkitBackgroundSize : 'cover',
                backgroundRepeat: 'no-repeat',
                paddingTop: screen_size === "xs" ? "0px" : "1px",
                paddingBottom: screen_size === "xs" ? "0px" : "calc(" + spaces.l + " - " + spaces.m + ")",
                // paddingBottom:"0px",
                backgroundAttachment: "fixed"
            }} key="policies">
                <div className="page_container components">
                    <Heading size="l" screen_size={screen_size} color={"#fff"}
                             style={{marginTop: '0'}}
                             dangerouslySetInnerHTML={{__html: title}}/>
                    <div className="row__cards" style={{background: 'inherit'}}>
                        {polices.map((item) => <Cards key={"policy-" + item.id} type="polis" info={item}
                                                      layout={"layout_colored"} screen_size={screen_size}/>)}
                        {additionalServices.map((item) => <Cards key={item.alias} type="polis" info={item.info} layout={"layout_colored"}
                                                                 screen_size={screen_size}/>)}
                        <Cards type="newPolis" info={infoNewPolis} layout={"layout_colored"}
                               screen_size={screen_size}/>
                        <Cards type="newPolis" info={infoNewTravelPolis} layout={"layout_colored"}
                               screen_size={screen_size}/>
                    </div>
                </div>
            </div>,

            shownRecommendations.length ?
                <div className="recommendations"
                     style={{paddingBottom: "calc(" + spaces.l + " - " + spaces.m + ")"}}
                     key="recommendations">
                    <div className="page_container">
                        <Heading size="l" screen_size={screen_size}
                                 dangerouslySetInnerHTML={{__html: recommendationsTitle}}/>
                        <div className={recommdClass ? "row__cards" : "row__cards row__cards-recomm"}>
                            {shownRecommendations.sort(function (a, b) {
                                if (a.order < b.order) {
                                    return 1;
                                }
                                if (a.order > b.order) {
                                    return -1;
                                }
                                return 0;
                            }).map((item) => <Cards recommdClass={recommdClass}
                                                                       recommendationsModalOnclick={recommendationsModalOnclick}
                                                                       key={"advert-" + item.id} type="advert"
                                                                       info={item}
                                                                       layout={"layout_colored"}
                                                                       screen_size={screen_size}/>)}
                            {shownRecommendations.length > 8 ?
                                <Cards recommendationsOnclick={recommendationsOnclick} recommdClass={recommdClass}
                                       type="newAdvert" info={infoNewAdvert} screen_size={screen_size}/> : null}
                        </div>
                    </div>
                </div> : null

        ];


        return <div className="page" style={{paddingBottom: "20px"}}>
            {screen_size === "xs" ? <div className="policies__tabs">
                <Tabs typeSwitcher={"scrollContent"} styleSwitcher={"iconed"} items={this.mobileTabs}
                      style={this.tabStyles} screen_size={screen_size}>
                    {content}
                </Tabs>
            </div> : content}
            {children}
        </div>
    }
}

